import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, ICalendarDatePatientSetRQ, CalendarDatePatientSetRQ, EntityRQ } from 'src/app/core';
import { BackOfficeCalendarDatePatientViewCustom } from 'src/app/core/models/BackOfficeCalendarDatePatientView-custom.model';
import { DateService } from 'src/app/core/services/date.service';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-calendar-date-patient-detail',
  templateUrl: './calendar_date_patient-detail.component.html',
  styleUrls: ['./calendar_date_patient-detail.component.css']
})
export class CalendarDatePatientDetailComponent implements OnInit {
  id?: string;
  idPatient?: string;
  calendarDatePatientDetail?: BackOfficeCalendarDatePatientViewCustom;

  loading: boolean = true;

  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private editService: EditServiceService,
    private dateService: DateService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString(); 

    this.getCalendarDatePatientDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigate(["/patients/detail", this.idPatient]);
  }

  EditCalendarDatePatient() {
    this.router.navigate(['/calendar-date-patient/edit', this.id]);
  }

  getCalendarDatePatientDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.calendarDatePatientGet(data).subscribe(
      response =>{
        this.calendarDatePatientDetail = BackOfficeCalendarDatePatientViewCustom.fromJS(response, (key)=> this.getTranslation(key));;
        this.idPatient = response.idPatient;

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }


  DeleteCalendarDatePatient(){

    this.modalService.setModalData({ 
      title: (this.calendarDatePatientDetail?.dateDeleted) ? "CALENDAR_ORDER_RESTORE_MODAL_TITLE" : "CALENDAR_ORDER_DELETE_MODAL_TITLE",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.calendarDatePatientDetail?.dateDeleted ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.calendarDatePatientDetail?.dateDeleted ? 
          this.getTranslation('CALENDAR_ORDER_RESTORE_MODAL_BTN') : this.getTranslation('CALENDAR_ORDER_DELETE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 

        var dateDeleted = undefined;
        if (this.calendarDatePatientDetail?.dateDeleted == undefined){
          dateDeleted = new Date()
        }
        
        let input: ICalendarDatePatientSetRQ;
        input = {
          idCalendarDatePatient: this.calendarDatePatientDetail?.idCalendarDatePatient,
          idCalendarDate: this.calendarDatePatientDetail?.idCalendarDate,
          idPatient: this.calendarDatePatientDetail?.idPatient,
          deliveryDate: this.calendarDatePatientDetail?.deliveryDate,
          pickingDate: this.calendarDatePatientDetail?.pickingDate,
          dueDate: this.calendarDatePatientDetail?.dueDate,
          dateDeleted: dateDeleted,
        }
        this.clientService.calendarDatePatientSet(new CalendarDatePatientSetRQ(input)).subscribe(
          response =>{
            if(response.success){
              this.ShowSuccessModal();
            }
          },
          error=> console.log(error)
        );
      }
     })
     
    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.calendarDatePatientDetail?.dateDeleted) ? "CALENDAR_ORDER_RESTORED_SUCCESSFULLY" : "CALENDAR_ORDER_DELETED_SUCCESSFULLY",
      actionToDo: () => {
        this.getCalendarDatePatientDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }
}
