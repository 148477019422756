import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, ILegalTextGetRQ, LegalTextGetRQ, LegalTextTypeEnum } from 'src/app/core/services';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class FaqModalComponent implements OnInit {

  isMainSection: boolean = true;
  legalType: string = "";
  legalText: string = "";
  
  constructor( private translate : TranslateService,
    private fb: FormBuilder,
    private clientService: ClientService,
    private cookieService: CookieService,
    private utilsService: UtilsService,
    private ngxModal : NgxSmartModalService) { }

  ngOnInit(): void {
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  openNurseFAQ(){
    window.open(this.utilsService.getBeforeParametersByKey("web_backoffice_faq_nurse_link").parameterValue, "_blank");
  }

  openPatientFAQ(){
    window.open(this.utilsService.getBeforeParametersByKey("web_backoffice_faq_patient_link").parameterValue, "_blank");
  }

  openNephrologistFAQ(){
    window.open(this.utilsService.getBeforeParametersByKey("web_backoffice_faq_nephrologist_link").parameterValue, "_blank");
  }

  openLegalText(type: any){
    this.legalType = type;
    this.isMainSection = false;

    let legalBody : ILegalTextGetRQ = {
      legalTextType: type, 
      langCode: this.cookieService.get('defaultLang'), 
      countryIsoCode: this.cookieService.get('defaultCountry')
    }
    this.clientService.getLegalTextFromLangCode(new LegalTextGetRQ(legalBody)).subscribe(
      terms=>{
        this.legalText = terms.resourceValue!
      }
    )
    
  }
}