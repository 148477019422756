<!-- Modal -->
<div class="modal fade" id="genericModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true" [attr.data-backdrop]="'static'" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="spinner-box" *ngIf="loading; else modalBody"><spinner></spinner></div>
    <ng-template #modalBody>
      <div [ngSwitch]="modalConf.type">
        <div *ngSwitchDefault>default</div>
      </div>
    </ng-template>
  </div>
</div>
