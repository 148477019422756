<ngx-smart-modal #faqModal identifier="faqModal" [style.height.px]="'900'" [style.width.px]="'800'" data-backdrop="static" id="faqModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="modal-header">
        <div *ngIf="isMainSection">
            <h4 class="edit-title mb-3 mt-1"> {{'FAQ_MODAL_TITLE' | translate }}</h4>
            <h6 class="info-text mt-2">{{'FAQ_MODAL_DESC' | translate }}</h6>
        </div>

        <a *ngIf="!isMainSection" (click)="this.isMainSection = true; this.legalText = ''"><i class="fas fa-chevron-left"></i><u class="edit_link"> {{ 'LABEL_BACK' | translate }} </u></a>
        
        <app-round-button [type]="'CLOSE'" (onClick)="this.isMainSection = true; this.legalText = ''; faqModal.close()"></app-round-button>
  </div>
  <div class="modal-body">
    
    <!--MAIN SECTION-->
    <div *ngIf="isMainSection">

        <!--Nurse Web App-->
        <b class="text-right"><i class="fas fa-user-nurse"></i> {{'LABEL_NURSEAPP_WEB' | translate }} </b>
        <br/>

        <u style="padding-left:10px" class="info-text mt-2 edit_link" (click)="openNurseFAQ()">{{'LABEL_OPEN_FAQ' | translate }} <i class="fas fa-external-link-alt"></i></u>
        <br/><br/>

        <!--Nurse Mobile App-->
        <b class="text-right"><i class="fas fa-user-nurse"></i> {{'LABEL_NURSEAPP_MOBILE' | translate }} </b>
        <br/>

        <u style="padding-left:10px" class="info-text mt-2 edit_link" (click)="openNurseFAQ()">{{'LABEL_OPEN_FAQ' | translate }} <i class="fas fa-external-link-alt"></i></u>

        <div style="padding-left:10px">            
            <br/>
            <u (click)="openLegalText('TermsAndCondition_NURSE')" class="info-text mt-2 edit_link">{{'LABEL_TERMS_AND_CONDITION' | translate}}</u>
            <br/>
            <u (click)="openLegalText('PrivacyPolicy_NURSE')" class="info-text mt-2 edit_link">{{'LABEL_PRIVACY_POLICY' | translate}}</u>
        </div>

        <br/><br/>

        <!--Patient App-->
        <b class="text-right"><i class="fas fa-user-injured"></i> {{'LABEL_PATIENTAPP' | translate }} </b>
        <br/>

        <div style="padding-left:10px">

            <u class="info-text mt-2 edit_link" (click)="openPatientFAQ()">{{'LABEL_OPEN_FAQ' | translate }} <i class="fas fa-external-link-alt"></i></u>
            <br/><br/>
            <u (click)="openLegalText('TermsAndCondition_PATIENT')" class="info-text mt-2 edit_link">{{'LABEL_TERMS_AND_CONDITION' | translate}}</u>
            <br/>
            <u (click)="openLegalText('PrivacyPolicy_PATIENT')" class="info-text mt-2 edit_link">{{'LABEL_PRIVACY_POLICY' | translate}}</u>
        </div>

        <br/><br/>

        <!--Nephrologist-->
        <b class="text-right"><i class="fas fa-user-md"></i> {{'LABEL_NEPHROLOGIST' | translate}}</b>
        <br/>

        <div style="padding-left:10px">

            <u class="info-text mt-2 edit_link" (click)="openNephrologistFAQ()">{{'LABEL_OPEN_FAQ' | translate }} <i class="fas fa-external-link-alt"></i></u>
            <br/><br/>
            <u (click)="openLegalText('PrivacyPolicy_NEPHROLOGIST')" class="info-text mt-2 edit_link">{{'LABEL_PRIVACY_POLICY' | translate}}</u>
        </div>

        <br/>

    </div>

    <div *ngIf="!isMainSection">

        <!--Nurse App-->
        <b *ngIf="legalType == 'TermsAndCondition_NURSE' || legalType == 'PrivacyPolicy_NURSE'" class="text-right"><i class="fas fa-user-nurse"></i> {{'LABEL_NURSEAPP' | translate }}</b>

        <!--Patient App-->
        <b *ngIf="legalType == 'TermsAndCondition_PATIENT' || legalType == 'PrivacyPolicy_PATIENT'" class="text-right"><i class="fas fa-user-injured"></i> {{'LABEL_PATIENTAPP' | translate }}</b>

        <b *ngIf="legalType == 'PrivacyPolicy_NEPHROLOGIST'" class="text-right"><i class="fas fa-user-md"></i> {{'LABEL_NEPHROLOGIST' | translate }}</b>

        <br/>
        <br/>
        
        <div class="legal-text-content">
            <span [innerHtml]="legalText"></span>
        </div>
    </div>

  </div> 
</ngx-smart-modal>