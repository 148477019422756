import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tableFilter',
    pure: false
  })
  export class CustomFilterPipe implements PipeTransform {
  
    transform(value: any[], searchText: string, prop?: any): any {
      if (!value) {
        return [];
      }
      if (!searchText || !prop) {
        return value;
      }
      const _searchText = searchText.toLowerCase(),
        isArr = Array.isArray(value),
        flag = isArr && typeof value[0] === 'object' ? true : isArr && typeof value[0] !== 'object' ? false : true;
  
      return value.filter(ele => {
        let val = flag ? ele[prop] : ele;
        return val.toString().toLowerCase().includes(_searchText);
      });
  
    }
  }