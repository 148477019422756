<ng-container [ngSwitch]="rowType">
    <!-- Row + Col -->
    <ng-container *ngSwitchCase="'BOTH'">
        <div class="row" [ngClass]="rowClass ? rowClass : ''" >
            <div [ngClass]="getColumnStyle()" >
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </div>
    </ng-container>
    <!-- Only Row -->
    <ng-container *ngSwitchCase="'ROW'">
        <div class="row" [ngClass]="rowClass ? rowClass : ''">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </ng-container>
    <!-- Only Col -->
    <ng-container *ngSwitchCase="'COL'">
        <div [ngClass]="getColumnStyle()">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </ng-container>

    <!-- Template -->
    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>
</ng-container>