<div ngbDropdown class="d-inline-flex" #drop [autoClose]="'inside'">
    <button class="custom-dropdown" id="prescription-dropdown" ngbDropdownToggle (click)="changeArrow()">{{selectConfig.title}}</button>
    <div ngbDropdownMenu aria-labelledby="prescription-dropdown">
        <div *ngFor="let option of selectConfig.options; let i = index">
            <hr *ngIf="selectConfig.hasDivider && i % 2 !== 0">
            <button ngbDropdownItem (click)="sendAction(option.value)" [attr.data-toggle]="option.toggle? option.toggle : ''" [attr.data-target]="option.target? '#' + option.target : ''">
                <svg-icon [src]="option.url" *ngIf="option.url"></svg-icon> {{option.text}}
            </button>
        </div>
    </div>
    <div class="arrow"><svg-icon [src]="arrowUrl"></svg-icon></div>
</div>
