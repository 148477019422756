import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements OnInit {

  constructor() { }

  @Output() selectEmittet = new EventEmitter();
  @Input() selectConfig: any;
  arrowDown : boolean = true;
  arrowUrl : any = '../../../assets/icons/major-icon.svg'
  ngOnInit(): void {
  }




  sendAction(value: any){
    this.selectEmittet.emit(value);
  }

  changeArrow(){
    if(this.arrowDown){
      document.querySelector(".arrow")?.classList.remove('downRotate');
      document.querySelector(".arrow")?.classList.add('upRotate');
      this.arrowDown = false;
    }else{
      document.querySelector(".arrow")?.classList.remove('upRotate');
      document.querySelector(".arrow")?.classList.add('downRotate');
      this.arrowDown = true;
    }
  }
}
