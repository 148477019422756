import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService, ClientService, EntityRQ, IOrderSetWindowRQ, IPatientDeactivateRQ, IPatientHistoryGetV2RQ, IPatientNewAppInvitationRQ, OrderSetWindowRQ, PatientDeactivateRQ, PatientGetAddressRS, PatientHistoryGetV2RQ, PatientNewAppInvitationRQ, PatientRS, RegistrationCodesRS, UserIdentityRequestStatusEnum } from 'src/app/core';
import { BackOfficeSupportRequestViewCustom } from 'src/app/core/models/backOfficeSupportRequestView-custom.model';
import { Location } from '@angular/common';
import { RequestService } from 'src/app/core/services/request.service';
import { first } from 'rxjs/operators';
import { Role } from 'src/app/core/enum/roles';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { BackOfficeCalendarDatePatientViewCustom } from 'src/app/core/models/BackOfficeCalendarDatePatientView-custom.model';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent implements OnInit {
  id?: string;
  patientDetail?: any;
  reqSelected?: BackOfficeSupportRequestViewCustom;
  showSnackBar?: boolean;
  snackText?: string;
  isDemoNurse : boolean = false;
  isPatientDataManagementEnabled: boolean = false;
  isFromRequest = false

  modalSuccessTitle: any;

  loading: boolean = true;
  loadingHistory: boolean = true;

  entityVersions: any = [];
  historySyncronizations: any = [];
  activeTab = 'PRESCRIPTIONS';

  calendarManagement: string = this.utilsService.getParametersByKey("flow_trigger__calendar_modality").parameterValue!;
  isPharmacyRelatedToClinic = this.utilsService.getParametersByKey('flow_trigger__is_pharmacy_related_to_clinic').parameterValue == "True";

  btnConfigUnlock = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_UNLOCK'),
    width: '30px',
    height: '30px'
  }

  btnConfigDeactive = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('PATIENT_DETAIL_DEACTIVATEPATIENT'),
    width: '30px',
    height: '30px'
  }

  btnConfigStart = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_START_TASK'),
    width: '30px',
    height: '30px'
  }

  btnConfigResolved = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_RESOLVE'),
    width: '30px',
    height: '30px'
  }

  constructor(
    private location: Location,
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private requestService: RequestService,
    private authService: AuthenticationService,
    private utilsService: UtilsService,
  ) {}

  EditPatient(){
    this.router.navigate(['/patients/edit', this.id]);
  }

  ngOnInit() {
    this.isDemoNurse = this.route.snapshot.data.isDemo;
    this.route.queryParams.subscribe(params => {
      this.isFromRequest = params['fromRequest'] ? params['fromRequest'] == "true" : false;
    });
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getPatientDetail();
    this.isPatientDataManagementEnabled = this.authService.currentUserValue.isPatientDataManagementEnabled!;
  }

  backToList(){   
    if(this.isFromRequest){
      this.router.navigate(['/requests']);
    }
    else this.router.navigate(['/patients']);
  }

  assignCalendar(){
    if(this.patientDetail.idCalendar != undefined){
      this.router.navigate(['/patients/detail', this.id, 'assign-calendar', this.patientDetail?.idCalendar]);
    }
    else{
      this.router.navigate(['/patients/detail', this.id, 'assign-calendar']);
    }
  }

  getPatientDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.patientGet(data).subscribe(
      response =>{
        this.patientDetail = response;
        if(response.addresses?.some(x => x.deliveryCountryIsoCode == "GB"))
        {
          this.patientDetail.mobileNumber = this.utilsService.getHiddenPhoneNumber(this.patientDetail.mobileNumber)
          this.patientDetail.phoneNumber = this.utilsService.getHiddenPhoneNumber(this.patientDetail.phoneNumber)
        }
        
        this.patientDetail.activeRequests = response.activeRequests?.map(v => new BackOfficeSupportRequestViewCustom(v));
        this.patientDetail.resolvedRequests = response.resolvedRequests?.map(v => new BackOfficeSupportRequestViewCustom(v));

        if(this.patientDetail.ordersFrequencyInWeeks != undefined){
          this.patientDetail.ordersFrequencyInWeeks = this.patientDetail.ordersFrequencyInWeeks + " ";
          this.patientDetail.ordersFrequencyInWeeks += this.patientDetail.ordersFrequencyInWeeks == 1 ? 
            this.getTranslation("LABEL_WEEK") : this.getTranslation("LABEL_WEEKS");
        }

        if(response.nextOrderDates != undefined)
        {
          this.patientDetail!.nextOrderDates = BackOfficeCalendarDatePatientViewCustom.fromJS(response.nextOrderDates!, (key)=> this.getTranslation(key));
        }

        if(this.authService.currentUserValue.countryIsoCode == null)
        {
          this.utilsService.getParameterByCountry("flow_trigger__calendar_modality", this.patientDetail.addresses[0]?.deliveryCountryIsoCode ?? 'DE').pipe().subscribe(ret => {
            this.calendarManagement = ret;
          });
        }

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getListStatus(request: BackOfficeSupportRequestViewCustom){
    this.clientService.requestStatusGetAll(request.idUserIdentityRequest, null, "dateCreated_desc").subscribe(
      response =>{
        request.listStatus = response.list?.map(v => new BackOfficeSupportRequestViewCustom(v));
        request.listStatus.forEach((element: any) => {
          element.comment = element.comment != "" ? '"' + element.comment + '"' : "------";
          element.fullName = element.fullName != null ? element.fullName : "OMG"
        });

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

  deactivatePatient(req = null){
    this.openModal(req, "PATIENT_DETAIL_DEACTIVATE_MODAL_TITLE", "PATIENT_DETAIL_DEACTIVATE_MODAL_DESC", "PATIENT_DETAIL_DEACTIVATE_MODAL_BTN", "deactivate", true);
  }

  unlock(req: any){
    this.openModal(req, "REQUEST_LIST_UNLOCK_MODAL_TITLE", "REQUEST_LIST_UNLOCK_MODAL_DESC", "REQUEST_LIST_UNLOCK_MODAL_BTN", "unlock");
  }

  startTask(req: any){
    this.openModal(req, "REQUEST_LIST_START_MODAL_TITLE", "REQUEST_LIST_START_MODAL_DESC", "REQUEST_LIST_START_MODAL_BTN", "start");
  }

  resolved(req: any){
    this.openModal(req, "REQUEST_LIST_RESOLVE_MODAL_TITLE", "REQUEST_LIST_RESOLVE_MODAL_DESC", "REQUEST_LIST_RESOLVE_MODAL_BTN", "resolved");
  }

  addComment(req: any){
    this.openModal(req, "", "", "BTN_COMMON_SAVE", "addComment");
  }

  cancelRequest(req: any){
    this.openModal(req, "REQUEST_CANCEL_MODAL_TITLE", "REQUEST_CANCEL_MODAL_DESC", "REQUEST_CANCEL_MODAL_BTN", "cancelled", true);
  }

  reopenRequest(req: any){
    this.openModal(req, "REQUEST_REOPEN_MODAL_TITLE", "REQUEST_REOPEN_MODAL_DESC", "REQUEST_REOPEN_MODAL_BTN", "reopen");
  }

  requestModalBtnConfig = {
    type: 'button',
    bclass : 'btn btn-primary add-patient-button',
    text : "",
    action: "",
    width: '30px',
    height: '30px'
  }
  
  openModal(req: any, title: string, desc: string, btnConfirm: string, event: string, isDanger: boolean = false){
    this.reqSelected = req;
    this.requestModalBtnConfig = {
      type: 'button',
      bclass : isDanger ? 'btn btn-primary deactive-button' : 'btn btn-primary add-patient-button',
      text : this.getTranslation(btnConfirm),
      action: event,
      width: '30px',
      height: '30px'
    };

    this.modalService.setModalData({ 
      title: title,
      description: desc,
      commentVisible: req != null,
      actionBtnConfig: this.requestModalBtnConfig,
      actionToDo: (comment: any) => 
      {
        if(event == "deactivate"){
          let input: IPatientDeactivateRQ = {
            idPatient: this.id,
            idUserIdentityRequest: this.reqSelected?.idUserIdentityRequest,
            keyStatus: UserIdentityRequestStatusEnum.Resolved, 
            comment: comment
          }
          this.clientService.patientDeactivate(new PatientDeactivateRQ(input)).subscribe(
            response =>{
              this.ShowSuccessModal("PATIENT_DETAIL_DEACTIVATE_MODAL_SUCCESS");
            },
            error=> console.log(error)
          );
        }
        if(event == "unlock" && this.reqSelected != null){
          this.requestService.approveRequest(this.reqSelected.idUserIdentityRequest!, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_LIST_UNLOCK_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "start" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.InProgress, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_LIST_START_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "resolved" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.Resolved, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_LIST_RESOLVE_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "addComment" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.Commented, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.getListStatus(this.reqSelected!);
              }
            }
          )
        }
        if(event == "cancelled" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.Cancelled, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_CANCEL_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "reopen" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.InProgress, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_REOPEN_MODAL_SUCCESS");
              }
            }
          )
        }
      }
    });

    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(title: string){
    this.modalService.setModalData({ 
      title: title,
      actionToDo: () => {
        this.getPatientDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

  openComments(req: any){
    this.getListStatus(req);
    req.isExpanded = !req.isExpanded;
  }

  showPlanningCodeMode(){    
    this.ngxModal.getModal('planRegistrationCodeModal').open();
  }

  sendCode(event: any = null){
    this.loading = true;
    let input: IPatientNewAppInvitationRQ = {
      idPatient: this.id,
      datePlanned: event?.datePlanned
    }
    this.clientService.patientNewAppInvitation(new PatientNewAppInvitationRQ(input)).subscribe(
      response =>{
        this.loading = false;

        this.getPatientDetail();

        this.snackText = event == undefined ? this.getTranslation("NURSE_DETAIL_CODE_SENT") : this.getTranslation("NURSE_DETAIL_CODE_PLANNED");

        this.ShowSnackBar();
      } ,
      error=> console.log(error)
    );
  }

  GoToPrescriptions() {
    this.router.navigate(['/prescriptions/', this.id]);
  }

  GoToOrders() {
    this.router.navigate(['/orders/', this.id]);
  }

  planNextOrder(event: any){
    this.loading = true;

    let input : IOrderSetWindowRQ = {      
      idOrder: this.patientDetail?.idOrder,
      dateDue: event.dateDue,
      dateDeliveryDesired: event.dateDeliveryDesired      
    }

    this.clientService.orderSetWindow(new OrderSetWindowRQ(input)).subscribe(
      response =>{
        this.loading = false;

        this.getPatientDetail();
      },
      error=> console.log(error)
    );
  }

  EditOrderWindow(){
    this.ngxModal.getModal('planNextOrderModal').open();
  }

  ShowOrderCatalog(){
    this.ngxModal.getModal('orderCatalogModal').setData({
      idOrder: this.patientDetail?.idOrder,
      dateDeliveryDesired: this.patientDetail?.orderDateDeliveryDesired
    }).open();
  }

  ShowPatientListing(){
    this.ngxModal.getModal('patientListingModal').setData({
      idPatient: this.patientDetail?.idPatient,
    }).open();
  }

  showOrderCalendar(){
    this.ngxModal.getModal('patientOrderCalendar').setData({ idPatient: this.patientDetail?.idPatient }).open();
  }

  EditDeliveryFee(address: PatientGetAddressRS){
    this.ngxModal.getModal('patientDeliveryFeeModal').setData({
      idPatientAddress: address.idPatientAddress,
      deliveryFee: address.deliveryFee,
      logisticCompanyInstructions: address.logisticCompanyInstructions,
    }, true).open();
  }

  getPatientHistory(event: any){
    if (event?.nextId == "ENTITY_VERSIONS"){
      this.getPatientEntityVersion();
    }
    else if (event?.nextId == "CMS_SYNCRONIZATIONS"){
      this.getPatientHistorySynchronization();
    }
  }

  //ENTITY VERSION

  currentEntityVersionPage: number = 1;
  totalItemsEntityVersion: number = 0;
  
  tableEntityVersionConfig =  {
    actions: true,
    gridData: this.entityVersions,
    pagination: {
      id: "tableEntityVersion",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsEntityVersion
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  pageEntityVersionChanged(pageNumber: any){
    this.currentEntityVersionPage = Number.parseInt(pageNumber);
    this.getPatientEntityVersion();
  }

  getPatientEntityVersion() {
    this.loadingHistory = true;

    let input: IPatientHistoryGetV2RQ = {
      idPatient: this.id,
      pageIndex: this.currentEntityVersionPage
    }
    this.clientService.patientEntityVersionGetAll(new PatientHistoryGetV2RQ(input)).subscribe(
      response =>{
        let versionsList: any[] = [];
        for(let entity of response.list!){
          var jsonObj = JSON.parse(entity.jsonData || "");
          const jsonObj1 = Object.assign({VersionDate: entity.versionDate?.toISOString(), MethodName: entity.methodName}, jsonObj);
          versionsList.push(jsonObj1);
        }
        this.entityVersions = versionsList;
        this.tableEntityVersionConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableEntityVersionConfig.pagination.totalItems = response.count || 0;
        this.tableEntityVersionConfig.pagination.currentPage = this.currentEntityVersionPage;
        this.tableEntityVersionConfig.gridData = this.entityVersions;
        
        this.loadingHistory = false;
      } ,
      error=> console.log(error)
    );
  }

  //CMS SYNCHRONIZATIONS

  currentHistorySynchronizationPage: number = 1;
  totalItemsHistorySynchronization: number = 0;
  
  tableHistorySynchronizationConfig =  {
    actions: true,
    gridData: this.historySyncronizations,
    pagination: {
      id: "tableHistorySynchronization",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsHistorySynchronization
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  pageHistorySynchronizationChanged(pageNumber: any){
    this.currentHistorySynchronizationPage = Number.parseInt(pageNumber);
    this.getPatientHistorySynchronization();
  }

  getPatientHistorySynchronization() {
    this.loadingHistory = true;

    let input: IPatientHistoryGetV2RQ = {
      idPatient: this.id,
      pageIndex: this.currentHistorySynchronizationPage
    }
    this.clientService.patientHistorySynchronizationGetAll(new PatientHistoryGetV2RQ(input)).subscribe(
      response =>{
        this.historySyncronizations = response.list || [];
        this.tableHistorySynchronizationConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableHistorySynchronizationConfig.pagination.totalItems = response.count || 0;
        this.tableHistorySynchronizationConfig.pagination.currentPage = this.currentHistorySynchronizationPage;
        this.tableHistorySynchronizationConfig.gridData = this.historySyncronizations;
        
        this.loadingHistory = false;
      } ,
      error=> console.log(error)
    );
  }
}

