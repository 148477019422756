import { IBackOfficeCalendarView } from "../services";
import { getDayNameFromDayNumber, getDaysBeforeDeliveryLabel } from "../utils/calendar-utils";

export class BackOfficeCalendarViewCustom implements IBackOfficeCalendarView {
    idCalendar?: string;
    baseYear?: number;
    baseWeek?: number;
    baseWeekStartDay?: Date;
    baseWeekEndDay?: Date;
    deliveryDateWeekDay?: number;
    deliveryDateFrequency?: number;
    calendarDescription?: string | undefined;
    pickingWeekDay?: number;
    pickingWeek?: number;
    dueDateWeekDay?: number;
    dueDateWeek?: number;
    countryIsoCode?: string | undefined;
    dateDeleted?: Date | undefined;
    reviewNeeded?: boolean | undefined;

    frequencyLabel?: string

    dueDateLabel?: string //LIST
    dueDateWeekDayLabel?: string
    dueDateWeekLabel?: string

    pickingLabel?: string  //LIST
    pickingWeekDayLabel?: string      
    pickingWeekLabel?: string
    
    deliveryDateLabel?: string
    isDeleted?: boolean;
    calendarName?: string | undefined;
    deliveryWeekLabel?: string | undefined;
    isSelected?: boolean = false;

    constructor(data?: IBackOfficeCalendarView) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.idCalendar = _data["idCalendar"];
            this.baseYear = _data["baseYear"];
            this.baseWeek = _data["baseWeek"];
            this.baseWeekStartDay = _data["baseWeekStartDay"] ? new Date(_data["baseWeekStartDay"].toString()) : <any>undefined;
            this.baseWeekEndDay = _data["baseWeekEndDay"] ? new Date(_data["baseWeekEndDay"].toString()) : <any>undefined;
            this.deliveryDateWeekDay = _data["deliveryDateWeekDay"];
            this.deliveryDateFrequency = _data["deliveryDateFrequency"];
            this.calendarDescription = _data["calendarDescription"];
            this.pickingWeekDay = _data["pickingWeekDay"];
            this.pickingWeek = _data["pickingWeek"];
            this.dueDateWeekDay = _data["dueDateWeekDay"];
            this.dueDateWeek = _data["dueDateWeek"];
            this.countryIsoCode = _data["countryIsoCode"];
            this.dateDeleted = _data["dateDeleted"] ? new Date(_data["dateDeleted"].toString()) : <any>undefined;
            this.reviewNeeded = _data["reviewNeeded"];
        }
    }

    static fromJS(data: IBackOfficeCalendarView, translate: (key: string) => string){
        const custom = new BackOfficeCalendarViewCustom();

        // Assign base values
        for (var property in data) {
            if (data.hasOwnProperty(property))
                (<any>custom)[property] = (<any>data)[property];
        }
        custom.frequencyLabel = `${data.deliveryDateFrequency?.toString()} ${data.deliveryDateFrequency === 1  ? translate('LABEL_WEEK') : translate('LABEL_WEEKS')}`
        
        custom.pickingLabel = `${translate(getDayNameFromDayNumber(data?.pickingWeekDay ?? 100))}, <br/> ${translate(getDaysBeforeDeliveryLabel(data?.pickingWeek ?? 100))}`
        custom.pickingWeekLabel =  `${translate(getDaysBeforeDeliveryLabel(data?.pickingWeek ?? 100))}`;
        custom.pickingWeekDayLabel = `${translate(getDayNameFromDayNumber(data?.pickingWeekDay ?? 100))}`;

        custom.dueDateLabel = `${translate(getDayNameFromDayNumber(data?.dueDateWeekDay ?? 100))}, <br/> ${translate(getDaysBeforeDeliveryLabel(data?.dueDateWeek ?? 100))}`
        custom.dueDateWeekLabel = `${translate(getDaysBeforeDeliveryLabel(data?.dueDateWeek ?? 100))}`;
        custom.dueDateWeekDayLabel = `${translate(getDayNameFromDayNumber(data?.dueDateWeekDay ?? 100))}`

        custom.deliveryDateLabel = `${translate(getDayNameFromDayNumber(data?.deliveryDateWeekDay ?? 100))}`
        custom.isDeleted = data.dateDeleted !== null && data.dateDeleted !== undefined
        
        custom.deliveryWeekLabel = this.StringFormat(translate("CALENDAR_BASE_WEEK_FORMAT"), data?.baseWeek!.toString(), 
                                                data?.baseWeekStartDay!.toLocaleDateString(),
                                                data?.baseWeekEndDay!.toLocaleDateString());
        
        return custom;       
    }

    static StringFormat = (str: string, ...args: string[]) =>
        str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["idCalendar"] = this.idCalendar;
        data["baseYear"] = this.baseYear;
        data["baseWeek"] = this.baseWeek;
        data["baseWeekStartDay"] = this.baseWeekStartDay ? this.baseWeekStartDay.toISOString() : <any>undefined;
        data["baseWeekEndDay"] = this.baseWeekEndDay ? this.baseWeekEndDay.toISOString() : <any>undefined;
        data["deliveryDateWeekDay"] = this.deliveryDateWeekDay;
        data["deliveryDateFrequency"] = this.deliveryDateFrequency;
        data["calendarDescription"] = this.calendarDescription;
        data["pickingWeekDay"] = this.pickingWeekDay;
        data["pickingWeek"] = this.pickingWeek;
        data["dueDateWeekDay"] = this.dueDateWeekDay;
        data["dueDateWeek"] = this.dueDateWeek;
        data["countryIsoCode"] = this.countryIsoCode;
        data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
        data["reviewNeeded"] = this.reviewNeeded;
        return data;
    }
}