<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'COUNTRY_LIST_TITLE' | translate}}</h2>
            </div>
            <div *appHasRole="['SUPER_ADMIN']" class="col-6">
                <app-button (click)="countryAdd()" [buttonConfig]="btnConfig" ></app-button>
            </div>
        </div>

        <div *ngIf="anyResult else noResultContent">
        <div class="row mt-2">
            <div class="col-lg-4 col-md-auto">
                <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)" ></app-search-field>
            </div>
        </div>
        <div class="col-12 text-right">
            <div>
                <a (click)="this.isClosedReportPopup = !this.isClosedReportPopup"><u class="edit_link">Download country feature report</u></a>
            </div>
            <div class="language-drp" [ngClass]="isClosedReportPopup ? 'closed' : 'open'" *ngIf="reportModalities.length > 0">
                <div class="drp-content edit_link">
                    <app-text *ngFor="let item of reportModalities" [text]="item" extraClass="p-2 c-pointer" [fontSizePx]="16" [isBold]="true" (onTextClick)="countryFeatureReportDownload(item)" [avoidTextSelection]="true" ></app-text>
                </div>
            </div>
        </div>
        <app-table [tableConfig]= "tableConfig" (orderChanged)="orderCountryChanged($event)" (onClick)="GoToCountryDetail($event)" (pageChanged)="pageCountryChanged($event)"></app-table>
        </div>
        <ng-template #noResultContent>
            <div class="text-center noResult">
                <svg-icon class="icon" src="../../../../../assets/icons/demouser-figure.svg"></svg-icon>
                <p class="alert">{{ 'COUNTRY_NORESULT' | translate }}</p>
                <p class="desc">{{ 'COUNTRY_NORESULT_DESC' | translate }}</p>
            </div>
        </ng-template>
</ng-template>
</div>
