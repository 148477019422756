<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'SIDEBAR_RELEASE_NOTES' | translate}}</h2>
            </div>
            <div class="col-6">
                <app-button (click)="releaseNoteAdd()" [buttonConfig]="btnConfig"></app-button>
            </div>
        </div>

        <div *ngIf="anyResult else noResultContent">
            <br/>
            <app-table [tableConfig]="tableConfig" (orderChanged)="orderReleaseNoteChanged($event)"
                (onClick)="GoToReleaseNoteDetail($event)" (pageChanged)="pageReleaseNoteChanged($event)"></app-table>
        </div>

        <ng-template #noResultContent>
            <div class="text-center noResult">
                <p class="alert">{{ 'RELEASE_NOTE_NORESULT' | translate }}</p>
            </div>
        </ng-template>
    </ng-template>
</div>