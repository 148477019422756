import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService, ClinicGetSettingsRS, ClinicSetSettingsRQ, EntityRQ, IClinicSetSettingsRQ, SecuritySettings } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-clinic-security',
  templateUrl: './clinic-security.component.html',
  styleUrls: ['./clinic-security.component.css']
})
export class ClinicSecurityComponent implements OnInit {
  id?: string;
  clinicSettings?: ClinicGetSettingsRS;
  defaultSettings?: SecuritySettings;

  loading: boolean = true;

  isSameSettings: boolean = true;

  isWebApp: boolean = false;
  isMobileApp: boolean = false;

  isTrustedMachine?: boolean;
  isTrustedDescVisible?: boolean = false;
  isOTP?: boolean;
  isOTPDescVisible?: boolean = false;

  showSnackBar?: boolean;
  snackText: string = this.getTranslation("DEFAULT_SETTINGS_CONFIRM");

  saveBtnConfig = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_KEEP_SETTINGS'),
    disabled : true
  }

  countryBasedTranslation: any;

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private editService: EditServiceService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    this.getClinicDetail();
    this.loading = false;
  }

  formSecurityAdd: FormGroup = this.fb.group({
    passwordType: [null, Validators.required],
    passwordDuration: [null, Validators.required],
    logoutType: [null, Validators.required]
  });

  formSecurityInfo={
    key: 'formSecurityInfo',
    radioButtonIsTrusted:{      
      id: 'isTrusted',
      name: 'isTrusted',
      class : 'more-padding',
      labels: [
        this.getTranslation('CLINIC_SECURITY_TRUSTED'),
      ],
      values: ['TRUSTED_MACHINE'],
      selected : '',
      divider: [false],
      disabled: true //UNTIL PASSWORD FLOW FUNCTIONALITY WILL BE DEVELOPED
    },
    radioButtonIsOTP:{      
      id: 'isOTP',
      name: 'isOTP',
      class : 'more-padding',
      labels: [
        this.getTranslation('CLINIC_SECURITY_OTP'),
      ],
      values: ['OTP_VIA_SMS'],
      selected : '',
      divider: [false],
      disabled: true //UNTIL OTP FUNCTIONALITY WILL BE DEVELOPED
    },
    radioButtonPassword:{      
      id: 'passwordType',
      name: 'passwordType',
      class : 'more-padding',
      labels: [
        this.getTranslation('CLINIC_SECURITY_PASSWORD_LOW'),
        this.getTranslation('CLINIC_SECURITY_PASSWORD_MIDDLE'),
        this.getTranslation('CLINIC_SECURITY_PASSWORD_HIGH'),
        this.getTranslation('CLINIC_SECURITY_PASSWORD_VERY_HIGH')
      ],
      descriptions: [
        this.getTranslation('CLINIC_SECURITY_PASSWORD_LOW_DESC'),
        this.getTranslation('CLINIC_SECURITY_PASSWORD_MIDDLE_DESC'),
        this.getTranslation('CLINIC_SECURITY_PASSWORD_HIGH_DESC'),
        this.getTranslation('CLINIC_SECURITY_PASSWORD_VERY_HIGH_DESC')
      ],
      values: ['LOW','MEDIUM','HIGH','VERY_HIGH'],
      selected : '',
      divider: [false,false,false],
    },
    radioButtonDuration:{      
      id: 'passwordDuration',
      name: 'passwordDuration',
      class : 'more-padding',
      labels: [
        this.getTranslation('CLINIC_SECURITY_DURATION_90_DAYS'),
        this.getTranslation('CLINIC_SECURITY_DURATION_180_DAYS'),
        this.getTranslation('CLINIC_SECURITY_DURATION_0_DAYS'),
      ],
      values: [90,180,0],
      selected : 180,
      divider: [false,false,false],
    },
    radioButtonLogout: {
      id: 'logoutType',
      name: 'logoutType',
      class : 'more-padding',
      labels: [
        this.getTranslation('CLINIC_SECURITY_LOGOUT_5_MINUTES'),
        this.getTranslation('CLINIC_SECURITY_LOGOUT_10_MINUTES'),
        this.getTranslation('CLINIC_SECURITY_LOGOUT_15_MINUTES'),
        this.getTranslation('CLINIC_SECURITY_LOGOUT_30_MINUTES')
      ],
      values: [5,10,15,30],
      selected : 10,
      divider: [false,false,false,false]
    },
    toggleButtonConfig: {
      value: false,
      disabled: false
    }
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.saveBtnConfig.disabled = !(form.valid && (this.isWebApp || this.isMobileApp))
      });
    this.saveBtnConfig.disabled = !(form.valid && (this.isWebApp || this.isMobileApp))
  }

  inputValues(event: any){
    
    if(event.target.name === 'isTrusted'){
      this.formSecurityInfo.radioButtonIsTrusted.selected = event.target.value;
      this.formSecurityInfo.radioButtonIsOTP.selected = '';
      this.formSecurityAdd.controls[event.target.name].setValue(event.target.value);
    }
    else if(event.target.name === 'isOTP'){
      this.formSecurityInfo.radioButtonIsOTP.selected = event.target.value;
      this.formSecurityInfo.radioButtonIsTrusted.selected = '';
      this.formSecurityAdd.controls[event.target.name].setValue(event.target.value);
    }
    else if(event.target.name === 'passwordType'){
      this.formSecurityInfo.radioButtonPassword.selected = event.target.value;
      this.formSecurityAdd.controls[event.target.name].setValue(event.target.value);
    }
    else if(event.target.name === 'passwordDuration'){
      this.formSecurityInfo.radioButtonDuration.selected = event.target.value;
      this.formSecurityAdd.controls[event.target.name].setValue(event.target.value);
    }
    else if(event.target.name === 'logoutType'){
      this.formSecurityInfo.radioButtonLogout.selected = event.target.value;
      this.formSecurityAdd.controls[event.target.name].setValue(event.target.value);
    }

    this.checkDefaultSettings();
    
    this.formValidation(this.formSecurityAdd);
  }

  checkWebApp(){
    this.isWebApp = !this.isWebApp;
    this.formValidation(this.formSecurityAdd);
  }

  checkMobileApp(){
    this.isMobileApp = !this.isMobileApp;
    this.formValidation(this.formSecurityAdd);
  }

  getClinicDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.clinicGetSettings(data).subscribe(
      response =>{
        this.clinicSettings = response;
        this.defaultSettings = response.defaultSettings;

        this.countryBasedTranslation = this.getTranslation('CLINIC_SECURITY_SIGNATURE_REQUIRED_V2');
        this.countryBasedTranslation = this.countryBasedTranslation.replace("{0}", response.country);

        this.loading = false;

        this.isWebApp = this.clinicSettings!.currentSettings?.isNurseWebAppEnabled!;
        this.isMobileApp = this.clinicSettings!.currentSettings?.isNurseAppEnabled!

        this.formSecurityAdd.patchValue({
          passwordType: this.clinicSettings.currentSettings?.passwordType,
          passwordDuration: this.clinicSettings.currentSettings?.passwordDuration,
          logoutType: this.clinicSettings.currentSettings?.sessionDuration
        });

        this.formSecurityInfo!.radioButtonIsTrusted!.selected = this.clinicSettings.currentSettings?.secondFactorType!;
        this.formSecurityInfo!.radioButtonIsTrusted = {...this.formSecurityInfo!.radioButtonIsTrusted}

        this.formSecurityInfo!.radioButtonIsOTP.selected = this.clinicSettings.currentSettings?.secondFactorType!;
        this.formSecurityInfo!.radioButtonIsOTP = {...this.formSecurityInfo!.radioButtonIsOTP}

        this.formSecurityInfo!.radioButtonPassword.selected = this.clinicSettings.currentSettings?.passwordType!;
        this.formSecurityInfo!.radioButtonPassword = {...this.formSecurityInfo!.radioButtonPassword}

        this.formSecurityInfo!.radioButtonDuration.selected = this.clinicSettings.currentSettings?.passwordDuration!;
        this.formSecurityInfo!.radioButtonDuration = {...this.formSecurityInfo!.radioButtonDuration}

        this.formSecurityInfo!.radioButtonLogout.selected = this.clinicSettings.currentSettings?.sessionDuration!;
        this.formSecurityInfo!.radioButtonLogout = {...this.formSecurityInfo!.radioButtonLogout}

        this.formSecurityInfo.toggleButtonConfig.value = this.clinicSettings.currentSettings?.isSignatureNeeded!;
        this.formSecurityInfo.toggleButtonConfig.disabled = !this.clinicSettings.isSignatureNeededEnabled!;

        this.checkDefaultSettings();

        this.formValidation(this.formSecurityAdd);
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  saveClinic(){
    if (!this.saveBtnConfig.disabled) {

      this.loading = true;
      let input: IClinicSetSettingsRQ;
      input = {
        idClinic: this.id,
        isNurseAppEnabled: this.isMobileApp,
        isNurseWebAppEnabled: this.isWebApp,
        secondFactorType: this.formSecurityInfo!.radioButtonIsTrusted!.selected ? "TRUSTED_MACHINE" : "OTP_VIA_SMS",
        isSignatureNeeded: this.formSecurityInfo.toggleButtonConfig.value,
        sessionDuration: this.formSecurityAdd.get('logoutType')?.value,
        passwordType: this.formSecurityAdd.get('passwordType')?.value,
        passwordDuration: this.formSecurityAdd.get('passwordDuration')?.value,
      }
      this.clientService.clinicSetSettings(new ClinicSetSettingsRQ(input)).subscribe(
        response =>{

          this.loading = false;

          if(response){
            this.ShowSnackBar();
          }
        },
        error=> {
          this.loading = false;
        }
      )
    }
  }

  ShowSnackBar() {

    if(!this.isSameSettings){
      this.snackText = this.getTranslation("CUSTOM_SETTINGS_SAVED");
    }
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
      this.router.navigate(['/clinics/detail', this.id]);
    }, 5000);
  }

  checkDefaultSettings(){

    var newSettings = Object.assign(new SecuritySettings(), {
      isNurseAppEnabled: this.isMobileApp,
      isNurseWebAppEnabled: this.isWebApp,
      isSignatureNeeded: this.formSecurityInfo.toggleButtonConfig.value,
      secondFactorType: this.formSecurityInfo!.radioButtonIsTrusted!.selected ? "TRUSTED_MACHINE" : "OTP_VIA_SMS",
      passwordType: this.formSecurityAdd.get('passwordType')?.value,
      passwordDuration: this.formSecurityAdd.get('passwordDuration')?.value,
      sessionDuration: this.formSecurityAdd.get('logoutType')?.value,
    });
    if(JSON.stringify(newSettings) !== JSON.stringify(this.defaultSettings)){
      this.isSameSettings = false;
      this.saveBtnConfig.text = this.getTranslation('BTN_SAVE_SETTINGS');
    }
  }
}
