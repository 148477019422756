import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, IOrderGetValidNextDueDateRQ, OrderGetValidNextDueDateRQ } from 'src/app/core';

@Component({
  selector: 'app-plan-next-order',
  templateUrl: './plan-next-order.component.html',
  styleUrls: ['./plan-next-order.component.css']
})
export class PlanNextOrderComponent implements OnInit {
  
  @Input() idOrder: string = '';
  @Output() planNextOrderOut : EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private clientService: ClientService,
    private translate : TranslateService,
    private fb: FormBuilder,
    private ngxModal : NgxSmartModalService) { }

  ngOnInit(): void {
  }

  formPlanDate: FormGroup = this.fb.group({
    dateDeliveryDesired: [null, Validators.required],
    dateDue: [null, Validators.required],
  })

  inputValues(event: any){
    
    this.formPlanDate.controls[event.target.name].setValue(event.target.value);

    if (event.target.name === 'dateDeliveryDesired') {
      this.getValidNextDueDate(event.target.value);
    }
    this.formValidation(this.formPlanDate);
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.btnConfigConfirm.disabled = !form.valid;
      });
    this.btnConfigConfirm.disabled = !form.valid;
  }
  
  btnConfigConfirm = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_CONFIRM'),
    width: '30px',
    height: '30px',
    disabled: true
  }

  dateDeliveryDesiredConfig = {
    labelName: this.getTranslation('DateDeliveryDesired'), 
    optional: false, 
    inputName: 'dateDeliveryDesired', 
    minDate: new Date(Date.now() + 24*60*60*1000),
    class: 'm-auto',
    value: undefined as any,
  }

  dateDueConfig = {
    labelName: this.getTranslation('Proposed DateDue'), 
    optional: false, 
    inputName: 'dateDue', 
    minDate: undefined,
    class: 'm-auto',
    value: undefined as any,
    disabled: true
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getValidNextDueDate(dateSelected: Date){

    if(dateSelected instanceof Date && dateSelected != undefined){

      let input : IOrderGetValidNextDueDateRQ = {      
        idOrder: this.idOrder,
        dateDeliveryDesired: dateSelected      
      }

      this.clientService.orderGetValidNextDueDate(new OrderGetValidNextDueDateRQ(input)).subscribe(
        response =>{

          this.formPlanDate.controls['dateDue'].setValue(response);

          this.dateDueConfig!.value = response;
          this.dateDueConfig = {...this.dateDueConfig};

          this.formValidation(this.formPlanDate);
        },
        error=> console.log(error)
      );
    }
  }

  planNextOrder(){
    
    let dateDue : Date = this.formPlanDate.controls['dateDue'].value;
    let dateDeliveryDesired : Date = this.formPlanDate.controls['dateDeliveryDesired'].value;
    
    this.planNextOrderOut.emit({dateDue, dateDeliveryDesired});

    this.ngxModal.getModal('planNextOrderModal').close();
  }
  
}
