<div *ngIf="environment.show_red_bars" style="background-color: red; width:100%; position: fixed; top:0; height: 8px;"></div>
<ng-container *ngIf="translationLoaded; else loadingTemplate">
    <ng-container *ngIf="(currentUser | json) != '{}' ; else login">
        <!-- <ng-container> -->
        <app-navbar></app-navbar>
        <div class="full-page">
            <div class="sidebar-box" *ngIf="(viewSidebar | async)">
                <app-sidebar></app-sidebar>
            </div>
            <div class="main-content" [ngClass]="!(viewSidebar | async) ? 'hideSideBar': ''" id="mainContent" [style.opacity]="this.mainContentOpacity">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-modal></app-modal>
        <app-nxg-modal></app-nxg-modal>
        <app-release-notes-modal></app-release-notes-modal>
        <app-compare-entities-modal></app-compare-entities-modal>
        <app-quit-modal></app-quit-modal>
        <app-request-modal></app-request-modal>
        <app-success-modal></app-success-modal>
    </ng-container>
    <ng-template #login>    
        <div *ngIf="this.loading ; else content" class="spinner-box">
            <spinner></spinner>
        </div>
        <ng-template #content>
            <router-outlet></router-outlet>
        </ng-template>
      </ng-template>
</ng-container>
<ng-template #loadingTemplate>    
    <div class="spinner-box">
        <spinner></spinner>
    </div>
</ng-template>

