import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.css']
})
export class RoundButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() type!: 'BACK' | 'NEXT' | 'CLOSE' | 'LOGOUT' | 'PLUS' | 'DELETE' | 'PENCIL' | 'WITHDRAW';
  @Input() disabled? : boolean = false
  @Input() extraClass? : {[klass: string] : any}
  @Input() id? = ''
  @Input() text?: string;
  @Output() onClick = new EventEmitter();
  @Input() size? = 0.8


  onBtnClick(){
    this.onClick.emit();
  }

  getExtraStyle(){
    if(this.extraClass !== undefined){
      return this.extraClass;
    }
    return {};
  }

  setSvgSize(){
    return {'width' : `${this.size}rem`, 'height' : `${this.size}rem`}
  }

}
