import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { AuthenticationService } from "src/app/core";

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  @Input() appHasRole: any;

  stop$ = new Subject();

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    var role = this.authService.currentUserValue.role;

    if (!role) {
      this.viewContainerRef.clear();
    }
    
    if (this.appHasRole.includes(role)){
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
  
  ngOnDestroy() {
    this.stop$.next();
  }
}
