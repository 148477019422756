import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgOtpInputComponent } from 'ng-otp-input';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { first } from 'rxjs/operators';
import { AuthenticationService, ClientService, GoogleAnalyticsService, ISendOTPCodeRQ, SendOTPCodeRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { TimeoutService } from 'src/app/core/services/timeout.service';

@Component({
  selector: 'app-check-otp-code',
  templateUrl: './check-otp-code.component.html',
  styleUrls: ['./check-otp-code.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class CheckOTPCodeComponent implements OnInit {
  @Input() login: string = "";
  @Input() password: string = "";
  @Input() idUserIdentity: string = "";

  formCheckCode: FormGroup = this.fb.group({
    code: ['', Validators.required],
  });

  errorMessage: string = "";

  showSnackBar = false;
  otpMessage = this.getTranslation("LABEL_OTP_RESEND");
  otpResendDisabled = false;

  btnConfig = {
    bclass: 'btn button-login mb-4',
    type: 'submit',
    text: this.getTranslation('CHECK_OTP_CODE_BTN'),
    disabled: true
  }
  
  constructor( private translate : TranslateService,
    private authService: AuthenticationService,
    private clientService: ClientService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private fb: FormBuilder,
    private ngxModal : NgxSmartModalService,
    private editService: EditServiceService,
    private timeout: TimeoutService) { }

  ngOnInit(): void {  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  checkOTPAndLogin(){

    this.authService.checkOTPAndLogin(this.login, this.password, this.otpCode!).pipe(first())
    .subscribe(
      dataPromise => {
        let data: any;
        dataPromise.then(x => data = x).finally(() => {
        
          this.ngxModal.getModal('checkOTPCode').close();

          if(data){
            if (!data?.userAuthEntity?.isPassCodeTemporary) {
              /* IF LOGGED SAVE PARAMETER AFTER LOGIN */
              this.timeout.startTimer();
              this.router.navigate(['/']);
              let lastLogin = data?.userAuthEntity?.dateTimeLastLogin as Date
              window.sessionStorage.setItem('releaseNotesDate', lastLogin.toUTCString())
            }
            else{
              this.editService.showTemporaryPasswordTemplate = true;
              this.router.navigate(['/changePassCode']);
            }
          }
        });
      },
      error => {
        let err = JSON.parse(error.response);
        this.errorMessage = this.getTranslation("ERR_CHECKOTP_" + err.errorType);
      });
  }

  inputValues(event: any) {
    this.formCheckCode.controls[event.target.name].setValue(event.target.value);
    this.btnConfig.disabled = !this.formCheckCode.valid;
  }

  resendOtp(){
    let loginData: ISendOTPCodeRQ;
    loginData = {
      idUserIdentity : this.idUserIdentity,
    };
    this.clientService.sendOTPCode(new SendOTPCodeRQ(loginData)).subscribe(
      response =>{
        this.otpResendDisabled = true;
        this.startButtonTimer();
        this.ShowSnackBar();
      },
      error=> console.log(error)
    );
  }

  startButtonTimer(){
    let timer = 15
    const ms = (timer + 1) * 1000
    const interval = setInterval(()=>{
      this.otpMessage = this.getTranslation('SIMPLE_RESEND_OTP_LABEL').replace('{0}', timer.toString())
      timer -= 1;
    },1000)
    setTimeout(() => {
      this.otpResendDisabled = false;
      clearInterval(interval);
      this.otpMessage = this.getTranslation('LABEL_OTP_RESEND');
    }, ms);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

  isOtpWrong = false
  otpCode?: string = ''
  otpExpression = /^[0-9]*$/

  OtpComponentConfiguration: NgOtpInputComponent["config"] = {
    length: 5,
    allowNumbersOnly: true,
    inputClass: 'otp-input',
    containerClass: 'otp-box'
  }
  
  controlOtpValue(number: string) {
    if (this.isOtpWrong) {
      this.isOtpWrong = false;
      this.errorMessage = ''
      this.OtpComponentConfiguration.inputClass = 'otp-input'
    }
    this.otpCode = number
    this.btnConfig.disabled = !(this.otpCode?.length === 5 && this.otpExpression.test(this.otpCode))
  }
}