<div class="login-container">
    <div id="formContent">
      <!-- Icon -->
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 mb-4">
            <img style="width: 150px;" [src]="logoFresenius" alt="fresenius-login-image">
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 mb-5">
            <h1>{{'BACKOFFICE_TITLE' | translate}}</h1>
          </div>
        </div>
        <!-- Login Form -->
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="row">
              <div class="col-12 mb-3">
                <app-input [inputConfig]="loginFormInput?.username" (value)="inputValues($event)" (keyup)="inputValues($event)" (change)="inputValues($event)"></app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-4">
                <app-input [avoidSanitizing]="true" [inputConfig]="loginFormInput?.password" (value)="inputValues($event)" (keyup)="inputValues($event)" (change)="inputValues($event)"></app-input>
              </div>
            </div>
            <div class="row" *ngIf="errorMessage">
              <div class="col-12" >
                <div class="error"><span class="error-message">{{errorMessage | translate}}</span></div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 justify-content-center">
                <app-button [buttonConfig]="loginBtnConfig"></app-button>
              </div>
            </div>
          </div>  
        </form>  
        <div class="row">
          <div class="col-12 text-center">
            <a class="underlineHover" (click)="openForgetPasswordModal()">{{'BTN_FORGOT_PASSWORD' | translate}}</a>
            <a class="hidden" id="account-locked" data-target="#modalLocked" data-toggle="modal"></a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========================= FOOTER ============================ -->
    <div class="login-footer">
      <div class="main">
        <div class="img">
          <img src="../../assets/images/footer-logo.png" alt="">
        </div>
        <div>
          <div class="line"></div>
        </div>
        <div class="textes" [innerHTML]="'FOOTER_PUBLISHER_LABEL' | translate">
        </div>
      </div>
        <div *ngIf="selectedLanguage" class="drp" (click)="languageDropdown()">
          <img src="../../assets/images/globe.png" alt="globe_dropdown">
          <span>{{selectedLanguage.key}}</span>
          <svg-icon [src]="'../../assets/icons/down-icon.svg'" [ngClass]="isClosed === undefined ? '' : isClosed ? 'closed' : 'open'"></svg-icon>
        </div>
    </div>
    <div class="language-drp" [ngClass]="isClosed === undefined ? '' : isClosed ? 'closed' : 'open'" *ngIf="languagesAvailale.length > 0">
      <div class="drp-content">
        <span *ngFor="let item of languagesAvailale" (click)="changeLanguage(item)"> {{item.key}} </span>
      </div>
    </div>
  </div>

<app-check-otp-code [login]="loginFormInput?.username.value" 
                    [password]="loginFormInput?.password.value"
                    [idUserIdentity]="idUserIdentity">
</app-check-otp-code>

<app-forget-password-modal></app-forget-password-modal>

<app-account-deactivated-modal></app-account-deactivated-modal>

<app-account-locked-modal></app-account-locked-modal>