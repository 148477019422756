import { BackOfficeCountryResponsibleView, IBackOfficeCountryResponsibleView } from "../services";

export class BackOfficeCountryResponsibleViewCustom implements BackOfficeCountryResponsibleView {
    countryIsoCode?: string | undefined;
    role?: string | undefined;
    roleString?: string | undefined;
    idCountryResponsible?: string | undefined;
    name?: string | undefined;
    surname?: string | undefined;
    fullName?: string | undefined;
    emailAddress?: string | undefined;

    constructor(data?: IBackOfficeCountryResponsibleView) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.countryIsoCode = _data["countryIsoCode"];
            this.role = _data["role"];
            this.roleString = _data["roleString"];
            this.idCountryResponsible = _data["idCountryResponsible"];
            this.name = _data["name"];
            this.surname = _data["surname"];
            this.fullName = _data["fullName"];
            this.emailAddress = _data["emailAddress"];
        }
    }

    static fromJS(data: any): BackOfficeCountryResponsibleView {
        data = typeof data === 'object' ? data : {};
        let result = new BackOfficeCountryResponsibleView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["countryIsoCode"] = this.countryIsoCode;
        data["role"] = this.role;
        data["roleString"] = this.roleString;
        data["idCountryResponsible"] = this.idCountryResponsible;
        data["name"] = this.name;
        data["surname"] = this.surname;
        data["fullName"] = this.fullName;
        data["emailAddress"] = this.emailAddress;
        return data;
    }
}
