<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'DEMO_USER_ADD_TITLE' : 'DEMO_USER_EDIT_TITLE') | translate}}</h2>

        <form [formGroup]="formDemoUserAdd">
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formDemoUserInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formDemoUserInfo.input[1]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div *isGlobalUser class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formDemoUserInfo?.autocompleteCountryConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formDemoUserInfo?.autocompleteCultureConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>
            
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formDemoUserInfo.input[2]" [formControlInput]="formDemoUserAdd"  (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <br/>
            <p *ngIf="!patientAppAccessRequired" class="desc-gray bold">{{'LABEL_OPTIONAL'| translate}}</p>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <div class="row row-aligned">
                        <div class="col-md-4 col-4">
                            <app-phoneprefix-dropdown [autocompleteConfig]="formDemoUserInfo?.autocompleteCountryPhonePrefixConfig" (selected)="inputValues($event)" ></app-phoneprefix-dropdown>
                        </div>
                        <div class="col md-8 col-8">
                            <app-input [inputConfig]="formDemoUserInfo.input[3]" (keyup)="inputValues($event)"></app-input>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="id == undefined">
                <app-checkbox *ngIf="nurseAppAvailable" [checked]="nurseAppAccessRequired" (check)="checkNurseAppAccessRequired()" [text]="getTranslation('DEMO_USER_NURSE_APP_REQUIRED')"></app-checkbox>

                <app-checkbox [checked]="patientAppAccessRequired" (check)="checkPatientAppAccessRequired()" [text]="getTranslation('DEMO_USER_PATIENT_APP_REQUIRED')"></app-checkbox>
            </div>

            <hr/>

            <!-- TREATMENT MODALITY SECTION -->                
            <div *ngIf="id == undefined && isTherapyModalityVisible">
                <b>{{ 'LABEL_TREATMENT_MODALITY' | translate }}</b>
            
                <div class="row my-2" >
                    <div class="col-md-4 col-12">
                        <app-radio-button [radioButtonConfig]="formDemoUserInfo?.radioButton" (radioOutput)="inputValues($event)"></app-radio-button>
                    </div>
                </div>
            </div>

            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>
            
            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveDemoUser()"></app-button>
                </div>
            </div>

            <br/>
        </form>
    </div>
</ng-template>