import { RadioButtonInterface } from './../../../../core/models/radiobuttons.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent implements OnInit, RadioButtonInterface {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() chipsConfig!: ChipsConfig;
  @Output() onChipSelect = new EventEmitter(); 

  
  isSelectedClass(value: any){
    if(this.chipsConfig.selectedValue.some(x=> x === value)){
      return `${this.chipsConfig.versionWhenSelected.toLowerCase()}-selected`
    }
    return '';
  }

  isSelected = (value: any) => this.chipsConfig.selectedValue.some(x=> x === value)
  
  
  getBoxClass(){
    let finalClass = ''
    if(this.chipsConfig.extraClass){
      finalClass = finalClass + ' ' + this.chipsConfig.extraClass
    }
    if(this.chipsConfig.scrollable){
      finalClass = finalClass + ' ' + 'scrollable';
    }
    return finalClass;
  }

  isBold = (value: any) => this.chipsConfig.versionWhenSelected === 'Order' && this.isSelected(value);
  


  emitValue(value: any): void{
    this.onChipSelect.emit({target:{name: this.chipsConfig.valueName, value: value}});
  }

}


export class ChipsConfig {
  valueName!: string
  values!: {label: string, value: any}[]
  selectedValue! : any[]
  scrollable?: boolean = false
  versionWhenSelected!: 'Dark' | 'Light' | 'Order'
  extraClass?: string
//   chipsDirection?: 'Horizontal' | 'Vertical' = 'Horizontal'    - Not used, but in future could be useful
  singleSelection?: boolean = false

  constructor(chipName: string,versionWhenSelected: 'Dark' | 'Light' | 'Order'){
    this.valueName = chipName
    this.versionWhenSelected = versionWhenSelected;
    this.values = []
    this.selectedValue = []
    this.scrollable = false
    this.singleSelection = false
  }

  setValues?(values: {label: string, value: any}[]){
    this.values = values
    return this;
  }
}