

export const getDayNameFromDayNumber = (day: number) => {
    var defaultLang = window.localStorage.getItem('defaultLang') || "en-GB";

    var baseDate = new Date(Date.UTC(2017, 0, 1)); // just a Sunday (like C#)
    var weekDays = [];
    for(var i = 0; i < 7; i++)
    {
        weekDays.push(baseDate.toLocaleDateString(defaultLang, { weekday: 'long' }));
        baseDate.setDate(baseDate.getDate() + 1);
    }

    if(day > 6 || day < 0) return 'Error: Day must be a number between 0 and 6'
    return weekDays[day];
}

export const getDaysBeforeDeliveryLabel = (day: number) =>{
    if(day === 0) return 'CALENDAR_DELIVERY_SAME_WEEK';
    if(day === -1) return 'CALENDAR_DELIVERY_ONE_WEEK_BEFORE';
    if(day === -2) return 'CALENDAR_DELIVERY_TWO_WEEKS_BEFORE';
    
    if(day < -2) {
        return 'ERROR: Week number expect to be more than -2';
    }
    if(day > 0){
        return 'ERROR: Week number expect to be less than 0';
    }
    return 'ERORR: No values matched';
}
