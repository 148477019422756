import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { ClientService, ClinicSetRQ, IClinicSetRQ } from 'src/app/core';

@Component({
  selector: 'app-clinic-name-modal',
  templateUrl: './clinic-name-modal.component.html',
  styleUrls: ['./clinic-name-modal.component.css']
})
export class ClinicNameModalComponent implements OnInit, AfterViewInit, OnDestroy {

  clinicName: any = '';
  @Output() setClinicNameOut : EventEmitter<any> = new EventEmitter<any>();
  
  subscriptions: Subscription[] = []
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private ngxModal: NgxSmartModalService,
    private fb: FormBuilder,
  ) { }

  loading: boolean = true;
  
  okBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('BTN_COMMON_SAVE'),
    disabled : false
  }

  ngOnInit(): void {
    this.subscriptions = []
  }

  ngAfterViewInit(): void {
    let modalDataSubscription = this.ngxModal.getModal('clinicNameModal').onOpen.subscribe(()=>{
      this.clinicName = this.ngxModal.getModal('clinicNameModal').getData();

      this.formClinicInfo.value = this.clinicName;
      this.formClinicAdd.controls['clinicName'].setValue(this.clinicName);
      this.formValidation(this.formClinicAdd);
    })
    this.subscriptions.push(modalDataSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  formClinicAdd: FormGroup = this.fb.group({
    clinicName: [null, Validators.required],
  });

  formClinicInfo={
    name: this.getTranslation("LABEL_NAME"), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'clinicName', value: this.formClinicAdd.get("clinicName")?.value
  };

  inputValues(event: any){    
    this.formClinicAdd.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.formClinicAdd);
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.okBtnConfig.disabled = !form.valid;
      });
    this.okBtnConfig.disabled = !form.valid;
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }

  setClinicName(){
    
    let clinicName = this.formClinicAdd.controls['clinicName'].value;    
    this.setClinicNameOut.emit({clinicName});
    
    this.closeModal();
  }

  closeModal(){
    this.ngxModal.getModal('clinicNameModal').close();
  }
}
