<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'PATIENT_ADD_TITLE' : 'PATIENT_EDIT_TITLE') | translate}}</h2>

        <form [formGroup]="formPatientAdd">

            <div class="row my-2">
                <div class="col-md-6 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formPatientInfo?.autocompleteClinicConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                    <hr class="w-100">
                </div>
            </div>
            
            <!--<div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>-->

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[1]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[2]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>
        
            <!--<div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-radio-button [radioButtonConfig]="formPatientInfo?.radioButton"
                        (radioOutput)="inputValues($event)"></app-radio-button>
                </div>
            </div>-->

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-date-picker (resultDate)="inputValues($event)"
                        [datePickerConfig]="formPatientInfo!.datePickerConfig"></app-date-picker>
                </div>
            </div>

            <!--
å            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[3]" [formControlInput]="formPatientAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formPatientInfo?.autocompleteCountryPhonePrefixConfig"
                        (selected)="inputValues($event)"></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[4]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formPatientInfo?.autocompleteCountryMobilePrefixConfig"
                        (selected)="inputValues($event)"></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[5]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[6]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[7]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[8]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[9]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[10]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[11]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[12]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[13]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formPatientInfo.input[14]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-12">
                <app-checkbox [checked]="firstOrderNeeded" (check)="checkFirstOrderNeeded()" [text]="getTranslation('PATIENT_ADD_FIRSTORDERNEEDED')"></app-checkbox>
              </div>
            </div>-->

            <app-error-box *ngIf="this.validationError"
                  [text]="validationError"></app-error-box>

            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveDetails()"></app-button>
                </div>
            </div>

            <br/>

        </form>
    </div>
</ng-template>