<div class="sticky-top">
  <nav class="navbar navbar-expand-sm">
    <div *ngIf="environment.show_red_bars" style="background-color: red; width:100%; position: fixed; top:0; margin-left: -16px; height: 8px;"></div>
    <div class="logo-wrapper">
      <a routerLink="/nurses">
        <img src="../../../assets/images/logo_fresenius_big.png" alt="NetCare_Logo">
      </a>
    </div>
    
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <app-tab></app-tab>
      <div class="collapse navbar-collapse d-flex flex-row-reverse" id="navbar-list-4">
        <ul class="navbar-nav">
            <li class="nav-item dropdown">
            <a class="nav-link dropdown" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="avatar-circle">
                <span class="initials">{{userInitials}}</span>
              </div>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <div class="row profile-row" (click)="openProfileInformation('userInfo')">
                <div class="col-3 pr-0">
                  <div class="avatar-circle">
                    <span class="initials">{{userInitials}}</span>
                  </div>
                </div>
                <div class="col-7">
                  <div class="row"><div class="col-12 pl-2"><app-title [textConfiguration]="profileConfiguration?.title[0]"></app-title></div></div>
                  <div class="row"><div class="col-12 pl-2"><app-title [textConfiguration]="profileConfiguration?.title[1]"></app-title></div></div>
                  <div class="row"><div class="col-12 pl-2"><app-title [textConfiguration]="profileConfiguration?.title[2]"></app-title></div></div>
                </div>
                <div class="col-2">
                  <app-button [buttonConfig]="profileConfiguration?.buttons[0]"></app-button>
                </div>
              </div>
              <div class="profile-options">
                <div class="row" (click)="openProfileInformation('language')">
                  <div class="col-10 pl-0"><app-title [textConfiguration]="profileConfiguration?.title[3]"></app-title></div>
                  <div class="col-2 profile-col"><app-button [buttonConfig]="profileConfiguration?.buttons[1]"></app-button></div>
                </div>
                <div class="row" (click)="changePasscode()">
                  <div class="col-10 pl-0"><app-title [textConfiguration]="profileConfiguration?.title[4]"></app-title></div>
                  <div class="col-2 profile-col"><app-button [buttonConfig]="profileConfiguration?.buttons[1]"></app-button></div>
                </div>
                <div style="display: none" class="row" (click)="openFAQ()">
                  <div class="col-10 pl-0"><app-title [textConfiguration]="profileConfiguration?.title[5]"></app-title></div>
                  <div class="col-2 profile-col"><app-button [buttonConfig]="profileConfiguration?.buttons[1]"></app-button></div>
                </div>
                <hr class="w-100">
                <div class="log-box" (click)="logout()">
                  <div>
                    <app-button [buttonConfig]="profileConfiguration?.buttons[2]"></app-button>
                  </div>
                  <div>
                    <app-title [textConfiguration]="profileConfiguration?.title[6]"></app-title>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
</div>

<app-user-profile></app-user-profile>

<app-faq-modal></app-faq-modal>