import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NxgCustomModalService {

  modalSubject?: Subject<any>;
  constructor() {
    this.modalSubject = new Subject<any>();
   }

   setModalData(data: any){
    this.modalSubject?.next(data);
   }
   getModalData(){
     return this.modalSubject?.asObservable();
   }
}
