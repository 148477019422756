import { Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/core/services/date-formatter.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable()
export class I18n {
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [
    I18n, 
    {provide: NgbDatepickerI18n, useClass: DatePickerComponent},
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class DatePickerComponent extends NgbDatepickerI18n implements OnInit, OnChanges{

  days!: any;
  months!: any;

  constructor(
    private translate: TranslateService,
    private calendar: NgbCalendar
  ) {
    super();
    this.translate.get("days").subscribe((translated: string) => {
      this.days = translated;
    });
    this.translate.get("months").subscribe((translated: string) => {
      this.months = translated;
    });

  }

  markDisabled : any;

  isDayDisabled = (date: NgbDate) => {
    if(this.datePickerConfig.availableDates){
      const isPresentInAvailableDates = this.datePickerConfig.availableDates.some(dt=>  dt.getDate() == date.day && (dt.getMonth() + 1) == date.month && dt.getFullYear() == date.year)
      return !isPresentInAvailableDates
    }
    if(this.datePickerConfig.holidays){
      const isHoliday = this.datePickerConfig.holidays.some(x => x.getDate() == date.day && (x.getMonth() + 1) == date.month && x.getFullYear() == date.year)
      return isHoliday || this.getDayWithoutDeliveries(this.datePickerConfig.datePattern!).some((x: number)=> x === this.calendar.getWeekday(date));
    }
    return false
  }
  
  getWeekdayShortName(weekday: number): string {
    return this.translate.instant("Day_" + (weekday));
  }
  getMonthShortName(month: number): string {
    return this.translate.instant("Month_" + (month));
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }


  @Input() datePickerConfig!: DatePickerConfig
  @Output() resultDate : EventEmitter<any> = new EventEmitter<any>();
  model!: any;
  minDate =(dt?: Date): NgbDateStruct =>{ return dt ? { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()} : { year: 1900, month: 1, day: 1}};
  maxDate =(dt?: Date): NgbDateStruct =>{ return dt ? { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()} : { year: 2030, month: 1, day: 1}};

  ngOnInit(): void {
    if(this.datePickerConfig.value){
      this.model = new NgbDate(this.datePickerConfig.value?.getFullYear()!,this.datePickerConfig.value?.getMonth()! + 1, this.datePickerConfig.value?.getDate()!)
    }
  }

  getDayWithoutDeliveries(arr: string[]): number[]{
    let final = []
    for(let i =1;i < 8 ; i++){
      if(arr.find(x=> parseInt(x) === i) === undefined){
        final.push(i);
      }
    }
    return final;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.datePickerConfig.value){
      this.model = new NgbDate(this.datePickerConfig.value?.getFullYear()!,this.datePickerConfig.value?.getMonth()! + 1, this.datePickerConfig.value?.getDate()!)
    }
  }

  sendDate(event: any){
    let eventDate = moment();
    let minDate = null;

    if(event){
      eventDate = moment.utc([event.year, event.month -1 , event.day]);

      if(this.datePickerConfig.minDate){
        minDate = moment.utc(this.datePickerConfig.minDate.toDateString()).toDate();
      }
    }

    const e = {
      target:{
        name : this.datePickerConfig.inputName,
        value : null as any
      }
    }

    const isSelectedDateValid = event && eventDate && eventDate.isValid();
    const isMinDateNullOrPresentAndMinorThanSelectedDate = ((minDate && eventDate.toDate() >= minDate) || !minDate)

    const structDate = new Date(eventDate.toDate().getFullYear(),eventDate.toDate().getMonth(), eventDate.toDate().getDate())
    const isDateWrittenFromUserValid = isSelectedDateValid && 
    (
      !this.isDayDisabled(new NgbDate(eventDate.toDate().getFullYear()!,eventDate.toDate().getMonth()! + 1, eventDate.toDate().getDate()!))
    ) 
    
    //Check event to manage empty field 
    if( isSelectedDateValid && isMinDateNullOrPresentAndMinorThanSelectedDate && isDateWrittenFromUserValid)
    {
      this.datePickerConfig.class=""
      e.target.value = eventDate.toDate()
    } 
    else 
    {
      if(!this.datePickerConfig.optional) this.datePickerConfig.class="error"
      e.target.value = null
    }
    this.resultDate.emit(e);
  }

}

export class DatePickerConfig{
  labelName!: string
  inputName!: string
  optional!: boolean
  datePattern?: string[] = ["0","1","2","3","4","5","6"]
  value?: Date
  minDate?: Date
  maxDate?: Date
  holidays?: Date[]
  class?: string = ''
  errorMessage?: string = ''
  disabled?: boolean
  availableDates?: Date[]
}