import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public ViewSidebar: BehaviorSubject<boolean>;

  constructor() {
    this.ViewSidebar = new BehaviorSubject<boolean>(true);
  }

  hideSideBar(){
    this.ViewSidebar.next(false);
  }


  showSidebar(){
    this.ViewSidebar.next(true);
  }

}
