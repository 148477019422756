import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-today',
  templateUrl: './table-today.component.html',
  styleUrls: ['./table-today.component.css']
})
export class TableTodayComponent implements OnInit {

  @Input() tableConfig: any;
  @Output() onClick = new EventEmitter<any>();
  selectedOption? : string;
  searchText? : string;

  alertConfig = {
    aclass: 'alert alert-dark m-4',
    msg: this.getTranslation('LOADING_LABEL')
  }

  constructor(
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.selectedOption ='id';
  }

  ngOnChanges(changes: SimpleChanges){
    //console.log(this.tableConfig.gridData.filter((x: { taskType: string; }) => x.taskType == 'StationaryIsItTimeToDischarge'));
    //this.tableConfig.gridData = this.tableConfig.gridData.filter((record: any) => record.taskType=='PatientInactivityInPatientApp');
  }

  handlePageChange(event: any) {
    this.tableConfig.pagination.currentPage = event;
  }
  onClickButton(record: any, action: any, event: any){
    if(event?.type != 'click' || action != 'info'){
      this.onClick.emit({record,action});
    }
  }

  isDate(value:string){
    return (Date.parse(value) || Date.parse(value)==0);
  }

  updatePage() {
    this.tableConfig.pagination.currentPage = 1;
  }

  checkValidityDate(date: Date){
    let today = new Date();
    today.setDate(today.getDate() + 31);
    return today <= date;
  }
  expired(date: Date){
    return new Date() > date;
  }

  dueColor(record: any){
    if(record.dateDue < new Date()){
      return 'red';
    } else {
      return 'gray';
    }
  }

  getDueDateWarning(record: any){
    if(!record.dateDue){
      return;
    }
    let difference;
    let today = new Date();
    difference = Math.ceil((record.dateDue.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));
    if(difference > 0){
      return this.getTranslation('IN_TIME_LABEL') +' '+difference+' '+this.pluralDate(this.getTranslation('SINGLE_DAY_LABEL'),this.getTranslation('MULTIPLE_DAY_LABEL'),difference)
    } else {
      return this.getTranslation('FOR_LABEL') +' '+Math.abs(difference)+' '+this.pluralDate(this.getTranslation('SINGLE_DAY_LABEL'),this.getTranslation('MULTIPLE_DAY_LABEL'),difference)
    }
   }
  pluralDate(singular: string, plural: string, difference: any){
    return Math.abs(difference) == 1 ? singular : plural;
  }
  isDueOrder(record: any){
    let flag;
    if(record){
      if(record.dateDue){
        flag = record.dateDue > new Date();
      } else if(record.dateDue==undefined){
        flag = false;
      } else flag = true;
    }
    return flag;
  }

  getTaskIcon(record: any){
    if(record.taskType == 'OrderFirst' || record.taskType == 'OrderRoutine' ||
    record.taskType == 'OrderRoutineNotManagedByPatient'){
      return '../../../assets/icons/task-order-icon.svg'
    } else if(record.taskType == 'Prescription' || record.taskType == 'PrescriptionEdit' ||
    record.taskType == 'PrescriptionEditRejected' || record.taskType == 'PrescriptionRejected' ||
    record.taskType == 'PrescriptionRenew' || record.taskType == 'PrescriptionRenewRejected' ||
     record.taskType == 'PrescriptionWithoutTreatmentInfo'){
      return '../../../assets/icons/task-prescription-icon.svg'
    } else if(record.taskType == 'PrescriptionSignature' || record.taskType == 'PrescriptionRenewSignature' ||
    record.taskType == 'PrescriptionEditSignature'){
      return '../../../assets/icons/task-signature-icon.svg'
    } else if(record.taskType == 'StationaryDischargeExpectedReached' || record.taskType == 'StationaryIsItTimeToDischarge' ||
    record.taskType == 'SuspensionIsItTimeToDischarge'){
      return '../../../assets/icons/task-discharge-icon.svg'
    } else if(record.taskType == 'PatientInactivityInPatientApp'){
      return '../../../assets/icons/task-inactivity-icon.svg'
    } else {
      console.log('DEFAULT')
      return ''
    }
  }

  getTaskName(record: any){
    switch (record.taskType){
      case "PrescriptionRenew":
        if(record.taskStatusForLabel=='New'){
          return this.getTranslation('TASK_LABEL_PRESCRIPTION_RENEW_NEW');
        } else if (record.taskStatusForLabel=='InProgress'){
          return this.getTranslation('TASK_LABEL_PRESCRIPTION_RENEW_INPROGRESS');
      }
        break;
      case "PrescriptionRenewRejected":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_RENEW_REJECTED');
      case "Prescription":
        if(record.taskStatusForLabel=='New'){
          return this.getTranslation('TASK_LABEL_PRESCRIPTION_NEW');
        } else if (record.taskStatusForLabel=='InProgress'){
          return this.getTranslation('TASK_LABEL_PRESCRIPTION_INPROGRESS');
      }
        break;
      case "PrescriptionSignature":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_SIGNATURE');
      case "PrescriptionEdit":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_EDIT');
      case "PrescriptionEditRejected":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_EDIT_REJECTED');
      case "PrescriptionRejected":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_REJECTED');
      case "PrescriptionRenewSignature":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_RENEW_SIGNATURE');
      case "OrderRoutine":
        if(record.taskStatusForLabel=='New'){
          return this.getTranslation('TASK_LABEL_ORDER_ROUTINE_NEW');
        } else if (record.taskStatusForLabel=='InProgress'){
          return this.getTranslation('TASK_LABEL_ORDER_ROUTINE_INPROGRESS');
      }
        break;
      case "OrderFirst":
        if(record.taskStatusForLabel=='New'){
          return this.getTranslation('TASK_LABEL_ORDER_FIRST_NEW');
        } else if (record.taskStatusForLabel=='InProgress'){
          return this.getTranslation('TASK_LABEL_ORDER_FIRST_INPROGRESS');
      }
        break;
      case "OrderRoutineNotManagedByPatient":
        return this.getTranslation('TASK_LABEL_ORDER_ROUTINE_NOT_MANAGED');
      case "PrescriptionSignature":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_SIGNATURE');
      case "PrescriptionEditSignature":
        return this.getTranslation('TASK_LABEL_PRESCRIPTION_EDIT_SIGNATURE');
      case "SuspensionIsItTimeToDischarge":
        return this.getTranslation('TASK_LABEL_SUSPENSION_IS_IT_TIME_TO_DISCHARGE');
      case "StationaryIsItTimeToDischarge":
        return this.getTranslation('TASK_LABEL_STATIONARY_IS_IT_TIME_TO_DISCHARGE');
      case "StationaryDischargeExpectedReached":
        return this.getTranslation('TASK_LABEL_STATIONARY_DISCHARGE_EXPECTED_REACHED');
      case "PatientInactivityInPatientApp":
        return this.getTranslation('TASK_LABEL_PATIENT_INACTIVITY');
      case "PrescriptionWithoutTreatmentInfo":
       return this.getTranslation('TASK_LABEL_PRESCRIPTION_WITHOUT_TREATMENT_INFO');
      default:
        console.log("ERROR: NO LABEL FOUND!")
        return 'unknown label'
    }
    console.log("ERROR: NO LABEL FOUND!")
    return '?';
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
