import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  AuthenticationService, BackOfficePatientView, ClientService, BackOfficeClinicView, IBaseGetAllRQ, BaseGetAllRQ } from 'src/app/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  patientList! : BackOfficePatientView[];  
  clinicList! : BackOfficeClinicView[];
  loading: boolean = true;
  record?: any;
  totalItemsPatient: number = 0;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_PATIENT'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_PATIENT_V2'), value: '' }

  autocompleteConfig = {
    name: this.getTranslation('FILTER_CLINIC'),
    attr: 'clinicName' as const,
    array: this.clinicList,
    numberToShow: 6,
    value : null as any,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_CLINIC'),
  }

  tableConfig =  {
    actions: true,
    gridData: this.patientList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
      {name: this.getTranslation('LABEL_BIRTHDATE'), field: 'birthDate', sortable: true, isDate: true},
      {name: this.getTranslation('LABEL_CLINIC'), field: 'clinicName', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsPatient
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  anyResult: boolean = false;

  constructor(
    private clientService: ClientService,
    private authService: AuthenticationService,
    private router : Router,
    private translate: TranslateService,
    private utilService: UtilsService
  ) { }

  isPharmacyRelatedToClinic = this.utilService.getParametersByKey('flow_trigger__is_pharmacy_related_to_clinic').parameterValue == "True";

  ngOnInit(): void {
    if(this.isPharmacyRelatedToClinic){
      this.tableConfig.headerList.push({name: this.getTranslation('PHARMACY_LABEL'), field: 'pharmacyName', sortable: true})
    }



    this.currentPage = 1;

    this.getFiltersBySession();
    
    this.getPatientList();

    this.getClinicList();
  }

  filterName?: string = '';
  filterClinic?: string = '';

  orderDirection?: string = "Surname_asc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getPatientList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterClinic = value.target.value.idClinic;
      this.getPatientList(); 
    }
    else{
      this.filterClinic = '';
      this.getPatientList(); 
    } 
  }
 
  getPatientList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName, 
      idClinicSelected: this.filterClinic,
    }
    this.clientService.patientGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.patientList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.patientList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderPatientChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getPatientList();
  }

  pagePatientChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getPatientList();
  }

  getClinicList() {
    let input: IBaseGetAllRQ = {
      pageIndex: undefined,
      sortOrder: "ClinicName_asc",
      searchString: "",
      city: ""
    }
    this.clientService.clinicGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        response.list?.forEach(x => x.clinicName = x.sapUuid + " " + x.clinicName);
        this.clinicList = response.list || [];
        this.autocompleteConfig.array = this.clinicList;
      } ,
      error=> console.log(error)
    );
  }

  getUpdatedPatientList(): any{
    return this.getPatientList;
  }

  GoToPatientDetail(event: any) {
    if(event.action == 'edit'){
      this.router.navigate(['/patients/detail', event.record.idPatient]);
      this.saveFilters();
    }
  }

  patientAdd() {
    this.router.navigate(['/patients/add']);
  }

  bindButtons(patientList: BackOfficePatientView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let patient of patientList){
      this.tableConfig.recordButtonConfigs.set(patient,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };

    //ASSIGN PREVIOUS DROPDOWN FILTER
    var clinicSelected = JSON.parse(window.localStorage.getItem('filterApplied') || '{}');
    this.filterClinic = clinicSelected?.idClinic ?? '';
    this.autocompleteConfig.value = clinicSelected;

    //ASSIGN PREVIOUS SORT ORDER
    this.orderDirection = window.localStorage.getItem('sortOrder') || this.orderDirection;

    //ASSIGN PREVIOUS LIST PAGE VISITED    
    this.currentPage = Number(window.localStorage.getItem('currentPage')) || 1;
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);

    //SET PREVIOUS DROPDOWN FILTER
    window.localStorage.setItem('filterApplied', JSON.stringify(this.autocompleteConfig.value || '{}'));

    //SET PREVIOUS SORT ORDER
    window.localStorage.setItem('sortOrder', this.orderDirection!);

    //SET PREVIOUS LIST PAGE VISITED
    window.localStorage.setItem('currentPage', this.currentPage!.toString());
  }
}