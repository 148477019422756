<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>
        <br/>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'CALENDAR_HISTORY' | translate}}</h2>
            </div>
        </div>
        <div *ngIf="anyResult else noResultContent">
        <div class="row mt-2">
            <div class="col-8 d-flex align-items-end">
                <app-filters [filters]="this.filters" (onFilterToggle)="onFilterUpdate($event)"></app-filters>
            </div>
            <div class="col-lg-4 col-md-auto">
                <app-autocomplete-dropdown [autocompleteConfig]="autocompleteConfig" (selected)="applyFilter($event)"></app-autocomplete-dropdown>
            </div>
        </div>
        <br/>
        <app-table [tableConfig]= "tableConfig" (orderChanged)="orderCalendarChanged($event)" (onClick)="GoToCalendarDetail($event)" (pageChanged)="pageCalendarChanged($event)"></app-table>
    </div>
    <ng-template #noResultContent>
        <div class="text-center noResult">            
            <p class="alert">{{ 'CALENDAR_NORESULT' | translate }}</p>
            <p class="desc">{{ 'CALENDAR_NORESULT_DESC' | translate }}</p>
        </div>
    </ng-template>
</ng-template>
</div>
