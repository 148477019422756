import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, BackOfficeCalendarView, ICalendarDateGetAllBackOfficeRQ, CalendarDateGetAllBackOfficeRQ, ICalendarPatientGetAllBackOfficeRQ, BackOfficeCalendarPatientView, CalendarPatientGetAllBackOfficeRQ, EntityRQ } from 'src/app/core';
import { BackOfficeCalendarDateViewCustom } from 'src/app/core/models/BackOfficeCalendarDateView-custom.model';
import { BackOfficeCalendarViewCustom } from 'src/app/core/models/BackOfficeCalendarView-custom.model';
import { DateService } from 'src/app/core/services/date.service';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-calendar-detail',
  templateUrl: './calendar-detail.component.html',
  styleUrls: ['./calendar-detail.component.css']
})
export class CalendarDetailComponent implements OnInit {
  id?: string;
  calendarDetail?: BackOfficeCalendarViewCustom;

  calendarDateList! : BackOfficeCalendarDateViewCustom[];
  calendarPatientList! : BackOfficeCalendarPatientView[];
  
  loading: boolean = true;
  record?: any;
  totalItemsCalendarDate: number = 0;
  totalItemsCalendarPatient: number = 0;
  anyResult: boolean = false;
  orderCalendarDateDirection?: string = "deliveryDate_asc";
  orderCalendarPatientDirection?: string = "surname_asc";
  currentCalendarDatePage: number = 1;
  currentCalendarPatientPage: number = 1;

  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private editService: EditServiceService,
    private modalService: NxgCustomModalService,
    private dateService: DateService,
    private ngxModal: NgxSmartModalService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString(); 

    this.getCalendarDetail();

    this.getCalendarDateList();
    this.getCalendarPatientList();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/calendars");
  }

  EditCalendar() {
    this.router.navigate(['/calendars/edit', this.id]);
  }

  CloneCalendar() {
    this.router.navigate(['/calendars/clone', this.id]);
  }

  getCalendarDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.calendarGet(data).subscribe(
      response =>{
        this.calendarDetail = BackOfficeCalendarViewCustom.fromJS(response, (key)=> this.getTranslation(key));
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

  DeleteCalendar(){

    if (this.calendarPatientList.length > 0){
      this.modalService.setModalData({ 
        title: "CALENDAR_CANNOT_BE_DELETED_MODAL_TITLE",
        description: "CALENDAR_CANNOT_BE_DELETED_MODAL_DESC",
        commentVisible: false
      })
      this.ngxModal.getModal('successModal').open();
      return;
    }

    this.modalService.setModalData({ 
      title: (this.calendarDetail?.dateDeleted) ? "CALENDAR_RESTORE_MODAL_TITLE" : "CALENDAR_DELETE_MODAL_TITLE",
      description: (this.calendarDetail?.dateDeleted) ? "CALENDAR_RESTORE_MODAL_DESC" : "CALENDAR_DELETE_MODAL_DESC",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.calendarDetail?.dateDeleted ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.calendarDetail?.dateDeleted ? 
          this.getTranslation('CALENDAR_RESTORE_MODAL_BTN') : this.getTranslation('CALENDAR_DELETE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        
        if(this.calendarDetail?.dateDeleted){
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.calendarUnDelete(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
        else{
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.calendarDelete(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
      }
     })
     
    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.calendarDetail?.dateDeleted) ? "CALENDAR_RESTORED_SUCCESSFULLY" : "CALENDAR_DELETED_SUCCESSFULLY",
      actionToDo: () => {
        this.getCalendarDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

  tableCalendarDateConfig =  {
    actions: true,
    gridData: this.calendarDateList,
    headerList: [
      {name: this.getTranslation('CALENDAR_NAME'), field: 'fullCalendarName', sortable: false},
      {name: this.getTranslation('CALENDAR_DELIVERY_DATE'), field: 'deliveryDate', sortable: true, isDate: true},
      {name: this.getTranslation('CALENDAR_PICKING'), field: 'pickingDate', sortable: true, isDate: true},
      {name: this.getTranslation('CALENDAR_DUE_DATE'), field: 'dueDate', sortable: true, isDate: true},
      {name: '', field: 'reviewNeeded', sortable: false},
      {name: this.getTranslation('LABEL_NOTES'), field: 'notes', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableCalendarDates",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsCalendarDate
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  getCalendarDateList() {
    let input: ICalendarDateGetAllBackOfficeRQ = {
      pageIndex: this.currentCalendarDatePage,
      sortOrder: this.orderCalendarDateDirection,
      idCalendar: this.id,
    }
    this.clientService.calendarDateGetAllBackOffice(new CalendarDateGetAllBackOfficeRQ(input)).subscribe(
      response =>{
        this.calendarDateList = response.list?.map(v => BackOfficeCalendarDateViewCustom.fromJS(v,  (key)=> this.getTranslation(key))) || [];
        this.anyResult = response.anyResult || false;
        this.tableCalendarDateConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableCalendarDateConfig.pagination.totalItems = response.count || 0;
        this.tableCalendarDateConfig.pagination.currentPage = this.currentCalendarDatePage;
        this.tableCalendarDateConfig.gridData = this.calendarDateList;
        this.bindButtonsCalendarDate(this.tableCalendarDateConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderCalendarDateChanged(orderDirection: string){
    this.orderCalendarDateDirection = orderDirection;
    this.getCalendarDateList();
  }

  pageCalendarDateChanged(pageNumber: any){
    this.currentCalendarDatePage = Number.parseInt(pageNumber);
    this.getCalendarDateList();
  }

  getUpdatedCalendarDateList(): any{
    return this.calendarDateList;
  }

  getTableCalendarDateConfig() {
    return this.tableCalendarDateConfig;
  }

  GoToCalendarDateDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      if(!event.record.isDeliveryDateReviewNeeded && !event.record.isPickingDateReviewNeeded && !event.record.isDueDateReviewNeeded)
        this.router.navigate(['/calendar-dates/detail', event.record.idCalendarDate]);
      else
      this.router.navigate(['/calendar-dates/edit', event.record.idCalendarDate]);
    }
  }

  bindButtonsCalendarDate(calendarDateList: BackOfficeCalendarDateViewCustom[]){
    this.tableCalendarDateConfig.recordButtonConfigs = new Map();
    for(let calendarDate of calendarDateList){
      this.tableCalendarDateConfig.recordButtonConfigs.set(calendarDate,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  tableCalendarPatientConfig =  {
    actions: true,
    gridData: this.calendarPatientList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
      {name: this.getTranslation('LABEL_CITY'), field: 'deliveryCity', sortable: true},
      {name: this.getTranslation('LABEL_POSTALCODE'), field: 'deliveryZipCode', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableCalendarDatePatients",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsCalendarPatient
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  getCalendarPatientList() {
    let input: ICalendarPatientGetAllBackOfficeRQ = {
      pageIndex: this.currentCalendarPatientPage,
      sortOrder: this.orderCalendarPatientDirection,
      idCalendar: this.id,
    }
    this.clientService.calendarPatientGetAllBackOffice(new CalendarPatientGetAllBackOfficeRQ(input)).subscribe(
      response =>{
        this.calendarPatientList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableCalendarPatientConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableCalendarPatientConfig.pagination.totalItems = response.count || 0;
        this.tableCalendarPatientConfig.pagination.currentPage = this.currentCalendarPatientPage;
        this.tableCalendarPatientConfig.gridData = this.calendarPatientList;
        this.bindButtonsCalendarPatient(this.tableCalendarPatientConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderCalendarPatientChanged(orderDirection: string){
    this.orderCalendarPatientDirection = orderDirection;
    this.getCalendarPatientList();
  }

  pageCalendarPatientChanged(pageNumber: any){
    this.currentCalendarPatientPage = Number.parseInt(pageNumber);
    this.getCalendarPatientList();
  }

  getUpdatedCalendarPatientList(): any{
    return this.calendarPatientList;
  }

  getTableCalendarPatientConfig() {
    return this.tableCalendarPatientConfig;
  }

  GoToCalendarPatientDetail(event: any) {
    if(event.action == 'edit'){
      this.router.navigate(['/patients/detail', event.record.idPatient]);
    }
  }

  bindButtonsCalendarPatient(calendarPatientList: BackOfficeCalendarPatientView[]){
    this.tableCalendarPatientConfig.recordButtonConfigs = new Map();
    for(let calendarPatient of calendarPatientList){
      this.tableCalendarPatientConfig.recordButtonConfigs.set(calendarPatient,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  isClosedEditPopup: boolean = true;
}
