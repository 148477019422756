<ngx-smart-modal #requestModal identifier="requestModal" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-header header-edit">
    <div class="container-fluid">
        <div class="text-center">
            <p class="modal-title mt-4">{{title}}</p>
            <p class="modal-description mt-4" [innerHtml]="description"></p>

            <form *ngIf="commentVisible" [formGroup]="formAdd">

                <hr/>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <app-input [inputConfig]="formInfo.input[0]" [formControlInput]="formAdd"
                            (keyup)="inputValues($event)"></app-input>
                    </div>
                </div>
            </form> 
        </div>
    </div>
</div>
<div class="modal-body mt-2 text-center d-flex flex-row justify-content-around">
    <app-button (click)="onCancel()" [buttonConfig]="cancelBtnConfig"></app-button>
    <app-button (click)="onClick()" [buttonConfig]="actionBtnConfig"></app-button>
</div>
</ngx-smart-modal>