<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'SIDEBAR_CALENDARS' | translate}}</h2>
            </div>
            <div class="col-6">
                <app-button (click)="calendarAdd()" [buttonConfig]="btnConfig" ></app-button>
            </div>
        </div>
        <br/>
        <div *ngIf="anyResult else noResultContent">
        <div class="row mt-2">
            <div class="col-10 d-flex align-items-end">
                <app-filters [filters]="this.filters" (onFilterToggle)="onFilterUpdate($event)"></app-filters>
            </div>
            <div class="col-2 d-flex filter-box edit_link">
                <i class="fa fa-trash" aria-hidden="true"></i>
                <app-text [text]="'CALENDAR_HISTORY' | translate" [fontSizePx]="12" [isBold]="true" [noWrap]="true"
                    (click)="GoToCalendarHistory()"></app-text>
            </div>
        </div>
        <br/>
        <app-table [tableConfig]= "tableConfig" (orderChanged)="orderCalendarChanged($event)" (onClick)="GoToCalendarDetail($event)" (pageChanged)="pageCalendarChanged($event)"></app-table>
    </div>
    <ng-template #noResultContent>
        <div class="text-center noResult">            
            <p class="alert">{{ 'CALENDAR_NORESULT' | translate }}</p>
            <p class="desc">{{ 'CALENDAR_NORESULT_DESC' | translate }}</p>
        </div>
    </ng-template>
</ng-template>
</div>
