import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SecurityContext } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit{
  

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if(this.inputConfig && this.inputConfig.name && !this.inputConfig.optional){
      if(this.inputConfig.inputName != "username" && 
       this.inputConfig.passwordIcon === undefined &&
       this.inputConfig.inputName != "phoneNumber" && !this.inputConfig.name.includes("*"))
      {
        this.inputConfig.name = this.inputConfig.name + " *";
      }
        
    }

    if(this.inputConfig.passwordIcon !== undefined){
      this.passwordIcon = this.inputConfig.passwordIcon ? '../../../../assets/icons/hide-password-icon.svg' : '../../../../assets/icons/show-password-icon.svg'
    }
    this.isPasswordInput = this.inputConfig.passwordIcon !== undefined
  }

  @Input() textValue: any;
  @Input() inputConfig: any = {} as any || [];
  @Input() formControlInput?: FormGroup;
  @Output() value : EventEmitter<any> = new EventEmitter<any>();
  @Input() avoidSanitizing?: boolean = false;
  passwordIcon = '';
  isPasswordInput = false

  inputValue(event: any){
    this.mandatoryValidation(event);
    this.inputConfig.value = event.target.value;
    this.value.emit(event);
  }

  mandatoryValidation(event: any){
    if(this.inputConfig && !this.inputConfig.optional && !event.target.value){
      this.inputConfig.errorMessage = this.getTranslation('FIELD_MANDATORY_LABEL');
      this.inputConfig.class = "input-form error"
    }
    else if(this.inputConfig && event.target.value && this.formControlInput?.get(this.inputConfig.inputName)?.errors?.email){
      this.inputConfig.errorMessage = this.getTranslation('EMAIL_NOT_VALID_LABEL');
      this.inputConfig.class = "input-form error"
    }
    else
    {
        this.inputConfig.errorMessage = null;
        this.inputConfig.class = "input-form " + this.inputConfig.class.replace('error', ' ');
      }
    }

  getTranslation(wordYouNeedToTranslate: string){
    return this.translate.instant(wordYouNeedToTranslate);
  }

  passwordIconChange(){
    if (this.inputConfig.type === 'password' && this.inputConfig.value) {
      this.inputConfig.type = 'text';
      this.passwordIcon = '../../../../assets/icons/show-password-icon.svg'
    } else {
      this.inputConfig.type = 'password';
      this.passwordIcon = '../../../../assets/icons/hide-password-icon.svg'
    }
  }

  customSanitize = (value: string, chars: string[]): string =>
    Array.from(value)
      .filter((carattere) => !chars.includes(carattere))
      .join('');

}
