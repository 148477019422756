import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css']
})
export class PhoneNumberComponent implements OnInit {

  @Input() phoneNumberConfig : any;
  @Output() prefixOutput = new EventEmitter<any>();
  @Output() phoneNumberOutput = new EventEmitter<any>();

  prefix: any;
  phoneNumber: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if(this.phoneNumberConfig.phoneNumberValue){
    let stringPhone = this.phoneNumberConfig.phoneNumberValue.toString();
    this.phoneNumber = stringPhone.substring(0,3)+"-"+stringPhone.substring(3,6)+"-"+stringPhone.substring(6,16);
    }
    if(this.phoneNumberConfig.autocompleteConfig.value){
      this.prefix=this.phoneNumberConfig.autocompleteConfig.value;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.phoneNumberConfig.phoneNumberValue){
      let stringPhone = this.phoneNumberConfig.phoneNumberValue.toString();
      this.phoneNumber = stringPhone.substring(0,3)+"-"+stringPhone.substring(3,6)+"-"+stringPhone.substring(6,16);
      }
      if(this.phoneNumberConfig.autocompleteConfig.value){
        this.prefix=this.phoneNumberConfig.autocompleteConfig.value;
      }
  }
  isNumericInput(event: any) {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105)
    );
  };

  isModifierKey(event: any){
	const key = event.keyCode;
	return (event.shiftKey === true || key === 35 || key === 36) ||
		(key === 8 || key === 9 || key === 13 || key === 46) ||
		(key > 36 && key < 41) ||
		(
			(event.ctrlKey === true || event.metaKey === true) &&
			(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
		)
};

isAllowed(event: any){
  this.phoneNumber=event.target.value;
  const input = event.target.value.replace(/\D/g,'').substring(0,17);
	if(!this.isNumericInput(event) && !this.isModifierKey(event)){
		event.preventDefault();
	}
};

formatToPhone(event: any){
  this.phoneNumber=event.target.value;
  if(!this.prefix){
    this.error();
  }
	if(this.isModifierKey(event) && event.keyCode != 8) {return;}
	const target = event.target;
	const input = event.target.value.replace(/\D/g,'').substring(0,15);
	const zip = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,15);

  if(input.length >= 10 && input.length <= 15){
    this.phoneNumber = parseInt(`${zip}${middle}${last}`);
    if(this.prefix){
    this.noError();
    }
  } else {
    this.error();
  }
	if(input.length > 6){
    target.value = `${zip}-${middle}-${last}`;
    this.phoneNumber = `${zip}-${middle}-${last}`;
  }
	else if(input.length > 3){target.value = `${zip}-${middle}`;
  }
	else if(input.length > 0){
    target.value = `${zip}`;
  }
  this.emit(`${zip}${middle}${last}`);
};

handlePrefix(event: any){
  this.prefix = event.target.value;
  if(this.prefix && this.phoneNumber && this.phoneNumber.length>=12 && this.phoneNumber.length<=17){
    this.noError();
  } else {
    this.error();
  }
  this.prefixOutput.emit(event);
}

emit(phoneNumber: any){
  let event = {
     target: {
       name: this.phoneNumberConfig.inputName,
       value: phoneNumber
     }
   }
   this.phoneNumberOutput.emit(event);
}

error(){
  /*this.phoneNumberConfig.class = 'input-form error';
  this.phoneNumberConfig.autocompleteConfig.class = 'form-control autocomplete-input error';
  this.phoneNumberConfig.errorMessage = this.getTranslation('TELEPHONE_NUMBER_ALERT');
  this.phoneNumberConfig.errorExample = this.getTranslation('TELEPHONE_NUMBER_EXAMPLE');*/
}
noError(){
  this.phoneNumberConfig.class = 'input-form';
  this.phoneNumberConfig.autocompleteConfig.class = 'form-control autocomplete-input';
  this.phoneNumberConfig.errorMessage = '';
  this.phoneNumberConfig.errorExample = '';
}

getTranslation(wordYouNeedToTranslate: string): string {
  return this.translate.instant(wordYouNeedToTranslate);
}

}
