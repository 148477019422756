import { Injectable } from '@angular/core';

@Injectable()
export class LocaleService {

  constructor() { }

  private _locale: string | undefined;

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en-GB';
  }

  public registerCulture(culture: string) {

    if (!culture) {
      return;
    }
    return culture;
  }
}