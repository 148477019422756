<div *ngIf="this.spinner" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>

<div  *ngIf="!this.spinner" class="container-fluid py-5 assign-calendar-page">
    <h3>{{ 'CALENDAR_ASSIGN' | translate }}</h3>
    <br/>
    <app-filters [filters]="this.filters" (onFilterToggle)="onFilterUpdate($event)"></app-filters>
    <br/>
    <app-assing-calendar-list [calendars]="calendarList"></app-assing-calendar-list>

    <div class="row mt-5">
        <div class="col-10 d-flex flex-row">
            <app-button [buttonConfig]="saveBtnConfig" (click)="showAssignCalendarModal()"></app-button>
        </div>
    </div>
</div>
