import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AdminGetParameterByCountryRS, AuthenticationService, BackOfficeSapSyncClinicView, ClientService, ClinicRSV2, ClinicSetRQ, Country, EntityRQ, IClinicSetRQ, ISapSyncClinicGetAllRQ, ISapSyncClinicHierarchyGetRQ, PharmacyGetAllRQ, PharmacyRS, SapSyncClinicAddressSetRQ, SapSyncClinicGetAllRQ, SapSyncClinicHierarchyCheckAlreadyExistsRS, SapSyncClinicHierarchyGetRQ, SapSyncClinicHierarchySetRQ } from 'src/app/core';
import { Role } from 'src/app/core/enum/roles';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-clinic-edit',
  templateUrl: './clinic-edit.component.html',
  styleUrls: ['./clinic-edit.component.css']
})
export class  ClinicEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  clinicDetails?: ClinicRSV2;
  loading: boolean = true;

  isSignatureNeeded = true;

  isClinicSelected: boolean = false;
  clinicSelected: BackOfficeSapSyncClinicView = new BackOfficeSapSyncClinicView();

  sapClinicList!: any | [];
  record?: any;
  totalItemsClinic: number = 0;
  anyResult: boolean = false;
  orderDirection?: string = "ShipToClinicName_asc";
  currentPage: number = 1;

  cultureList!: any | [];
  validationError!: any;

  filterName?: string;
  filterSapCode?: string;
  filterCity?: string;

  pharmacies: PharmacyRS[] = []
  availableCountries: Country[] = []

  isPDEnabled = this.utilsService.getParametersByKey("flow_trigger__is_in_center_pd_enabled")!.parameterValue === "True";
  isHDEnabled = this.utilsService.getParametersByKey("flow_trigger__is_in_center_hd_enabled")!.parameterValue === "True";
  isHHDEnabled = this.utilsService.getParametersByKey("flow_trigger__is_in_center_hhd_enabled")!.parameterValue === "True";
  isPharmacyRelatedToClinic = this.utilsService.getParametersByKey('flow_trigger__is_pharmacy_related_to_clinic').parameterValue == "True"; 
  
  isInCenterOrderingEnabled = false;
  isInCenterHDOrderingEnabled = false;
  isInCenterHHDOrderingEnabled = false;

  isAddingNewSapClinic = false;
  isNewSapClinicWithSameCodes = false;
  isSapClinicChecked = false
  sapSyncClinics: SapSyncClinicHierarchyCheckAlreadyExistsRS[] = [];
  sapSyncCreationErrorMessage = '';


  formClinicAdd: FormGroup = this.fb.group({
    shipTo:  [null, Validators.required],
    shipToClinicName: [null, Validators.required],
    shipToFullAddress: [null, Validators.required],
    soldTo: [null, Validators.required],
    soldToClinicName: [null, Validators.required],
    soldToFullAddress: [null, Validators.required],
    payer: [null, Validators.required],
    payerClinicName: [null, Validators.required],
    payerFullAddress: [null, Validators.required],
    countryIsoCode : [null, Validators.required],
    cultureIsoCode : [null, Validators.required],
    isFirstPartyClinic: [true, Validators.required],
    logisticService: [null],
    loadingEquipmentByContract: [null],
    idPharmacy: [null]
  });

  formNewClinicFromSap: FormGroup = this.fb.group({
    newShipTo: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    newSoldTo: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])], 
    newPayer: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    clinicData: this.fb.array([])
  })

  addSAPClinigBtnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('New Sap'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }
  

  autocompleteCountryConfigs: any[] = []; 

  continueButton = {
    bclass : 'btn btn-primary float-right add-list-button px-5',
    text : this.getTranslation('Continue'),
    disabled: true
  }
  
  saveButton = {
    bclass : 'btn btn-primary float-right add-list-button px-5',
    text : this.getTranslation('Create'),
    disabled: true
  }

  newSapClinicCodesInput = [
    { name: this.getTranslation('CLINIC_SHIP_TO'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'newShipTo', value: this.formClinicAdd.get("logisticService")?.value },
    { name: this.getTranslation('CLINIC_SOLD_TO'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'newSoldTo', value: this.formClinicAdd.get("logisticService")?.value },
    { name: this.getTranslation('CLINIC_PAYER'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'newPayer', value: this.formClinicAdd.get("logisticService")?.value },
  ]

  sapSyncInputConfig: any[][] = [
    [
      { name: 'ShipTo/SoldTo/Payer', optional: false, type: 'text', value: '', placeholder: '', class: 'input-form', inputName: 'sapUuid',  disabled: true, readOnly: true },
      { name: this.getTranslation('LABEL_CLINIC_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncClinicName',  disabled: false, readOnly: false },
      { name: this.getTranslation('LABEL_ADDRESS'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncAddress', disabled: false, readOnly: false },
      { name: this.getTranslation('LABEL_CITY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncCity', disabled: false, readOnly: false },
      { name: this.getTranslation('CLINIC_DETAIL_ZIPCODE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncZipCode', disabled: false, readOnly: false }
    ]
  ]

  formClinicInfo={
    key: 'formClinictInfo',
    input:
    [
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'shipTo', value: this.formClinicAdd.get("shipTo")?.value, disabled: true, readOnly: true },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'shipToClinicName', value: this.formClinicAdd.get("shipToClinicName")?.value,  disabled: true, readOnly: true },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'shipToFullAddress', value: this.formClinicAdd.get("shipToFullAddress")?.value,  disabled: true, readOnly: true },
      
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'soldTo', value: this.formClinicAdd.get("soldTo")?.value, disabled: true, readOnly: true },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'soldToClinicName', value: this.formClinicAdd.get("soldToClinicName")?.value,  disabled: true, readOnly: true },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'soldToFullAddress', value: this.formClinicAdd.get("soldToFullAddress")?.value,  disabled: true, readOnly: true },

      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'payer', value: this.formClinicAdd.get("payer")?.value, disabled: true, readOnly: true },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'payerClinicName', value: this.formClinicAdd.get("payerClinicName")?.value,  disabled: true, readOnly: true },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'payerFullAddress', value: this.formClinicAdd.get("payerFullAddress")?.value,  disabled: true, readOnly: true },

      { name: this.getTranslation('LABEL_COUNTRY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'countryIsoCode', value: this.formClinicAdd.get("countryIsoCode")?.value, disabled: true, readOnly: true },
      
      { name: this.getTranslation('CLINIC_LOGISTIC_SERVICE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'logisticService', value: this.formClinicAdd.get("logisticService")?.value },
    ],
    searchNameConfig: { placeholder: this.getTranslation('SEACH_PLH_CLINIC')},
    searchSapCodeConfig: { placeholder: this.getTranslation('SEACH_PLH_SAPCODE')},
    searchCityConfig: { placeholder: this.getTranslation('SEACH_PLH_CITY')},
    autocompleteCultureConfig: {
      name: this.getTranslation('LABEL_CULTURE'),
      attr: 'cultureName' as const,
      array: this.cultureList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'cultureIsoCode',
      class: 'form-control autocomplete-input',
      placeholder: this.getTranslation('COMMON_SELECT_LANGUAGE'),
    },
    radioButton:
    {
      id: 'isFirstPartyClinicRadio',
      count: '2',
      name: 'isFirstPartyClinic',
      labels: [this.getTranslation('CLINIC_DETAIL_FIRSTPARTY'), this.getTranslation('CLINIC_DETAIL_THIRDPARTY')],
      values: [true, false],
      selected : true,
      class: 'form4'
    },
    radioButtonLoadingEquipmentByContract:{      
      id: 'loadingEquipmentByContract',
      name: 'loadingEquipmentByContract',
      class : 'more-padding',
      labels: [
        this.getTranslation('PACKAGE_TYPE_PALLET'),
        this.getTranslation('PACKAGE_TYPE_ROLLING_CONTAINER'),
      ],
      values: ['PALLET','ROLLING_CONTAINER'],
      selected : '',
      divider: [false],
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_ADDCLINIC'),
    disabled : true
  }
  
  tableConfig =  {
    actions: true,
    gridData: this.sapClinicList,
    headerList: [
      {name: this.getTranslation('LABEL_CLINIC_NAME'), field: 'shipToClinicName', sortable: true},
      {name: this.getTranslation('SHIP_TO_CODE'), field: 'shipTo', sortable: true},
      {name: this.getTranslation('LABEL_CITY'), field: 'shipToCity', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsClinic
    },
    sort:{
      field: "shipToClinicName",
      direction: "asc"
    },
    notes: false
  };

  pharmacyAutocomplete = {
    name: this.getTranslation('PHARMACY_AUTOCOMPLETE_LABEL'),
    attr: 'pharmacyName' as const,
    array: this.cultureList,
    numberToShow: 6,
    prefix: false,
    value: null as any,
    inputName : 'idPharmacy',
    class: 'form-control autocomplete-input',
    placeholder: this.getTranslation('PHARMACY_LABEL'),
  }

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private authService: AuthenticationService,
    private editService: EditServiceService,
    private utilsService: UtilsService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !form.valid;
      });
    this.addBtnConfig.disabled = !form.valid;
  }

  inputValues(event: any){
    if(this.isAddingNewSapClinic){
      this.formNewClinicFromSap.controls[event.target.name].setValue(event.target.value);
      const { newShipTo, newSoldTo, newPayer } = this.formNewClinicFromSap.controls
      this.continueButton.disabled = !(newShipTo.valid && newSoldTo.valid && newPayer.valid)
    }
    else{
      if(event.target.name === 'isFirstPartyClinic'){
        this.formClinicInfo.radioButton.selected = event.target.value;
        this.formClinicAdd.controls[event.target.name].setValue(event.target.value);
      }
      else if(event.target.name === 'loadingEquipmentByContract'){
        this.formClinicInfo.radioButtonLoadingEquipmentByContract.selected = event.target.value;
        this.formClinicAdd.controls[event.target.name].setValue(event.target.value);
      }
      else{
        this.formClinicAdd.controls[event.target.name].setValue(event.target.value);
      }
      this.formValidation(this.formClinicAdd);
    }
    
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/clinics');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/clinics/detail', this.id]);
    }
  }

  ngOnInit(): void {

    this.getAllCulture();
    this.getAllCountry();
    this.getAllPharmacies('SE').then(()=>{
      this.id = this.route.snapshot.paramMap.get('id')?.toString();
      if(this.id !== undefined){
        this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
        this.getClinicDetail();
      }  
      else{
        const defaultCulture = this.authService.currentUserValue.cultureIsoCode;
        let cultureName = this.getTranslation("CULTURE_" + defaultCulture);
        if(cultureName.startsWith("CULTURE_")){
          cultureName = defaultCulture!;
        }
        this.formClinicInfo.autocompleteCultureConfig.value = defaultCulture !== "" ? 
        { cultureIsoCode: defaultCulture,
          cultureName: cultureName
        } : null as any;
        this.formClinicAdd.controls["cultureIsoCode"].setValue(this.formClinicInfo.autocompleteCultureConfig.value);

        if(this.clinicDetails?.pharmacyName && this.isPharmacyRelatedToClinic){
          this.pharmacyAutocomplete.value = this.clinicDetails.idPharmacy;
          this.formClinicAdd.controls["idPharmacy"].setValue(this.clinicDetails.idPharmacy);
        }
  
      }

    })
    
    if(this.isPharmacyRelatedToClinic){
      this.formClinicAdd.controls['idPharmacy'].setValidators([Validators.required])
    }else{
      this.formClinicAdd.controls['idPharmacy'].clearValidators();
    }
    this.formClinicAdd.updateValueAndValidity();
  }


  async getAllCountry(){
    const response = await this.clientService.countryGetAllV2(true).toPromise();
    response.forEach(element => {
      element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
      if(element.timeZoneName.startsWith("COUNTRY_")){
        element.timeZoneName = element.countryIsoCode;
      }
    });
    this.availableCountries = response;
  }

  getClinicDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.clinicGetV2(data).subscribe(
      async response =>{

        this.clinicDetails = response;

        this.clinicSelected.shipTo = response.shipTo ?? response.sapUuid;
        this.clinicSelected.shipToClinicName = response.shipToClinicName ?? "";
        this.clinicSelected.shipToAddress = response.shipToAddress ?? "";
        this.clinicSelected.shipToCity = response.shipToCity ?? "";
        this.clinicSelected.shipToZipCode = response.shipToZipCode ?? "";

        this.clinicSelected.soldTo = response.soldTo;
        this.clinicSelected.soldToClinicName = response.soldToClinicName ?? "";
        this.clinicSelected.soldToAddress = response.soldToAddress ?? "";
        this.clinicSelected.soldToCity = response.soldToCity ?? "";
        this.clinicSelected.soldToZipCode = response.soldToZipCode ?? "";

        this.clinicSelected.payer = response.payer;
        this.clinicSelected.payerClinicName = response.payerClinicName ?? "";
        this.clinicSelected.payerAddress = response.payerAddress ?? "";
        this.clinicSelected.payerCity = response.payerCity ?? "";
        this.clinicSelected.payerZipCode = response.payerZipCode ?? "";

        this.clinicSelected.root = response.root;

        this.isSignatureNeeded = response.isSignatureNeeded ?? true;
        this.isInCenterOrderingEnabled = response.isInCenterOrderingEnabled!;
        this.isInCenterHDOrderingEnabled = response.isInCenterHDOrderingEnabled!;
        this.isInCenterHHDOrderingEnabled = response.isInCenterHHDOrderingEnabled!;

        this.hierarchyClinic = response.hierarchyClinic;

        this.formClinicAdd.patchValue({
          shipTo: response.shipTo ?? response.sapUuid,
          shipToClinicName: response.shipToClinicName,
          shipToFullAddress: this.getFullAddress(response.shipToAddress!, response.shipToCity!, response.shipToZipCode!),
          soldTo: response.soldTo,
          soldToClinicName: response.soldToClinicName,
          soldToFullAddress: this.getFullAddress(response.soldToAddress!, response.soldToCity!, response.soldToZipCode!),
          payer: response.payer,
          payerClinicName: response.payerClinicName,
          payerFullAddress: this.getFullAddress(response.payerAddress!, response.payerCity!, response.payerZipCode!),
          countryIsoCode: response.countryIsoCode,
          cultureIsoCode: { cultureIsoCode: response.cultureIsoCode },
          isFirstPartyClinic: response.isFirstPartyClinic,
          logisticService: response.logisticService,
          loadingEquipmentByContract: response.loadingEquipmentByContract,
          idPharmacy: this.pharmacyAutocomplete.value
        });

        this.formClinicInfo!.input[0].value = this.formClinicAdd.get("shipTo")?.value,
        this.formClinicInfo!.input[1].value = this.formClinicAdd.get("shipToClinicName")?.value;
        this.formClinicInfo!.input[2].value = this.formClinicAdd.get("shipToFullAddress")?.value;

        this.formClinicInfo!.input[3].value = this.formClinicAdd.get("soldTo")?.value,
        this.formClinicInfo!.input[4].value = this.formClinicAdd.get("soldToClinicName")?.value;
        this.formClinicInfo!.input[5].value = this.formClinicAdd.get("soldToFullAddress")?.value;

        this.formClinicInfo!.input[6].value = this.formClinicAdd.get("payer")?.value,
        this.formClinicInfo!.input[7].value = this.formClinicAdd.get("payerClinicName")?.value;
        this.formClinicInfo!.input[8].value = this.formClinicAdd.get("payerFullAddress")?.value;

        this.formClinicInfo!.input[9].value = this.formClinicAdd.get("countryIsoCode")?.value;

        this.formClinicInfo!.input[10].value = this.formClinicAdd.get("logisticService")?.value;

        this.formClinicInfo!.radioButton.selected = this.clinicDetails.isFirstPartyClinic ?? true;
        this.formClinicInfo!.radioButton = {...this.formClinicInfo!.radioButton}

        this.formClinicInfo!.radioButtonLoadingEquipmentByContract.selected = 
          (this.clinicDetails.loadingEquipmentByContract !== '' && this.clinicDetails.loadingEquipmentByContract !== undefined) ?
          this.clinicDetails.loadingEquipmentByContract : 'PALLET';
        this.formClinicInfo!.radioButtonLoadingEquipmentByContract = {...this.formClinicInfo!.radioButtonLoadingEquipmentByContract}

        let cultureName: string = this.getTranslation("CULTURE_" + response.cultureIsoCode);
        if(cultureName.startsWith("CULTURE_")){
          cultureName = response.cultureIsoCode!;
        }
        this.formClinicInfo!.autocompleteCultureConfig.value = { 
          cultureIsoCode: this.clinicDetails.cultureIsoCode,
          cultureName: cultureName
        }

        await this.getDefaultByCountry(response.countryIsoCode!);
        
        this.isClinicSelected = true;


        if(this.clinicDetails?.idPharmacy && this.isPharmacyRelatedToClinic){
          this.pharmacyAutocomplete.value = this.pharmacies.find(ph=> ph.idPharmacy === this.clinicDetails?.idPharmacy); 
          this.pharmacyAutocomplete = {...this.pharmacyAutocomplete} 
        }

        
        this.formValidation(this.formClinicAdd);

        this.formClinicInfo!.autocompleteCultureConfig = {...this.formClinicInfo!.autocompleteCultureConfig}

        this.loading = false;
      },
      error=> console.log(error)
    );
  }


  async getAllPharmacies(countryIsoCode: string){
    this.pharmacies = await this.clientService.pharmacyGetAll(new PharmacyGetAllRQ({countryIsoCode : countryIsoCode})).toPromise();
    this.pharmacyAutocomplete.array = this.pharmacies;
    this.pharmacyAutocomplete = {...this.pharmacyAutocomplete};
  }

  getAllSapClinics(): any{
    let input: ISapSyncClinicGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName,
      sapUuid: this.filterSapCode,
      city: this.filterCity
    }
    this.clientService.sapSyncClinicGetAll(new SapSyncClinicGetAllRQ(input)).subscribe(
      response =>{
        this.sapClinicList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.sapClinicList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getAllCulture(){
    this.loading = true;
    this.clientService.cultureGetAll().subscribe(
      response =>{
        
        response.forEach(element => {
          element.cultureName = this.getTranslation("CULTURE_" + element.cultureIsoCode);
          if(element.cultureName.startsWith("CULTURE_")){
            element.cultureName = element.cultureIsoCode;
          }
        });
        this.formClinicInfo.autocompleteCultureConfig.array = response;
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  saveClinic(){
    if (this.formClinicAdd.valid) {

      this.loading = true;
      const requestBody = new ClinicSetRQ({
            idClinic: this.id,
            clinicName: this.clinicSelected.shipToClinicName,
            sapUuid: this.clinicSelected.soldTo, //SAPUUID = SOLD TO FOR NEW MANAGEMENT
            shipTo: this.clinicSelected.shipTo,
            soldTo: this.clinicSelected.soldTo,
            payer: this.clinicSelected.payer,
            root: this.clinicSelected.root,
            address: this.clinicSelected.shipToAddress,
            city: this.clinicSelected.shipToCity,
            zipCode: this.clinicSelected.shipToZipCode,
            countryIsoCode: this.formClinicAdd.get('countryIsoCode')?.value,
            cultureIsoCode: this.formClinicAdd.get('cultureIsoCode')?.value?.cultureIsoCode,
            isFirstPartyClinic: this.formClinicAdd.get('isFirstPartyClinic')?.value,
            isSignatureNeeded: this.isSignatureNeeded,
            isInCenterOrderingEnabled: this.isInCenterOrderingEnabled,
            isInCenterHDOrderingEnabled: this.isInCenterHDOrderingEnabled,
            isInCenterHHDOrderingEnabled: this.isInCenterHHDOrderingEnabled,
            logisticService: this.isInCenterHDOrderingEnabled ? this.formClinicAdd.get('logisticService')?.value : '',
            loadingEquipmentByContract: this.isInCenterHDOrderingEnabled ? this.formClinicInfo.radioButtonLoadingEquipmentByContract.selected : '',
            idPharmacy: (this.formClinicAdd.get('idPharmacy')?.value as PharmacyRS)?.idPharmacy ?? undefined
          });
      this.clientService.clinicSet(requestBody).subscribe(
        response =>{

          this.loading = false;

          if(response.success){

            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'CLINIC_ADD_SUCCESSFULLY',
                description: this.clinicSelected.shipToClinicName,
                info: 'CLINIC_ADD_SUCCESSFULLY_INFO',
                actionToDo: () => 
                {
                  this.router.navigate(['/clinics/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType)
              this.validationError = this.getTranslation(response?.errorType);
          }
        },
        error=> {
          this.loading = false;
          this.validationError = this.getTranslation("ERROR_GENERIC");
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  searchNameFilter(value:any){
    this.filterName = value;

    this.currentPage = 1;
    this.getAllSapClinics();
  }

  searchSapCodeFilter(value:any){
    this.filterSapCode = value;

    this.currentPage = 1;
    this.getAllSapClinics();
  }

  searchCityFilter(value:any){
    this.filterCity = value;

    this.currentPage = 1;
    this.getAllSapClinics();
  }

  GoToClinicDetail(event: any) {
    if(event.action == 'edit'){
      
      this.clinicSelected = event.record;
      this.formClinicInfo.input[0].value = event.record?.shipTo ?? null;
      this.formClinicInfo.input[1].value = event.record?.shipToClinicName ?? null;
      this.formClinicInfo.input[2].value = this.getFullAddress(event.record?.shipToAddress, event.record?.shipToCity, event.record?.shipToZipCode);

      this.formClinicInfo.input[3].value = event.record?.soldTo ?? null;
      this.formClinicInfo.input[4].value = event.record?.soldToClinicName ?? null;
      this.formClinicInfo.input[5].value = this.getFullAddress(event.record?.soldToAddress, event.record?.soldToCity, event.record?.soldToZipCode);

      this.formClinicInfo.input[6].value = event.record?.payer ?? null;
      this.formClinicInfo.input[7].value = event.record?.payerClinicName ?? null;
      this.formClinicInfo.input[8].value = this.getFullAddress(event.record?.payerAddress, event.record?.payerCity, event.record?.payerZipCode);

      this.formClinicInfo.input[9].value = event.record?.countryIsoCode ?? null;

      this.isSignatureNeeded = event.record?.isSignatureNeeded;

      this.formClinicInfo.input.forEach(element => {
        this.formClinicAdd.controls[element.inputName].setValue(element.value);
      });

      this.formValidation(this.formClinicAdd);

      this.getHierarchy(event.record?.shipTo, event.record?.soldTo, event.record?.payer);

      this.getDefaultByCountry(event.record?.countryIsoCode);
      
      this.isClinicSelected = true;
    }
  }

  orderClinicChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getAllSapClinics();
  }

  pageClinicChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getAllSapClinics();
  }

  getUpdatedClinicList(): any{
    return this.getAllSapClinics;
  }

  checkIsInCenterOrderingEnabled(){
    this.isInCenterOrderingEnabled = !this.isInCenterOrderingEnabled;
  }

  checkIsInCenterHDOrderingEnabled(){
    this.isInCenterHDOrderingEnabled = !this.isInCenterHDOrderingEnabled;
  }

  checkIsInCenterHHDOrderingEnabled(){
    this.isInCenterHHDOrderingEnabled = !this.isInCenterHHDOrderingEnabled;
  }

  bindButtons(clinicList: BackOfficeSapSyncClinicView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let clinic of clinicList){
      this.tableConfig.recordButtonConfigs.set(clinic,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getFullAddress(address: string, city: string, zipCode: string){
    return address + ", " + city + " (" + zipCode + ")";
  }

  hierarchyClinic: any;
  
  getHierarchy(shipTo: string, soldTo: string, payer: string){

    this.loading = true;

    let input: ISapSyncClinicHierarchyGetRQ = {
      shipTo: shipTo,
      soldTo: soldTo,
      payer: payer
    }
    this.clientService.sapSyncClinicHierarchyGet(new SapSyncClinicHierarchyGetRQ(input)).subscribe(
      response =>{

        this.hierarchyClinic = response;

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  async getDefaultByCountry(countryIsoCode: string){
    if(this.authService.currentUserValue.countryIsoCode == null)
    {
      const pdValue = await this.utilsService.getParameterByCountry("flow_trigger__is_in_center_pd_enabled", countryIsoCode!).toPromise();
      this.isPDEnabled = pdValue == "True";

      const hhdValue = await this.utilsService.getParameterByCountry("flow_trigger__is_in_center_hhd_enabled", countryIsoCode!).toPromise();
      this.isHHDEnabled = hhdValue == "True";

      const hdValue = await this.utilsService.getParameterByCountry("flow_trigger__is_in_center_hd_enabled", countryIsoCode!).toPromise();
      this.isHDEnabled = hdValue == "True";

      const pharmacyVal = await this.utilsService.getParameterByCountry("flow_trigger__is_pharmacy_related_to_clinic", countryIsoCode!).toPromise();
      this.isPharmacyRelatedToClinic = pharmacyVal == "True";
    }
  }




  /* ======== SapSync Clinic Management ========== */

  

  resetSapSyncDataStatus(){
    this.isAddingNewSapClinic = false;
    this.isNewSapClinicWithSameCodes = false;
    this.isSapClinicChecked = false
    this.sapSyncClinics = [];
    this.sapSyncInputConfig = [
      [
        { name: 'ShipTo/SoldTo/Payer', optional: false, type: 'text', value: '', placeholder: '', class: 'input-form', inputName: 'sapUuid',  disabled: true, readOnly: true },
        { name: this.getTranslation('LABEL_CLINIC_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncClinicName',  disabled: false, readOnly: false },
        { name: this.getTranslation('LABEL_ADDRESS'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncAddress', disabled: false, readOnly: false },
        { name: this.getTranslation('LABEL_CITY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncCity', disabled: false, readOnly: false },
        { name: this.getTranslation('CLINIC_DETAIL_ZIPCODE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapSyncZipCode', disabled: false, readOnly: false }
      ]
    ];
    this.continueButton.disabled = true
    this.saveButton.disabled = true
    this.sapSyncCreationErrorMessage = ''

    this.formNewClinicFromSap.reset();
    const clinics = (this.formNewClinicFromSap.controls['clinicData'] as FormArray)
    while(clinics.length !== 0){
      clinics.removeAt(0)
    }
    this.formNewClinicFromSap.controls['clinicData'].reset();
    this.newSapClinicCodesInput.forEach(inp=> inp.value = '')
    this.autocompleteCountryConfigs = [];
  }

  async checkForSapClinicByCodes(){
    var defaultIsoCountry = this.authService.currentUserValue.countryIsoCode === null ? null : this.availableCountries.find(x => x.countryIsoCode === this.authService.currentUserValue.countryIsoCode)

    const { newShipTo, newSoldTo, newPayer } = this.formNewClinicFromSap.controls
    const data = await this.clientService.sapSyncClinicHierarchyCheckAlreadyExists(new SapSyncClinicHierarchyGetRQ({ shipTo: newShipTo.value, soldTo: newSoldTo.value, payer: newPayer.value})).toPromise()

    this.sapSyncClinics = data;
    this.isSapClinicChecked = true;
    this.isNewSapClinicWithSameCodes = data.length === 1 && !data[0].isAlreadyExists && data[0].sapCodeType === 'ShipTo/SoldTo/Payer';
    if(data.length > 1){
      
      this.sapSyncInputConfig = [];
      data.forEach((clinic,i)=>{
        this.sapSyncInputConfig.push([
          { name: clinic.sapCodeType, optional: false, type: 'text', value: clinic.sapUuid, placeholder: '', class: 'input-form', inputName: 'sapUuid',  disabled: true, readOnly: true },
          { name: this.getTranslation('LABEL_CLINIC_NAME'), optional: false, type: 'text', value: clinic.clinicName, placeholder: '', class: 'input-form', inputName: 'sapSyncClinicName',  disabled: clinic.isAlreadyExists, readOnly: clinic.isAlreadyExists },
          { name: this.getTranslation('LABEL_ADDRESS'), optional: false, type: 'text', value: clinic.address, placeholder: '', class: 'input-form', inputName: 'sapSyncAddress', disabled: clinic.isAlreadyExists, readOnly: clinic.isAlreadyExists },
          { name: this.getTranslation('LABEL_CITY'), optional: false, type: 'text', value: clinic.city, placeholder: '', class: 'input-form', inputName: 'sapSyncCity', disabled: clinic.isAlreadyExists, readOnly: clinic.isAlreadyExists },
          { name: this.getTranslation('CLINIC_DETAIL_ZIPCODE'), optional: false, type: 'text', value: clinic.zipCode, placeholder: '', class: 'input-form', inputName: 'sapSyncZipCode', disabled: clinic.isAlreadyExists, readOnly: clinic.isAlreadyExists },
        ]);

        (this.formNewClinicFromSap.controls['clinicData'] as FormArray).push(
          this.fb.group({
            isAlreadyExists: [clinic.isAlreadyExists],
            sapSyncClinicName: [clinic.clinicName, Validators.required],
            sapSyncAddress: [clinic.address, Validators.required],
            sapSyncCity: [clinic.city, Validators.required],
            sapSyncZipCode: [clinic.zipCode, Validators.required],
            sapCodeType: [clinic.sapCodeType],
            sapUuid: [clinic.sapUuid],
            sapSyncCountryIsoCode: [clinic.countryIsoCode,Validators.required],
          })
        )

        const countryValue = clinic.isAlreadyExists ? { 
          countryIsoCode: clinic.countryIsoCode,
          timeZoneName: this.availableCountries.find(country=> country.countryIsoCode === clinic.countryIsoCode)!.countryName,
        } : null
        
        this.autocompleteCountryConfigs.push({
          name: this.getTranslation('LABEL_COUNTRY'),
          attr: 'timeZoneName' as const,
          array: this.availableCountries,
          numberToShow: 6,
          prefix: false,
          value : countryValue == null? defaultIsoCountry: countryValue,
          inputName : ('sapSyncCountryIsoCode' + i.toString()),
          class: 'form-control autocomplete-input',
          disabled: clinic.isAlreadyExists,
          placeholder: this.getTranslation('COMMON_SELECT_COUNTRY'),
        })

        if(countryValue === null && defaultIsoCountry !== null){
          this.onNewSapSyncClinicDataInputValuesCustom('sapSyncCountryIsoCode' + i, defaultIsoCountry!, i)
        }
        this.autocompleteCountryConfigs = [...this.autocompleteCountryConfigs]
      })
    }
    else
    {
      this.sapSyncInputConfig[0][0].value = data[0].sapUuid;
      (this.formNewClinicFromSap.controls['clinicData'] as FormArray).push(
        this.fb.group({
          isAlreadyExists: [false],
          sapSyncClinicName: [null, Validators.required],
          sapSyncAddress: [null, Validators.required],
          sapSyncCity: [null, Validators.required],
          sapSyncZipCode: [null, Validators.required],
          sapCodeType: [data[0].sapCodeType],
          sapUuid: [data[0].sapUuid], 
          sapSyncCountryIsoCode: [data[0].countryIsoCode, Validators.required],
        })
      )
      this.autocompleteCountryConfigs.push({
        name: this.getTranslation('LABEL_COUNTRY'),
        attr: 'timeZoneName' as const,
        array: this.availableCountries,
        numberToShow: 6,
        prefix: false,
        value : defaultIsoCountry,
        inputName : ('sapSyncCountryIsoCode' + 0),
        class: 'form-control autocomplete-input',
        disabled: false,
        placeholder: this.getTranslation('COMMON_SELECT_COUNTRY'),
      })

      if(defaultIsoCountry !== null){
        this.onNewSapSyncClinicDataInputValuesCustom('sapSyncCountryIsoCode' + 0, defaultIsoCountry!, 0 )
      }

    }
    this.autocompleteCountryConfigs = [...this.autocompleteCountryConfigs]
  }


  onNewSapSyncClinicDataInputValues(event: any, index: number){
    const curr = (this.formNewClinicFromSap.controls['clinicData'] as FormArray).at(index) as FormGroup;
    const { name, value }: {name: string, value: any} = event.target 
    // Set the right form based on country dropdown name
    if(name.includes('sapSyncCountryIsoCode')){
      const dynamicName = name.split(index.toString())[0]
      curr.controls[dynamicName].setValue(value)
    
    }else{
      curr.controls[event.target.name].setValue(event.target.value)
    }
    this.saveButton.disabled = !this.formNewClinicFromSap.controls['clinicData'].valid
  }

  onNewSapSyncClinicDataInputValuesCustom(name: string, value: Country, index: number){
    const curr = (this.formNewClinicFromSap.controls['clinicData'] as FormArray).at(index) as FormGroup;
    if(name.includes('sapSyncCountryIsoCode')){
      const dynamicName = name.split(index.toString())[0]
      curr.controls[dynamicName].setValue(value)    
    }else{
      curr.controls[name].setValue(value)
    }
    this.saveButton.disabled = !this.formNewClinicFromSap.controls['clinicData'].valid
  }

  async saveNewSapSyncClinic(){
    this.loading = true
    const newClinics = ((this.formNewClinicFromSap.controls['clinicData'] as FormArray).value as any[]).filter(clinicForm=> !clinicForm.isAlreadyExists)
    const rq = new SapSyncClinicHierarchySetRQ({
      shipTo: this.formNewClinicFromSap.get('newShipTo')!.value,
      soldTo: this.formNewClinicFromSap.get('newSoldTo')!.value,
      payer: this.formNewClinicFromSap.get('newPayer')!.value,
      addresses: newClinics.map(cl=> new SapSyncClinicAddressSetRQ({
        clinicName: cl.sapSyncClinicName,
        address: cl.sapSyncAddress,
        city: cl.sapSyncCity,
        zipCode: cl.sapSyncZipCode,
        sapUuid: cl.sapUuid,
        countryIsoCode: cl.sapSyncCountryIsoCode.countryIsoCode ?? this.authService.currentUserValue.countryIsoCode
      }))
    })
    const response = await this.clientService.sapSyncClinicHierarchySet(rq).toPromise();
    this.loading = false;
    this.sapSyncCreationErrorMessage = response.errorType ?? ''
    if(response.success){
      this.goToDetailAfterNewSapSyncClinic(response.sapSyncClinicHierarchyCreated!)
      this.resetSapSyncDataStatus()
    }
  }


  goToDetailAfterNewSapSyncClinic(clinicCreated: BackOfficeSapSyncClinicView){   
    const formClinics = ((this.formNewClinicFromSap.controls['clinicData'] as FormArray).value as any[]);
    const shipToClinic = formClinics.find(cl=> cl.sapCodeType.includes('ShipTo'))
    const soldToClinic = formClinics.find(cl=> cl.sapCodeType.includes('SoldTo'))
    const payerClinic = formClinics.find(cl=> cl.sapCodeType.includes('Payer'))

    const isSingleClinic = formClinics.length <= 1;
    const existingClinic = ((this.formNewClinicFromSap.controls['clinicData'] as FormArray).value as any[]).filter(clinicForm=> clinicForm.isAlreadyExists)

    this.clinicSelected = clinicCreated
    this.formClinicInfo.input[0].value = clinicCreated.shipTo ?? this.formNewClinicFromSap.get('newShipTo')!.value;
    this.formClinicInfo.input[1].value = clinicCreated.shipToClinicName ?? shipToClinic.sapSyncClinicName;
    this.formClinicInfo.input[2].value = this.getFullAddress(clinicCreated.shipToAddress ?? shipToClinic.sapSyncAddress, clinicCreated.shipToCity ?? shipToClinic.sapSyncCity, clinicCreated.shipToZipCode ?? shipToClinic.sapSyncZipCode);

    this.formClinicInfo.input[3].value = clinicCreated.soldTo ?? this.formNewClinicFromSap.get('newSoldTo')!.value;
    this.formClinicInfo.input[4].value = clinicCreated.soldToClinicName ?? soldToClinic.sapSyncClinicName;
    this.formClinicInfo.input[5].value = this.getFullAddress(clinicCreated.soldToAddress ?? soldToClinic.sapSyncAddress, clinicCreated.soldToCity ?? soldToClinic.sapSyncCity, clinicCreated.soldToZipCode ?? soldToClinic.sapSyncZipCode);

    this.formClinicInfo.input[6].value = clinicCreated.payer ?? this.formNewClinicFromSap.get('newPayer')!.value;
    this.formClinicInfo.input[7].value = clinicCreated.payerClinicName ?? payerClinic.sapSyncClinicName;
    this.formClinicInfo.input[8].value = this.getFullAddress(clinicCreated.payerAddress ?? payerClinic.sapSyncAddress, clinicCreated.payerCity ?? payerClinic.sapSyncCity, clinicCreated.payerZipCode ?? payerClinic.sapSyncZipCode);

    const countryToCheck = isSingleClinic ? formClinics[0].sapSyncCountryIsoCode.countryIsoCode : existingClinic[0].sapSyncCountryIsoCode
    this.formClinicInfo.input[9].value = this.availableCountries.find(ct=> ct.countryIsoCode == countryToCheck)!.countryIsoCode ?? null;

    this.isSignatureNeeded = clinicCreated.isSignatureNeeded ?? false;

    this.formClinicInfo.input.forEach(element => {
      this.formClinicAdd.controls[element.inputName].setValue(element.value);
    });

    this.formValidation(this.formClinicAdd);

    this.getHierarchy(clinicCreated.shipTo!, clinicCreated.soldTo!, clinicCreated.payer!);

    this.getDefaultByCountry(clinicCreated.countryIsoCode!);
    
    this.isClinicSelected = true;
  }
}
