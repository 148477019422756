<ngx-smart-modal #compareEntityModal identifier="compareEntityModal" [style.height.px]="'900'" [style.width.px]="'1400'" data-backdrop="static" id="compareEntityModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="modal-header">
  
      <h2>Compare Text</h2>
  
      <app-round-button [type]="'CLOSE'" (onClick)="closeModal()"></app-round-button>
    </div>
    <div style="overflow-x:auto;" class="release-content">
      <table class="table">
        <thead>
          <tr *ngFor="let key of keys">
            <th>{{key}}</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let key of keys">  
          <ng-container *ngFor="let obj of selectedRecords, let i=index">
            <td *ngIf="i == 0" nowrap [innerHtml]="obj[key]">{{obj[key]}}</td>
            <td *ngIf="i > 0" [ngStyle]="{'background-color': !skipColumns.includes(key) && obj[key] != selectedRecords[i-1][key] ? 'yellow' : null}"
            nowrap [innerHtml]="calcDiff(selectedRecords[i-1][key], obj[key], key)">{{calcDiff(selectedRecords[i-1][key], obj[key], key)}}</td>
          </ng-container>
          
        </tbody>
      </table>
    </div>
  </ngx-smart-modal>