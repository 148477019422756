import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-separated-date',
  templateUrl: './separated-date.component.html',
  styleUrls: ['./separated-date.component.css']
})
export class SeparatedDateComponent implements OnInit {
  @Output() sendDate: EventEmitter<any> = new EventEmitter<any>();

  day: any;
  month: any;
  year: any;
  error = false;

  @Input() separateDateConfig: any;

  ngOnInit(): void {
    this.day = this.separateDateConfig.input[0].value;
    this.month = this.separateDateConfig.input[1].value;
    this.year = this.separateDateConfig.input[2].value;
  }

  constructor(private translate: TranslateService) {}

  dateChange(event?: any) {

    if(this.day){
      this.day = ("0"+this.day.toString()).slice(-2);
    }
    if(this.month){
      this.month = ("0"+this.month.toString()).slice(-2);
    }
    if(this.year && this.year.toString().length>4){
      this.year = (this.year.toString()).slice(0,4);
    }
    if (this.day !== "" && this.month !== "" && this.year !== "") {
      const dateD = new Date(+this.year, +this.month - 1, +this.day);
      const today = new Date();
      if (
        +this.year >= 1900 &&
        dateD.getTime() < today.getTime() &&
        dateD.getDate() == +this.day &&
        dateD.getMonth() == +this.month - 1 &&
        dateD.getFullYear() == +this.year &&
        this.day.length==2 &&
        this.month.length==2
      ) {
        this.separateDateConfig.input[0].value = this.day;
        this.separateDateConfig.input[1].value = this.month;
        this.separateDateConfig.input[2].value = this.year;
        let e = {
          target:{
            name: this.separateDateConfig.input[0].inputName,
            value: dateD
          }
        }
        this.sendDate.emit(e);
        this.dateIsValid();
      } else {
        let e = {
          target:{
            name: this.separateDateConfig.input[0].inputName,
            value: null
          }
        }
        this.sendDate.emit(e);
        this.dateIsNotValid();
      }
    }
  }

  dateIsValid(){
    this.separateDateConfig.message.class = "message";
    this.separateDateConfig.errorMessage = "";
    this.separateDateConfig.input[0].class = "input-form";
    this.separateDateConfig.input[1].class = "input-form";
    this.separateDateConfig.input[2].class = "input-form";
  }

  dateIsNotValid(){
    this.error = true;
    this.separateDateConfig.message.class = "error-message";
    this.separateDateConfig.errorMessage = this.getTranslation('DATE_OF_BIRTH_ALERT')
    this.separateDateConfig.input[0].class = "input-form error";
    this.separateDateConfig.input[1].class = "input-form error";
    this.separateDateConfig.input[2].class = "input-form error";
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
