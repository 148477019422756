<ngx-smart-modal #accountLockedModal identifier="accountLockedModal"  data-backdrop="static" id="accountLockedModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div>
        <app-round-button [type]="'CLOSE'" (onClick)="accountLockedModal.close()"></app-round-button>
    </div>
    <div class="modal-body">
        <!-- account locked page -->
        <div class="locked">
            <div class="row mt-4 justify-content-center">
                <img src="../../assets/images/account-locked.png" alt="patient-registration-success" />
            </div>
            <div class="row mt-5 mb-5">
                <div class="col-12 d-flex justify-content-center">
                    <h3 class="text-center"> {{'LOCKED_ACCOUNT_TITLE' | translate}}</h3>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <span class="text-center">{{'LOCKED_ACCOUNT_DESC' | translate}}</span>
                </div>                
            </div>
            <div class="row mt-5 justify-content-center">
                <app-button [buttonConfig]="okBtnConfig" (onClick)="accountLockedModal.close()"></app-button>
            </div>
        </div>
    </div>
</ngx-smart-modal>
