<ngx-smart-modal #patientDeliveryFeeModal identifier="patientDeliveryFeeModal" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-body mt-2">
    <form [formGroup]="formDeliveryFeeAdd">    
        <p class="modal-title mt-4">{{'COMMON_EDIT_DATA' | translate}}</p>
        <div class="row my-2">
            <div class="col-12">
                <app-input [inputConfig]="formDeliveryFeeInfo.input[1]" (keyup)="inputValues($event)"></app-input>
            </div>
        </div>
        <div class="row my-2">
            <div class="col-12">
                <app-input [inputConfig]="formDeliveryFeeInfo.input[0]" (keyup)="inputValues($event)"></app-input>
            </div>
        </div>
        <hr/>
        <div class="modal-body mt-2 text-center d-flex flex-row justify-content-around">
            <app-button (click)="cancel()" [buttonConfig]="cancelBtnConfig"></app-button>
            <app-button (click)="saveDeliveryFee()" [buttonConfig]="savetnConfig"></app-button>
        </div>
    </form>
</div>
</ngx-smart-modal>