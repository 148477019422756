import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeCountryResponsibleView, BaseGetAllRQ, ClientService, Country, CountryGetRS, CountryRegion, IBaseGetAllRQ, Parameter } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { BackOfficeCountryResponsibleViewCustom } from 'src/app/core/models/BackOfficeCountryResponsibleView-custom.model';

@Component({
  selector: 'app-country-detail',
  templateUrl: './country-detail.component.html',
  styleUrls: ['./country-detail.component.css']
})
export class CountryDetailComponent implements OnInit {
  id?: string;
  countryDetail?: CountryGetRS;
  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  loading: boolean = true;

  parameterList! : Parameter[];  
  regionList! : CountryRegion[]; 
  countryResponsibleList! : BackOfficeCountryResponsibleViewCustom[]; 

  totalItemsParameters: number = 0;
  totalItemsRegions: number = 0; 
  totalItemsCountryResponsible: number = 0; 

  filterParameterName?: string;
  filterRegionName?: any;
  filterCountryResponsible?: any;

  orderParameterDirection?: string = "ParameterKey_asc";
  orderRegionDirection?: string = "Description_asc";
  orderCountryResponsibleDirection?: string = "Role_asc";

  currentParameterPage: number = 1;
  currentRegionPage: number = 1;
  currentCountryResponsiblePage: number = 1;

  searchParameterConfig = { placeholder: this.getTranslation('SEACH_PLH_NURSE')}
  searchRegionConfig = { placeholder: this.getTranslation('SEACH_PLH_NURSE')}

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private editService: EditServiceService
  ) {}

  tableParameterConfig =  {
    actions: true,
    gridData: this.parameterList,
    headerList: [
      {name: this.getTranslation('Functionality'), field: 'functionality', sortable: true},
      {name: this.getTranslation('Value'), field: 'value', sortable: true},
      {name: this.getTranslation('Description'), field: 'description', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableParameter",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsParameters
    },
    sort:{
      field: "parameterKey",
      direction: "asc"
    },
    notes: false
  };

  tableRegionConfig =  {
    actions: true,
    gridData: this.regionList,
    headerList: [
      {name: "RegionCode", field: 'regionCode', sortable: true},
      {name: "Description", field: 'description', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableRegion",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsParameters
    },
    sort:{
      field: "description",
      direction: "asc"
    },
    notes: false
  };

  tableCountryResponsibleConfig =  {
    actions: true,
    gridData: this.regionList,
    headerList: [
      {name: this.getTranslation("LABEL_ROLE"), field: 'role', sortable: true},
      {name: this.getTranslation("USERIDENTITY_SURNAME"), field: 'surname', sortable: true},
      {name: this.getTranslation("USERIDENTITY_NAME"), field: 'name', sortable: true},
      {name: this.getTranslation("USERIDENTITY_EMAIL"), field: 'emailAddress', sortable: true},      
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableCountryResponsible",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsParameters
    },
    sort:{
      field: "role",
      direction: "asc"
    },
    notes: false
  };

  orderParameterChanged(orderDirection: string){
    this.orderParameterDirection = orderDirection;
  }

  pageParameterChanged(pageNumber: any){
    this.currentParameterPage = Number.parseInt(pageNumber);
  }

  orderRegionChanged(orderDirection: string){
    this.orderRegionDirection = orderDirection;
  }

  pageRegionChanged(pageNumber: any){
    this.currentRegionPage = Number.parseInt(pageNumber);
  }

  orderCountryResponsibleChanged(orderDirection: string){
    this.orderCountryResponsibleDirection = orderDirection;
  }

  pageCountryResponsibleChanged(pageNumber: any){
    this.currentCountryResponsiblePage = Number.parseInt(pageNumber);
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getCountryDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/countries");
  }

  EditCountry() {
    this.router.navigate(['/countries/edit', this.id]);
  }

  getCountryDetail(){

    this.loading = true;
    this.clientService.countryGet(this.id).subscribe(
      response =>{
        this.countryDetail = response;
        this.parameterList = response.parameters || [];
        this.regionList = response.regions || [];
        this.countryResponsibleList = response.countryResponsibles?.map(v => new BackOfficeCountryResponsibleViewCustom(v)) || [];

        this.tableParameterConfig.pagination.itemsPerPage = 10;
        this.tableParameterConfig.pagination.totalItems = this.parameterList.length || 0;
        this.tableParameterConfig.pagination.currentPage = this.currentParameterPage;
        this.tableParameterConfig.gridData = this.parameterList;

        this.tableRegionConfig.pagination.itemsPerPage = 10;
        this.tableRegionConfig.pagination.totalItems = this.regionList.length || 0;
        this.tableRegionConfig.pagination.currentPage = this.currentRegionPage;
        this.tableRegionConfig.gridData = this.regionList;

        this.tableCountryResponsibleConfig.pagination.itemsPerPage = 10;
        this.tableCountryResponsibleConfig.pagination.totalItems = this.countryResponsibleList.length || 0;
        this.tableCountryResponsibleConfig.pagination.currentPage = this.currentCountryResponsiblePage;
        this.tableCountryResponsibleConfig.gridData = this.countryResponsibleList;
        this.bindButtons(this.tableCountryResponsibleConfig.gridData);

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
      window.location.reload();
    }, 5000);
  }

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('COUNTRY_RESPONSIBLE_ADD_TITLE'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  countryResponsibleAdd(){
    this.router.navigate(['/country-responsibles/add', this.countryDetail?.countryIsoCode ]);
  }

  bindButtons(countryResponsibleList: BackOfficeCountryResponsibleViewCustom[]){
    this.tableCountryResponsibleConfig.recordButtonConfigs = new Map();
    for(let countryResponsible of countryResponsibleList){

      this.tableCountryResponsibleConfig.recordButtonConfigs.set(countryResponsible,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  GoToCountryResponsibleDetail(event: any) {
    this.editService.showEditSnackBar = false;
    if(event.record.idCountryResponsible == undefined){
      this.router.navigate(['/country-responsibles/add', event.record.countryIsoCode, event.record.role ]);
    }
    else
      this.router.navigate(['/country-responsibles/detail', event.record.idCountryResponsible]);
  }
}
