import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateService } from 'src/app/core/services/date.service';

@Pipe({
    name: 'DateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
    
    constructor(public date : DateService){}

    transform(value: Date | undefined, isDateTime: boolean = false) {
        if(value == undefined)
            return "-";

        return isDateTime ? this.date.dateParseWithHour(value!) : this.date.dateParse(value);
    }
}