import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { AuthenticationService, CheckIfMobileNumberIsValidRQ, ClientService, EditContactsRQ, ICheckIfMobileNumberIsValidRQ, IEditContactsRQ, ILanguageSetLanguageRQ, JwtService, LanguageSetLanguageRQ } from 'src/app/core';
import { NavbarService } from 'src/app/core/services/navbar.service';
import { TabService } from 'src/app/core/services/tab.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit{

  constructor(
    private navbarService: NavbarService,
    private jwt: JwtService,
    private translate : TranslateService,
    private clientService: ClientService,
    private authService: AuthenticationService,
    private utilsService: UtilsService,
    private tabService: TabService,
    private smartModal : NgxSmartModalService,
    private cookieService: CookieService,
    private fb: FormBuilder,
  ) { }

  fullname: string = "";
  email: string = "";
  mobilePrefixCode: string = "";
  mobilePrefix: string = "";
  mobile: string = "";
  role: string = "";
  isDemoUserManagementEnabled: boolean = false;
  
  modalTitle = "";
  modalType?: any = ''
  clickDisabled: any;
  snackBarText = ''
  nextBtnConfig: any;
  countryPhonePrefixList!: any | [];

  isEditMode: boolean = false;

  validationError!: any;
  mobileNumberIsValid: boolean = true;
  emailAddressIsValid: boolean = true;
  previousEmail: any = "";
  previousPrefixCode: any = "";
  previousPhonePrefix: any = "";
  previousMobileNumber: any = "";

  saveBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_COMMON_SAVE'),
    disabled : true
  }

  formContactEdit: FormGroup = this.fb.group({
    email : [null, [Validators.required, Validators.pattern(new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/))]],
    phonePrefix : [null, Validators.required],
    mobileNumber : [null, Validators.required]
  });

  formContactInfo={
    key: 'formContactInfo',
    input:
    [
      { optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'email', value: this.email, maxLength: 256 },
      { optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'mobileNumber', value: this.mobile, maxLength: 16 },
    ],
    autocompleteCountryPhonePrefixConfig: {
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      prefix: '+',
      value : null as any,
      inputName : 'phonePrefix',
      class: 'form-control autocomplete-input'
    }
  }

  inputValues(event: any){
    this.validationError = null;
    this.formContactEdit.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.formContactEdit);
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.saveBtnConfig.disabled = !(form.valid && this.mobileNumberIsValid && this.emailAddressIsValid);
      });
    this.saveBtnConfig.disabled = !(form.valid && this.mobileNumberIsValid && this.emailAddressIsValid);
  }

  actionsButton = [{
    bclass: 'btn prescription-actions-button',
    type: 'pencil',
    width: '32px',
    height: '32px',
  },
  {
    bclass: 'btn prescription-actions-button',
    type: 'pencil',
    width: '32px',
    height: '32px',
  }]

  languageBlock: any ={
    radioButton:
    {
      radioButtonName: 'solutionType',
      selectedValue: '',
      extraClass: 'max-height-14',
      values:[],
      withDivider: true
    },
  }

  profileSubscriptions?: Subscription[]

  ngOnInit(): void {
    
    let infoSubcription = this.navbarService.getProfileInfoType()!.subscribe(
      response=>{
        this.modalType = response
        this.getInformations();
        if(this.modalType === 'language'){
          this.languageBlock.radioButton.selectedValue = this.utilsService.getDefaultLanguage()
        }
      }
    )  

    this.profileSubscriptions?.push(infoSubcription)

    this.utilsService.allLanguages.forEach(x => {
      this.languageBlock.radioButton.values.push({label: x.languageName, value: x.isoCode});
    });

    this.navbarService.getProfileInfoType()?.subscribe(
      response=>{
        this.nextBtnConfig  = {
          bclass : 'btn normal-button-next text-center',
          type : 'button',
          text : this.getTranslation('BTN_NEXT'),
          disabled : false
        }
        this.modalType = response
        this.setButtonText();
      }
    )

    this.countryPhonePrefixList = this.getAllCountryPhonePrefix();
  }

  ngAfterViewInit() {

    this.formContactEdit.get("email")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      if(this.previousEmail != this.formContactEdit.get("workEmail")?.value)
        this.checkEmailAddressIsValid();
    });

    this.formContactEdit.get("mobileNumber")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkMobileIsValid();
    });

    this.formContactEdit.get("phonePrefix")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkMobileIsValid();
    });

  }

  setButtonText(){
    switch(this.modalType){
      case 'userInfo':
        this.modalTitle = this.fullname;
        this.nextBtnConfig.text = this.getTranslation('LOGOUT_LABEL');
        this.nextBtnConfig.bclass = 'btn normal-button-next';
        break;
      case 'language':
        this.modalTitle = this.getTranslation('CHOOSE_LANGUAGE_TITLE_LABEL');
        this.nextBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE');
        this.nextBtnConfig.bclass = 'btn button-next';
        break;
      default:
        this.nextBtnConfig.text = this.getTranslation('BTN_NEXT');
        break;
    }
  }

  getLanguageRadio(event: any){
    this.languageBlock.radioButton.selectedValue = event.target.value;
  }

  changeLanguage(){
    let lang: ILanguageSetLanguageRQ = {isoCode: this.languageBlock.radioButton.selectedValue}
    this.clientService.setBackOfficeUserLanguage(new LanguageSetLanguageRQ(lang)).subscribe(
      ()=>{
        this.utilsService.setDefaultLanguage(this.languageBlock.radioButton.selectedValue); 
        this.authService.changeLanguageFlow();
        this.resetFields();
        this.smartModal.getModal('patientProfileModal').close();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error=>console.log(error)
    )
  }

  logout(){
    this.tabService.emptyTabs();
    this.resetFields();
    this.smartModal.getModal('patientProfileModal').close();
    this.authService.logout(false);
  }

  changeStep(step: string){
    this.modalType = step;
  }

  resetFields(){
    this.languageBlock.radioButton.selected = '';
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  editMode(){
    this.isEditMode = !this.isEditMode;
  }

  getAllCountryPhonePrefix(){
    this.clientService.countryPhonePrefixGetAll().subscribe(
      response =>{
        this.formContactInfo.autocompleteCountryPhonePrefixConfig.array = response;
      },
      error=> console.log(error)
    )
  }

  saveContacts(){

    if (this.formContactEdit.valid) {

      let request: ICheckIfMobileNumberIsValidRQ;
      request = {
        mobileNumberCountryPrefix: this.formContactEdit.get('phonePrefix')?.value?.phonePrefix,
        mobileNumber: this.formContactEdit.get('mobileNumber')?.value,
      }
      this.clientService.checkIfMobileNumberIsValid(new CheckIfMobileNumberIsValidRQ(request)).subscribe( 
        response =>{

          if(response.isValid == true){

            let request: IEditContactsRQ;
            request = {
              emailAddress: this.formContactEdit.get('email')?.value,
              mobileNumberCountryPrefixCode: this.formContactEdit.get('phonePrefix')?.value?.countryIsoCode ?? null,
              mobileNumberCountryPrefix: this.formContactEdit.get('phonePrefix')?.value?.phonePrefix,
              mobileNumber: this.formContactEdit.get('mobileNumber')?.value,
            }
            this.clientService.editContacts(new EditContactsRQ(request)).subscribe(
              response =>{

                if(response == true){
                  this.getInformations();
                }
              },
              error=> console.log(error)
            )
          }
          else{
            this.validationError = this.getTranslation(response.errorType || "");
          }
        },
        error=> console.log(error)
      )
    }
  }

  getInformations(){
    this.isEditMode = false;
    this.validationError = null;

    this.clientService.getLoggedUser()?.subscribe(response => {
      this.fullname = response.surname + " " + response.name;
      this.role = response.role!;
      this.isDemoUserManagementEnabled = response.isDemoUserManagementEnabled!;
      this.email = response.email!;
      this.previousEmail = response.email!;
      this.mobilePrefixCode = response.mobileNumberCountryPrefixCode!;
      this.previousPrefixCode = response.mobileNumberCountryPrefixCode!;
      this.mobilePrefix = response.mobileNumberCountryPrefix!;
      this.previousPhonePrefix = response.mobileNumberCountryPrefix!;
      this.mobile = response.mobileNumber!;
      this.previousMobileNumber = response.mobileNumber!;

      this.formContactEdit.patchValue({ 
        email: this.email,
        mobileNumber: this.mobile,
        phonePrefix: { phonePrefix: this.mobilePrefix, countryIsoCode: this.mobilePrefixCode ?? null } 
      });
      
      this.formContactInfo.input[0].value = this.email;
      this.formContactInfo.input[1].value = this.mobile;

      this.formContactInfo.autocompleteCountryPhonePrefixConfig.value =
      { 
        phonePrefix: this.mobilePrefix,
        countryIsoCode: this.mobilePrefixCode
      };
      this.formContactInfo.autocompleteCountryPhonePrefixConfig = {...this.formContactInfo.autocompleteCountryPhonePrefixConfig}

      this.formValidation(this.formContactEdit);
    });
  }

  checkMobileIsValid(){
    this.formContactInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input";
      
    this.utilsService.checkMobileNumberIsValid(this.authService.currentUserValue.idUserIdentity, this.formContactEdit.get('phonePrefix')?.value?.countryIsoCode, this.formContactEdit.get('phonePrefix')?.value?.phonePrefix, this.formContactEdit.get('mobileNumber')?.value, this.authService.currentUserValue.role!).pipe(first()).subscribe(
      data => {
        this.mobileNumberIsValid = data;
        if(!data) {
          this.formContactInfo.input[1].class = "input-form error";
          this.formContactInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
          this.validationError = this.getTranslation("ERROR_MOBILE_ALREADY_EXISTS");
        }
        this.formValidation(this.formContactEdit);
      }
    )
  }

  checkEmailAddressIsValid(){      
    this.utilsService.checkEmailAddressIsValid(this.authService.currentUserValue.idUserIdentity, this.formContactEdit.get('email')?.value, this.authService.currentUserValue.role!).pipe(first()).subscribe(
      data => {
        this.emailAddressIsValid = data;
        if(!data) {
          this.formContactInfo.input[0].class = "input-form error";
          this.validationError = this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS");
        }
        this.formValidation(this.formContactEdit);
      }
    )
  }

  closeModal(){
    this.isEditMode = false;
    this.formContactInfo.input[0].value = this.previousEmail;
    this.formContactInfo.input[1].value = this.previousMobileNumber;
    this.formContactInfo.autocompleteCountryPhonePrefixConfig.value =
      { 
        phonePrefix: this.previousPhonePrefix,
        countryIsoCode: this.previousPrefixCode
      };
    this.formContactInfo.autocompleteCountryPhonePrefixConfig = {...this.formContactInfo.autocompleteCountryPhonePrefixConfig}
    this.validationError = '';
    this.smartModal.getModal('patientProfileModal').close();
  }
}
