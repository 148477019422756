import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';
import {distinctUntilChanged, map, filter} from 'rxjs/operators';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class AutocompleteDropdownComponent implements OnInit {

  @Input() autocompleteConfig: any = {} as any || [];
  @Output() selected = new EventEmitter<any>();
  public model: (any) | undefined;

  constructor(
    private utilService: UtilsService,
    ) {
  }

  ngOnInit() {
    if(this.autocompleteConfig.value){
      this.model = this.autocompleteConfig.value;
    } else {
      this.model = null;
    }
    if(this.autocompleteConfig.filterIsVisible){
      this.autocompleteConfig.class = this.autocompleteConfig.class + " form-control-filter-dark";
    }
    else if(!this.autocompleteConfig.optional && this.autocompleteConfig.name && !this.autocompleteConfig.name.includes("*")){
      this.autocompleteConfig.name = this.autocompleteConfig.name + " *";
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.autocompleteConfig.value){
      this.model = this.autocompleteConfig.value;
    } else {
      this.model = null;
    }
  }
  emit(event: any){
    this.model = event.item
     let e = {
        target:{
          name: this.autocompleteConfig.inputName,
          value: event.item
        }
      }
      this.autocompleteConfig.value = event.item;
      this.selected.emit(e);
      let inputValue = document.querySelector('input[name="'+this.autocompleteConfig.inputName+'"]') as HTMLInputElement;
      inputValue.blur();
  }

  addClass(){
    if(!this.autocompleteConfig.prefix){
    let items = document.querySelectorAll('button.dropdown-item').forEach((item)=>{
            if(!item.classList[1] || item.classList[1]==='active'){
        if(!this.autocompleteConfig.prefix){
          item.classList.add('location');
        }
      }
    })
    }
  }

  formatter = (array: { [x: string]: any; }) => this.autocompleteConfig.prefix ? this.autocompleteConfig.prefix + array[this.autocompleteConfig.attr] : array[this.autocompleteConfig.attr];

  search: OperatorFunction<string, any> = (text$: Observable<string>) => text$.pipe(
    map(term => this.autocompleteConfig.array.filter((elem : any) => this.isValid(term) && this.matchPattern(elem[this.autocompleteConfig.attr], term))))


    
  show(){
    this.autocompleteConfig.errorMessage = '';
    this.autocompleteConfig.class = 'form-control autocomplete-input';
    if(this.autocompleteConfig.filterIsVisible){
      this.autocompleteConfig.class = this.autocompleteConfig.class + " form-control-filter-dark";
    }
    if(!this.autocompleteConfig.readOnly){
    if(this.autocompleteConfig.value){
      let e = {item : null}
      this.emit(e);
    }
    const event = new Event('input', { bubbles: true });
    let inputValue = document.querySelector('input[name="'+this.autocompleteConfig.inputName+'"]') as HTMLInputElement;
    inputValue.value = "";
    inputValue.dispatchEvent(event);
    inputValue.focus();

    this.addClass();
    }
  }

  clearSelection(){
    let e = {item : null}
    this.emit(e);
  }

  isValid(term: string){
    return term.match("^[a-zA-Z \-_. \x7f-\xff]+$") || /^\d+$/.test(term) || term=="" || term.startsWith("+");
  }

  matchPattern(word: string, pattern: string){
    let match = false;
    word = word.toString().toLowerCase();
    pattern = pattern.toString().toLowerCase();
    if(this.autocompleteConfig.prefix && pattern.startsWith("+")){
      word = "+" + word;
    }
    match = word.includes(pattern);
    return match;
  }
}
