import { IClinicUnloadingPointRS, } from "../services";

export class ClinicUnloadingPointRSCustom implements IClinicUnloadingPointRS {
    idClinicUnloadingPoint?: string;
    name?: string | undefined;
    dateDeleted?: Date | undefined;
    isEditMode?: boolean = false;

    constructor(data?: IClinicUnloadingPointRS) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.idClinicUnloadingPoint = _data["idClinicUnloadingPoint"];
            this.name = _data["name"];
            this.dateDeleted = _data["dateDeleted"] ? new Date(_data["dateDeleted"].toString()) : <any>undefined;
            this.isEditMode = false;
        }
    }

    static fromJS(data: any): ClinicUnloadingPointRSCustom {
        data = typeof data === 'object' ? data : {};
        let result = new ClinicUnloadingPointRSCustom();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["idClinicUnloadingPoint"] = this.idClinicUnloadingPoint;
        data["name"] = this.name;
        data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
        data["isEditMode"] = this.isEditMode;
        return data;
    }
}