import { IBackOfficeSupportRequestView } from "..";
import { BackOfficeSupportRequestStatusView, BackOfficeSupportRequestView } from "../services";

export class BackOfficeSupportRequestViewCustom implements BackOfficeSupportRequestView {

    idUserIdentityRequest?: string;
    idUserIdentity?: string;
    requestType?: string | undefined;
    dateCreated?: Date;
    dateApproved?: Date | undefined;
    idNurse?: string | undefined;
    idPatient?: string | undefined;
    name?: string | undefined;
    surname?: string | undefined;
    fullName?: string | undefined;
    clinicName?: string | undefined;
    countryIsoCode?: string | undefined;
    appType?: string | undefined;
    idUserIdentityRequestStatus?: string | undefined;
    supportUser?: string | undefined;
    status?: string | undefined;
    comment?: string | undefined;
    statusDateCreated?: Date | undefined;
    isExpanded?: boolean = false;
    listStatus?: any;

    constructor(data?: IBackOfficeSupportRequestView) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.idUserIdentityRequest = _data["idUserIdentityRequest"];
            this.idUserIdentity = _data["idUserIdentity"];
            this.requestType = _data["requestType"];
            this.dateCreated = _data["dateCreated"] ? new Date(_data["dateCreated"].toString()) : <any>undefined;
            this.dateApproved = _data["dateApproved"] ? new Date(_data["dateApproved"].toString()) : <any>undefined;
            this.idNurse = _data["idNurse"];
            this.idPatient = _data["idPatient"];
            this.name = _data["name"];
            this.surname = _data["surname"];
            this.fullName = _data["fullName"];
            this.clinicName = _data["clinicName"];
            this.countryIsoCode = _data["countryIsoCode"];
            this.appType = _data["appType"];
            this.idUserIdentityRequestStatus = _data["idUserIdentityRequestStatus"];
            this.supportUser = _data["supportUser"];
            this.status = _data["status"];
            this.comment = _data["comment"];
            this.statusDateCreated = _data["statusDateCreated"] ? new Date(_data["statusDateCreated"].toString()) : <any>undefined;
            this.isExpanded = false;
            this.listStatus = _data["listStatus"];
        }
    }

    static fromJS(data: any): BackOfficeSupportRequestView {
        data = typeof data === 'object' ? data : {};
        let result = new BackOfficeSupportRequestView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["idUserIdentityRequest"] = this.idUserIdentityRequest;
        data["idUserIdentity"] = this.idUserIdentity;
        data["requestType"] = this.requestType;
        data["dateCreated"] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data["dateApproved"] = this.dateApproved ? this.dateApproved.toISOString() : <any>undefined;
        data["idNurse"] = this.idNurse;
        data["idPatient"] = this.idPatient;
        data["name"] = this.name;
        data["surname"] = this.surname;
        data["fullName"] = this.fullName;
        data["clinicName"] = this.clinicName;
        data["countryIsoCode"] = this.countryIsoCode;
        data["appType"] = this.appType;
        data["idUserIdentityRequestStatus"] = this.idUserIdentityRequestStatus;
        data["supportUser"] = this.supportUser;
        data["status"] = this.status;
        data["comment"] = this.comment;
        data["statusDateCreated"] = this.statusDateCreated ? this.statusDateCreated.toISOString() : <any>undefined;
        data["isExpanded"] = this.isExpanded;
        data["listStatus"] = this.listStatus;
        return data; 
    }
}
