<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'SUPPORT_USER_ADD_TITLE' : 'SUPPORT_USER_EDIT_TITLE') | translate}}</h2>

        <form [formGroup]="formSupportUserAdd">
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formSupportUserInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formSupportUserInfo.input[1]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div *isGlobalUser class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formSupportUserInfo?.autocompleteCountryConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formSupportUserInfo?.autocompleteCultureConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>
            
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <div class="row row-aligned">
                        <div class="col-md-4 col-4">
                            <app-phoneprefix-dropdown [autocompleteConfig]="formSupportUserInfo?.autocompleteCountryPhonePrefixConfig" (selected)="inputValues($event)" ></app-phoneprefix-dropdown>
                        </div>
                        <div class="col md-8 col-8">
                            <app-input [inputConfig]="formSupportUserInfo.input[3]" (keyup)="inputValues($event)"></app-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formSupportUserInfo.input[2]" [formControlInput]="formSupportUserAdd"  (keyup)="this.emailAddressIsValid=false;inputValues($event);"></app-input>
                
                    <br><span><i class="fas fa-exclamation-triangle"></i><b>{{'NURSE_ADD_EMAIL_MUSTPERSONAL_NOCOLLECTIVE' | translate}}</b></span>
                </div>
            </div>

            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>
           
            
            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveSupportUser()"></app-button>
                </div>
            </div>
            
            <br/>

        </form>
    </div>
</ng-template>