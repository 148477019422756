<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="calendarDateDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true">
                <svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>
                
        <h2 class="mt-4">{{ 'CALENDAR_ORDER_DETAILS' | translate}}</h2>

        <div *ngIf="calendarDateDetail.dateDeleted == undefined" class="row">
            <div class="col-12 text-right">
                <a (click)="EditCalendarDate()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA'
                        | translate }} </u></a>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_ORDER_STATUS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail.dateDeleted ? ('ACCOUNT_STATUS_DELETED' | translate) : ('ACCOUNT_STATUS_ACTIVE' | translate) }}
            </div>
        </div>

        <hr/>
        
        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail.fullCalendarName }}
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_DELIVERY_DATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail.deliveryDate! | DateFormatPipe }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_PICKING' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail!.pickingDate! | DateFormatPipe }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_DUE_DATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail!.dueDate! | DateFormatPipe }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_ORDER_CLOSING_WINDOW' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail!.closingWindowDate! | DateFormatPipe }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_ORDER_OPENING_WINDOW' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDateDetail!.openingWindowDate! | DateFormatPipe }}
            </div>
        </div>

        <br/><br/>

        <div class="col-12 text-left">
            <a (click)="DeleteCalendarDate()" class="text-right"><i class="far" [ngClass]="this.calendarDateDetail.dateDeleted ? 'fas fa-redo' : 'fas fa-trash'"></i> <u class="edit_link">
                {{ this.calendarDateDetail!.dateDeleted! ? ('CALENDAR_ORDER_RESTORE_MODAL_BTN' | translate) : ('CALENDAR_ORDER_DELETE_MODAL_BTN' | translate) }}</u></a>
        </div>

        <br/>

    </div>

</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>