import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { ClientService, IPatientSetDeliveryFeeRQ, PatientSetDeliveryFeeRQ } from 'src/app/core';

@Component({
  selector: 'app-patient-delivery-fee-modal',
  templateUrl: './patient-delivery-fee.component.html',
  styleUrls: ['./patient-delivery-fee.component.css']
})
export class PatientDeliveryFeeComponent implements OnInit, AfterViewInit {

  constructor(
    private clientService: ClientService,
    private translate : TranslateService,
    private fb: FormBuilder,
    private smartModal : NgxSmartModalService) { }
    
    @Output() patientDeliveryFeeOut : EventEmitter<any> = new EventEmitter<any>();

    subscriptions: Subscription[] = []
    loading: boolean = true;

    idPatientAddress: string = '';

    ngOnInit(): void {
      this.subscriptions = []
    }
  
    ngAfterViewInit(): void {
      let modalDataSubscription = this.smartModal.getModal('patientDeliveryFeeModal').onOpen.subscribe(()=>{
        var content = this.smartModal.getModal('patientDeliveryFeeModal').getData();

        this.idPatientAddress = content.idPatientAddress;

        this.formDeliveryFeeAdd.patchValue({
          deliveryFee: content.deliveryFee,
          logisticCompanyInstructions: content.logisticCompanyInstructions
        });

        this.formDeliveryFeeInfo!.input[0].value = this.formDeliveryFeeAdd.get("deliveryFee")?.value;
        this.formDeliveryFeeInfo!.input[1].value = this.formDeliveryFeeAdd.get("logisticCompanyInstructions")?.value;

        this.formValidation(this.formDeliveryFeeAdd);
      })
      this.subscriptions.push(modalDataSubscription);
    }
  
    ngOnDestroy(): void {
      this.subscriptions.forEach(sub=>sub.unsubscribe())
    }

  formDeliveryFeeAdd: FormGroup = this.fb.group({
    deliveryFee: [null],
    logisticCompanyInstructions: [null],
  })

  formDeliveryFeeInfo={
    key: 'formDeliveryFeeInfo',
    input:
    [
      { name: this.getTranslation('PATIENT_DETAIL_FEE'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryFee', value: this.formDeliveryFeeAdd.get("deliveryFee")?.value, maxLength: 64 },
      { name: this.getTranslation('PATIENT_DETAIL_LOGISTIC_COMPANY'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'logisticCompanyInstructions', value: this.formDeliveryFeeAdd.get("logisticCompanyInstructions")?.value, maxLength: 64},
    ]
  } 

  inputValues(event: any){
    
    this.formDeliveryFeeAdd.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.formDeliveryFeeAdd);
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.savetnConfig.disabled = !form.valid;
      });
    this.savetnConfig.disabled = !form.valid;
  }
  
  cancelBtnConfig = {
    bclass : 'btn button-to-patient',
    text : this.getTranslation('BTN_CANCEL'),
    action: 'cancel',
    width: '30px',
    height: '30px'
  }

  savetnConfig = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_COMMON_SAVE'),
    action: 'quit',
    width: '30px',
    height: '30px',
    disabled: true
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  cancel(){
    this.smartModal.getModal('patientDeliveryFeeModal').close();
  }

  saveDeliveryFee(){

    if (this.formDeliveryFeeAdd.valid) {

      let input : IPatientSetDeliveryFeeRQ = {      
        idPatientAddress: this.idPatientAddress,
        deliveryFee: this.formDeliveryFeeAdd.get('deliveryFee')?.value,
        logisticCompanyInstructions: this.formDeliveryFeeAdd.get('logisticCompanyInstructions')?.value,  
      }

      this.clientService.patientSetDeliveryFee(new PatientSetDeliveryFeeRQ(input)).subscribe(
        response =>{

          if(response){

            this.cancel();

            this.patientDeliveryFeeOut.emit();
          }
        },
        error=> console.log(error)
      );
    }
  }  
}
