

<ng-container *ngIf="node.idClinic == null; else urlAvailable">
    <b>{{node.shipToClinicName + ' (' + node.shipTo + ')'}}</b>
</ng-container>
<ng-template #urlAvailable>
    <b><a href="javascript:void()" (click)="GoToClinicDetail(node.idClinic)">{{node.shipToClinicName + ' (' + node.shipTo + ')'}}</a></b>
</ng-template>
<ul class="tree">
    <li *ngFor="let node of node.children">
        <tree-node [node]="node"></tree-node>
    </li>
</ul>