import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerServiceUserRS, ClientService, EntityRQ } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-customer-service-user-detail',
  templateUrl: './customer_service_user-detail.component.html',
  styleUrls: ['./customer_service_user-detail.component.css']
})
export class CustomerServiceDetailComponent implements OnInit {
  id?: string;
  customerServiceDetail?: CustomerServiceUserRS;
  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  loading: boolean = true;

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getCustomerServiceDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/customer-service-users");
  }

  EditCustomerService() {
    this.router.navigate(['/customer-service-users/edit', this.id]);
  }

  getCustomerServiceDetail(){
    this.loading = true;
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.customerServiceUserGet(data).subscribe(
      response =>{
        this.customerServiceDetail = response;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  DeactivateCustomerService(){

    this.modalService.setModalData({ 
      title: (this.customerServiceDetail?.dateDeactivated) ? "CUSTOMER_SERVICE_USER_DETAIL_REACTIVATE_MODAL_TITLE" : "CUSTOMER_SERVICE_USER_DETAIL_DEACTIVATE_MODAL_TITLE",
      description: (this.customerServiceDetail?.dateDeactivated) ? "CUSTOMER_SERVICE_USER_DETAIL_REACTIVATE_MODAL_DESC" : "CUSTOMER_SERVICE_USER_DETAIL_DEACTIVATE_MODAL_DESC",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.customerServiceDetail?.dateDeactivated ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.customerServiceDetail?.dateDeactivated ? 
          this.getTranslation('CUSTOMER_SERVICE_USER_DETAIL_REACTIVATE_MODAL_BTN') : this.getTranslation('CUSTOMER_SERVICE_USER_DETAIL_DEACTIVATE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        
        if(this.customerServiceDetail?.dateDeactivated){
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.customerServiceUserReactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
        else{
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.customerServiceUserDeactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
      }
     })

    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.customerServiceDetail?.dateDeactivated) ? "CUSTOMER_SERVICE_USER_DETAIL_REACTIVATE_MODAL_SUCCESS" : "CUSTOMER_SERVICE_USER_DETAIL_DEACTIVATE_MODAL_SUCCESS",
      actionToDo: () => {
        this.getCustomerServiceDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

}
