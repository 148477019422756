import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRequestStatusRQ, ClientService, IAddRequestStatusRQ, UserIdentityRequestStatusEnum } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private clientService : ClientService) { 
  }

  addRequestStatus(idUserIdentityRequest: string, keyStatus: UserIdentityRequestStatusEnum, comment: string): Observable<boolean>{
    let input: IAddRequestStatusRQ = {
      idUserIdentityRequest: idUserIdentityRequest,
      keyStatus: keyStatus, 
      comment: comment
    }
    return this.clientService.addRequestStatus(new AddRequestStatusRQ(input)).pipe(
      map(
        (x: boolean) => {
          return x;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }

  approveRequest(idUserIdentityRequest: string, comment: string){
    let input: IAddRequestStatusRQ = {
      idUserIdentityRequest: idUserIdentityRequest,
      comment: comment
    }
    return this.clientService.approveRequest(new AddRequestStatusRQ(input)).pipe(
      map(
        (x: boolean) => {
          return x;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }
}
