import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService, EntityRQ } from 'src/app/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ipd-order-detail',
  templateUrl: './ipd-order-detail.component.html',
  styleUrls: ['./ipd-order-detail.component.css']
})
export class IPDOrderDetailComponent implements OnInit {
  id?: string;
  entityVersions!: any;
  historySyncronizations!: any;
  activeTab = 'ENTITY_VERSIONS';

  loading: boolean = true;  

  constructor(
    private location: Location,
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}


  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getOrderDetail();
  }

  backToList(){   
    this.location.back();
  }

  currentEntityVersionPage: number = 1;
  totalItemsEntityVersion: number = 0;

  tableEntityVersionConfig =  {
    actions: true,
    gridData: this.entityVersions,
    pagination: {
      id: "tableEntityVersion",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsEntityVersion
    },
    sort:{
      field: "versionDate",
      direction: "asc"
    },
    notes: false
  };

  getOrderDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.orderIPDGetBackOffice(data).subscribe(
      response =>{

        let versionsList: any[] = [];
        for(let entity of response.entityVersions!){
          var jsonObj = JSON.parse(entity.jsonData || "");
          const jsonObj1 = Object.assign({VersionDate: entity.versionDate?.toISOString(), MethodName: entity.methodName}, jsonObj)
          
          versionsList.push(jsonObj1);
        }

        //ENTITY VERSION
        this.entityVersions = versionsList;
        this.tableEntityVersionConfig.gridData = versionsList || [];
        this.tableEntityVersionConfig.pagination.itemsPerPage =  20;
        this.tableEntityVersionConfig.pagination.totalItems = this.entityVersions.lenght || 0;
        this.tableEntityVersionConfig.pagination.currentPage = this.currentEntityVersionPage;
        this.tableEntityVersionConfig.gridData = this.entityVersions;
  
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }
}

