<div style="padding:0" class="col-12 col-lg-6">

  <div *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']">
      <div class="mt-5" *ngIf="registrationCodes.length != 0">

          <table class="table w-100 mt-5">
              <tr>
                  <th nowrap scope="col">{{ 'NURSE_DETAIL_REGCODE' | translate }}</th>
                  <th scope="col">{{ 'NURSE_DETAIL_REGCODE_CREATION' | translate }}</th>
                  <th scope="col">{{ 'NURSE_DETAIL_REGCODE_STATUS' | translate }}</th>
              </tr>
              <tr *ngFor="let regCode of registrationCodes">
                  <td *ngIf="!isNurse" scope="row">{{regCode.dynamicLink}}</td>
                  <td *ngIf="isNurse" scope="row">{{regCode.registrationCode}}</td>                  
                  <td nowrap>{{regCode.dateCreated | DateFormatPipe: true}}</td>
                  <td nowrap>{{ 'NURSE_DETAIL_' + regCode.status | uppercase | translate }}
                      <span *ngIf="regCode.datePlanned">{{ 'NURSE_DETAIL_CODE_PLANNED_FOR' | translate }}
                          {{regCode.datePlanned | DateFormatPipe: true}}</span>
                  </td>
              </tr>
          </table>
      </div>
  </div>

  <div style="width: auto;" class="border-box p-4 text-center">
    <h5>{{ title | translate }}</h5>
    <p class="desc-italic mt-4">{{ description | translate }}</p>

    <div class="text-center mt-5">
        <app-button (click)="sendCode($event)" [buttonConfig]="btnConfigSendCode"></app-button>
        <p class="desc-italic mt-2">{{ info | translate }}</p>
    </div>

    <hr class="my-4 mx-5">
        
    <div class="text-center">
        <app-button [buttonConfig]="btnConfigSendCodePlanned" (click)="sendCodePlanned($event)"></app-button>
        <p class="desc-italic mt-2">{{ plannedDesc | translate }}</p>
    </div>
  </div>
</div>