<div class="autocomplete-container">
<label class="input-lable" *ngIf="autocompleteConfig.name">{{autocompleteConfig.name}} <span class="disabled" *ngIf="autocompleteConfig.optional">{{'OPTIONAL_LABEL'|translate}}</span></label> <br *ngIf="autocompleteConfig.name">
<div class="autocomplete-close">
<span *ngIf="autocompleteConfig.filterIsVisible" class="fa fa-filter form-control-filter"></span>
<a *ngIf="model && !autocompleteConfig.disabled" (click)="clearSelection()" class="fa fa-times form-control-close"></a>
<a *ngIf="!model" (click)="show()" class="fa fa-chevron-down form-control-close"></a>
<input type="text" [ngClass]="autocompleteConfig.class" [attr.name]="autocompleteConfig.inputName"
       [placeholder]="autocompleteConfig.placeholder ? autocompleteConfig.placeholder : ''"
       [readOnly]="autocompleteConfig.readOnly ? autocompleteConfig.readOnly : false"
       [disabled]="autocompleteConfig.disabled ? autocompleteConfig.disabled : false"
       [(ngModel)]="model"
       [ngbTypeahead]="search"
       [inputFormatter]="formatter"
       [resultFormatter]="formatter"
       [editable]='false'
       (selectItem)="emit($event)"
       (keyup)="addClass()" (click)="show()"
       autocomplete="none"/>
</div>
</div>
<div class="error-message" *ngIf="autocompleteConfig.errorMessage">{{autocompleteConfig.errorMessage}}</div>