<div class="autocomplete-container">
<label class="input-lable" *ngIf="autocompleteConfig.name">{{autocompleteConfig.name}} <span class="disabled" *ngIf="autocompleteConfig.optional">{{'OPTIONAL_LABEL'|translate}}</span></label> <br *ngIf="autocompleteConfig.name">
<input type="text" [ngClass]="autocompleteConfig.class" [attr.name]="autocompleteConfig.inputName"
       [readOnly]="autocompleteConfig.readOnly ? autocompleteConfig.readOnly : false"
       [(ngModel)]="model"
       [ngbTypeahead]="search"
       [inputFormatter]="formatter"
       [resultFormatter]="formatter"
       [editable]='false'
       (selectItem)="emit($event)"
       (focusout)="outFocusValue($event)"
       (keyup)="addClass($event)"
       (click)="show()"
       [id]="autocompleteConfig.id ? autocompleteConfig.id : ''"
       autocomplete="none"
       />
</div>
<div class="error-message" *ngIf="autocompleteConfig.errorMessage">{{autocompleteConfig.errorMessage}}</div>
