import {Component, forwardRef, Input} from '@angular/core'
import { Router } from '@angular/router';

@Component({
  selector: 'tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css']
})
export class TreeNodeComponent {
    
    constructor(
        private router: Router,
      ) { }

    @Input() node: any = undefined;

    GoToClinicDetail(idClinic: any) {
        if(idClinic != null){
            this.router.navigate(['/clinics/detail', idClinic]);
        }
    }
}