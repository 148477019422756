import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, BaseGetAllRQ, ClientService, IBaseGetAllRQ, WeeklyTotalsReport } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {

  reportList! : WeeklyTotalsReport[];  
  loading: boolean = true;
  record?: any;
  totalItemsReport: number = 0;

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_REPORT')}

  tableConfig =  {
    actions: true,
    gridData: this.reportList,
    headerList: [
      {name: this.getTranslation('LABEL_ENTITY'), field: 'entity', sortable: false},
      {name: this.getTranslation('LABEL_TOTAL'), field: 'total', sortable: false},
      {name: this.getTranslation('LABEL_LASTWEEK'), field: 'lastWeek', sortable: false},
      {name: this.getTranslation('LABEL_SORTING'), field: 'sorting', sortable: false}
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsReport
    },
    sort:{
      field: "sorting",
      direction: "desc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router : Router,
    private translate: TranslateService,
    private editService: EditServiceService
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getReportList();
  }

  filterName?: string;

  orderDirection?: string = "Sorting_asc";
  currentPage: number = 1;
  anyResult: boolean = false;

  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getReportList();
  }
 
  getReportList() {

    let input: IBaseGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName,
    }
    this.clientService.reportGetAll(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.reportList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.reportList;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderReportChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getReportList();
  }

  pageReportChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getReportList();
  }

  getUpdatedReportList(): any{
    return this.getReportList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}