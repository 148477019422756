<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'NEPHROLOGIST_LIST_TITLE' | translate}}</h2>
            </div>
            <div *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']" class="col-6">
                <app-button (click)="nephrologistAdd()" [buttonConfig]="btnConfig" ></app-button>
            </div>
        </div>

        <div *ngIf="anyResult else noResultContent">
        <div class="row mt-2">
            <div class="col-lg-4 col-md-auto">
                <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)" ></app-search-field>
            </div>
            <div class="col-lg-4 col-md-auto"></div>
            <div class="col-lg-4 col-md-auto">
                <app-autocomplete-dropdown [autocompleteConfig]="autocompleteConfig" (selected)="applyFilter($event)"></app-autocomplete-dropdown>
            </div>
        </div>
        <app-table [tableConfig]= "tableConfig" (orderChanged)="orderNephrologistChanged($event)" (onClick)="GoToNephrologistDetail($event)" (pageChanged)="pageNephrologistChanged($event)"></app-table>
    </div>
    <ng-template #noResultContent>
        <div class="text-center noResult">
            <svg-icon class="icon" src="../../../../../assets/icons/nephrologist-figure.svg"></svg-icon>
            <p class="alert">{{ 'NEPHROLOGIST_CLINIC_NORESULT' | translate }}</p>
            <p class="desc">{{ 'NEPHROLOGIST_NORESULT_DESC' | translate }}</p>
        </div>
    </ng-template>
</ng-template>
</div>
