import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from 'src/app/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @Input() tableConfig: any;
  @Output() onClick = new EventEmitter<any>();
  @Output() pageChanged = new EventEmitter<any>();
  @Output() orderChanged = new EventEmitter<any>();

  selectedOption? : string;
  searchText? : string;
  filter? : string;
  filterType? : string = "asc";

  alertConfig = {
    aclass: 'alert alert-dark m-4',
    msg: this.getTranslation('NO_ITEMS')
  }

  constructor(
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.selectedOption ='id';
  }

  handlePageChange(event: any) {
    this.tableConfig.pagination.currentPage = event;
    this.pageChanged.emit(event);
  }

  onClickButton(record: any, action: any){
    this.onClick.emit({record,action});
  }

  isDate(value:string){
    return (Date.parse(value) || Date.parse(value)==0);
  }

  isBoolean(value:string){
    return Boolean(value);
  }

  filterVisibleItems(){
    return this.tableConfig.headerList.filter((x: { isHidden: any; }) => !x.hasOwnProperty("isHidden") || x.isHidden! == false);
  }

  updatePage() {
    this.tableConfig.pagination.currentPage = 1;
  }

  orderTableChanged(headerField: string){
    this.tableConfig.sort.field = headerField;
    this.tableConfig.sort.direction = this.tableConfig.sort.direction == "asc" ? "desc" : "asc";

    this.orderChanged.emit(this.tableConfig.sort.field + "_"  + this.tableConfig.sort.direction);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}

