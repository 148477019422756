<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="supportUserDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <div class="row">
            <div class="col-12 text-right">
                <a (click)="EditSupportUser()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA' | translate }} </u></a>
            </div>
        </div>

        <h2 class="mt-4">{{ supportUserDetail.name  + " " + supportUserDetail.surname}}</h2>
        
        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{supportUserDetail.name}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{supportUserDetail.surname}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ supportUserDetail.countryIsoCode != null ? ('COUNTRY_' + supportUserDetail.countryIsoCode | translate) : '-' }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CULTURE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ 'CULTURE_' + supportUserDetail.cultureIsoCode | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_WORKMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{supportUserDetail.emailAddress}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_MOBILE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ supportUserDetail.mobileNumberCountryPrefix != null ? supportUserDetail.mobileNumberCountryPrefix + " " + supportUserDetail.mobileNumber : '' }}
            </div>
        </div>

        <hr class="my-4">


        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{supportUserDetail.dateCreated | DateFormatPipe: true}}
            </div>
        </div>

        <div class="row mt-4" *ngIf='supportUserDetail.dateDeactivated'>
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DEACTIVATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{supportUserDetail.dateDeactivated | DateFormatPipe: true}}
            </div>
        </div>

        <div class="row mt-4" *ngIf='supportUserDetail.dateDeleted'>
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DELETIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{supportUserDetail.dateDeleted | DateFormatPipe: true}}
            </div>
        </div>

        <br>

        <div class="row">
            <div class="col-12 text-left">
                <a (click)="DeactivateSupportUser()" class="text-right"><i class="fas fa-user-slash"></i> <u class="edit_link">
                    {{ this.supportUserDetail.dateDeactivated ? ('SUPPORT_USER_DETAIL_REACTIVATE' | translate) : ('SUPPORT_USER_DETAIL_DEACTIVATE' | translate) }}</u></a>
            </div>
        </div>

    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>