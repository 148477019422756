import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private configSearchBarSubject: BehaviorSubject<any>;
  private filterList: BehaviorSubject<any>;  

  constructor() {
    this.configSearchBarSubject = new BehaviorSubject<any>({} as any);
    this.filterList = new BehaviorSubject<any>({} as any);
    this.profileSubject = new Subject<any>();
    this.sideBarSubject = new Subject<any>();
  }

  setSearchBarData(configuration: any){
    this.configSearchBarSubject.next(configuration);
  }

  getSearchBarData(): Observable<any> {
    return this.configSearchBarSubject.asObservable();
  }

  setfilterList(list: string, pattern: string){
    this.filterList.next({list: list, pattern: pattern});
  }

  getfilterList(): Observable<any> {
    return this.filterList.asObservable();
  }

  profileSubject?: Subject<any>;
  setProfileInfoType(type: any){ this.profileSubject?.next(type);}
  getProfileInfoType(){ return this.profileSubject?.asObservable();}

  sideBarSubject?: Subject<any>;
  updateSidebarLabel(){ this.sideBarSubject?.next(true);}
  getSidebarLabel(){ return this.sideBarSubject?.asObservable();}
}
