import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, ChangePasswordRQ, ClientService, IChangePasswordRQ } from 'src/app/core';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { TabService } from 'src/app/core/services/tab.service';
import { equalsFieldValidator } from 'src/app/core/validator/equals-field-validator';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-change-pass-code',
  templateUrl: './change-pass-code.component.html',
  styleUrls: ['./change-pass-code.component.css']
})
export class ChangePassCodeComponent implements OnInit {
  descriptionText = '';

  showTemporaryTemplate: boolean = false;

  /* Password */
  passwordStep: any;
  repeatPasswordContendDisabled = true;
  oldPassword: any = ''
  newPassword: any = ''
  repeatedPassword: any = ''
  isPasswordValid = false;
  paswordRestrictionLoaded = false

  changePassBTN = {
    bclass: 'btn button-next mb-4',
    type: 'submit',
    text: this.getTranslation('BTN_CHANGE_PASSCODE'),
    disabled: true
  }
  
  initConfiguration() {
    this.passwordStep = {
      newPasswordConfig: {
        input: { name: this.getTranslation('NEW_PASSWORD_LABEL'), disabled: false, optional: false, type: 'password', placeholder: '', class: 'input-form', inputName: 'password', noLengthCheck: true, id: 'change-password', passwordIcon: true },
        subtitle: { width: 100, text: this.getTranslation('CREATE_PASSWORD_RESTRICTION_MESSAGE'), size: 0.875, class: 'edit-label lh-normal' },
        restrictions: [
          { text: this.getTranslation('ISMANDATORYLOWERCASE_RESTRICTION_LABEL'), type: 'isMandatoryLowerCase', class: '' },
          { text: this.getTranslation('ISMANDATORYUPPERCASE_RESTRICTION_LABEL'), type: 'isMandatoryUpperCase', class: '' },
          { text: this.getTranslation('ISMANDATORYDIGIT_RESTRICTION_LABEL'), type: 'isMandatoryDigit', class: '' },
          { text: this.getTranslation('ISMANDATORYSPECIALCHARACTERS_RESTRICTION_LABEL'), type: 'isMandatorySpecialCharacters', class: '' },
          { text: this.getTranslation('LENGHT_RESTRICTION_LABEL'), type: 'lenght', class: '' },
        ],
        lenght: 10
      },
      currentPasswordConfig: { name: this.getTranslation('CURRENT_PASSWORD_LABEL'), optional: false, type: 'password', placeholder: '', class: 'input-form', inputName: 'newPassword', id: 'newpPassword-input', passwordIcon: true },
      confirmPasswordInput: { name: this.getTranslation('CONFIRM_NEW_PASSWORD'), disabled: false, optional: false, type: 'password', placeholder: '', class: 'input-form', inputName: 'ConfirmPassword', id: 'Confirm-password-input', passwordIcon: true },
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private clientService: ClientService,
    private translate: TranslateService,
    private sideBarService: SidebarService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
  ) 
  {}

  onSubmit(){
    if(!this.changePassBTN.disabled){

      if(this.showTemporaryTemplate){
        this.clientService.changeTemporaryPassword(this.newPassword).subscribe(
          response => {
            if(response){
              this.ShowSuccessModal();
            }
          })
      }
      else{
        let input: IChangePasswordRQ = {
          idUserIdentity: undefined,
          currentPassword: this.oldPassword,
          newPassword: this.newPassword
        }
        this.clientService.changePassword(new ChangePasswordRQ(input)).subscribe(
          response => {
            if(response){
              this.ShowSuccessModal();
            }
            else{
              this.passwordStep.currentPasswordConfig.errorMessage = this.getTranslation('INCORRECT_PASSWORD_LABEL');
              this.passwordStep.currentPasswordConfig.class = 'input-form error';
              this.changePassBTN.disabled = true
            }
          })
      }
        
    }
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: 'CHANGE_PASSCODE_MODAL_SUCCESS_TITLE',
      description: "CHANGE_PASSCODE_MODAL_SUCCESS_DESC",
      actionToDo: () => {
        if(this.showTemporaryTemplate)
          this.authenticationService.logout(false);
        else
          this.authenticationService.refreshCurrentUserAndGoHome();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

  ngOnInit(): void {

    this.initConfiguration();

    this.showTemporaryTemplate = this.editService.showTemporaryPasswordTemplate;
    this.descriptionText = this.showTemporaryTemplate ? this.getTranslation('CHANGE_TEMP_PASSCODE_DESC') : this.getTranslation('CHANGE_PASSCODE_DESC');

    if(this.showTemporaryTemplate){
      this.sideBarService.hideSideBar();
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getPasswordInput(e: any, type: string) {
    // reset current password input state if had error
    this.passwordStep.currentPasswordConfig.errorMessage = ''
    this.passwordStep.currentPasswordConfig.class = 'input-form'

    switch (type) {
      case 'OLD_PASSWORD':
        this.oldPassword = e.target.value
        this.repeatPasswordContendDisabled = false;
        this.passwordStep.newPasswordConfig.input.disabled = false
        this.passwordStep.confirmPasswordInput.disabled = false
        break;
      case 'NEW_PASSWORD':
        this.newPassword = e.target.value
        this.isPasswordValid = e.valid
        break;
      case 'REPEAT_PASSWORD':
        this.repeatedPassword = e.target.value
        break;
    }
    // Repeated password not the same as new password
    if((this.repeatedPassword !== this.newPassword) && (this.repeatedPassword !== '' && this.newPassword !== '')){
      this.passwordStep.confirmPasswordInput.errorMessage = this.getTranslation('MUST_MATCH_NEW_PASSWORD_LABEL');
      this.passwordStep.confirmPasswordInput.class = 'input-form error'
    }else{
      this.passwordStep.confirmPasswordInput.errorMessage = ''
      this.passwordStep.confirmPasswordInput.class = 'input-form'
    }
    if((this.newPassword === this.oldPassword) && this.oldPassword !== '' && this.newPassword !== ''){
      this.passwordStep.newPasswordConfig.input.errorMessage = this.getTranslation('CHOOSE_ANOTHER_PASSWORD_LABEL');
      this.passwordStep.newPasswordConfig.input.class = 'input-form error';
    }else{
      this.passwordStep.newPasswordConfig.input.errorMessage = ''
      this.passwordStep.newPasswordConfig.input.class = 'input-form'
    }

    // new password can't be same as old

    this.changePassBTN.disabled = !(
      (this.newPassword === this.repeatedPassword) && 
      (this.newPassword !== this.oldPassword) && 
      this.repeatedPassword !== '' && 
      (this.oldPassword !== '' || this.showTemporaryTemplate) && 
      this.isPasswordValid
    )
  }
}
