<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'COUNTRY_ADD_TITLE' : 'COUNTRY_EDIT_TITLE') | translate}}</h2>

        <form [formGroup]="formCountryAdd">

            <div class="row my-2">
                <div class="col-md-6 col-6">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCountryInfo?.autocompleteCountryConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-3 col-3">
                    <app-input [inputConfig]="formCountryInfo.input[0]" [formControlInput]="formCountryAdd" (keyup)="inputValues($event)"></app-input>
                </div>
                <div class="col-md-6 col-6">
                    <app-input [inputConfig]="formCountryInfo.input[1]" [formControlInput]="formCountryAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formCountryInfo.input[2]" [formControlInput]="formCountryAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-md-3 col-3">
                    <app-input [inputConfig]="formCountryInfo.input[3]" [formControlInput]="formCountryAdd" (keyup)="inputValues($event)"></app-input>
                </div>
                <div class="col-md-6 col-6">
                    <app-input [inputConfig]="formCountryInfo.input[4]" [formControlInput]="formCountryAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formCountryInfo.input[5]" [formControlInput]="formCountryAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>


            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-checkbox [checked]="isLeadingZero" (check)="checkIsLeadingZero()" [text]="getTranslation('LABEL_ISLEADINGZERO')"></app-checkbox>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-checkbox [checked]="isSignatureNeeded" (check)="checkIsSignatureNeeded()" [text]="getTranslation('CLINIC_DETAIL_SIGNATURE_REQUIRED')"></app-checkbox>
                </div>
            </div>

            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>
            
            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveCountry()"></app-button>
                </div>
            </div>

            <br/>
        </form>
    </div>
</ng-template>