import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';
import {distinctUntilChanged, map, filter} from 'rxjs/operators';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-phoneprefix-dropdown',
  templateUrl: './phoneprefix-dropdown.component.html',
  styleUrls: ['./phoneprefix-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class PhonePrefixDropdownComponent implements OnInit {

  @Input() autocompleteConfig: any = {} as any || [];
  @Output() selected = new EventEmitter<any>();
  public model: (any) | undefined;

  constructor(
    private utilService: UtilsService,
    ) {
  }

  ngOnInit() {
    if(this.autocompleteConfig.value && (this.autocompleteConfig.value.phonePrefix || this.autocompleteConfig.value.description)){
      this.model = this.autocompleteConfig.value;
    } else {
      this.model = null;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.autocompleteConfig.value && (this.autocompleteConfig.value.phonePrefix || this.autocompleteConfig.value.description)){
      this.model = this.autocompleteConfig.value;
    } else {
      this.model = null;
    }
  }
  emit(event: any){
    if(event.item){
      let inputValue = document.querySelector('input[name="'+this.autocompleteConfig.inputName+'"]') as HTMLInputElement;
      this.autocompleteConfig.value = event.item;
      inputValue.blur();
    }
     let e = {
        target:{
          name: this.autocompleteConfig.inputName,
          value: event.item
        }
      }
    this.selected.emit(e);
  }

  addClass(event: any){
    if(this.autocompleteConfig.value && event){
      let e = {item : null}
      this.emit(e);
      this.autocompleteConfig.errorMessage = '';
      this.autocompleteConfig.class = 'form-control autocomplete-input';
    }
    if(!this.autocompleteConfig.prefix){
    let items = document.querySelectorAll('button.dropdown-item').forEach((item)=>{
            if(!item.classList[1] || item.classList[1]==='active'){
        if(!this.autocompleteConfig.prefix){
          item.classList.add('location');
        }
      }
    })
    }
  }

  // control to emit value if nurse write himself the number
  outFocusValue(event: any){
    let val = event.target.value.split('(')[0].trim();
    let e = {
      target:{
        name: this.autocompleteConfig.inputName,
        value: event.item
      }
    }
    if(this.autocompleteConfig.array.find((x: any)=> x.phonePrefix === parseInt(val))){
      e.target.value = this.autocompleteConfig.array.find((x: any)=> x.phonePrefix === parseInt(val))
    }else{
      e.target.value = null
    }
    this.selected.emit(e);
  }

  formatter = (array: { [x: string]: any; }) =>this.autocompleteConfig.prefix ? this.autocompleteConfig.prefix + array[this.autocompleteConfig.attr] + ' (' + array['countryIsoCode'] + ')' : array[this.autocompleteConfig.attr];

  search: OperatorFunction<string, any> = (text$: Observable<string>) => text$.pipe(
    distinctUntilChanged(),
    map(term => this.autocompleteConfig.array.filter((elem : any) => this.isValid(term) && this.matchPattern(elem[this.autocompleteConfig.attr], term))))

  show(){
    if(!this.autocompleteConfig.readOnly){

    const event = new Event('input', { bubbles: true });
    let inputValue = document.querySelector('input[name="'+this.autocompleteConfig.inputName+'"]') as HTMLInputElement;
/*     inputValue.value = ""; */
    inputValue.dispatchEvent(event);
    inputValue.focus();

    this.addClass(null);
    }
  }

  isValid(term: string){
    return term.match("^[a-zA-Z]+$") || /^\d+$/.test(term) || term=="" || term.startsWith("+");
  }

  matchPattern(word: string, pattern: string){
    let match = false;
    word = word.toString().toLowerCase();
    pattern = pattern.toString().toLowerCase();
    if(this.autocompleteConfig.prefix && pattern.startsWith("+")){
      word = "+" + word;
    }
    match = word.startsWith(pattern);
    return match;
  }

}