import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { DateService } from './date.service';
import * as moment from 'moment';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '.';
  readonly FORMAT = 'yyyy.MM.dd'

  constructor( private util: UtilsService, private dateService: DateService ){
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if(!date) return '';
    let dateToFormat = moment.utc([date!.year, date!.month -1 , date!.day]).toDate();
    return this.dateService.dateParseWithoutTimeZone(dateToFormat);
  }
}
