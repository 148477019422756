<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="demoUserDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true">
                <svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>

        <div class="row">
            <div class="col-12 text-right">
                <a (click)="EditDemoUser()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link">{{ 'COMMON_EDIT_DATA' |
                    translate }}</u></a>
            </div>
        </div>

        <h2 class="mt-4">{{demoUserDetail?.name + ' ' + demoUserDetail?.surname}}</h2>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_CLINIC' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{demoUserDetail?.clinicName}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_NAME' | translate }}:</b>
            </div>
            <div class="col-9 col-md-6">
                {{demoUserDetail?.name}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{demoUserDetail?.surname}}
            </div>
        </div>  
        
        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_BIRTHDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{demoUserDetail?.birthDate | DateFormatPipe}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_WORKMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{demoUserDetail?.emailAddress}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_MOBILE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ demoUserDetail.mobileNumberCountryPrefix != null ? demoUserDetail.mobileNumberCountryPrefix + " " + demoUserDetail.mobileNumber : '' }}
            </div>
        </div>

        <hr class="my-4">

        <!-- PATIENT -->
        <div *ngIf="demoUserDetail.accountType == 'NURSE'; else patientApp" class="col-4 border-box p-4">
            <h4>{{'LABEL_PATIENTAPP' | translate}}</h4>
            <br/>
            <app-button *ngIf="demoUserDetail.mobileNumber" (click)="createDemoPatient()" [buttonConfig]="btnConfigCreateDemo"></app-button>
        </div>

        <ng-template #patientApp>
            <h4>{{'LABEL_PATIENTAPP' | translate}}</h4>

            <div class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{'DEMO_USER_DETAIL_STATUS' | translate}}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{'LABEL_CREATED' | translate}}
                </div>
            </div>

            <div *ngIf="!demoUserDetail.dateDeactivated" class="col-6 text-right edit_link" (click)="showPatient()">
                <u>{{ (isPatientVisible == true ? 'LABEL_HIDE_DATA' : 'LABEL_VIEW_DATA') | translate }} </u> &nbsp;
                <i [ngClass]="isPatientVisible == true ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </div>

            <div *ngIf="isPatientVisible">
                <app-invitation-box [title]="'DEMO_PATIENT_DETAIL_INVITE_TITLE'" [description]="'DEMO_PATIENT_DETAIL_INVITE_DESC'" 
                                    [info]="'DEMO_PATIENT_DETAIL_INVITE_INFO'"  [plannedDesc]="'DEMO_PATIENT_DETAIL_INVITE_PLANNED'"
                                    [isNurse]="false" (onClick)="createDemoPatient()" (onClick2)="showPlanningCodeMode(false)"
                                    [registrationCodes]="demoUserDetail.patientRegistrationCodes"></app-invitation-box>
            </div>
        </ng-template>    

        <hr class="my-4">

        <!-- NURSE -->
        <div *ngIf="demoUserDetail.accountType == 'PATIENT'; else nurseApp">
            <div *ngIf="this.nurseAppAvailable" class="col-4 border-box p-4">
                <h4>{{'LABEL_NURSEAPP' | translate}}</h4>
                <br/>
                <app-button (click)="createDemoNurse()" [buttonConfig]="btnConfigCreateDemo"></app-button>
            </div>
        </div>

        <ng-template #nurseApp>
            <h4>{{'LABEL_NURSEAPP' | translate}}</h4>

            <div class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{'DEMO_USER_DETAIL_STATUS' | translate}}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{'LABEL_CREATED' | translate}}
                </div>
            </div>

            <div *ngIf="!demoUserDetail.dateDeactivated" class="col-6 text-right edit_link" (click)="showNurse()">
                <u>{{ (isNurseVisible == true ? 'LABEL_HIDE_DATA' : 'LABEL_VIEW_DATA') | translate }} </u> &nbsp;
                <i [ngClass]="isNurseVisible == true ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </div>

            <div *ngIf="isNurseVisible">
                <app-invitation-box [title]="'DEMO_NURSE_DETAIL_INVITE_TITLE'" [description]="'DEMO_NURSE_DETAIL_INVITE_DESC'" 
                                    [info]="'DEMO_NURSE_DETAIL_INVITE_INFO'" [plannedDesc]="'DEMO_NURSE_DETAIL_INVITE_PLANNED'"
                                    [isNurse]="true" (onClick)="createDemoNurse()" (onClick2)="showPlanningCodeMode(true)"
                                    [registrationCodes]="demoUserDetail.nurseRegistrationCodes"></app-invitation-box>
            </div>
        </ng-template>

        <br/>
        <br/>

        <div class="row">
            <div class="col-12 text-left">
                <a (click)="DeactivateDemoUser()" class="text-right"><i class="fas fa-user-slash"></i> <u class="edit_link">
                    {{ this.demoUserDetail?.dateDeactivated ? ('DEMO_USER_DETAIL_REACTIVATE' | translate) : ('DEMO_USER_DETAIL_DEACTIVATE' | translate) }}</u></a>
            </div>
        </div>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>

<app-plan-registration-code [isNurse]="nursePlannedModal" (planSendCodeOut)="planSendCode($event)"></app-plan-registration-code>