import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dismissable-alert',
  templateUrl: './dismissable-alert.component.html',
  styleUrls: ['./dismissable-alert.component.css']
})
export class DismissableAlertComponent implements OnInit {

  constructor() { }

  @Input() alertConfig: any;

  ngOnInit(): void {
  }

  dismissAlert(){
    this.alertConfig.showAlert = false;
  }

}
