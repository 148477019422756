<div class="sidebar">
    <div class="icons">
        <div class="top-icon">
            <ng-container *ngFor="let icon of topIcons">
                <div class="icon-box" *ngIf="icon.enabledForRole && icon.enabledForPermission" [routerLink]="icon.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }" (click)="disableTabs()">
                    <i class="sidebar-icon fas {{icon.icon}}"></i>
                    <span>{{icon.label}}</span>
                </div>
            </ng-container>
        </div>
        <!-- <div class="bot-icon">
            <ng-container *ngFor="let icon of botIcon">
                <div class="icon-box" [routerLink]="icon.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="disableTabs()">
                    <svg-icon [src]="icon.icon" class="sidebar-icon"></svg-icon>
                    <span>{{icon.label}}</span>
                </div>
            </ng-container>
        </div> -->
    </div>
</div>