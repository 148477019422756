import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService, ClientService, Country, ICountrySetRQ, CountrySetRQ, CountryGetRS } from 'src/app/core';
import { Role } from 'src/app/core/enum/roles';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['./country-edit.component.css']
})
export class CountryEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  countryDetails?: CountryGetRS;
  loading: boolean = true;

  isSignatureNeeded = false;
  isLeadingZero = false;

  countryList!: any | [];
  cultureList!: any | [];
  validationErrors: any[] = [];

  selectedCountry!: any;

  formCountryAdd: FormGroup = this.fb.group({
    countryIsoCode: [null, Validators.required],
    countryName: [null, Validators.required],
    timeZoneName: [null, Validators.required],
    cultureCode: [null, Validators.required],
    cultureName: [null, Validators.required],
    phonePrefix : [null, Validators.required]
  });

  formCountryInfo={
    key: 'formCountryInfo',
    input:
    [
      { name: this.getTranslation('LABEL_COUNTRY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'countryIsoCode', value: this.formCountryAdd.get("countryIsoCode")?.value, disabled: true},
      { name: this.getTranslation('LABEL_COUNTRY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'countryName', value: this.formCountryAdd.get("countryName")?.value, disabled: true},
      { name: this.getTranslation('LABEL_TIMEZONE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'timeZoneName', value: this.formCountryAdd.get("timeZoneName")?.value, disabled: true},
      { name: this.getTranslation('LABEL_CULTURE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'cultureCode', value: this.formCountryAdd.get("cultureIsoCode")?.value, disabled: true},
      { name: this.getTranslation('LABEL_CULTURE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'cultureName', value: this.formCountryAdd.get("cultureName")?.value, disabled: true},
      { name: this.getTranslation('LABEL_COUNTRYPREFIX'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'phonePrefix', value: this.formCountryAdd.get("phonePrefix")?.value, disabled: true},
    ],
    autocompleteCountryConfig: {
      name: this.getTranslation('LABEL_COUNTRY'),
      attr: 'countryName' as const,
      array: this.countryList,
      numberToShow: 6,
      prefix: false,
      value : null as any,
      inputName : 'countryName',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('COMMON_SELECT_COUNTRY'),
    }
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_ADD_COUNTRY'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private authService: AuthenticationService,
    private utilsService: UtilsService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled =  !form.valid;
      });
    this.addBtnConfig.disabled = !form.valid;
  }

  inputValues(event: any){

    this.formCountryAdd.controls[event.target.name].setValue(event.target.value);

    if (event.target.name === 'countryName') {
      this.formCountryAdd.patchValue({
        countryIsoCode: event.target.value?.countryIsoCode,
        countryName: event.target.value?.countryName,
        timeZoneName: event.target.value?.timeZoneName,
        cultureCode: event.target.value?.cultureCode,
        cultureName: event.target.value?.cultureName,
        phonePrefix: event.target.value?.phonePrefix,
      });

      this.formCountryInfo!.input[0].value = this.formCountryAdd.get("countryIsoCode")?.value;
      this.formCountryInfo!.input[1].value = this.formCountryAdd.get("countryName")?.value;
      this.formCountryInfo!.input[2].value = this.formCountryAdd.get("timeZoneName")?.value;
      this.formCountryInfo!.input[3].value = this.formCountryAdd.get("cultureCode")?.value;
      this.formCountryInfo!.input[4].value = this.formCountryAdd.get("cultureName")?.value;
      this.formCountryInfo!.input[5].value = this.formCountryAdd.get("phonePrefix")?.value;
    }

    this.formValidation(this.formCountryAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/countries');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/countries/detail', this.id]);
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    this.countryList = this.getAllCountry();

    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE');
      this.getCountryDetail();
    } 

    this.loading = false;
  }

  getCountryDetail(){
    this.clientService.countryGet(this.id).subscribe(
      response =>{

        this.countryDetails = response;

        this.formCountryAdd.patchValue({
          countryIsoCode: response.countryIsoCode,
          countryName: response.countryName,
          timeZoneName: response.timeZoneName,
          cultureCode: response.cultureCode,
          cultureName: response.cultureName,
          phonePrefix: response.phonePrefix?.phonePrefix,
        });
  
        this.formCountryInfo!.input[0].value = this.formCountryAdd.get("countryIsoCode")?.value;
        this.formCountryInfo!.input[1].value = this.formCountryAdd.get("countryName")?.value;
        this.formCountryInfo!.input[2].value = this.formCountryAdd.get("timeZoneName")?.value;
        this.formCountryInfo!.input[3].value = this.formCountryAdd.get("cultureCode")?.value;
        this.formCountryInfo!.input[4].value = this.formCountryAdd.get("cultureName")?.value;
        this.formCountryInfo!.input[5].value = this.formCountryAdd.get("phonePrefix")?.value;

        this.formCountryInfo!.autocompleteCountryConfig.value = { 
          countryName: response.countryName,
        }

        this.formCountryInfo!.autocompleteCountryConfig = {...this.formCountryInfo!.autocompleteCountryConfig}

        this.isLeadingZero = response.phonePrefix?.isLeadingZeroToBeRemoved ?? false;
        let isSignature = response.parameters?.find(x => x.functionality == "flow__prescription__is_signature_needed")!.value;
        this.isSignatureNeeded = this.primitiveToBoolean(isSignature);
        
        this.formValidation(this.formCountryAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  getAllCountry(): any{
    this.clientService.countryGetAllV2(false).subscribe(
      response =>{

        this.countryList = response;
        this.formCountryInfo.autocompleteCountryConfig.array = response;
        this.formCountryInfo.autocompleteCountryConfig.disabled = false;
      } ,
      error=> console.log(error)
    );
  }

  saveCountry(){
    if (this.formCountryAdd.valid) {

      this.selectedCountry = this.formCountryAdd.get('countryIsoCode')?.value?.countryIsoCode ?? this.authService.currentUserValue.countryIsoCode;

      this.loading = true;
      let country: ICountrySetRQ = {
        countryIsoCode: this.formCountryAdd.get('countryIsoCode')?.value,
        countryName: this.formCountryAdd.get('countryName')?.value,
        timeZoneName: this.formCountryAdd.get('timeZoneName')?.value,
        cultureCode: this.formCountryAdd.get('cultureCode')?.value,
        cultureName: this.formCountryAdd.get('cultureName')?.value,
        phonePrefix: this.formCountryAdd.get('phonePrefix')?.value,
        isSignatureNeeded: this.isSignatureNeeded,
        isLeadingZeroToBeRemoved: this.isLeadingZero,
        isAdd: this.id == undefined
      }
      this.clientService.countrySet(new CountrySetRQ(country)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){
            
            this.idCreated = response.countryIsoCode;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'COUNTRY_ADD_SUCCESSFULLY',
                description: this.selectedCountry,
                actionToDo: () => 
                {
                  this.router.navigate(['/countries/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              console.log("ERRORE");
            }
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  checkIsSignatureNeeded(){
    this.isSignatureNeeded = !this.isSignatureNeeded;
  }

  checkIsLeadingZero(){
    this.isLeadingZero = !this.isLeadingZero;
  }

  primitiveToBoolean(value: string | number | boolean | null | undefined): boolean {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true' || !!+value;  // here we parse to number first
    }
  
    return !!value;
  }
}
