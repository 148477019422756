<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true">
                <svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>

        <ng-container *ngIf="!id ; else editTitle">
            <h2 class="mt-4">{{'NURSE_ADD_TITLE' | translate}}</h2>
        </ng-container>
        <ng-template #editTitle>
            <h2 class="mt-4">{{'NURSE_EDIT_TITLE' | translate}}</h2>
        </ng-template>

        <form [formGroup]="formNurseAdd" class="my-4">

            <div class="row my-2">
                <div class="col-md-6 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formNurseInfo.clinicSelectConfig"
                        (selected)="inputValues($event)"></app-autocomplete-dropdown>

                    <span class="info-text mt-2">{{'NURSE_ADD_CLINIC_NOTE' | translate}}</span>
                    <hr class="w-100">

                </div>

            </div>


            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNurseInfo.input[0]" [formControlInput]="formNurseAdd"
                        (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNurseInfo.input[1]" [formControlInput]="formNurseAdd"
                        (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-radio-button [radioButtonConfig]="formNurseInfo?.radioButton"
                        (radioOutput)="inputValues($event)"></app-radio-button>
                </div>
                <div class="col m-auto">
                    <app-info-tooltip [description]="'PATIENT_GENDER' | translate"></app-info-tooltip>
                </div>
            </div>
            
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNurseInfo.input[3]" [formControlInput]="formNurseAdd"
                        (keyup)="this.emailAddressIsValid=false;inputValues($event)"></app-input>
                    
                    <br><span><i class="fas fa-exclamation-triangle"></i><b>{{'NURSE_ADD_EMAIL_MUSTPERSONAL_NOCOLLECTIVE' | translate}}</b></span>
                </div>
                <div class="col m-auto">
                    <app-info-tooltip [description]="'NURSE_ADD_INFO_WORK_EMAIL' | translate"></app-info-tooltip>
                </div>
            </div>

            <hr/>
            
            <br/>
            <p class="desc-gray bold">{{'LABEL_OPTIONAL'| translate}}</p>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <div class="row row-aligned">
                        <div class="col-md-4 col-4">
                            <app-phoneprefix-dropdown [autocompleteConfig]="formNurseInfo?.autocompleteCountryPhonePrefixConfig" (selected)="inputValues($event)" ></app-phoneprefix-dropdown>
                        </div>
                        <div class="col md-8 col-8">
                            <app-input [inputConfig]="formNurseInfo.input[2]" (keyup)="inputValues($event)"></app-input>
                        </div>
                    </div>
                </div>
                <div class="col m-auto">
                    <app-info-tooltip [description]="'NURSE_ADD_INFO_PHONENUMBER' | translate"></app-info-tooltip>
                </div>
            </div>

            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>

            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button (click)="addNurse()" [buttonConfig]="addBtnConfig"></app-button>
                    <span *ngIf="!id" class="info-text m-2">{{'NURSE_ADD_NEXTSTEP_NOTE' | translate}}</span>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'"
    [SnackText]="snackText"></app-snackbar>