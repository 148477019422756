<ngx-smart-modal #releaseNotesModal identifier="releaseNotesModal" [style.height.px]="'900'" [style.width.px]="'800'" data-backdrop="static" id="releaseNotesModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="w-100 d-flex justify-content-end p-4 mb-1">
        <app-round-button [type]="'CLOSE'" (onClick)="releaseNotesModal.close()"></app-round-button>
    </div>
    <div class="release-note-body">
        <img src="../../../assets/images/release-note.png" alt="" class="release-image">
        <app-title [textConfiguration]="titles[0]"></app-title>
        <app-title [textConfiguration]="titles[1]"></app-title>
        <div class="release-content">
            <div [innerHTML]="releaseNotesContent"></div>
        </div>
        <div class="w-100 d-flex justify-content-center my-4">
            <app-button [buttonConfig]="okButtonConfig" (onClick)="releaseNotesModal.close()"></app-button>
        </div>
    </div>
</ngx-smart-modal>
