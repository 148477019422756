import { HostListener, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ITab } from '../models/tab.model';

@Injectable({
  providedIn: 'root',
})
export class TabService {

  // active counter observable //
  private countSubject?: Subject<any>;
  public  countObs?: Observable<any>;
  // active tab id observable //
  private activeIdSubject?: Subject<any>;
  public  activeIdObs?: Observable<any>;

  tabs: any[] = []
  activeTab: number = 0;
  activeTabId: any; 

  tabsIndex: any;
  mainContentOpacity:any;
  tabsDisplay: any;

  tabClicked = false;

  private stylesSubject?: Subject<any>;
  public  stylesObs?: Observable<any>;

  maxTabNumber?: number = 6;

  needsTruncate: boolean = false;
  previousUrl: any;
  constructor(
    private router: Router
  ) {
    this.countSubject = new Subject<any>();
    this.countObs = this.countSubject.asObservable();

    this.activeIdSubject = new Subject<any>();

    this.stylesSubject = new Subject<any>();
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event:any) => {
      this.previousUrl = event.url;
    });
  }
    // Set tab's patient id i want to edit
  setActiveTabId(id: any, isTabClicked:boolean){
    if(isTabClicked){
      this.tabClicked = true;
    }else{
      this.tabClicked = false;
    }

    this.activeIdSubject?.next(id)
    this.activeTabId = id
  }

  // Retrieve tab's patient id i'm clicking
  GetActiveTabId(){
    return this.activeIdSubject?.asObservable();
  }
  UnscribeActiveTab(){
    return this.activeIdSubject;
  }

  //set count to have right active tab
  setCount(num: number){
    this.countSubject?.next(num)
  }

  addTab(data: any) {
    if(this.tabs.length < this.maxTabNumber!){
      let exitstingTab = this.tabs.find(x=> x.idPatient == data.idPatient);
      if(exitstingTab && exitstingTab!== undefined){
        let existingIndex = this.tabs.findIndex(x=> x.idPatient === data.idPatient) + 1
        this.activeTab = existingIndex;
        this.setCount(this.activeTab);
      }else{
        this.tabs.push(data);
        this.activeTab = this.tabs.length;
        this.setCount(this.activeTab);
      }
      this.showTabs();
    }else{
      this.tabs.splice(0,1)
      this.tabs.push(data);
      this.activeTab = this.tabs.length;
      this.setCount(this.activeTab);
    }

    if(this.tabs.length > this.maxTabNumber!/2){
      this.needsTruncate = true
    }else{
      this.needsTruncate = false
    }
  }

  getTabByIndex(index: number): any{
    return this.tabs[index];
  }

  deleteTab(index: number) {
    if(this.tabs.length === 1){
      this.hideTabs();
      this.router.navigate([this.previousUrl])
      this.tabs.splice(index, 1);
    }else{
      this.tabs.splice(index, 1);
      this.activeTab = this.tabs.length
      this.setCount(this.activeTab)
      this.setActiveTabId(this.getTabByIndex(this.tabs.length-1).idPatient, false)

    }
  }
  emptyTabs(){
    this.tabs = [];
  }

  showTabs(){
    this.stylesSubject?.next({opacity: 0, display: 'block'})
  }

  hideTabs(){
    this.stylesSubject?.next({opacity: 1, display: 'none'})
  }

  getStyles(){
    return this.stylesSubject?.asObservable();
  }




}
