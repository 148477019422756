import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepicker, NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap';
import { BackOfficeCalendarDatePatientView } from 'src/app/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-orders-calendar',
  templateUrl: './orders-calendar.component.html',
  styleUrls: ['./orders-calendar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrdersCalendarComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private calendar: NgbCalendar
  ) { }
  
  @Input() deliveries!: BackOfficeCalendarDatePatientView[];
  @Input() maxDate!: NgbDateStruct;
  @Output() onMonthChange = new EventEmitter<{month: number, year: number}>();
  @ViewChild('dp') dp!: NgbDatepicker;
  
  plannedOrderIcon = '../../../../../../assets/icons/truck-icon.svg';
  pickingIcon = '../../../../../../assets/icons/calendar-picking-date-icon.svg';
  dueDateIcon = '../../../../../../assets/icons/calendar-due-date-icon.svg';
  
  firstWindow?: {from: Date, to: Date};
  firstDelivery?: Date;
  firstPickingDate?: Date;
  firstDueDate?: Date;

  ngOnInit(): void {
    this.firstWindow = { from: this.deliveries[0].dueDate!, to: this.deliveries[0].deliveryDate!}
    this.firstDelivery = this.deliveries[0].deliveryDate
    this.firstPickingDate = this.deliveries[0].pickingDate;
    this.firstDueDate = this.deliveries[0].dueDate;
  }

  ngAfterViewInit(): void {
    // Nagivate to correct month
    const firstDate = this.deliveries[0].pickingDate!;
    this.dp.navigateTo({ year: firstDate.getFullYear(), month: firstDate.getMonth() + 1 })
  }

  monthChanged(e: NgbDatepickerNavigateEvent){
    const { month, year } = e.next; 
    this.onMonthChange.emit({month, year});
  }

  // Used for checking order windows in calendar
  isInside = (date: NgbDate) => this.deliveries.some(x=> date.after(this.getStructureFromDate(x.dueDate)) && date.before(this.getStructureFromDate(x.deliveryDate))) 
	isEdge = (date: NgbDate) => this.deliveries.some(x=> date.equals(this.getStructureFromDate(x.dueDate)) || date.equals(this.getStructureFromDate(x.deliveryDate))) 
  
  // Check che first available delivery date to set calendar Min Date
  getFirstDateAvailableFromFirstDelivery = () => this.getStructureFromDate(new Date())

  // Utils
  isDifferentMonth = (date: NgbDate, currentMonth: any) => date.month !== currentMonth
  getStructureFromDate = (date: Date | undefined): NgbDateStruct => ({year: date!.getFullYear(), month: date!.getMonth() + 1, day: date!.getDate() })
  getDateFromNgbDate = (struct: NgbDateStruct): Date => new Date(struct.year, struct.month - 1, struct.day);
  getDateWithoutTime = (date: Date) => new Date(date.toDateString());
  

  isDeliveryDate = (date: NgbDate) => this.deliveries.some(x=> date.equals(this.getStructureFromDate(x.deliveryDate!)))
  isPickingDate = (date: NgbDate) => this.deliveries.some(x=> date.equals(this.getStructureFromDate(x.pickingDate!)))
  isDueDate = (date: NgbDate) => this.deliveries.some(x=> date.equals(this.getStructureFromDate(x.dueDate!)))
  isToday = (date: NgbDate) => date.equals(this.getStructureFromDate(new Date()));
  isHoliday = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  // Check if the day is part of first window present
  isFirstWindow = (date: NgbDate) => (
    this.firstWindow &&
    this.getDateFromNgbDate(date).getTime() >= this.getDateWithoutTime(this.firstWindow!.from).getTime() && 
    this.getDateFromNgbDate(date).getTime() <= this.firstWindow!.to.getTime() 
  );

  isFirstDelivery = (date: NgbDate) => date.equals(this.getStructureFromDate(this.firstDelivery!));
  isFirstDueDate = (date: NgbDate) => date.equals(this.getStructureFromDate(this.firstDueDate!));
  isFirstPickingDate = (date: NgbDate) => date.equals(this.getStructureFromDate(this.firstPickingDate!));






  // The execution order above is **NOT** casual, we have a hierarchy to see in a proper way the calendar
  getDayClass(date: NgbDate, currentMonth: any){


    if(this.isDifferentMonth(date, currentMonth)) return 'day-disabled';
    
    if(this.isDueDate(date)) return `startRange ${this.isFirstWindow(date) ? 'isEdge' : 'followingEdge'}`;

    if(this.isDeliveryDate(date)) return `endRange ${this.isFirstWindow(date) ? 'isEdge' : 'followingEdge'}`;
    
    if(this.isPickingDate(date)) return `${this.isFirstPickingDate(date) ? 'pickingDay' : 'followingPickingDay'} ${this.isFirstWindow(date)? 'inside' : 'followingInside'}`;

    if(this.isToday(date)) return 'today';

    if(this.isInside(date)) return `${this.isFirstWindow(date)? 'inside' : 'followingInside'}`;
    
    if(this.isHoliday(date)) return 'isHoliday';
    
    return '';
  }
}
