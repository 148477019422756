<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'REPORT_LIST_TITLE' | translate}}</h2>
            </div>
        </div>

        <div *ngIf="anyResult else noResultContent">
        <div class="row mt-2">
            <div class="col-lg-3 col-md-auto">
                <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)" ></app-search-field>
            </div>
        </div>
        <app-table [tableConfig]= "tableConfig" (orderChanged)="orderReportChanged($event)" (pageChanged)="pageReportChanged($event)"></app-table>
    </div>
    <ng-template #noResultContent>
        <div class="text-center noResult">
            <p class="alert">{{ 'REPORT_NORESULT' | translate }}</p>
            <p class="desc">{{ 'REPORT_NORESULT_DESC' | translate }}</p>
        </div>
    </ng-template>
</ng-template>
</div>
