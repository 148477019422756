<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="countryResponsibleDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>
        
        <div class="row">
            <div class="col-12 text-right">
                <a (click)="EditCountryResponsible()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA' | translate }} </u></a>
            </div>
        </div>

        <h2 class="mt-4">{{ countryResponsibleDetail.name  + " " + countryResponsibleDetail.surname}}</h2>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{countryResponsibleDetail.name}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{countryResponsibleDetail.surname}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryResponsibleDetail.countryIsoCode != null ? ('COUNTRY_' + countryResponsibleDetail.countryIsoCode | translate) : '-' }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_WORKMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{countryResponsibleDetail.emailAddress}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_MOBILE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryResponsibleDetail.mobileNumberCountryPrefix != null ? countryResponsibleDetail.mobileNumberCountryPrefix + " " + countryResponsibleDetail.mobileNumber : '' }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ROLE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryResponsibleDetail.role }}
                <br/>
                <i [innerHTML]="countryResponsibleDetail.roleDescription"></i>
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{countryResponsibleDetail.dateCreated | DateFormatPipe: true}}
            </div>
        </div>

        <div class="row mt-4" *ngIf='countryResponsibleDetail.dateDeleted'>
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DELETIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{countryResponsibleDetail.dateDeleted | DateFormatPipe: true}}
            </div>
        </div>

    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>