import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { ClientService, IOrderGetCatalogRQ, OrderGetCatalogRQ } from 'src/app/core';
import { SubCategoryGroupCustom } from 'src/app/core/models/subCategoryGroup-custom.model';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-order-catalog-modal',
  templateUrl: './order-catalog-modal.component.html',
  styleUrls: ['./order-catalog-modal.component.css']
})
export class OrderCatalogModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private smartModal: NgxSmartModalService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
  ) { }

  subscriptions: Subscription[] = []
  loading: boolean = true;

  idOrder: string = '';

  totalItemsCatalog = 0;
  
  okBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('BTN_OK'),
    disabled : false
  }

  dateDeliveryDesiredConfig = {
    labelName: this.getTranslation('DateDeliveryDesired'), 
    optional: false, 
    inputName: 'dateDeliveryDesired', 
    minDate: new Date(Date.now() + 24*60*60*1000),
    class: 'm-auto',
    value: undefined as any,
  }
  
  isDateDeliveryDesiredSelected = false;
  
  headers = [
    {name: this.getTranslation('PRODUCT_NAME'), field: 'productName', sortable: true},
    {name: this.getTranslation('PRODUCT_ARTICLE_NUMBER'), field: 'articleNumber', sortable: false},
    {name: this.getTranslation('PRODUCT_AVAILABLE_IN_CATALOG'), field: 'isAvailableInCatalog', sortable: false, isBoolean: true},
    {name: this.getTranslation('PRODUCT_REMOVAL_REASON'), field: 'removalReason', sortable: false},
  ];
  pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.totalItemsCatalog
  }
  sort = {
    field: "productName",
    direction: "asc"
  }
  subCategoryGroups!: any;
  filteredSubCategoryGroups!: any;
  orderDirection?: string = "productName_asc";
  currentPage: number = 1;

  filterProductName?: string;
  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_PRODUCT')}

  ngOnInit(): void {
    this.subscriptions = []
  }

  ngAfterViewInit(): void {
    let modalDataSubscription = this.smartModal.getModal('orderCatalogModal').onOpen.subscribe(()=>{
      var content = this.smartModal.getModal('orderCatalogModal').getData();
      this.idOrder = content.idOrder;
      this.dateDeliveryDesiredConfig.value = content.dateDeliveryDesired;
      this.formPlanDate.controls['dateDeliveryDesired'].setValue(content.dateDeliveryDesired);
      this.dateDeliveryDesiredConfig = {...this.dateDeliveryDesiredConfig};
      this.formValidation(this.formPlanDate);
    })
    this.subscriptions.push(modalDataSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  formPlanDate: FormGroup = this.fb.group({
    dateDeliveryDesired: [null, Validators.required],
  })

  inputValues(event: any){    
    this.formPlanDate.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.formPlanDate);
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.okBtnConfig.disabled = !form.valid;
      });
    this.okBtnConfig.disabled = !form.valid;
  }

  getOrderCatalog(){

    this.loading = true;

    let input: IOrderGetCatalogRQ;
    input = {
      idOrder: this.idOrder,
      cultureIsoCode: this.utilsService.getDefaultLanguage() ?? "en-GB",
      desiredDeliveryDate: this.formPlanDate.controls['dateDeliveryDesired'].value
    }
    this.clientService.orderGetCatalogBackOffice(new OrderGetCatalogRQ(input)).subscribe(
      response =>{

        this.subCategoryGroups = response.subCategoryGroups!.map(v => new SubCategoryGroupCustom(v));
        this.subCategoryGroups[0].isExpanded = true;
        this.filteredSubCategoryGroups = this.subCategoryGroups;
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderTableChanged(headerField: string){
    this.sort.field = headerField;
    this.sort.direction = this.sort.direction == "asc" ? "desc" : "asc";
  }

  pageCatalogChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
  }

  openSubCategory(subCategoryGroup: any){
    subCategoryGroup.isExpanded = !subCategoryGroup.isExpanded;
  }

  searchFilterKeyUp(value:any){
    this.filterProductName = value;

    this.filteredSubCategoryGroups = this.subCategoryGroups;

    if(this.filterProductName != "")
    {
      this.filteredSubCategoryGroups = this.filteredSubCategoryGroups.filter((x: { productClusters: any; }) => 
        x.productClusters.some((y: any) => y.productName.toLowerCase().includes(this.filterProductName!.toLowerCase()) || 
                                            y.products[0]?.articleNumber?.toLowerCase().includes(this.filterProductName!.toLowerCase())))
        .map((element: { productClusters: any[]; }) => {
          return Object.assign({}, element, {productClusters : element.productClusters.filter(y => y.productName.toLowerCase().includes(this.filterProductName!.toLowerCase()) ||
                                                                                                  y.products[0]?.articleNumber?.toLowerCase().includes(this.filterProductName!.toLowerCase())
          )});
        }); 
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }

  closeModal(){
    this.smartModal.getModal('orderCatalogModal').close();
    this.isDateDeliveryDesiredSelected = false;
  }
}
