<ngx-smart-modal #planRegistrationCodeModal identifier="planRegistrationCodeModal" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-header header-edit">
    <app-round-button [type]="'CLOSE'" (onClick)="planRegistrationCodeModal.close()"></app-round-button>
</div>
<div class="modal-body mt-2 text-center">
    <app-date-picker [datePickerConfig]="datePickerConfig" (resultDate)="inputValues($event)">
    </app-date-picker>
    <br>
    <app-button [buttonConfig]="btnConfigConfirm" (click)="planSendCode()"></app-button>
</div>
</ngx-smart-modal>