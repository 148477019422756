import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-locked-modal',
  templateUrl: './account-locked-modal.component.html',
  styleUrls: ['./account-locked-modal.component.css']
})
export class AccountLockedModalComponent implements OnInit {

  constructor(
    private translate : TranslateService
  ) { }

  ngOnInit(): void {
  }

  okBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('BTN_OK'),
    disabled : false
  }

  getTranslation(wordYouNeedToTranslate: string){
    return this.translate.instant(wordYouNeedToTranslate);
  }

}
