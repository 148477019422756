<ngx-smart-modal #orderCatalogModal identifier="orderCatalogModal" [style.height.px]="'900'" [style.width.px]="'1400'" data-backdrop="static" id="compareEntityModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="modal-header">
  
      <h2>{{ 'ORDER_CATALOG_LABEL' | translate }}</h2>
  
      <app-round-button [type]="'CLOSE'" (onClick)="closeModal()"></app-round-button>
    </div>
    <div class="release-content">

        <ng-container *ngIf="isDateDeliveryDesiredSelected == false; else stepCatalog">
            <app-date-picker [datePickerConfig]="dateDeliveryDesiredConfig" (resultDate)="inputValues($event)"></app-date-picker>
      
            <div class="w-100 d-flex justify-content-center my-4">
              <app-button [buttonConfig]="okBtnConfig" (onClick)="isDateDeliveryDesiredSelected = true; getOrderCatalog();"></app-button>
            </div>
        </ng-container>

        <ng-template #stepCatalog>

            <a (click)="isDateDeliveryDesiredSelected = false;"><i class="fas fa-chevron-left"></i><u class="edit_link"> {{ 'CLINIC_ADD_BACKRESULTS' | translate }} </u></a>

            <br/><br/>

            <div class="col-lg-4 col-md-auto">
                <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)" ></app-search-field>
            </div>

            <br/>

            <!--CATEGORIES-->
            <div class="col-12 col-lg-12" *ngIf="filteredSubCategoryGroups?.length != 0">
                <ul *ngFor="let group of filteredSubCategoryGroups">
                    <li class="edit_link" (click)="openSubCategory(group)">
                        <b>{{group.subCategoryName}}</b>
                        &nbsp;
                        <i [ngClass]="group.isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </li>

                    <br *ngIf="group.isExpanded"/>
                    
                    <!--PRODUCTS-->
                    <table *ngIf="group.isExpanded" class="table">
                        <thead class="thead" >
                            <tr class="table-header">
                            <ng-container *ngFor="let header of headers">
                                <th>
                                <span [appSort]= "group.productClusters" (click)="orderTableChanged(header.field)" data-order="desc" [attr.data-name]="header.field" scope="col">
                                    {{header.name}} <svg-icon src="../../../assets/icons/sort-icon.svg" ></svg-icon>
                                </span>
                                </th>
                            </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of group.productClusters">
                                <td scope="row">{{record.products[0].productName}}</td>
                                <td scope="row">{{record.products[0].articleNumber}}</td>
                                <td>
                                    <app-checkbox [checked]="record.products[0].isAvailableInCatalog"></app-checkbox>
                                </td>
                                <td scope="row">{{ record.products[0].removalReason | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
            </div>
        </ng-template>
    </div>
  </ngx-smart-modal>