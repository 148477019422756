<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="calendarDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true">
                <svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>

        <br/>

        <div class="row">
            <div class="col-12 text-right">
                <i class="far" class="fas fa-ellipsis-v" (click)="isClosedEditPopup = !isClosedEditPopup"></i>

                <div class="language-drp" [ngClass]="isClosedEditPopup ? 'closed' : 'open'">
                    <div class="drp-content edit_link">
                        <app-text *ngIf="calendarDetail.dateDeleted == undefined" [text]="'COMMON_EDIT_DATA' | translate" extraClass="p-2 c-pointer" [fontSizePx]="16" [isBold]="true" (onTextClick)="EditCalendar()" [avoidTextSelection]="true" ></app-text>
                        <app-text [text]="'CALENDAR_CLONE' | translate" extraClass="p-2 c-pointer" [fontSizePx]="16" [isBold]="true" (onTextClick)="CloneCalendar()" [avoidTextSelection]="true" ></app-text>
                    </div>
                </div>
            </div>
        </div>

        <h2 class="mt-4">{{ 'CALENDAR_DETAILS' | translate }}</h2>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.calendarName }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_DESCRIPTION' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.calendarDescription }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_STATUS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.isDeleted ? ('ACCOUNT_STATUS_DELETED' | translate) : ('ACCOUNT_STATUS_ACTIVE' | translate) }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_DETAIL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ 'COUNTRY_' + calendarDetail.countryIsoCode | translate }}
            </div>
        </div>

        <br/><hr/><br/>
        
        <h4> {{ 'CALENDAR_DELIVERY_DATE' | translate }}</h4>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_BASE_WEEK' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.deliveryWeekLabel }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_WEEK_DAY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.deliveryDateLabel }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_FREQUENCY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.frequencyLabel }}
            </div>
        </div>

        <br/><hr/><br/>
        
        <h4> {{ 'CALENDAR_PICKING' | translate }}</h4>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_WEEK_DAY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.pickingWeekDayLabel }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_WEEK' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.pickingWeekLabel }}
            </div>
        </div>

        <br/><hr/><br/>
        
        <h4> {{ 'CALENDAR_DUE_DATE' | translate }}</h4>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_WEEK_DAY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.dueDateWeekDayLabel }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CALENDAR_WEEK' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ calendarDetail.dueDateWeekLabel }}
            </div>
        </div>

        <br/><hr/><br/>

        <h4>{{ 'CALENDAR_ANNUAL_ORDER' | translate }}</h4>

        <app-table [tableConfig]= "tableCalendarDateConfig" (orderChanged)="orderCalendarDateChanged($event)" (onClick)="GoToCalendarDateDetail($event)" (pageChanged)="pageCalendarDateChanged($event)"></app-table>

        <br/><hr/><br/>

        <h4>{{ 'CALENDAR_PATIENT_ASSIGNED' | translate }}</h4>

        <app-table [tableConfig]= "tableCalendarPatientConfig" (orderChanged)="orderCalendarPatientChanged($event)" (onClick)="GoToCalendarPatientDetail($event)" (pageChanged)="pageCalendarPatientChanged($event)"></app-table>

        <br/><br/>

        <div class="col-12 text-left">
            <a (click)="DeleteCalendar()" class="text-right"><i class="far" [ngClass]="this.calendarDetail.dateDeleted ? 'fas fa-redo' : 'fas fa-trash'"></i> <u class="edit_link">
                {{ this.calendarDetail.dateDeleted ? ('CALENDAR_RESTORE_MODAL_BTN' | translate) : ('CALENDAR_DELETE_MODAL_BTN' | translate) }}</u></a>
        </div>

        <br/>

    </div>

</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>