<div class="container-fluid summary-solutions" [ngClass]="solutionsTableConfig.extraClass ? solutionsTableConfig.extraClass : ''">
  <!-- CAPD SOLUTION -->
  <div *ngIf="CAPDList!.length > 0">
    <app-title *ngIf="!solutionsTableConfig.addConsumption" [textConfiguration]="solutionsTableConfig.capdListTitle"></app-title>
    <!-- Header -->
    <div class="solution-record" [ngClass]="solutionsTableConfig.roundedStyle ? 'none' : 'header-start'">
      <span *ngFor="let head of solutionsTableConfig.solutionListHeader">
        <ng-container *ngIf="head.key !== 'actions'">
          {{head.label}}
        </ng-container>
      </span>
    </div>
    <!-- Record -->
    <div *ngFor="let element of this.CAPDList; let j= index" class="solution-record content" [ngClass]="solutionsTableConfig.roundedStyle ? 'rounded' : 'none'" >
      <span *ngFor="let head of solutionsTableConfig.solutionListHeader">
        <div [ngSwitch]="head.key">
          <div *ngSwitchCase="'solutionType'">
            <div class="bag-solution">
              <svg-icon [src]="head.url" [ngClass]="element['bagColour']"></svg-icon>
              <span>{{element[head.key]}}&reg;</span>
            </div>
          </div>
          <div *ngSwitchCase="'actions'">
            <div class="actions" *ngIf="!solutionsTableConfig.addConsumption">
              <app-button *ngIf="solutionsTableConfig.editButtonConfig" [buttonConfig]="solutionsTableConfig.editButtonConfig" (click)="solutionEventEmit('edit',element,true,$event)"></app-button>
              <app-button *ngIf="solutionsTableConfig.deleteButtonConfig" [buttonConfig]="solutionsTableConfig.deleteButtonConfig"
              (click)="solutionEventEmit('delete',element.idPrescriptionSolution, true, $event)"></app-button>
            </div>
          </div>
          <div *ngSwitchCase="'treatmentsPerWeek'">
            <div *ngIf="isNumber(element.treatmentsPerWeek)">{{element['exchangesNumberPerDay']}} <span class="disabled">({{element.treatmentsPerWeek}} {{'EXCHANGES_NUMBER_PER_DAY_LABEL'|translate}})</span></div>
            <div *ngIf="!isNumber(element.treatmentsPerWeek) && !solutionsTableConfig.addConsumption" class="indicate-amount-consumption">
              {{element['treatmentsPerWeek']}}
              <app-button *ngIf="solutionsTableConfig.editConsumption" [buttonConfig]="solutionsTableConfig.editConsumption" (click)="solutionEventEmit('edit',element,true,$event)"></app-button>
            </div>
          </div>
          <div *ngSwitchCase="'suggestedBagsAmountPerYear'">
            <div *ngIf="isNumber(element.suggestedBagsAmountPerYear)">{{element[head.key]}} <span class="disabled">{{'RECORD_LABEL_SUGGESTED_BAGS_AMOUNT_PER_YEAR'|translate}}</span></div>
            <div *ngIf="!isNumber(element.suggestedBagsAmountPerYear) && !solutionsTableConfig.addConsumption" class="indicate-amount">{{element[head.key]}}</div>
          </div>
          <div *ngSwitchDefault>
            <div>{{element[head.key]}}</div>
          </div>
        </div>
      </span>
    </div>
  </div>
  <!-- END CAPD SOLUTIONS -->
  <!-- APD SOLUTIONS -->
  <div *ngIf="APDList!.length > 0">
    <app-title  *ngIf="!solutionsTableConfig.addConsumption" [textConfiguration]="solutionsTableConfig.apdListTitle"></app-title>
    <!-- Header -->
    <div class="solution-record" [ngClass]="solutionsTableConfig.roundedStyle ? 'none' : 'header-start'">
      <span *ngFor="let head of solutionsTableConfig.solutionListHeader">
        <ng-container *ngIf="head.key !== 'actions'">
          {{head.label}}
        </ng-container>
      </span>
    </div>
    <!-- Record -->
    <div *ngFor="let element of this.APDList; let j= index" class="solution-record content" [ngClass]="solutionsTableConfig.roundedStyle ? 'rounded' : 'none'">
      <span *ngFor="let head of solutionsTableConfig.solutionListHeader">
        <div [ngSwitch]="head.key">
          <div *ngSwitchCase="'solutionType'">
            <div class="bag-solution">
              <svg-icon [src]="head.url" [ngClass]="element['bagColour']"></svg-icon>
              <span>{{element[head.key]}}&reg;</span>
            </div>
          </div>
          <div *ngSwitchCase="'actions'">
            <div class="actions" *ngIf="!solutionsTableConfig.addConsumption">
              <app-button *ngIf="solutionsTableConfig.editButtonConfig" [buttonConfig]="solutionsTableConfig.editButtonConfig" (click)="solutionEventEmit('edit',element,true,$event)"></app-button>
              <app-button *ngIf="solutionsTableConfig.deleteButtonConfig" [buttonConfig]="solutionsTableConfig.deleteButtonConfig"
              (click)="solutionEventEmit('delete',element.idPrescriptionSolution, true, $event)"></app-button>
            </div>
          </div>
          <div *ngSwitchCase="'treatmentsPerWeek'">
            <div *ngIf="isNumber(element.treatmentsPerWeek)">{{element['exchangesNumberPerDay']}} <span class="disabled">({{element.treatmentsPerWeek}} {{'EXCHANGES_NUMBER_PER_DAY_LABEL'|translate}})</span></div>
            <div *ngIf="!isNumber(element.treatmentsPerWeek) && !solutionsTableConfig.addConsumption" class="indicate-amount">
              {{element['treatmentsPerWeek']}}
              <app-button *ngIf="solutionsTableConfig.editConsumption" [buttonConfig]="solutionsTableConfig.editConsumption" (click)="solutionEventEmit('edit',element,true,$event)"></app-button>
            </div>
          </div>
          <div *ngSwitchCase="'suggestedBagsAmountPerYear'">
            <div *ngIf="isNumber(element.suggestedBagsAmountPerYear)">{{element['suggestedBagsAmountPerYear']}} <span class="disabled">{{'RECORD_LABEL_SUGGESTED_BAGS_AMOUNT_PER_YEAR'|translate}}</span></div>
            <div *ngIf="!isNumber(element.suggestedBagsAmountPerYear) && !solutionsTableConfig.addConsumption" class="indicate-amount">{{element['suggestedBagsAmountPerYear']}}</div>
          </div>
          <div *ngSwitchDefault>
            <div>{{element[head.key]}}</div>
          </div>
        </div>
      </span>
    </div>
  </div>
  <div *ngIf="this.solutionsTableConfig.addButtonConfig">
    <app-button [buttonConfig]="this.solutionsTableConfig.addButtonConfig" (click)="solutionEventEmit('add',null,true,$event)"></app-button>
    <span class="normal-text">{{'BTN_SOLUTION_ADD'|translate}}</span>
  </div>
</div>
