<ngb-accordion [closeOthers]="true" (panelChange)="panelChange($event)">
	<ng-container *ngFor="let calendar of calendars; let i = index">
		<ngb-panel [id]="('cd_' + (i | json))" [cardClass]="isSelected(calendar.idCalendar!) ? 'selected' : ''">
			<ng-template ngbPanelTitle class="w-100">
				<div class="accordion-title" (click)="getCalendarInfo(calendar.idCalendar!)">
					<div *ngFor="let key of header">
						<div class="w-100 d-flex align-items-center justify-content-start" *ngIf="key.field === 'checkbox'">
							<div class="normal-circle" (click)="$event.stopPropagation(); onCheck(calendar)">
								<div [class.selected]="isChecked(calendar.idCalendar!)" class="selectet-ball"></div>
							</div>
						</div>
						<span [innerHTML]="$any(calendar)[key.field]" *ngIf="key.field !== 'checkbox'" style="overflow-wrap: anywhere;"></span>
					</div>
					<div class="accordion-arrow">
						<svg-icon src="../../../assets/icons/down-icon.svg" class="d-flex" [ngStyle]="{'transform' : openedPanel === ('cd_' + i) ? 'rotate(180deg)' : 'rotate(0)'}"></svg-icon>
					</div>
				</div>
			</ng-template>
			<ng-template ngbPanelContent>
				<div *ngIf="this.infoSpinner" class="w-100 h-100 d-flex align-items-center justify-content-center">
					<div class="spinner-box">
						<spinner></spinner>
						<p>{{'LOADING' | translate }}</p>
					</div>
				</div>
				<ng-container *ngIf="!this.infoSpinner">
					<div class="row">
						<div class="col-10">
							<h5 class="mb-4" >{{ 'PATIENT_NEXT_ORDERS' | translate }}</h5>
							<div class="d-flex flex-row align-items-center justify-content-between my-2 w-100" *ngFor="let date of currentCalendarInfo[calendar.idCalendar!] | slice:0:3">
								<div class="d-flex flex-column alitems-center justify-content-center">
									<span class="font-weight-bold">{{ 'CALENDAR_DELIVERY_DATE' | translate }}</span>
									<p class="text-muted">{{date.deliveryDate | DateFormatPipe}}</p>
								</div>
								<div class="d-flex flex-column alitems-center justify-content-center">
									<span class="font-weight-bold">{{ 'CALENDAR_PICKING' | translate }}</span>
									<p class="text-muted">{{date.pickingDate | DateFormatPipe}}</p>
								</div>
								<div class="d-flex flex-column alitems-center justify-content-center">
									<span class="font-weight-bold">{{ 'CALENDAR_DUE_DATE' | translate }}</span>
									<p class="text-muted">{{date.dueDate | DateFormatPipe}}</p>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-template>
		</ngb-panel>
	</ng-container>
</ngb-accordion>