<ngx-smart-modal #forgetPasswordModal identifier="forgetPasswordModal" [style.height.px]="'700'" [style.width.px]="'400'" data-backdrop="static" id="forgetPasswordModal" data-keyboard="false" [closable]="false" [dismissable]="false">
  <div class="modal-header">
    <h4 class="edit-title m-0" *ngIf="currentStep === 'reset'"> {{'LABEL_RESET_PASSWORD' | translate }}</h4>

    <app-round-button [type]="'CLOSE'" (onClick)="forgetPasswordModal.close(); this.currentStep='forget';"></app-round-button>
</div>
<div class="modal-body">
    <ng-container [ngSwitch]="currentStep">

          <!-- forget password page -->
          <ng-container *ngSwitchCase="'forget'">
              <div class="forget">
                  <div class="row mt-4 justify-content-center">
                    <img src="../../assets/images/forgot-password.png" alt="patient-registration-success" />
                  </div>
                  <div class="row mt-5 mb-5">
                      <div class="col-12">
                        <h4 class="edit-title mb-3 mt-1 text-center" [id]="'forgot-password-title'"> {{'FORGET_PASSWORD_TITLE' | translate }}</h4>
                        <p class="text-center"> {{'FORGET_PASSWORD_MODAL_MESSAGE' | translate}}</p>
                      </div>
                  </div>
                  <div class="row mt-5 justify-content-center">
                    <app-button [buttonConfig]="nextBtnConfig" (onClick)="goToStep('reset')"></app-button>
                  </div>
              </div>
          </ng-container>
          
          <!-- reset password page -->
          <ng-container *ngSwitchCase="'reset'">
              <div class="reset">
                  <div class="row mt-2 mb-5">
                    <div class="col-12 mb-4">
                      <h6 class="text-center" [innerHTML]="('FORGET_PASSWORD_MODAL_INSTRUCTION_MESSAGE' | translate)"></h6>
                    </div>
                      <div class="col-10 mx-auto">
                        <app-input [inputConfig]="emailInput" (value)="getEmailValue($event)"></app-input>
                      </div>
                      <div class="col-12">
                        <!-- <h6 class="text-center" id="reset-password-message"> {{'RESET_PASSWORD_MESSAGE' | translate}}</h6> -->

                      <div class="row mt-5 justify-content-center">
                        <app-button [buttonConfig]="sendLinkBtnConfig" (onClick)="sendLinkRequest()"></app-button>
                      </div>
                      
                      <div class="row mt-3 justify-content-center">
                        <app-button [buttonConfig]="doLaterBtnConfig" (onClick)="forgetPasswordModal.close(); this.currentStep='forget';"></app-button>
                      </div>
                     
                    </div>
                </div>
                <img class="delivery-woman" src="../../../assets/images/delivery-woman.png" alt="delivery-woman" />
                <img class="wave" src="../../../assets/backgrounds/wave.png" alt="delivery-woman" />
            </div>
        </ng-container>

        <!-- success page -->
        <ng-container *ngSwitchCase="'success'">
            <div class="success text-center">
                <div class="row mt-4 justify-content-center">
                    <img src="../../../../../assets/images/check-mail.png" alt="check_mail_image">
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                      <h2 class="success-title">{{'CHECK_MAIL_TITLE'|translate}}</h2>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <p class="success-subtitle" [id]="'forgot-password-success-title'" [innerHTML]="('FORGET_PASSWORD_MODAL_SUCCESS_MESSAGE'|translate)"></p>
                    </div>
                  </div>

                  <div class="row mt-5 justify-content-center">
                    <app-button [buttonConfig]="okBtnConfig" (onClick)="forgetPasswordModal.close(); this.currentStep='forget';"></app-button>
                  </div>
            </div>
        </ng-container>

    </ng-container>
</div>
</ngx-smart-modal>
