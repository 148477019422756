import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core';
import { Role } from './core/enum/roles';
import { LoginComponent } from './login/login.component';
import { ClinicDetailComponent } from './modules/pages/clinics/clinic-detail/clinic-detail.component';
import { ClinicListComponent } from './modules/pages/clinics/clinic-list/clinic-list.component';
import { ClinicEditComponent } from './modules/pages/clinics/clinic-edit/clinic-edit.component';
import { NurseDetailComponent } from './modules/pages/nurses/nurse-detail/nurse-detail.component';
import { NurseListComponent } from './modules/pages/nurses/nurse-list/nurse-list.component';
import { NephrologistDetailComponent } from './modules/pages/nephrologists/nephrologist-detail/nephrologist-detail.component';
import { NephrologistListComponent } from './modules/pages/nephrologists/nephrologist-list/nephrologist-list.component';
import { NephrologistEditComponent } from './modules/pages/nephrologists/nephrologist-edit/nephrologist-edit.component';
import { PatientListComponent } from './modules/pages/patients/patient-list/patient-list.component';
import { PatientDetailComponent } from './modules/pages/patients/patient-detail/patient-detail.component';
import { PatientEditComponent } from './modules/pages/patients/patient-edit/patient-edit.component';
import { SpecialistListComponent } from './modules/pages/specialists/specialist-list/specialist-list.component';
import { SpecialistDetailComponent } from './modules/pages/specialists/specialist-detail/specialist-detail.component';
import { SpecialistEditComponent } from './modules/pages/specialists/specialist-edit/specialist-edit.component';
import { SupportUserListComponent } from './modules/pages/support_users/support_user-list/support_user-list.component';
import { SupportUserDetailComponent } from './modules/pages/support_users/support_user-detail/support_user-detail.component';
import { SupportUserEditComponent } from './modules/pages/support_users/support_user-edit/support_user-edit.component';
import { DemoUserListComponent } from './modules/pages/demo_users/demo_user-list/demo_user-list.component';
import { DemoUserDetailComponent } from './modules/pages/demo_users/demo_user-detail/demo_user-detail.component';
import { DemoUserEditComponent } from './modules/pages/demo_users/demo_user-edit/demo_user-edit.component';
import { NurseEditComponent } from './modules/pages/nurses/nurse-edit/nurse-edit.component';
import { ChangePassCodeComponent } from './modules/pages/change-pass-code/change-pass-code.component';
import { RequestListComponent } from './modules/pages/requests/request-list/request-list.component';
import { NotificationListComponent } from './modules/pages/notifications/notification-list/notification-list.component';
import { NotificationDetailComponent } from './modules/pages/notifications/notification-detail/notification-detail.component';
import { ReportListComponent } from './modules/pages/reports/report-list/report-list.component';
import { ClinicSecurityComponent } from './modules/pages/clinics/clinic-security/clinic-security.component';
import { TimelineComponent } from './modules/pages/timeline/timeline.component';
import { CountryListComponent } from './modules/pages/country/country-list/country-list.component';
import { CountryEditComponent } from './modules/pages/country/country-edit/country-edit.component';
import { CountryDetailComponent } from './modules/pages/country/country-detail/country-detail.component';
import { PrescriptionDetailComponent } from './modules/pages/prescriptions/prescription-detail/prescription-detail.component';
import { PrescriptionListComponent } from './modules/pages/prescriptions/prescription-list/prescription-list.component';
import { OrderListComponent } from './modules/pages/orders/order-list/order-list.component';
import { OrderDetailComponent } from './modules/pages/orders/order-detail/order-detail.component';
import { AdminEditComponent } from './modules/pages/admins/admin-edit/admin-edit.component';
import { AdminDetailComponent } from './modules/pages/admins/admin-detail/admin-detail.component';
import { AdminListComponent } from './modules/pages/admins/admin-list/admin-list.component';
import { CountryResponsibleDetailComponent } from './modules/pages/country_responsibles/country_responsible-detail/country_responsible-detail.component';
import { CountryResponsibleEditComponent } from './modules/pages/country_responsibles/country_responsible-edit/country_responsible-edit.component';
import { IPDOrderListComponent } from './modules/pages/orders/ipd-order-list/ipd-order-list.component';
import { IPDOrderDetailComponent } from './modules/pages/orders/ipd-order-detail/ipd-order-detail.component';
import { CustomerServiceListComponent } from './modules/pages/customer_service_users/customer_service_user-list/customer_service_user-list.component';
import { CustomerServiceDetailComponent } from './modules/pages/customer_service_users/customer_service_user-detail/customer_service_user-detail.component';
import { CustomerServiceEditComponent } from './modules/pages/customer_service_users/customer_service_user-edit/customer_service_user-edit.component';
import { CalendarListComponent } from './modules/pages/calendars/calendar-list/calendar-list.component';
import { CalendarDetailComponent } from './modules/pages/calendars/calendar-detail/calendar-detail.component';
import { CalendarEditComponent } from './modules/pages/calendars/calendar-edit/calendar-edit.component';
import { CalendarDateEditComponent } from './modules/pages/calendar_dates/calendar_date-edit/calendar_date-edit.component';
import { CalendarDateDetailComponent } from './modules/pages/calendar_dates/calendar_date-detail/calendar_date-detail.component';
import { CalendarHistoryComponent } from './modules/pages/calendars/calendar-history/calendar-history.component';
import { CalendarDatePatientDetailComponent } from './modules/pages/calendar_dates_patient/calendar_date_patient-detail/calendar_date_patient-detail.component';
import { CalendarDatePatientEditComponent } from './modules/pages/calendar_dates_patient/calendar_date_patient-edit/calendar_date_patient-edit.component';
import { PatientAssignCalendarComponent } from './modules/pages/patients/patient-assign-calendar/patient-assign-calendar.component';
import { ReleaseNoteListComponent } from './modules/pages/release_notes/release_note-list/release_note-list.component';
import { ReleaseNoteDetailComponent } from './modules/pages/release_notes/release_note-detail/release_note-detail.component';
import { ReleaseNoteEditComponent } from './modules/pages/release_notes/release_note-edit/release_note-edit.component';

const routes: Routes = [
  {path: '' , redirectTo: '/requests', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'login/:email',component: LoginComponent},
  {path: 'changePassCode', component: ChangePassCodeComponent, canActivate: [AuthGuard]},
  {
    path: 'clinics',
    component: ClinicListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'clinics/detail/:id',
    component: ClinicDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'clinics/add',
    component: ClinicEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.CustomerService] }
  },
  {
    path: 'clinics/edit/:id',
    component: ClinicEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.CustomerService] }
  },
  {
    path: 'clinics/security/:id',
    component: ClinicSecurityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'nurses',
    component: NurseListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurses/detail/:id',
    component: NurseDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurses/demodetail/:id',
    data: {
      isDemo: true
    },
    component: NurseDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurses/add',
    component: NurseEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.CustomerService] }
  },
  {
    path: 'nurses/edit/:id',
    component: NurseEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nephrologists',
    component: NephrologistListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nephrologists/detail/:id',
    component: NephrologistDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'nephrologists/add',
    component: NephrologistEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.CustomerService] }
  },
  {
    path: 'nephrologists/edit/:id',
    component: NephrologistEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patients',
    component: PatientListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patients/detail/:id',
    component: PatientDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/detail/:id1/assign-calendar',
    component: PatientAssignCalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patients/detail/:id1/assign-calendar/:id2',
    component: PatientAssignCalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patients/demodetail/:id',
    data: {
      isDemo: true
    },
    component: PatientDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patients/add',
    component: PatientEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'patients/edit/:id',
    component: PatientEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'admins',
    component: AdminListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'admins/detail/:id',
    component: AdminDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'admins/add',
    component: AdminEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'admins/edit/:id',
    component: AdminEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }    
  },
  {
    path: 'specialists',
    component: SpecialistListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'specialists/detail/:id',
    component: SpecialistDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'specialists/add',
    component: SpecialistEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'specialists/edit/:id',
    component: SpecialistEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }    
  },
  {
    path: 'support-users',
    component: SupportUserListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'support-users/detail/:id',
    component: SupportUserDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'support-users/add',
    component: SupportUserEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'support-users/edit/:id',
    component: SupportUserEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'demo-users',
    component: DemoUserListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'demo-users/detail/:id',
    component: DemoUserDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'demo-users/add',
    component: DemoUserEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'demo-users/edit/:id',
    component: DemoUserEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'requests',
    component: RequestListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'nurses-requests',
    component: RequestListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'patients-requests',
    component: RequestListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSupport] }
  },
  {
    path: 'notifications',
    component: NotificationListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'notifications/detail/:id',
    component: NotificationDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'release-notes',
    component: ReleaseNoteListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'release-notes/detail/:id',
    component: ReleaseNoteDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'release-notes/add',
    component: ReleaseNoteEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'reports',
    component: ReportListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'timeline',
    component: TimelineComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'countries',
    component: CountryListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'countries/detail/:id',
    component: CountryDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'countries/add',
    component: CountryEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }
  },
  {
    path: 'countries/edit/:id',
    component: CountryEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin] }    
  },
  {
    path: 'country-responsibles/detail/:id',
    component: CountryResponsibleDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'country-responsibles/add/:countryIsoCode/:role',
    component: CountryResponsibleEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'country-responsibles/add/:countryIsoCode',
    component: CountryResponsibleEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'country-responsibles/edit/:id',
    component: CountryResponsibleEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }    
  },
  {
    path: 'customer-service-users',
    component: CustomerServiceListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'customer-service-users/detail/:id',
    component: CustomerServiceDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'customer-service-users/add',
    component: CustomerServiceEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }
  },
  {
    path: 'customer-service-users/edit/:id',
    component: CustomerServiceEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin] }    
  },
  {
    path: 'prescriptions/:id',
    component: PrescriptionListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'prescriptions/detail/:id',
    component: PrescriptionDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'orders/:id',
    component: OrderListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'orders/detail/:id',
    component: OrderDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'ipd-orders/:id',
    component: IPDOrderListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'ipd-orders/detail/:id',
    component: IPDOrderDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.AppSpecialist] }
  },
  {
    path: 'calendars',
    component: CalendarListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-history',
    component: CalendarHistoryComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendars/detail/:id',
    component: CalendarDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendars/add',
    component: CalendarEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendars/edit/:id',
    component: CalendarEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendars/clone/:id',
    component: CalendarEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-dates/detail/:id',
    component: CalendarDateDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-dates/add',
    component: CalendarDateEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-dates/edit/:id',
    component: CalendarDateEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-date-patient/detail/:id',
    component: CalendarDatePatientDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-date-patient/add/:id',
    component: CalendarDatePatientEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {
    path: 'calendar-date-patient/edit/:id',
    component: CalendarDatePatientEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Admin, Role.CustomerService] }
  },
  {path: '**' , redirectTo: '/requests'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
