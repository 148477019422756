<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="patientDetail">
        
        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <!--BF 2022-09-19 edit name/surname patient-->
        <div *ngIf="isPatientDataManagementEnabled" class="row">
            <div class="col-12 text-right" >
                <a (click)="EditPatient()" class="text-right"><i class="fas fa-pencil-alt"></i> <u class="edit_link">{{ 'COMMON_EDIT_DATA' | translate }}</u></a>
            </div>
        </div>
        
        <div class="demoTag" *ngIf="isDemoNurse">
            <a>{{ 'COMMON_DEMO_MODE' | translate }}</a>
        </div>

        <h2 class="mt-4">{{ patientDetail.name  + " " + patientDetail.surname }}</h2>

        <div class="row mt-4" *ngIf="isPharmacyRelatedToClinic">
            <div class="col-6 col-lg-3">
                <b>{{ 'PHARMACY_LABEL' | translate }}</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.pharmacyName}}
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_CLINIC' | translate }}</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.clinicName}}
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.name}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.surname}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ID' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.idPatient}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SAPCODE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.sapUuid}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'PATIENT_GENDER' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (patientDetail.gender != null ? 'PATIENT_GENDER_' + patientDetail.gender : '') | translate }}
            </div>
        </div>


        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_BIRTHDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.birthDate | DateFormatPipe}}
            </div>
        </div>
        
        <div *ngIf="calendarManagement != 'OMG'" class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'ORDER_FREQUENCY_LABEL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.ordersFrequencyInWeeks}}
            </div>
        </div>

        <hr class="my-4">

        <div class="row">
            <div class="cardComp col-6" *ngFor="let address of patientDetail.addresses;">
                
                <b>{{ 'PATIENT_ADDRESS_' + address.type | translate }}:</b>

                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_COUNTRY' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryCountry}}
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_CITY' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryCity}}
                    </div>
                </div>
        
                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_ADDRESS' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryAddress}}
                    </div>
                </div>
        
                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_FLOOR' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryFloorString}}
                    </div>
                </div>
        
                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_APTNR' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryApartmentNumber}}
                    </div>
                </div>
        
                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_ZIPCODE' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryZipCode}}
                    </div>
                </div>
        
                <div class="row mt-4">
                    <div class="col-6">
                        <b>{{ 'PATIENT_DETAIL_COMMENTS' | translate }}:</b>
                    </div>
                    <div class="col-6">
                        {{address.deliveryComments}}
                    </div>
                </div>

                <div *ngIf="calendarManagement == 'OMG'">

                    <div class="row mt-4">
                        <div class="col-6">
                            <b>{{ 'PATIENT_DETAIL_LOGISTIC_COMPANY' | translate }}:</b>
                        </div>
                        <div class="col-6">
                            {{address.logisticCompanyInstructions}}
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-6">
                            <b>{{ 'PATIENT_DETAIL_FEE' | translate }}:</b>
                        </div>
                        <div class="col-6">
                            {{address.deliveryFee}}
                        </div>
                    </div>                
                
                    <br/>
                    
                    <a (click)="EditDeliveryFee(address)" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA' | translate }} </u></a>
                
                </div>

                <hr class="my-4">

            </div>
        </div>        

        <div class="row mt-4" >
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_MOBILE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.mobileNumber ? patientDetail.mobileNumberCountryPrefix + " " + patientDetail.mobileNumber : ""}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_LANDLINE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.phoneNumber ? patientDetail.phoneNumberCountryPrefix + " " + patientDetail.phoneNumber : ""}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_EMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.emailAddress}}
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.dateCreated | DateFormatPipe: true}}
            </div>
        </div>

        <div *ngIf="patientDetail.dateOnBoardingEnd" class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ONBOARDINGEND' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{patientDetail.dateOnBoardingEnd | DateFormatPipe: true}}
            </div>
        </div>

        <br />

        <div *ngIf="patientDetail.dateDeactivated">
            <div class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{ 'NURSE_DETAIL_DATEDEACTIVATION' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{patientDetail.dateDeactivated | DateFormatPipe: true}}
                </div>
            </div>
            <div *ngIf="patientDetail.supportUserDeactivation != null" class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{ 'NURSE_DETAIL_AGENT' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{patientDetail.supportUserDeactivation}}
                </div>
            </div>

            <br />
            <i>{{ 'PATIENT_REGISTER_AGAIN' | translate}}</i>
        </div>

        <br />
        <hr />

        <table class="table mt-5">

            <h5 class="col-8">{{ 'PATIENT_DETAIL_MYORDERS' | translate }}</h5>
            <tr>
                <th scope="col">{{ 'PATIENT_DETAIL_ACCOUNT_DATECREATED' | translate }}</th>
                <!--<th scope="col">{{ 'PATIENT_DETAIL_ACCOUNT_STATUS' | translate }}</th>-->
                <th scope="col">{{ 'PATIENT_DETAIL_ACCOUNT_ACCESS' | translate }}</th>
            </tr>
            <tr>
                <td scope="col">{{patientDetail.accountDateCreated | DateFormatPipe: true}}</td>
                <!--<td scope="col">{{ 'ACCOUNT_STATUS_' + patientDetail.accountStatus | translate }}</td>-->
                <td scope="col">{{ 'ACCOUNT_ACCESS_' + patientDetail.accountAccess | translate }}</td>
            </tr>
        </table>

        <div *ngIf="!patientDetail.dateDeactivated && patientDetail.mobileNumber">
            <app-invitation-box [title]="'PATIENT_DETAIL_INVITE_TITLE'" [description]="'PATIENT_DETAIL_INVITE_DESC'" 
                                [info]="'PATIENT_DETAIL_SENDCODE_NOW_DESC'" [plannedDesc]="'PATIENT_DETAIL_SENDCODE_PLANNED_DESC'"
                [isNurse]="false" (onClick)="sendCode()" (onClick2)="showPlanningCodeMode()" [registrationCodes]="patientDetail.registrationCodes"></app-invitation-box>
        </div>

        <!-- SUPPORT USER/ADMIN SECTION -->
        <div *appHasRole="['SUPER_ADMIN','ADMIN','SUPPORT_USER']" class="row">

            <!-- ACTIVE REQUESTS -->
            <div class="col-12 col-lg-12">
                <div class="mt-8" *ngIf="this.patientDetail.activeRequests?.length != 0">
                    
                    <table class="table w-5 mt-5">
                        <h5 class="col-8">{{ 'LABEL_ACTIVE_REQUESTS' | translate }}</h5>
                        <tr>
                            <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REQUEST' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REASON' | translate }}</th>
                            <th scope="col">{{ 'LABEL_USERCOMMENT' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_STATUS' | translate }}</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                        <ng-container *ngFor="let req of patientDetail.activeRequests">
                            <tr>
                                <td scope="row">{{req.dateCreated | DateFormatPipe: true}}</td>
                                <td *ngIf="req.requestType == 'PatientAccountHelp'" scope="row">{{ 'REQ_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'PatientAccountHelp'" scope="row">{{ 'REQ_' + req.requestType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType == 'PatientAccountHelp'" scope="row">{{ 'REASON_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'PatientAccountHelp'" scope="row">{{ 'REASON_' + req.requestType | uppercase | translate }}</td>
                                <td scope="row">{{req.requestDescription}}</td>
                                <td scope="row">{{ 'REQUEST_STATUS_' + req.status | uppercase | translate }}</td>
                                <td *ngIf="!patientDetail.dateDeactivated">
                                    <app-button
                                        *ngIf="req.status == 'New' && req.requestType == 'PatientUnlockAndRecoverPasscode'"
                                        [buttonConfig]="btnConfigUnlock" (click)="unlock(req)"></app-button>
                                    <app-button *ngIf="req.status == 'New' && (req.requestType == 'PatientSupport' || req.requestType == 'PatientAccountHelp' && req.requestSubType == 'GenericRequest')"
                                        [buttonConfig]="btnConfigStart" (click)="startTask(req)"></app-button>
                                    <app-button
                                        *ngIf="req.status == 'InProgress' && (req.requestType == 'PatientSupport' || req.requestType == 'PatientAccountHelp' && req.requestSubType == 'GenericRequest')"
                                        [buttonConfig]="btnConfigResolved" (click)="resolved(req)"></app-button>
                                    <app-button *ngIf="req.status == 'New' && req.requestType == 'PatientAccountHelp' && req.requestSubType == 'AccountDeletion'"
                                        [buttonConfig]="btnConfigDeactive" (click)="deactivatePatient(req)"></app-button>
                                </td>
                                <td>
                                    <i *ngIf="!req.isExpanded && req.status != 'New'" (click)="openComments(req)" class="fas fa-chevron-down edit_link"></i></td>
                                <td>
                                    <i *ngIf="req.isExpanded" (click)="openComments(req)" class="fas fa-chevron-up edit_link"></i>
                                </td>
                                <td *ngIf="!patientDetail.dateDeactivated">    
                                    <i *ngIf="req.requestType == 'PatientSupport' || req.requestType == 'PatientAccountHelp'" (click)="cancelRequest(req)" class="fas fa-trash-alt edit_link"></i>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8" *ngIf="req.isExpanded && req.status != 'New'">
                                    <table>
                                        <tbody>
                                            <tr scope="row">
                                                <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                                                <th scope="col">{{ 'NURSE_DETAIL_AGENT' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_STATUSHISTORY' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_COMMENTS' | translate }}</th>
                                            </tr>

                                            <tr *ngFor="let status of req.listStatus">
                                                <td>{{status.dateCreated | DateFormatPipe: true}}</td>
                                                <td>{{status.fullName}}</td>
                                                <td>{{ 'STATUS_TO_' + status.status | uppercase | translate}}</td>
                                                <td>{{status.comment}}</td>
                                            </tr>

                                            <tr scope="row">
                                                <td colspan="4">
                                                    <div class="text-right">
                                                        <button
                                                            style="background-color: white; border-color: gray; padding: 10px;"
                                                            (click)="addComment(req)">
                                                            {{ 'BTN_ADDCOMMENT' | translate }}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </ng-container>

                    </table>
                </div>
            </div>

            <!-- RESOLVED REQUESTS -->
            <div class="col-12 col-lg-12">
                <div class="mt-8" *ngIf="this.patientDetail.resolvedRequests?.length != 0">
                        
                    <table class="table w-5 mt-5">
                        <h5 class="col-8">{{ 'LABEL_RESOLVED_REQUESTS' | translate }}</h5>
                        <tr>
                            <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REQUEST' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REASON' | translate }}</th>
                            <th scope="col">{{ 'LABEL_USERCOMMENT' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_STATUS' | translate }}</th>
                            <th scope="col"></th>
                        </tr>
                        <ng-container *ngFor="let req of patientDetail.resolvedRequests">
                            <tr>
                                <td scope="row">{{req.dateCreated | DateFormatPipe: true}}</td>
                                <td *ngIf="req.requestType == 'PatientAccountHelp'" scope="row">{{ 'REQ_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'PatientAccountHelp'" scope="row">{{ 'REQ_' + req.requestType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType == 'PatientAccountHelp'" scope="row">{{ 'REASON_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'PatientAccountHelp'" scope="row">{{ 'REASON_' + req.requestType | uppercase | translate }}</td>
                                <td scope="row">{{req.requestDescription}}</td>
                                <td scope="row">{{ 'REQUEST_STATUS_' + req.status | uppercase | translate }}</td>
                                <td>
                                    <i *ngIf="!req.isExpanded && req.status != 'New'" (click)="openComments(req)" class="fas fa-chevron-down edit_link"></i></td>
                                <td>
                                    <i *ngIf="req.isExpanded" (click)="openComments(req)" class="fas fa-chevron-up edit_link"></i>
                                </td>
                                <td *ngIf="!patientDetail.dateDeactivated">   
                                    <i *ngIf="req.requestType == 'PatientSupport'" (click)="reopenRequest(req)" class="fas fa-undo-alt edit_link"></i>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8" *ngIf="req.isExpanded && req.status != 'New'">
                                    <table>
                                        <tbody>
                                            <tr scope="row">
                                                <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                                                <th scope="col">{{ 'NURSE_DETAIL_AGENT' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_STATUSHISTORY' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_COMMENTS' | translate }}</th>
                                            </tr>

                                            <tr *ngFor="let status of req.listStatus">
                                                <td>{{status.dateCreated | DateFormatPipe: true}}</td>
                                                <td>{{status.fullName}}</td>
                                                <td>{{ 'STATUS_TO_' + status.status | uppercase | translate}}</td>
                                                <td>{{status.comment}}</td>
                                            </tr>

                                            <tr scope="row">
                                                <td colspan="4">
                                                    <div class="text-right">
                                                        <button
                                                            style="background-color: white; border-color: gray; padding: 10px;"
                                                            (click)="addComment(req)">
                                                            {{ 'BTN_ADDCOMMENT' | translate }}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </ng-container>

                    </table>
                </div>
            </div>
        </div>

        <div >
            <br/><hr/>

            <h3 class="my-4">{{ 'PATIENT_NEXT_ORDERS' | translate }}</h3>
            
            <div *ngIf="patientDetail.idOrder != undefined; else noOrders">

                <!-- CALENDAR MANAGEMENT = OMG -->
                <div *ngIf="calendarManagement == 'OMG'; else noCalendarManagement">

                    <div *ngIf="patientDetail.idCalendar == undefined; else calendarAlreadySet" class="p-4 text-center" style="background-color: #CCD6EC; border: 1px solid black;">
                        <h4>{{ 'PATIENT_NO_CALENDAR' | translate }}</h4>
                        <span class="text-muted font-italic mt-2 font-bold">{{ 'PATIENT_NO_CALENDAR_DESC' | translate }}</span>
                        <div class="d-flex flex-row align-items-center justify-content-center mt-4">
                            <app-button *appHasRole="['SUPER_ADMIN','ADMIN','CUSTOMER_SERVICE_USER']" (click)="assignCalendar()" [buttonConfig]="{
                                bclass : 'btn btn-primary add-patient-button',
                                text : this.getTranslation('CALENDAR_ASSIGN'),
                                width: '30px',
                                height: '30px'
                            }"
                            ></app-button>
    
                        </div>
                    </div>
    
                    <ng-template #calendarAlreadySet>
                        <div class="col-12 text-right" >
                            <a (click)="assignCalendar()" class="text-right"><i class="fas fa-pencil-alt"></i> <u class="edit_link">{{ 'PATIENT_EDIT_CALENDAR' | translate }}</u></a>
                        </div>
    
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_NAME' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail.nextOrderDates?.calendarName }}
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_DESCRIPTION' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail.nextOrderDates?.calendarDescription }}
                            </div>
                        </div>
    
                        <div class="row mt-4" *appHasRole="'SUPER_ADMIN'">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'IdOrder' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail.idOrder }}
                            </div>
                        </div>
    
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_FREQUENCY' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail.nextOrderDates?.frequencyLabel }}
                            </div>
                        </div>
                        
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_DELIVERY_DATE' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail.nextOrderDates?.deliveryDate! | DateFormatPipe }}
                            </div>
                        </div>
                
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_PICKING' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail!.nextOrderDates?.pickingDate! | DateFormatPipe }}
                            </div>
                        </div>
                
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_DUE_DATE' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail!.nextOrderDates?.dueDate! | DateFormatPipe }}
                            </div>
                        </div>
                
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_ORDER_CLOSING_WINDOW' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail!.nextOrderDates?.closingWindowDate! | DateFormatPipe }}
                            </div>
                        </div>
                
                        <div class="row mt-4">
                            <div class="col-6 col-lg-3">
                                <b>{{ 'CALENDAR_ORDER_OPENING_WINDOW' | translate }}:</b>
                            </div>
                            <div class="col-6 col-lg-9">
                                {{ patientDetail!.nextOrderDates?.openingWindowDate! | DateFormatPipe }}
                            </div>
                        </div>

                        <div class="col-12 text-right mb-3" >
                            <a (click)="showOrderCalendar()" class="text-right"><i class="fas fa-calendar"></i>  <u class="edit_link">{{ 'PATIENT_ORDER_CALENDAR_LABEL' | translate }}</u></a>
                        </div>
                        <div class="col-12 text-right" >
                            <a (click)="ShowOrderCatalog()" class="text-right"><i class="fas fa-box"></i> <u class="edit_link"> {{ 'ORDER_CATALOG_LABEL' | translate }}</u></a>
                        </div>
                    </ng-template>
                </div>
    
                <!-- CALENDAR MANAGEMENT = NONE -->
                <ng-template #noCalendarManagement>
                    
                    <!--BF 2022-09-19 edit name/surname patient-->
                    <div *ngIf="isPatientDataManagementEnabled" class="col-12 text-right" >
                        <a (click)="EditOrderWindow()" class="text-right"><i class="fas fa-pencil-alt"></i> <u class="edit_link">{{ 'COMMON_EDIT_DATA' | translate }}</u></a>
                    </div>

                    <div class="row mt-4">
                        <div class="col-6 col-lg-3">
                            <b>IdNextOrder:</b>
                        </div>
                        <div class="col-6 col-lg-9">
                            {{patientDetail.idOrder}}
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-6 col-lg-3">
                            <b>DateDue:</b>
                        </div>
                        <div class="col-6 col-lg-9">
                            {{patientDetail.orderDateDue | DateFormatPipe}}
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-6 col-lg-3">
                            <b>DateDeliveryDesired</b>
                        </div>
                        <div class="col-6 col-lg-9">
                            {{patientDetail.orderDateDeliveryDesired | DateFormatPipe}}
                        </div>
                    </div>

                    <div class="col-12 text-right" >
                        <a (click)="ShowOrderCatalog()" class="text-right"><i class="fas fa-box"></i> <u class="edit_link"> {{ 'ORDER_CATALOG_LABEL' | translate }}</u></a>
                    </div>
                </ng-template>                
            </div>
            <!-- NO FIRST ORDER -->
            <ng-template #noOrders>
                {{ 'PATIENT_ADD_FIRSTORDERNEEDED' | translate }}
            </ng-template>            
            
            <br/>

            <div class="col-12 text-right" >
                <a (click)="ShowPatientListing()" class="text-right"><i class="fas fa-list"></i> <u class="edit_link"> {{ 'PATIENT_LISTING_LABEL' | translate }}</u></a>
            </div>

        </div>

        <hr/><br/>

        <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" (navChange)="getPatientHistory($event)">
            <!-- PRESCRIPTIONS -->
            <ng-container ngbNavItem [ngbNavItem]="'PRESCRIPTIONS'">
                <a ngbNavLink>
                    <app-text [text]="'COMMON_PRESCRIPTIONS_LABEL'|translate" [textType]="activeTab === 'PRESCRIPTIONS' ? 'BLUE' : 'GREY'" [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <app-prescription-list></app-prescription-list>
                </ng-template>
            </ng-container>
            <!-- ORDERS PLACED -->
            <ng-container ngbNavItem [ngbNavItem]="'PLACED_ORDERS'">
                <a ngbNavLink>
                    <app-text [text]="calendarManagement == 'OMG' ? ('ORDER_PLACED_LABEL'|translate) : ('COMMON_ORDERS_LABEL'|translate)" [textType]="activeTab === 'PLACED_ORDERS' ? 'BLUE' : 'GREY'" [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <app-order-list></app-order-list>
                </ng-template>
            </ng-container>
            <!-- FUTURE ORDER -->
            <ng-container ngbNavItem [ngbNavItem]="'FUTURE_ORDERS'" *ngIf="calendarManagement == 'OMG'">
                <a ngbNavLink>
                    <app-text [text]="'ORDER_FUTURE_LABEL'|translate" [textType]="activeTab === 'FUTURE_ORDERS' ? 'BLUE' : 'GREY'" [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <app-calendar-date-patient-list></app-calendar-date-patient-list>
                </ng-template>
            </ng-container>
            <!-- ENTITY_VERSIONS -->
            <ng-container ngbNavItem [ngbNavItem]="'ENTITY_VERSIONS'" *appHasRole="['SUPER_ADMIN']">
                <a ngbNavLink>
                    <app-text [text]="'PATIENT_ENTITY_VERSION_LABEL'|translate" [textType]="activeTab === 'ENTITY_VERSIONS' ? 'BLUE' : 'GREY' " [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <div *ngIf="this.loadingHistory ; else content" class="spinner-box">
                        <spinner></spinner>
                    </div>
                    <ng-template #content>
                        <app-table-history [tableConfig]="tableEntityVersionConfig" (pageChanged)="pageEntityVersionChanged($event)"></app-table-history>
                    </ng-template>
                </ng-template>
            </ng-container>
            <!-- HISTORY_SYNCRONIZATIONS -->
            <ng-container ngbNavItem [ngbNavItem]="'CMS_SYNCRONIZATIONS'" *appHasRole="['SUPER_ADMIN']">
                <a ngbNavLink>
                    <app-text [text]="'PATIENT_CMS_SYNC_LABEL'|translate" [textType]="activeTab === 'CMS_SYNCRONIZATIONS' ? 'BLUE' : 'GREY'" [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <div *ngIf="this.loadingHistory ; else content" class="spinner-box">
                        <spinner></spinner>
                    </div>
                    <ng-template #content>
                        <app-table-history [tableConfig]="tableHistorySynchronizationConfig" (pageChanged)="pageHistorySynchronizationChanged($event)"></app-table-history>
                    </ng-template>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
        
        <br/>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'"
    [SnackText]="snackText"></app-snackbar>

<app-plan-next-order [idOrder]="patientDetail?.idOrder" (planNextOrderOut)="planNextOrder($event)"></app-plan-next-order>
<app-plan-registration-code (planSendCodeOut)="sendCode($event)"></app-plan-registration-code>
<app-order-catalog-modal></app-order-catalog-modal>
<app-patient-listing-modal></app-patient-listing-modal>
<app-patient-delivery-fee-modal (patientDeliveryFeeOut)="getPatientDetail()"></app-patient-delivery-fee-modal>
<app-patient-order-calendar-modal></app-patient-order-calendar-modal>