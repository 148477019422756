  <div class="row">
    <div class="col-2">
      <label class="input-lable">{{separateDateConfig.input[0].name}} <span class="disabled" *ngIf="separateDateConfig.input[0].optional">{{'OPTIONAL_LABEL'|translate}}</span></label> <br>
      <input [(ngModel)]="this.day" [name]="separateDateConfig.input[0].inputName ? separateDateConfig.input[0].inputName : ''"
      type="number" class="input-form" [maxLength]="separateDateConfig.input[0].maxLength"
      [min]="separateDateConfig.input[0].min" [max]="separateDateConfig.input[0].max" [value]="this.separateDateConfig.input[0].value"
      [ngClass]="separateDateConfig.input[0].class" (change)="this.dateChange($event)" (keyup)="this.dateChange($event)">
    </div>
    <div class="col-2">
      <label class="input-lable">{{separateDateConfig.input[1].name}} <span class="disabled" *ngIf="separateDateConfig.input[1].optional">{{'OPTIONAL_LABEL'|translate}}</span></label> <br>
      <input [(ngModel)]="this.month" [name]="separateDateConfig.input[1].inputName ? separateDateConfig.input[1].inputName : ''"
      type="number" class="input-form" [maxLength]="separateDateConfig.input[1].maxLength"
      [min]="separateDateConfig.input[1].min" [max]="separateDateConfig.input[1].max" [value]="this.separateDateConfig.input[1].value"
      [ngClass]="separateDateConfig.input[1].class" (change)="this.dateChange($event)" (keyup)="this.dateChange($event)">
    </div>
    <div class="col-3">
      <label class="input-lable">{{separateDateConfig.input[2].name}} <span class="disabled" *ngIf="separateDateConfig.input[2].optional">{{'OPTIONAL_LABEL'|translate}}</span></label> <br>
      <input [(ngModel)]="this.year" [name]="separateDateConfig.input[2].inputName ? separateDateConfig.input[2].inputName : ''"
      type="number" class="input-form" [maxLength]="separateDateConfig.input[2].maxLength"
      [min]="separateDateConfig.input[2].min" [max]="separateDateConfig.input[2].max" [value]="this.separateDateConfig.input[2].value"
      [ngClass]="separateDateConfig.input[2].class" (change)="this.dateChange($event)" (keyup)="this.dateChange($event)">
    </div>
  </div>
  <div class="text-info">
    <div class="error-message" *ngIf="separateDateConfig.errorMessage">{{separateDateConfig.errorMessage}}</div>
    <div [ngClass]="separateDateConfig.message.class">{{separateDateConfig.message.text}}</div>
  </div>
