<div class="error-box">
  <div class="row">
    <div class="col-1">
      <i style="color:red" class="fas fa-exclamation-circle"></i>
    </div>
    <div class="col-10">
      <div class="error-box-text">
        <span [innerHTML]="text"></span>
      </div>
    </div>
  </div>
</div>