<div class="quantity">
<label class="input-lable" *ngIf="numberInputConfig.label" [innerHTML]="numberInputConfig.label"></label><br>
  <span *ngIf="numberInputConfig.placeholder" class="placeholder">{{numberInputConfig.placeholder}}</span>
  <input type="number" [id]="numberInputConfig.name" [name]="numberInputConfig.name" (keyup)="emit($event)"
  [value]="numberInputConfig.value ? numberInputConfig.value.toString() : ''"/>
  <div class="quantity-nav">
    <button class="quantity-button quantity-up" (click)="increase()">
      <img class="number-img" src="../../../../assets/icons/up-icon.svg">
    </button>
    <button class="quantity-button quantity-down" (click)="decrease()">
      <img class="number-img" src="../../../../assets/icons/down-icon.svg">
    </button>
  </div>
</div>
