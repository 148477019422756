import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringReplace',
})
export class StringReplacePipe implements PipeTransform {
    
    constructor(){}

    transform(value: string, placeholder: string, newValue: string) {
        return value.replace(placeholder,newValue);
    }
}