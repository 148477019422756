import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-quit-modal',
  templateUrl: './quit-modal.component.html',
  styleUrls: ['./quit-modal.component.css']
})
export class QuitModalComponent implements OnInit {

  actionToDo : any;

  constructor( private translate : TranslateService,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService) { }

  ngOnInit(): void {

    this.modalService.getModalData()?.subscribe(
      response => {
        this.actionToDo = response.actionToDo;
      }
    )
  }

  cancelBtnConfig = {
    bclass : 'btn button-to-patient',
    text : this.getTranslation('BTN_CANCEL'),
    action: 'cancel',
    width: '30px',
    height: '30px'
  }

  quitBtnConfig = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('QUIT_FORM_MODAL_BTN'),
    action: 'quit',
    width: '30px',
    height: '30px'
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  
  onClick(){
    this.ngxModal.getModal('quitModal').close();
    this.actionToDo();
  }

  onCancel(){
    this.ngxModal.getModal('quitModal').close();
  }
}
