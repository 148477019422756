<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="customerServiceDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>
        
        <div class="row">
            <div class="col-12 text-right">
                <a (click)="EditCustomerService()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA' | translate }} </u></a>
            </div>
        </div>

        <h2 class="mt-4">{{ customerServiceDetail.name  + " " + customerServiceDetail.surname}}</h2>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.name }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.surname }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.countryIsoCode != null ? ('COUNTRY_' + customerServiceDetail.countryIsoCode | translate) : '-' }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CULTURE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ 'CULTURE_' + customerServiceDetail.cultureIsoCode | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_WORKMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.emailAddress }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_MOBILE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.mobileNumberCountryPrefix != null ? customerServiceDetail.mobileNumberCountryPrefix + " " + customerServiceDetail.mobileNumber : '' }}
            </div>
        </div>

        <hr class="my-4">


        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.dateCreated | DateFormatPipe: true }}
            </div>
        </div>

        <div class="row mt-4" *ngIf='customerServiceDetail.dateDeactivated'>
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DEACTIVATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ customerServiceDetail.dateDeactivated | DateFormatPipe: true }}
            </div>
        </div>

        <div class="row mt-4" *ngIf='customerServiceDetail.dateDeleted'>
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DELETIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{customerServiceDetail.dateDeleted | DateFormatPipe: true}}
            </div>
        </div>

        <br>

        <div class="row">
            <div class="col-12 text-left">
                <a (click)="DeactivateCustomerService()" class="text-right"><i class="fas fa-user-slash"></i> <u class="edit_link">
                    {{ this.customerServiceDetail.dateDeactivated ? ('CUSTOMER_SERVICE_USER_DETAIL_REACTIVATE' | translate) : ('CUSTOMER_SERVICE_USER_DETAIL_DEACTIVATE' | translate) }}</u></a>
            </div>
        </div>

    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>