import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService, EntityRQ } from 'src/app/core';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomPage } from 'src/app/core/models/page.model';
import { Components } from 'src/app/core/models/components.model';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
  id?: string;
  orderWindow: any;
  entityVersions!: any;
  historySyncronizations!: any;
  activeTab = 'ENTITY_VERSIONS';

  publicHolidaysArray: Date[] = new Array<Date>();

  loading: boolean = true;  

  constructor(
    private location: Location,
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
  ) {}


  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getOrderDetail();
    this.getOrderWindow();
  }

  backToList(){   
    this.location.back();
  }

  currentEntityVersionPage: number = 1;
  currentHistorySynchronizationPage: number = 1;

  totalItemsEntityVersion: number = 0;
  totalItemsHistorySynchronization: number = 0;

  tableEntityVersionConfig =  {
    actions: true,
    gridData: this.entityVersions,
    pagination: {
      id: "tableEntityVersion",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsEntityVersion
    },
    sort:{
      field: "versionDate",
      direction: "asc"
    },
    notes: false
  };
  
  tableHistorySynchronizationConfig =  {
    actions: true,
    gridData: this.historySyncronizations,
    pagination: {
      id: "tableHistorySynchronization",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsHistorySynchronization
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };
  
  getOrderDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.orderGetBackOffice(data).subscribe(
      response =>{

        let versionsList: any[] = [];
        for(let entity of response.entityVersions!){
          var jsonObj = JSON.parse(entity.jsonData || "");
          const jsonObj1 = Object.assign({VersionDate: entity.versionDate?.toISOString(), MethodName: entity.methodName}, jsonObj)
          
          versionsList.push(jsonObj1);
        }

        //ENTITY VERSION
        this.entityVersions = versionsList;
        this.tableEntityVersionConfig.gridData = versionsList || [];
        this.tableEntityVersionConfig.pagination.itemsPerPage =  20;
        this.tableEntityVersionConfig.pagination.totalItems = this.entityVersions.lenght || 0;
        this.tableEntityVersionConfig.pagination.currentPage = this.currentEntityVersionPage;
        this.tableEntityVersionConfig.gridData = this.entityVersions;
        
        //CMS SYNCHRONIZATIONS        
        this.historySyncronizations = response.historySynchronizations || [];
        this.tableHistorySynchronizationConfig.pagination.itemsPerPage =  20;
        this.tableHistorySynchronizationConfig.pagination.totalItems = this.historySyncronizations.lenght || 0;
        this.tableHistorySynchronizationConfig.pagination.currentPage = this.currentHistorySynchronizationPage;
        this.tableHistorySynchronizationConfig.gridData = this.historySyncronizations;

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }
  
  datePickerConfig = Components.DatePicker('dateStartTreatmentAtHome', '', {minDate: new Date()});

  getOrderWindow(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.getOrderWindow(data).subscribe(
      response =>{

        this.orderWindow = response;

        const publicHolidaysParam = response.publicHolidays;
        this.publicHolidaysArray = publicHolidaysParam!.split('|').map(x => {
          const dateParts = x.split("/");
          return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
        });

        var patientSelectedAddress = this.orderWindow.patientAddresses.filter((x: { type: string; }) => x.type == "PRIMARY")[0];

        //SET DAY AVAILABLE IN DESIRED DELIVERY DATE
        var daysWithoutOrder: string[] = [];
        daysWithoutOrder = response.daysWithoutOrder!.split('|')
        // Check Custom Patient
        const considerCustomDays = response.considerCustomDays;
        if (patientSelectedAddress!.customDeliveryDays !== null && (considerCustomDays && response.isEmergencyOrder || !response.isEmergencyOrder) ) {
          const custom = patientSelectedAddress!.customDeliveryDays?.split(',');
          var daysWithoutOrder = daysWithoutOrder.filter(value => custom?.includes(value));
        }
        this.datePickerConfig!.datePattern = daysWithoutOrder;

        //SET MIN DELIVERY DATE
        this.datePickerConfig!.minDate = patientSelectedAddress.minDeliveryDate;

        //SET PUBLIC HOLIDAYS
        this.datePickerConfig!.holidays = this.publicHolidaysArray;

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  inputValues(event: any) {
    this.datePickerConfig!.maxDate = event.target.value;
    this.datePickerConfig! = { ...this.datePickerConfig! };
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }
}

