import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiService, ClientService, CoreModule, JwtService, GoogleAnalyticsService } from './core';
import { TableComponent } from './shared/table/table.component';
import { ButtonComponent } from './shared/button/button.component';
import { AlertComponent } from './shared/alert/alert.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SortDirective } from './shared/directives/sort.directive';
import { HasRoleDirective } from './shared/directives/hasrole.directive';
import { CustomFilterPipe } from './shared/pipes/custom-filter.pipe';
import { DateFormatPipe } from './shared/pipes/date-format.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { SearchBarComponent } from './shared/search-bar/search-bar.component';
import { ModalComponent } from './shared/modal/modal.component';
import { InputComponent } from './shared/form/input/input.component';
import { DatePickerComponent } from './shared/form/date-picker/date-picker.component';
import { RadioButtonComponent } from './shared/form/radio-button/radio-button.component';
import { TitleComponent } from './shared/form/title/title.component';
import { NgbAccordionModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteDropdownComponent } from './shared/form/autocomplete-dropdown/autocomplete-dropdown/autocomplete-dropdown.component';
import { PhonePrefixDropdownComponent } from './shared/form/phoneprefix-dropdown/phoneprefix-dropdown/phoneprefix-dropdown.component';
import { NgbDateCustomParserFormatter } from './core/services/date-formatter.service';
import { SeparatedDateComponent } from './shared/form/separated-date/separated-date.component';
import { SpinnerModule } from '@tsmean/spinner';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PhoneNumberComponent } from './shared/form/phone-number/phone-number.component';
import { TabComponent } from './shared/tab/tab.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DurationComponent } from './shared/form/duration/duration.component';
import { NumberInputComponent } from './shared/form/number-input/number-input.component';
import { TableSecondaryComponent } from './shared/table-secondary/table-secondary.component';
import { SolutionsTableComponent } from './shared/solutions-table/solutions-table.component';
import { CustomSelectComponent } from './shared/custom-select/custom-select.component';
import { DismissableAlertComponent } from './shared/dismissable-alert/dismissable-alert.component';
import { TableTodayComponent } from './shared/table-today/table-today.component';
import { InfoBoxComponent } from './shared/info-box/info-box.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NxgModalComponent } from './shared/nxg-modal/nxg-modal.component';
import { CheckboxComponent } from './shared/form/checkbox/checkbox.component';
import { NurseListComponent } from './modules/pages/nurses/nurse-list/nurse-list.component';
import { NephrologistListComponent } from './modules/pages/nephrologists/nephrologist-list/nephrologist-list.component';
import { SpecialistListComponent } from './modules/pages/specialists/specialist-list/specialist-list.component';
import { SupportUserListComponent } from './modules/pages/support_users/support_user-list/support_user-list.component';
import { DemoUserListComponent } from './modules/pages/demo_users/demo_user-list/demo_user-list.component';
import { RequestListComponent } from './modules/pages/requests/request-list/request-list.component';
import { SearchFieldComponent } from './shared/search-field/search-field.component';
import { NurseDetailComponent } from './modules/pages/nurses/nurse-detail/nurse-detail.component';
import { NephrologistDetailComponent } from './modules/pages/nephrologists/nephrologist-detail/nephrologist-detail.component';
import { SpecialistDetailComponent } from './modules/pages/specialists/specialist-detail/specialist-detail.component';
import { SupportUserDetailComponent } from './modules/pages/support_users/support_user-detail/support_user-detail.component';
import { DemoUserDetailComponent } from './modules/pages/demo_users/demo_user-detail/demo_user-detail.component';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { NurseEditComponent } from './modules/pages/nurses/nurse-edit/nurse-edit.component';
import { PatientDetailComponent } from './modules/pages/patients/patient-detail/patient-detail.component';
import { PatientListComponent } from './modules/pages/patients/patient-list/patient-list.component';
import { PatientEditComponent } from './modules/pages/patients/patient-edit/patient-edit.component';
import { ClinicListComponent } from './modules/pages/clinics/clinic-list/clinic-list.component';
import { ClinicDetailComponent } from './modules/pages/clinics/clinic-detail/clinic-detail.component';
import { ClinicEditComponent } from './modules/pages/clinics/clinic-edit/clinic-edit.component';
import { NephrologistEditComponent } from './modules/pages/nephrologists/nephrologist-edit/nephrologist-edit.component';
import { SpecialistEditComponent } from './modules/pages/specialists/specialist-edit/specialist-edit.component';
import { SupportUserEditComponent } from './modules/pages/support_users/support_user-edit/support_user-edit.component';
import { DemoUserEditComponent } from './modules/pages/demo_users/demo_user-edit/demo_user-edit.component';
import { SuccessModalComponent } from './shared/commonModals/success-modal/success-modal.component';
import { InfoTooltipComponent } from './shared/info-tooltip/info-tooltip.component';
import { WarningTooltipComponent } from './shared/warning-tooltip/warning-tooltip.component';
import { RequestModalComponent } from './shared/commonModals/request-modal/request-modal.component';
import { ChangePassCodeComponent } from './modules/pages/change-pass-code/change-pass-code.component';
import { LocaleService } from './core/services/locale.service';
import { CookieService } from 'ngx-cookie-service';
import { PlanRegistrationCodeComponent } from './shared/commonModals/plan-registration-code/plan-registration-code.component';
import { UserProfileComponent } from './login/user-profile/user-profile.component';
import { CheckOTPCodeComponent } from './shared/commonModals/check-otp-code/check-otp-code.component';
import { ErrorBoxComponent } from './shared/error-box/error-box.component';
import { ForgetPasswordModalComponent } from './login/forget-password-modal/forget-password-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NotificationListComponent } from './modules/pages/notifications/notification-list/notification-list.component';
import { NotificationDetailComponent } from './modules/pages/notifications/notification-detail/notification-detail.component';
import { ReportListComponent } from './modules/pages/reports/report-list/report-list.component';
import { ClinicSecurityComponent } from './modules/pages/clinics/clinic-security/clinic-security.component';
import { ToggleComponent } from './shared/form/toggle/toggle.component';
import { TimelineComponent } from './modules/pages/timeline/timeline.component';
import { PasswordInputComponent } from './shared/form/password-input/password-input.component';
import { FaqModalComponent } from './shared/commonModals/faq-modal/faq-modal.component';
import { ReleaseNotesModalComponent } from './login/release-notes-modal/release-notes-modal.component';
import { AccountDeactivatedModalComponent } from './login/account-deactivated-modal/account-deactivated-modal.component';
import { RoundButtonComponent } from './shared/buttons/round-button/round-button.component';
import { AccountLockedModalComponent } from './login/account-locked-modal/account-locked-modal.component';
import { ChipsComponent } from './shared/form/radios/chips/chips.component';
import { SimpleRadiobuttonsComponent } from './shared/form/radios/simple-radiobuttons/simple-radiobuttons.component';
import { RowComponent } from './shared/row/row.component';
import { TextComponent } from './shared/text/text.component';
import { MainButtonComponent } from './shared/buttons/main-button/main-button.component';
import { CountryListComponent } from './modules/pages/country/country-list/country-list.component';
import { CountryEditComponent } from './modules/pages/country/country-edit/country-edit.component';
import { CountryDetailComponent } from './modules/pages/country/country-detail/country-detail.component';
import { PrescriptionListComponent } from './modules/pages/prescriptions/prescription-list/prescription-list.component';
import { PrescriptionDetailComponent } from './modules/pages/prescriptions/prescription-detail/prescription-detail.component';
import { OrderListComponent } from './modules/pages/orders/order-list/order-list.component';
import { OrderDetailComponent } from './modules/pages/orders/order-detail/order-detail.component';
import { TableHistoryComponent } from './shared/table-history/table-history.component';
import { CompareEntitiesModalComponent } from './login/compare-entities-modal/compare-entities-modal.component';
import { PlanNextOrderComponent } from './shared/commonModals/plan-next-order/plan-next-order.component';
import { InvitationBoxComponent } from './shared/invitation-box/invitation-box.component';
import { QuitModalComponent } from './shared/commonModals/quit-modal/quit-modal.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AdminListComponent } from './modules/pages/admins/admin-list/admin-list.component';
import { AdminDetailComponent } from './modules/pages/admins/admin-detail/admin-detail.component';
import { AdminEditComponent } from './modules/pages/admins/admin-edit/admin-edit.component';
import { OrderCatalogModalComponent } from './login/order-catalog-modal/order-catalog-modal.component';
import { IsGlobalUserDirective } from './shared/directives/isGlobalUser.directive';
import { ClinicNameModalComponent } from './login/clinic-name-modal/clinic-name-modal.component';
import { CountryResponsibleDetailComponent } from './modules/pages/country_responsibles/country_responsible-detail/country_responsible-detail.component';
import { CountryResponsibleEditComponent } from './modules/pages/country_responsibles/country_responsible-edit/country_responsible-edit.component';
import { IPDOrderListComponent } from './modules/pages/orders/ipd-order-list/ipd-order-list.component';
import { IPDOrderDetailComponent } from './modules/pages/orders/ipd-order-detail/ipd-order-detail.component';
import { ClinicListingModalComponent } from './login/clinic-listing-modal/clinic-listing-modal.component';
import { PatientListingModalComponent } from './login/patient-listing-modal/patient-listing-modal.component';
import { TreeNodeComponent } from './shared/tree-node/tree-node.component';
import { CustomerServiceListComponent } from './modules/pages/customer_service_users/customer_service_user-list/customer_service_user-list.component';
import { CustomerServiceEditComponent } from './modules/pages/customer_service_users/customer_service_user-edit/customer_service_user-edit.component';
import { CustomerServiceDetailComponent } from './modules/pages/customer_service_users/customer_service_user-detail/customer_service_user-detail.component';
import { CalendarListComponent } from './modules/pages/calendars/calendar-list/calendar-list.component';
import { CalendarEditComponent } from './modules/pages/calendars/calendar-edit/calendar-edit.component';
import { CalendarDetailComponent } from './modules/pages/calendars/calendar-detail/calendar-detail.component';
import { CalendarDateListComponent } from './modules/pages/calendar_dates/calendar_date-list/calendar_date-list.component';
import { CalendarDateEditComponent } from './modules/pages/calendar_dates/calendar_date-edit/calendar_date-edit.component';
import { CalendarDateDetailComponent } from './modules/pages/calendar_dates/calendar_date-detail/calendar_date-detail.component';
import { CalendarHistoryComponent } from './modules/pages/calendars/calendar-history/calendar-history.component';
import { CalendarDatePatientListComponent } from './modules/pages/calendar_dates_patient/calendar_date_patient-list/calendar_date_patient-list.component';
import { CalendarDatePatientEditComponent } from './modules/pages/calendar_dates_patient/calendar_date_patient-edit/calendar_date_patient-edit.component';
import { CalendarDatePatientDetailComponent } from './modules/pages/calendar_dates_patient/calendar_date_patient-detail/calendar_date_patient-detail.component';
import { PatientAssignCalendarComponent } from './modules/pages/patients/patient-assign-calendar/patient-assign-calendar.component';
import { AssingCalendarListComponent } from './shared/assing-calendar-list/assing-calendar-list.component';
import { FiltersComponent } from './shared/filters/filters.component';
import { PatientDeliveryFeeComponent } from './shared/commonModals/patient-delivery-fee/patient-delivery-fee.component';
import { PatientOrderCalendarModalComponent } from './shared/patient-order-calendar-modal/patient-order-calendar-modal.component';
import { OrdersCalendarComponent } from './shared/patient-order-calendar-modal/orders-calendar/orders-calendar.component';
import { StringReplacePipe } from './shared/pipes/string-replace.pipe';
import { ReleaseNoteListComponent } from './modules/pages/release_notes/release_note-list/release_note-list.component';
import { ReleaseNoteDetailComponent } from './modules/pages/release_notes/release_note-detail/release_note-detail.component';
import { ReleaseNoteEditComponent } from './modules/pages/release_notes/release_note-edit/release_note-edit.component';
import { NoSpecialCharsDirective } from './shared/pipes/nospecialchar.directive';

@NgModule({
  declarations: [
    AppComponent,
    TableComponent,
    ButtonComponent,
    AlertComponent,
    SortDirective,
    IsGlobalUserDirective,
    HasRoleDirective,
    CustomFilterPipe,
    DateFormatPipe,
    NavbarComponent,
    SidebarComponent,
    LoginComponent,
    SearchBarComponent,
    ModalComponent,
    InputComponent,
    DatePickerComponent,
    RadioButtonComponent,
    TitleComponent,
    AutocompleteDropdownComponent,
    PhonePrefixDropdownComponent,
    SeparatedDateComponent,
    PhoneNumberComponent,
    TabComponent,
    DurationComponent,
    NumberInputComponent,
    TableSecondaryComponent,
    SolutionsTableComponent,
    CustomSelectComponent,
    DismissableAlertComponent,
    TableTodayComponent,
    InfoBoxComponent,
    ErrorBoxComponent,
    NxgModalComponent,
    CheckboxComponent,
    ClinicListComponent,
    NurseListComponent,
    NephrologistListComponent,
    PatientListComponent,
    SpecialistListComponent,
    SupportUserListComponent,
    DemoUserListComponent,
    RequestListComponent,
    CountryListComponent,
    PrescriptionListComponent,
    OrderListComponent,
    SearchFieldComponent,
    ClinicDetailComponent,
    NurseDetailComponent,
    NephrologistDetailComponent,
    PatientDetailComponent,
    SpecialistDetailComponent,
    SupportUserDetailComponent,
    DemoUserDetailComponent,
    CountryDetailComponent,
    PrescriptionDetailComponent,
    OrderDetailComponent,
    SnackbarComponent,
    ClinicEditComponent,
    NurseEditComponent,
    NephrologistEditComponent,
    PatientEditComponent,
    SpecialistEditComponent,
    SupportUserEditComponent,
    DemoUserEditComponent,
    CountryEditComponent,
    SuccessModalComponent,
    InfoTooltipComponent,
    WarningTooltipComponent,
    RequestModalComponent,
    ChangePassCodeComponent,
    PlanRegistrationCodeComponent,
    UserProfileComponent,
    CheckOTPCodeComponent,
    ForgetPasswordModalComponent,
    NotificationListComponent,
    NotificationDetailComponent,
    ReportListComponent,
    ClinicSecurityComponent,
    ToggleComponent,
    TimelineComponent,
    PasswordInputComponent,
    FaqModalComponent,
    ReleaseNotesModalComponent,
    AccountDeactivatedModalComponent,
    AccountLockedModalComponent,
    RoundButtonComponent,
    ChipsComponent,
    SimpleRadiobuttonsComponent,
    RowComponent,
    TextComponent,
    MainButtonComponent,
    TableHistoryComponent,
    CompareEntitiesModalComponent,
    PlanNextOrderComponent,
    InvitationBoxComponent,
    QuitModalComponent,
    AdminListComponent,
    AdminDetailComponent,
    AdminEditComponent,
    OrderCatalogModalComponent,
    ClinicNameModalComponent,
    CountryResponsibleDetailComponent,
    CountryResponsibleEditComponent,
    IPDOrderListComponent,
    IPDOrderDetailComponent,
    ClinicListingModalComponent,
    PatientListingModalComponent,
    TreeNodeComponent,
    CustomerServiceListComponent,
    CustomerServiceEditComponent,
    CustomerServiceDetailComponent,
    CalendarListComponent,
    CalendarEditComponent,
    CalendarDetailComponent,
    CalendarDateListComponent,
    CalendarDateEditComponent,
    CalendarDateDetailComponent,
    CalendarHistoryComponent,
    CalendarDatePatientListComponent,
    CalendarDatePatientEditComponent,
    CalendarDatePatientDetailComponent,
    PatientAssignCalendarComponent,
    AssingCalendarListComponent,
    FiltersComponent,
    PatientDeliveryFeeComponent,
    PatientOrderCalendarModalComponent,
    OrdersCalendarComponent,
    StringReplacePipe,
    ReleaseNoteListComponent,
    ReleaseNoteDetailComponent,
    ReleaseNoteEditComponent,
    NoSpecialCharsDirective
  ],
  imports: [
    NgxSmartModalModule.forRoot(),
    BrowserModule,
    InfiniteScrollModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    NgxPaginationModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    NgbModule,
    SpinnerModule.forRoot({}),
    TranslateModule.forRoot({}),
    NgxUsefulSwiperModule,
    NgOtpInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbAccordionModule
  ],
  providers: [
    ApiService,
    JwtService,
    ClientService,
    DatePipe,
    GoogleAnalyticsService,
    NavbarComponent,
    NgxSmartModalModule,
    LocaleService,
    {
      provide: LOCALE_ID, deps: [LocaleService], useFactory: (localeService: LocaleService) => localeService.locale
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

