import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CalendarDatePatientGetAllBackOfficeRQ, ClientService, ICalendarDatePatientGetAllBackOfficeRQ } from 'src/app/core';
import { BackOfficeCalendarDatePatientViewCustom } from 'src/app/core/models/BackOfficeCalendarDatePatientView-custom.model';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-calendar-date-patient-list',
  templateUrl: './calendar_date_patient-list.component.html',
  styleUrls: ['./calendar_date_patient-list.component.css']
})
export class CalendarDatePatientListComponent implements OnInit {

  idPatient: any | undefined;
  calendarDatePatientList! : BackOfficeCalendarDatePatientViewCustom[];
  isAddOrderDateVisible: boolean = false;

  loading: boolean = true;
  record?: any;
  totalItems: number = 0;
  anyResult: boolean = false;

  tableConfig =  {
    actions: true,
    gridData: this.calendarDatePatientList,
    headerList: [
      {name: this.getTranslation('CALENDAR_NAME'), field: 'fullCalendarName', sortable: false},
      {name: this.getTranslation('CALENDAR_DELIVERY_DATE'), field: 'deliveryDate', sortable: true, isDate: true},
      {name: this.getTranslation('CALENDAR_PICKING'), field: 'pickingDate', sortable: true, isDate: true},
      {name: this.getTranslation('CALENDAR_DUE_DATE'), field: 'dueDate', sortable: true, isDate: true},
      {name: '', field: 'reviewNeeded', sortable: false},
      {name: this.getTranslation('LABEL_NOTES'), field: 'notes', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItems
    },
    sort:{
      field: "baseWeek",
      direction: "asc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private editService: EditServiceService,
  ) { }


  ngOnInit(): void {
    this.idPatient = this.route.snapshot.paramMap.get('id')?.toString();

    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getCalendarDatePatientList();
  }

  orderDirection?: string = "deliveryDate_asc";
  currentPage: number = 1;
 
  getCalendarDatePatientList() {
    let input: ICalendarDatePatientGetAllBackOfficeRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      idPatient: this.idPatient,
    }
    this.clientService.calendarDatePatientGetAllBackOffice(new CalendarDatePatientGetAllBackOfficeRQ(input)).subscribe(
      response =>{
        this.calendarDatePatientList = (response.list || []).map(row => BackOfficeCalendarDatePatientViewCustom.fromJS(row, (key)=> this.getTranslation(key)));
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.calendarDatePatientList;
        this.bindButtons(this.tableConfig.gridData);
        this.isAddOrderDateVisible = this.calendarDatePatientList.length > 0;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getCalendarDatePatientList();
  }

  pageChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getCalendarDatePatientList();
  }

  getUpdatedList(): any{
    return this.calendarDatePatientList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToCalendarDatePatientDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/calendar-date-patient/detail', event.record.idCalendarDatePatient ?? event.record.idCalendarDate]);
    }
  }

  calendarDatePatientAdd() {
    this.router.navigate(['/calendar-date-patient/add/', this.idPatient]);
  }

  bindButtons(calendarDatePatientList: any[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let calendarDatePatient of calendarDatePatientList){
      this.tableConfig.recordButtonConfigs.set(calendarDatePatient,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}