import { IBackOfficeCalendarDateView, } from "../services";
import { getDayNameFromDayNumber, getDaysBeforeDeliveryLabel } from "../utils/calendar-utils";

export class BackOfficeCalendarDateViewCustom implements IBackOfficeCalendarDateView {
    idCalendarDate?: string;
    idCalendar?: string;
    baseYear?: number;
    baseWeek?: number;
    deliveryDateWeekDay?: number;
    deliveryDateFrequency?: number;
    calendarDescription?: string | undefined;
    deliveryDate?: Date | undefined;
    pickingDate?: Date | undefined;
    dueDate?: Date | undefined;
    openingWindowDate?: Date | undefined;
    closingWindowDate?: Date | undefined;
    dateDeleted?: Date | undefined;
    isDeliveryDateReviewNeeded?: boolean | undefined;
    isPickingDateReviewNeeded?: boolean | undefined;
    isDueDateReviewNeeded?: boolean | undefined;
    countryIsoCode?: string | undefined;
    
    frequencyLabel?: string

    dueDateLabel?: string //LIST
    dueDateWeekDayLabel?: string
    dueDateWeekLabel?: string

    pickingLabel?: string  //LIST
    pickingWeekDayLabel?: string      
    pickingWeekLabel?: string
    
    deliveryDateLabel?: string
    isDeleted?: boolean;
    calendarName?: string | undefined;
    fullCalendarName?: string | undefined;
    notes = '';
    reviewNeeded: boolean = true;

    constructor(data?: IBackOfficeCalendarDateView) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.idCalendarDate = _data["idCalendarDate"];
            this.idCalendar = _data["idCalendar"];
            this.baseYear = _data["baseYear"];
            this.baseWeek = _data["baseWeek"];
            this.deliveryDateWeekDay = _data["deliveryDateWeekDay"];
            this.deliveryDateFrequency = _data["deliveryDateFrequency"];
            this.calendarDescription = _data["calendarDescription"];
            this.deliveryDate = _data["deliveryDate"] ? new Date(_data["deliveryDate"].toString()) : <any>undefined;
            this.pickingDate = _data["pickingDate"] ? new Date(_data["pickingDate"].toString()) : <any>undefined;
            this.dueDate = _data["dueDate"] ? new Date(_data["dueDate"].toString()) : <any>undefined;
            this.openingWindowDate = _data["openingWindowDate"] ? new Date(_data["openingWindowDate"].toString()) : <any>undefined;
            this.closingWindowDate = _data["closingWindowDate"] ? new Date(_data["closingWindowDate"].toString()) : <any>undefined;
            this.dateDeleted = _data["dateDeleted"] ? new Date(_data["dateDeleted"].toString()) : <any>undefined;
            this.isDeliveryDateReviewNeeded = _data["isDeliveryDateReviewNeeded"];
            this.isPickingDateReviewNeeded = _data["isPickingDateReviewNeeded"];
            this.isDueDateReviewNeeded = _data["isDueDateReviewNeeded"];
        }
    }

    static fromJS(data: IBackOfficeCalendarDateView, translate: (key: string) => string){
        const custom = new BackOfficeCalendarDateViewCustom();

        // Assign base values
        for (var property in data) {
            if (data.hasOwnProperty(property))
                (<any>custom)[property] = (<any>data)[property];
        }
        custom.frequencyLabel = `${data.deliveryDateFrequency?.toString()} ${data.deliveryDateFrequency === 1  ? translate('LABEL_WEEK') : translate('LABEL_WEEKS')}`
        
        custom.deliveryDateLabel = `${translate(getDayNameFromDayNumber(data?.deliveryDateWeekDay ?? 100))}`
        custom.isDeleted = data.dateDeleted !== null && data.dateDeleted !== undefined
 
        custom.fullCalendarName = custom.calendarName + " | " + data?.rowNumber!.toString();

        custom.reviewNeeded = (data.isDeliveryDateReviewNeeded ?? false) || (data.isPickingDateReviewNeeded ?? false) || (data.isDueDateReviewNeeded ?? false);
        
        custom.notes = data.dateDeleted ? translate('CALENDAR_ORDER_DELETED') 
            : (custom.reviewNeeded ? translate('CALENDAR_REVIEW_NEEDED') : '');

        return custom;       
    }

    static StringFormat = (str: string, ...args: string[]) =>
        str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["idCalendarDate"] = this.idCalendarDate;
        data["idCalendar"] = this.idCalendar;
        data["baseYear"] = this.baseYear;
        data["baseWeek"] = this.baseWeek;
        data["deliveryDateWeekDay"] = this.deliveryDateWeekDay;
        data["deliveryDateFrequency"] = this.deliveryDateFrequency;
        data["calendarDescription"] = this.calendarDescription;
        data["deliveryDate"] = this.deliveryDate ? this.deliveryDate.toISOString() : <any>undefined;
        data["pickingDate"] = this.pickingDate ? this.pickingDate.toISOString() : <any>undefined;
        data["dueDate"] = this.dueDate ? this.dueDate.toISOString() : <any>undefined;
        data["openingWindowDate"] = this.openingWindowDate ? this.openingWindowDate.toISOString() : <any>undefined;
        data["closingWindowDate"] = this.closingWindowDate ? this.closingWindowDate.toISOString() : <any>undefined;
        data["dateDeleted"] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
        data["isDeliveryDateReviewNeeded"] = this.isDeliveryDateReviewNeeded;
        data["isPickingDateReviewNeeded"] = this.isPickingDateReviewNeeded;
        data["isDueDateReviewNeeded"] = this.isDueDateReviewNeeded;
        return data;
    }
}
