<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li *ngFor="let tab of tabs;let j = index" [ngbNavItem]="j+1" (click)="goToTab(tab)" data-toggle="tooltip" data-placement="bottom" [title]="tab.patientFullName">  
      <a ngbNavLink >
        <div *ngIf="trunc === true; else fullName">
          {{truncate(tab.patientFullName)}}
        </div>
        <ng-template #fullName>
          {{startingTrunc(tab.patientFullName)}}
        </ng-template>
        <span class="close position-relative pl-2 font-weight-light" (click)="closeTab(j, $event)"><svg-icon [src]="'../../../assets/icons/close.svg'"></svg-icon></span>
      </a>
      <ng-template ngbNavContent>
          <div class="blank-content" [style.width.px]="mainContentWidth" [style.display]="tabDisplay">
          </div>
      </ng-template>
    </li>
  </ul>
  
  <div [ngbNavOutlet]="nav" class="mt-2"></div>