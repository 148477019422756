<ngx-smart-modal #planNextOrderModal identifier="planNextOrderModal" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-header header-edit">
    <app-round-button [type]="'CLOSE'" (onClick)="planNextOrderModal.close()"></app-round-button>
</div>
<div class="modal-body mt-2 text-center">
    <app-date-picker [datePickerConfig]="dateDeliveryDesiredConfig" (resultDate)="inputValues($event)"></app-date-picker>
    <app-date-picker [datePickerConfig]="dateDueConfig"></app-date-picker>
    <br>
    <app-button [buttonConfig]="btnConfigConfirm" (click)="planNextOrder()"></app-button>
</div>
</ngx-smart-modal>