import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class RequestModalComponent implements OnInit, OnChanges {
  title: any;
  description: any;
  commentVisible: boolean = false;
  actionBtnConfig: any;
  actionToDo : any;

  comment: string = "";
  @Output() commentChange = new EventEmitter<string>();

  formAdd: FormGroup = this.fb.group({
    comments: [null],
  });

  formInfo = {
    key: 'formInfo',
    input:
    [
      { type: 'textarea', optional: true, placeholder: this.getTranslation('LABEL_ADDCOMMENT'), class: 'input-form', inputName: 'comments', value: this.formAdd.get("comments")?.value },
    ]
  }

  cancelBtnConfig = {
    type: 'button',
    bclass : 'btn button-to-patient',
    text : this.getTranslation('BTN_CANCEL'),
    action: 'cancel',
    width: '30px',
    height: '30px'
  }
  
  constructor( private translate : TranslateService,
    private fb: FormBuilder,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService) { }

  ngOnInit(): void {
    this.cleanComments();

    this.modalService.getModalData()?.subscribe(
      response => {
        this.title = this.getTranslation(response.title);
        this.description = this.getTranslation(response.description);
        this.commentVisible = response.commentVisible;
        this.actionBtnConfig = response.actionBtnConfig;
        this.actionToDo = response.actionToDo;
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    this.comment = "";
    if(changes.comment){
      this.formAdd.get('comments')?.setValue(changes.comment.currentValue);
      this.formInfo!.input[0].value = this.formAdd.get('comments')?.value;
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    if(wordYouNeedToTranslate == undefined || wordYouNeedToTranslate == "")
      return "";
    return this.translate.instant(wordYouNeedToTranslate);
  }

  onClick(){
    this.ngxModal.getModal('requestModal').close();
    this.actionToDo(this.comment);
    this.cleanComments();
  }

  onCancel(){
    this.ngxModal.getModal('requestModal').close();
    this.cleanComments();
  }

  inputValues(event: any) {
    this.comment = event.target.value;
    this.commentChange.emit(this.comment);
    this.formAdd.controls[event.target.name].setValue(event.target.value);
  }

  cleanComments(){
    this.comment = '';
    this.formAdd.get('comments')?.setValue("");
    this.formInfo!.input[0].value = this.formAdd.get('comments')?.value;
  }
}