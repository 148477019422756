<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="notificationDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_ID' | translate }}</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.idNotification}}
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_CLINIC' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.clinicFrom}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3 col-lg-3">
                <b>{{ 'LABEL_FROM' | translate }}:</b>
            </div>
            <div class="col-3 col-lg-3">
                {{notificationDetail.entityFrom}}
            </div>
            <div class="col-3 col-lg-3">
                <b>{{ 'LABEL_TO' | translate }}:</b>
            </div>
            <div class="col-3 col-lg-3">
                {{notificationDetail.entityTo}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-3 col-lg-3">
                <b>{{ 'LABEL_ROLE_FROM' | translate }}:</b>
            </div>
            <div class="col-3 col-lg-3">
                {{notificationDetail.roleFrom}}
            </div>
            <div class="col-3 col-lg-3">
                <b>{{ 'LABEL_ROLE_TO' | translate }}:</b>
            </div>
            <div class="col-3 col-lg-3">
                {{notificationDetail.roleTo}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_CHANNEL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.channel}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_SUBJECT' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.subject}}
            </div>
        </div>

        <hr class="my-4">

        <b>{{ 'NOTIFICATION_DETAIL_OBJECT' | translate }}:</b>

        <br/><br/><br/>
        
        <span [innerHTML]="notificationDetail.object"></span>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_TRIALS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.sendingTrials}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_ERROR' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.errorLogging}}
            </div>
        </div>

        <hr *ngIf="notificationDetail.isDownloadable" class="my-4">

        <div *ngIf="notificationDetail.isDownloadable" class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NOTIFICATION_DETAIL_FILENAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                <a (click)="getAttachment()" class="text-right"><u class="edit_link">{{notificationDetail.fileName}}</u></a>
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.dateCreated | DateFormatPipe: true}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SENTDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{notificationDetail.dateSent | DateFormatPipe: true}}
            </div>
        </div>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>
