import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../core/services/authentication.service';
import { ClientService, GoogleAnalyticsService, JwtService } from '../core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UtilsService } from '../core/services/utils.service';
import { Role } from '../core/enum/roles';
import { TimeoutService } from '../core/services/timeout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [FormBuilder]
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl?: string;
  logoFresenius = '../../assets/images/logo_fresenius_big.png'
  loginBtnConfig?: any;
  loginFormInput?: any;
  errorMessage?: any = '';
  loginBtnDefault?: any;

  idUserIdentity: any;

  selectedLanguage: any = { key: 'English', value: 'en-GB' };
  languagesAvailale : any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private clientService: ClientService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private ngxModal: NgxSmartModalService,
    private utilsService: UtilsService,
    private jwt: JwtService,
    private timeout: TimeoutService,
  ) {
  }

  setLanguageForDropdown(){
    let langCode = this.utilsService.getDefaultLanguage() || 'en-GB'

    if(this.languagesAvailale.length > 0){
      this.selectedLanguage = this.languagesAvailale.find(x => x.value === langCode);
      this.languagesAvailale = this.languagesAvailale.filter(x => x.key != this.selectedLanguage.key)
    }

  }

  ngOnInit(): void {

    if(this.jwt.userExist()){
      if (JSON.stringify(this.authenticationService.currentUserValue) !== '{}') {
        if(this.authenticationService.currentUserValue.role == Role.AppSupport)
          this.router.navigate(['/requests']);
        else
          this.router.navigate(['/clinics']);
      }
    }
    else{   
      this.utilsService.allLanguages.forEach(x => {
        this.languagesAvailale.push({key: x.languageName, value: x.isoCode});
      });
    }

    this.setLanguageForDropdown();

    this.loginBtnConfig = {
      bclass: 'btn button-login mb-4',
      type: 'submit',
      text: this.getTranslation('BTN_LOGIN'),
      disabled: true
    }

    this.loginBtnDefault = {
      bclass: 'btn button-login mb-4',
      type: 'button',
      text: this.getTranslation('BTN_LOGIN'),
      disabled: false
    }

    this.loginFormInput = {
      username: {
        name: this.getTranslation('LABEL_EMAIL'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'username'
      },
      password: {
        name: this.getTranslation('LABEL_PASSCODE'), optional: false, type: 'password', placeholder: '', class: 'input-form', inputName: 'password',  passwordIcon: true
      }
    };

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.getForBackOfficeBeforeAuth();

    this.route.params.subscribe(
      res => {
        if (JSON.stringify(res) !== '{}') {

          this.loginForm.patchValue({
            username: res['email']
          });
  
          this.loginFormInput!.username.value = res['email'];
        }
      }
    )

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  inputValues(event: any) {
    this.errorMessage = '';
    /*
    if(event.target.value === 'username'){
      this.googleAnalyticsService.eventEmitter("OMG_Nurse_LOG_1_LoginEmail", {});
    }else{
      this.googleAnalyticsService.eventEmitter("OMG_Nurse_LOG_2_PassCode", {});
    }
    */
    this.loginForm.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.loginForm)
  }

  formValidation(form: any) {
    form.valueChanges
      .subscribe((changedObj: any) => {
      });
    this.loginBtnConfig.disabled = !form.valid;
  }

  get f() { return this.loginForm?.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.loginForm?.invalid) {
      return;
    }
    this.loading = true;
    var skipOtp = !(this.utilsService.getBeforeParametersByKey("flow_trigger__is_otp_enabled").parameterValue === "True");

    this.authenticationService.login(this.f?.username.value, this.f?.password.value, skipOtp)
      .pipe(first())
      .subscribe(
        data => {
          if(skipOtp)
          {
            this.timeout.startTimer();
            this.router.navigate(['/']);
            let lastLogin = data?.userAuthEntity?.dateTimeLastLogin as Date;
            window.sessionStorage.setItem('releaseNotesDate', lastLogin.toUTCString())           
          }
          else
          { 
            this.ngxModal.getModal('checkOTPCode').open();
            this.idUserIdentity = data?.idUserIdentity;
          }          
        },
        error => {
          let err = JSON.parse(error.response);
          if (err.errorType === 'ERR_USERLOCKED') {
            this.ngxModal.getModal('accountLockedModal').open();
          }
          else if(err.errorType === 'ERR_USERDEACTIVATED') {
            this.ngxModal.getModal('accountDeactivatedModal').open();
          } else {
            this.errorMessage = err.errorType;
          }

          this.loginFormInput.username.class = 'input-form error';
          this.loginFormInput.password.class = 'input-form error';

          this.loading = false;
        });
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
  
  openForgetPasswordModal() {
    this.ngxModal.getModal('forgetPasswordModal').open();
  }

  getForBackOfficeBeforeAuth() {
    this.clientService.getForBackOfficeBeforeAuth().subscribe(
      data => {
        sessionStorage.setItem('parametersBeforeAuth', JSON.stringify(data));
      },
      err => console.log(err)
    );
  }

  isClosed: any = undefined;
  languageDropdown() {
    if (this.isClosed === undefined) {
      this.isClosed = false
    } else {
      this.isClosed = !this.isClosed
    }
  }

  changeLanguage(langItem: any) {
    this.utilsService.setDefaultLanguage(langItem.value);
    this.translate.currentLang = langItem.value;
    this.translate.use(langItem.value);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

}
