import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class SearchFieldComponent {
  @Input() searchConfig: any = {} as any || [];
  @Output() searchEvent = new EventEmitter<any>();

  constructor() {}

  keyUp(event: any){
    this.searchEvent.emit(this.searchConfig.value);
  }
}


