import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-release-notes-modal',
  templateUrl: './release-notes-modal.component.html',
  styleUrls: ['./release-notes-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private smartModal: NgxSmartModalService
  ) { }

  titles: any;
  subscriptions: Subscription[] = []
  releaseNotesContent = ''

  ngOnInit(): void {
    this.titles =[
      { width: 100, text: this.getTranslation('RELEASE_NOTES_MODAL_TITLE'), size: 1.5, class: 'edit-title my-4 p-0' },
      { width: 100, text: this.getTranslation('RELEASE_NOTES_MODAL_SUBTITLE') + ' ', size: 1.125, class: 'edit-title mt-1 p-0' },
    ]
    this.subscriptions = []
  }

  ngAfterViewInit(): void {
    let modalDataSubscription = this.smartModal.getModal('releaseNotesModal').onOpen.subscribe(()=>{
      this.releaseNotesContent = this.smartModal.getModal('releaseNotesModal').getData();
    })
    this.subscriptions.push(modalDataSubscription);
  }

  okButtonConfig =  { bclass: 'btn button-next', type: 'button', text: this.getTranslation('RELEASE_NOTES_MODAL_BTN'), disabled: false }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

}
