<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="countryDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true">
                <svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>

        <div *appHasRole="['SUPER_ADMIN']" class="row">
            <div class="col-12 text-right">
                <a (click)="EditCountry()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link">{{ 'COMMON_EDIT_DATA' |
                    translate }}</u></a>
            </div>
        </div>

        <h2 class="mt-4">{{ countryDetail.countryName }}</h2>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryDetail.countryIsoCode }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_TIMEZONE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryDetail.timeZoneName }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CULTURE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryDetail.cultureName + " (" + countryDetail.cultureCode + ")" }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_COUNTRYPREFIX' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryDetail.phonePrefix?.phonePrefix }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ISLEADINGZERO' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ countryDetail.phonePrefix?.isLeadingZeroToBeRemoved }}
            </div>
        </div>

        <br/><br/>

        <!-- PARAMETERS -->
        <div *ngIf="tableParameterConfig.pagination.totalItems != 0 else noParameter">
            <app-table [tableConfig]="tableParameterConfig" (orderChanged)="orderParameterChanged($event)"
                (pageChanged)="pageParameterChanged($event)"></app-table>
        </div>
        <ng-template #noParameter>
            <div class="text-center noResult">
                <i class="fas fa-cog"></i>
                <p class="alert">{{ 'PARAMETER_NORESULT' | translate }}</p>
            </div>
        </ng-template>

        <br/><br/>
        
        <!-- COUNTRY REGIONS -->
        <div *ngIf="tableRegionConfig.pagination.totalItems != 0 else noRegion">
            <app-table [tableConfig]="tableRegionConfig" (orderChanged)="orderRegionChanged($event)"
                (pageChanged)="pageRegionChanged($event)"></app-table>
        </div>
        <ng-template #noRegion>
            <div class="text-center noResult">
                <i class="fas fa-map"></i>
                <p class="alert">{{ 'REGION_NORESULT' | translate }}</p>
            </div>
        </ng-template>

        <br/><br/>

        <!-- COUNTRY RESPONSIBLE -->
        <app-button (click)="countryResponsibleAdd()" [buttonConfig]="btnConfig" ></app-button>
        
        <br/><br/><br/>

        <div *ngIf="tableCountryResponsibleConfig.pagination.totalItems != 0 else noCountryResponsible">
            <app-table [tableConfig]="tableCountryResponsibleConfig" (orderChanged)="orderCountryResponsibleChanged($event)"
                (onClick)="GoToCountryResponsibleDetail($event)" (pageChanged)="pageCountryResponsibleChanged($event)"></app-table>
        </div>
        <ng-template #noCountryResponsible>
            <div class="text-center noResult">
                <i class="fas fa-map"></i>
                <p class="alert">{{ 'COUNTRY_RESPONSIBLE_NORESULT' | translate }}</p>
            </div>
        </ng-template>

        <br/><br/>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>