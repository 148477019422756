import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.css']
})
export class PasswordInputComponent implements OnInit {

  constructor() { }

  @Input() passwordInputConfig: any
  @Output() pwdEmit : EventEmitter<any> = new EventEmitter<any>();
  regexExpressions = new Map<string,RegExp>();
  lengPattern = "[\\w\\[\\]`!@#$%\\^&*()={}:;<>+'-]{num,}"


  ngOnInit(): void {
    if(this.passwordInputConfig.passwordIcon !== undefined){
      this.passwordIcon = this.passwordInputConfig.passwordIcon ? '../../../../assets/icons/hide-password-icon.svg' : '../../../../assets/icons/show-password-icon.svg'
    }
    this.isPasswordInput = this.passwordInputConfig.passwordIcon !== undefined

    let str1 = "num"
    var re = new RegExp(str1, "g");
    let lengRegex = this.lengPattern.replace(re, this.passwordInputConfig.lenght);
    this.regexExpressions.set("isMandatoryLowerCase",/[a-z]/)
    this.regexExpressions.set("isMandatoryUpperCase",/[A-Z]/)
    this.regexExpressions.set('isMandatorySpecialCharacters',/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)
    this.regexExpressions.set("isMandatoryDigit", /.*[0-9].*/)
    this.regexExpressions.set("isMandatoryAlphabetical", /.*[A-Za-z].*/)
    this.regexExpressions.set("lenght", new RegExp(lengRegex))


  }

  passwordIcon = '';
  isPasswordInput = false;


  passwordIconChange(){
    if(this.passwordInputConfig.value){
      if(this.passwordInputConfig.input.type === 'password'){
        this.passwordInputConfig.input.type = 'text'
        this.passwordInputConfig.input.class = 'input-form'
      }else{
        this.passwordInputConfig.input.type = 'password'
        this.passwordInputConfig.input.class = 'input-form'
      }
    }
  }

  getInputValue(e:any){
    let isValid = true;
    if(this.passwordInputConfig.restrictions){
      for(let rest of this.passwordInputConfig.restrictions){
        if(!this.regexExpressions.get(rest.type)?.test(e.target.value)){
          isValid = false;
          rest.class = 'invalid'
        }else{
          rest.class = 'valid';
        }
      }
    }
    this.pwdEmit.emit({target:{name: this.passwordInputConfig.input.inputName ,value:e.target.value}, valid: isValid})
  }
}
