import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  constructor(
    private editService: EditServiceService,
    private clientService : ClientService,
    private translate : TranslateService,
    private datePipe : DatePipe
  ) { }

  modalConf : any;

  // DEACTIVATION Declarations
  deactivationStep?: string;
  deactivationConfig?: any;
  deactivationAlert: any;
  contentDisabled?: boolean;
  loading?: boolean;
  msg: any = {title : '', msg: ''}

  ngOnInit(): void {
    this.modalConf ={
      type: undefined,
      content: '' as any,
      class: ''
    }
    this.editService.getModalConfig()?.subscribe(
      response=>{
        this.loading = true;
        this.modalConf = response;
        this.loading = false;
      },
      error=>console.log(error)
    )

    this.deactivationConfig ={
      title:[
        {width: 100, text: this.getTranslation('ACCOUNT_STATUS_LABEL'), size: 0.875, class: 'edit-label mb-0'},
      ],
      button:[
        {
          bclass : 'btn normal-button-next red-btn',
          type : 'button',
          text : 'Deactivate account',
          disabled : false,
        },
        {
          bclass : 'btn button-next white-text',
          type : 'button',
          text : 'Ok',
          disabled : false,
          dataDismiss : 'modal'
        },
        {
          bclass : 'btn normal-button-next',
          type : 'button',
          text : 'Mantain account',
          disabled : false,
        },
      ]
    }

    this.deactivationAlert = {
      title: this.getTranslation('DEACTIVATE_ALERT_TITLE'),
      msg: this.getTranslation('DEACTIVATR_ALERT_TEXT'),
      aclass: 'exit-alert',
      buttons:[
        {class: 'exit-button', text: this.getTranslation('BTN_DEACTIVE'), value: 'deactive'},
        {class: 'cancel-button', text: this.getTranslation('BTN_CANCEL'), value: 'cancel'}
      ],
      icon:{class: 'exit-icon'},
      show: false,
      noModal : true
    }
  }

  showDeactAlert(){
    this.deactivationAlert.show = true;
    this.contentDisabled = true;
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }


  dateParse(date: Date | undefined){
    if(date){
      return this.datePipe.transform(date, 'd MMMM yyyy');
    }
    return -1;
  }


}
