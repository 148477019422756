<ngx-smart-modal #patientOrderCalendar identifier="patientOrderCalendar" [style.height.px]="'900'" [style.width.px]="'1400'" data-backdrop="static" id="orderCalendarModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="modal-header">
      <h2>{{ 'CALENDAR_ORDERS_CALENDAR' | translate }}</h2>
      <app-round-button [type]="'CLOSE'" (onClick)="closeModal()"></app-round-button>
    </div>
    <div class="calendar-wrapper">
        <div *ngIf="!loading">
            <app-orders-calendar [maxDate]="maxDate" [deliveries]="deliveries" (onMonthChange)="updateDeliveryList($event)"></app-orders-calendar>
        </div>
        <div class="w-100">
            <div class="deliveries-wrapper" *ngIf="firstPrediction">
                <app-text [text]="(isFirstPredictionPresentInList() ? 'CALENDAR_UPCOMING_ORDER_LABEL' : 'CALENDAR_FUTURE_ORDER_OUTLOOK_LABEL' ) | translate" [fontSizePx]="18" [isBold]="true" extraClass="mb-3"></app-text>
                <div class="planned-deliveries">
                    <div class="delivery-box">
                        <div class="border range-border" [class.isFirst]="isFirstPredictionPresentInList()"></div>
                        <div class="ml-2 d-flex flex-row">
                            <svg-icon src="../../../assets/icons/calendar-due-date-icon.svg" class="d-flex"></svg-icon>
                            <div class="d-flex flex-column">
                                <app-text [text]="'CALENDAR_DUE_DATE' | translate" [fontSizePx]="18" extraClass="text-uppercase" textColor="#E11C70" [isSpan]="true"></app-text>
                                <app-text [text]="getDeliveryDate(firstPredictionInMonth!.dueDate!)" [fontSizePx]="18" [isBold]="true"></app-text>
                            </div>
                        </div>
                    </div>
                    <div class="delivery-box">
                        <div class="border range-border" [class.isFirst]="isFirstPredictionPresentInList()"></div>
                        <div class="ml-2 d-flex flex-row">
                            <svg-icon src="../../../assets/icons/calendar-picking-date-icon.svg" class="d-flex"></svg-icon>
                            <div class="d-flex flex-column">
                                <app-text [text]="'PICKING_CONTROL_DATE_CALENDAR_LABEL' | translate" extraClass="text-uppercase" [fontSizePx]="18" textColor="#E11C70" [isSpan]="true"></app-text>
                                <app-text [text]="getDeliveryDate(firstPredictionInMonth!.pickingDate!)" [fontSizePx]="18" [isBold]="true"></app-text>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Upcoming deliverier -->
                <div class="d-flex flex-row align-items-center justify-content-between w-100 my-3 c-pointer" *ngIf="extraDeliveriesPresent()" (click)="isAccordionOpen = !isAccordionOpen">
                    <app-text [text]="(isFirstPredictionPresentInList() ? 'CALENDAR_FUTURE_ORDER_OUTLOOK_LABEL' : 'VIEW_MORE' ) | translate" [fontSizePx]="18" [isBold]="true"></app-text>
                    <app-round-button [type]="'BACK'" [extraClass]="{'padding': '0.4rem', 'transform': isAccordionOpen ? 'rotate(90deg)': 'rotate(-90deg)'}" ></app-round-button>                
                </div>
                <div class="planned-deliveries" *ngIf="isAccordionOpen">
                    <ng-container *ngFor="let prediction of otherPredictions; let i = index">
                        <div class="delivery-box">
                            <div class="border range-border"></div>
                            <div class="ml-2 d-flex flex-row">
                                <svg-icon src="../../../assets/icons/calendar-due-date-icon.svg" class="d-flex"></svg-icon>
                                <div class="d-flex flex-column">
                                    <app-text [text]="'CALENDAR_DUE_DATE' | translate" [fontSizePx]="18" textColor="#E11C70" [isSpan]="true"></app-text>
                                    <app-text [text]="getDeliveryDate(prediction.dueDate!)" [fontSizePx]="18" [isBold]="true"></app-text>
                                </div>
                            </div>
                        </div>
                        <div class="delivery-box">
                            <div class="border range-border"></div>
                            <div class="ml-2 d-flex flex-row">
                                <svg-icon src="../../../assets/icons/calendar-picking-date-icon.svg" class="d-flex"></svg-icon>
                                <div class="d-flex flex-column">
                                    <app-text [text]="'PICKING_CONTROL_DATE_CALENDAR_LABEL' | translate" [fontSizePx]="18" textColor="#E11C70" [isSpan]="true"></app-text>
                                    <app-text [text]="getDeliveryDate(prediction.pickingDate!)" [fontSizePx]="18" [isBold]="true"></app-text>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- ========================== -->
        </div>

    </div>
  </ngx-smart-modal>