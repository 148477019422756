import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, OperatorFunction} from 'rxjs';
import {distinctUntilChanged, map, filter} from 'rxjs/operators';
import { NavbarService } from 'src/app/core/services/navbar.service';
import { TabService } from 'src/app/core/services/tab.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  encapsulation: ViewEncapsulation.None //To override ng-bootstrap Typeahead styles
})
export class SearchBarComponent implements OnInit {


  @Input() searchBarConfig: any = {} as any || [];
  @Output() selected = new EventEmitter<any>();

  constructor(
    private tabService: TabService,
    private navbarService: NavbarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.navbarService.getSearchBarData().subscribe((searchList)=>this.searchBarConfig.array=searchList);
  }

  public model: (any) | undefined;

  emit(event: any){
    if(this.router.url=='/patient-list'){
      let searchBar = document.querySelector('#search-bar') as HTMLInputElement;
      let closeButton = document.querySelector('#close-icon') as HTMLInputElement;
      searchBar.classList.add('empty');
      searchBar.blur();
      closeButton.style.display = 'none';
      this.tabService.showTabs();
      this.tabService.setActiveTabId(event.item.idPatient, false);
      this.tabService.addTab(event.item)
    }else{
      this.navbarService.setfilterList(this.router.url, event.item.patientFullName)
    }
  }

   formatter = (array: { [x: string]: any; }) => array[this.searchBarConfig.attr];
   formatterInput = (array: { [x: string]: any; }) => this.router.url=='/patient-list' ? "" : array[this.searchBarConfig.attr];

   search: OperatorFunction<string, any> = (text$: Observable<string>) => text$.pipe(
     distinctUntilChanged(),
     map(term => this.searchBarConfig.array.filter((elem : any) => this.isValid(term) && new RegExp('^' + term, 'i').test(elem[this.searchBarConfig.attr])).slice(0, this.searchBarConfig.numberToShow))
   )

   show(){
    if(this.router.url!='/patient-list'){
      this.searchBarConfig.numberToShow = 0;
     }else {
      this.searchBarConfig.numberToShow = 6;
     }
     if(!this.searchBarConfig.readOnly){
     this.addClass(null);
     }
   }

   addClass(event: any){
    if(event && this.router.url!='/patient-list'){
      let searchBar = document.querySelector('#search-bar') as HTMLInputElement;
      this.navbarService.setfilterList(this.router.url, searchBar.value);
    }
      let searchBar = document.querySelector('#search-bar') as HTMLInputElement;
      let closeButton = document.querySelector('#close-icon') as HTMLInputElement;
      if(searchBar.value==''){
        searchBar.classList.add('empty');
        closeButton.style.display = 'none';
      } else {
        searchBar.classList.remove('empty');
        closeButton.style.display = 'block';
      }

      document.querySelectorAll('button.dropdown-item').forEach((item)=>{
        if(!item.classList[1] || item.classList[1]==='active'){
          if(!this.searchBarConfig.prefix){
          item.classList.add('location');
        }
      }
    })

  }

  clear(){
    const event = new Event('input', { bubbles: true });
     let inputValue = document.querySelector('input[name="'+this.searchBarConfig.inputName+'"]') as HTMLInputElement;
     inputValue.value = "";
     inputValue.dispatchEvent(event);
     inputValue.blur();
     this.addClass(null);
     if(this.router.url!='/patient-list'){
      this.navbarService.setfilterList(this.router.url, '')
     }
  }

   isValid(term: string){
     return term.match("^[a-zA-Z ]+$") || /^\d+$/.test(term);
   }

}
