import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscriptionError } from 'rxjs';
import { AuthenticationService, JwtService } from 'src/app/core';
import { Role } from 'src/app/core/enum/roles';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { TabService } from 'src/app/core/services/tab.service';
import { NavbarService } from 'src/app/core/services/navbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  viewSidebar: boolean = true;
  topIcons!: any[]

  constructor(
    private translate: TranslateService,
    private authService: AuthenticationService,
    private tabService: TabService,
    private navbarService: NavbarService,
    private utilsService: UtilsService
  ) {   

  }

  RoleEnabledUser(roles : any) : boolean{
    let userRole = this.authService.currentUserValue.role;
    if (roles && roles.indexOf(userRole) !== -1) {
      return true;
    }
    return false;
  }

  PermissionEnabledUser() : boolean{    
    return this.authService.currentUserValue.isDemoUserManagementEnabled ?? false;
  }

  ngOnInit(): void {

    this.navbarService.getSidebarLabel()?.subscribe(() => {
      this.reloadSideBar();
    });

    this.reloadSideBar();
  }

  reloadSideBar(){
    this.topIcons = [
      {label : this.getTranslation('SIDEBAR_REQUESTS'), icon : 'fa-tasks', route : '/requests', enabledForRole: this.RoleEnabledUser([Role.AppSupport]), enabledForPermission: true },
      
      {label : this.getTranslation('SIDEBAR_CLINICS'), icon : 'fa-clinic-medical', route : '/clinics', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.AppSupport, Role.CustomerService]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_NURSES'), icon : 'fa-user-nurse',route : '/nurses', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.AppSupport, Role.CustomerService]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_NEPHROLOGIST'), icon : 'fa-user-md',route : '/nephrologists', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.AppSupport, Role.CustomerService]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_PATIENTS'), icon : 'fa-user-injured',route : '/patients', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.AppSupport, Role.CustomerService]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_ADMINS'), icon : 'fa-user-plus',route : '/admins', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_SPECIALISTS'), icon : 'fa-user-tag',route : '/specialists', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSupport]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_SUPPORT_USERS'), icon : 'fa-headset',route : '/support-users', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSupport]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_DEMO_USERS'), icon : 'fa-user-cog',route : '/demo-users', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.AppSpecialist, Role.AppSupport, Role.CustomerService]), enabledForPermission: this.PermissionEnabledUser() },
      {label : this.getTranslation('SIDEBAR_CALENDARS'), icon : 'fa-calendar',route : '/calendars', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin, Role.CustomerService]), enabledForPermission: this.PermissionEnabledUser() },
      {label : this.getTranslation('SIDEBAR_CUSTOMER_SERVICE_USERS'), icon : 'fa-toolbox',route : '/customer-service-users', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_NOTIFICATIONS'), icon : 'fa-bell',route : '/notifications', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_RELEASE_NOTES'), icon : 'fa-clipboard',route : '/release-notes', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_REPORTS'), icon : 'fa-chart-pie',route : '/reports', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_COUNTRIES'), icon : 'fa-globe',route : '/countries', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin]), enabledForPermission: true },
      {label : this.getTranslation('SIDEBAR_REQUESTS'), icon : 'fa-tasks', route : '/requests', enabledForRole: this.RoleEnabledUser([Role.SuperAdmin, Role.Admin]), enabledForPermission: true },
    ]
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  disableTabs(){
    this.tabService.hideTabs();
    this.tabService.setCount(-1);

    //CLEAR FILTERS
    this.utilsService.clearFilterStorage();
  }

}
