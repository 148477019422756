import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BackOfficeClinicView, BaseGetAllRQ, CityRS, ClientService, IBaseGetAllRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-clinic-list',
  templateUrl: './clinic-list.component.html',
  styleUrls: ['./clinic-list.component.css']
})
export class ClinicListComponent implements OnInit {

  clinicList! : BackOfficeClinicView[];
  cityList! : CityRS[];
  loading: boolean = true;
  record?: any;
  totalItemsClinic: number = 0;
  anyResult: boolean = false;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_CLINIC'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_CLINIC'), value: '' }

  autocompleteConfig = {
    name: this.getTranslation('FILTER_CITY'),
    attr: 'cityName' as const,
    array: this.cityList,
    numberToShow: 6,
    value : null as any,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_CITY'),
  }

  tableConfig =  {
    actions: true,
    gridData: this.clinicList,
    headerList: [
      {name: this.getTranslation('LABEL_CLINIC_NAME'), field: 'clinicName', sortable: true},
      {name: this.getTranslation('SHIP_TO_CODE'), field: 'sapUuid', sortable: true},
      {name: this.getTranslation('LABEL_ADDRESS'), field: 'address', sortable: true},
      {name: this.getTranslation('LABEL_POSTALCODE'), field: 'zipCode', sortable: true},
      {name: this.getTranslation('LABEL_CITY'), field: 'city', sortable: true},
      {name: this.getTranslation('LABEL_STATUS'), field: 'status', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsClinic
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private router: Router,
    private editService: EditServiceService,
    private utilsService: UtilsService,
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getFiltersBySession();

    this.getClinicList();

    this.getCityList();

    this.utilsService.checkReleaseNote();
  }

  filterName?: string = '';
  filterCity?: string = '';

  orderDirection?: string = "ClinicName_asc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;

    this.currentPage = 1;
    this.getClinicList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterCity = value.target.value.cityName
      this.getClinicList(); 
    }
    else{
      this.filterCity = '';
      this.getClinicList(); 
    } 
  }
 
  getClinicList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName,
      city: this.filterCity
    }
    this.clientService.clinicGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.clinicList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.clinicList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderClinicChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getClinicList();
  }

  pageClinicChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getClinicList();
  }

  getUpdatedClinicList(): any{
    return this.clinicList;
  }

  getCityList() {
    this.clientService.cityGetAll().subscribe(
      response =>{
        this.cityList = response || [];
        this.autocompleteConfig.array = this.cityList;
      } ,
      error=> console.log(error)
    );
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToClinicDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/clinics/detail', event.record.idClinic]);
      this.saveFilters();
    }
  }

  clinicAdd() {
    this.router.navigate(['/clinics/add']);
  }

  bindButtons(clinicList: BackOfficeClinicView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let clinic of clinicList){
      clinic.status = this.getTranslation("CLINIC_STATUS_" + clinic.status);
      this.tableConfig.recordButtonConfigs.set(clinic,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };

    //ASSIGN PREVIOUS DROPDOWN FILTER
    this.filterCity = window.localStorage.getItem('filterApplied') || '';
    this.autocompleteConfig.value = {
      cityName: this.filterCity,
    }
    this.autocompleteConfig = { ...this.autocompleteConfig };

    //ASSIGN PREVIOUS SORT ORDER
    this.orderDirection = window.localStorage.getItem('sortOrder') || this.orderDirection;

    //ASSIGN PREVIOUS LIST PAGE VISITED    
    this.currentPage = Number(window.localStorage.getItem('currentPage')) || 1;
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);

    //SET PREVIOUS DROPDOWN FILTER
    window.localStorage.setItem('filterApplied', this.filterCity!);

    //SET PREVIOUS SORT ORDER
    window.localStorage.setItem('sortOrder', this.orderDirection!);

    //SET PREVIOUS LIST PAGE VISITED
    window.localStorage.setItem('currentPage', this.currentPage!.toString());
  }
}