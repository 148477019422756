import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { AuthenticationService, AuthRefreshRequest, ClientService, IAuthRefreshRequest, JwtService } from '../services';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NxgCustomModalService } from '../services/nxg-custom-modal.service';
import { TimeoutService } from '../services/timeout.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private jwtService: JwtService,
    private clientService: ClientService,
    private authenticationService: AuthenticationService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private timeout: TimeoutService,
  ) {}

  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.jwtService.getCurrentToken()) {
      request = this.addToken(request, this.jwtService.getCurrentToken() as string);
    }
    else{
      request = this.addBasicHeaders(request);
    }

    return next.handle(request).pipe(
      map(event => {
        this.timeout.resetTimer();
        return event;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !request.url.includes("Login") && !request.url.includes("signalr")) {
          return this.handle401Error(request, next);
        } else if(error.status === 500 || error.status === 422) {
          this.modalService.setModalData({title: 'Generic Error', msg: error.statusText + "!"})
          this.ngxModal.getModal('myModal').open();
          return throwError("Internal Server error: " + JSON.stringify(error));
        }else if(error.status === 401 && request.url.includes("Login")){
          return throwError(error);
        }else{    
          if(error.status === 409){
            this.authenticationService.logout(false);
            return throwError(error);
          }else{
            this.modalService.setModalData({title: 'Generic Error', msg: error.statusText + "!"})
            this.ngxModal.getModal('myModal').open();
            return throwError(error);
          }
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Max-Age" : "",
        "AppType": "BACKOFFICE",
        "X-Frame-Options": "DENY",
        "BrowserInfo": this.getUserAgent(),
      },
    });
  }

  private addBasicHeaders(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        "Access-Control-Max-Age" : "",
        "AppType": "BACKOFFICE",
        "X-Frame-Options": "DENY"
      },
    });
  }

  browserInfo: string = "";
  private getUserAgent(){
    if(!this.browserInfo){
      var ua= navigator.userAgent;
      var tem; 
      var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edg)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      
      this.browserInfo =  M.join(' ');
    }
    
    return this.browserInfo;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      let sessionRefreshToken = window.sessionStorage['refreshToken'];

      if(sessionRefreshToken && sessionRefreshToken != ""){
        this.refreshTokenSubject.next(null);
        let refreshToken!: IAuthRefreshRequest;
        refreshToken = {
          refreshToken: window.sessionStorage['refreshToken']
        }
  
        return this.clientService.authenticateRefreshToken(new AuthRefreshRequest(refreshToken)).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            window.sessionStorage['accessToken'] = token?.accessToken;
            window.sessionStorage['refreshToken'] = token?.refreshToken;
            this.refreshTokenSubject.next(token.accessToken);
            return next.handle(this.addToken(request, token.accessToken));
          })
        );
      }
      else{
        this.authenticationService.logout(true);
        return next.handle(this.addToken(request, ""));
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
