import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  AuthenticationService, BackOfficePrescriptionView, ClientService, IPrescriptionGetAllBackOfficeRQ, PrescriptionGetAllBackOfficeRQ } from 'src/app/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit {

  id?: string;
  prescriptionList! : BackOfficePrescriptionView[];  
  loading: boolean = true;
  record?: any;
  totalItemsPrescription: number = 0;

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_PRESCRIPTION')}

  tableConfig =  {
    actions: true,
    gridData: this.prescriptionList,
    headerList: [
      {name: this.getTranslation('ValidityStart'), field: 'validityStart', sortable: true, isDate: true},
      {name: this.getTranslation('ValidityEnd'), field: 'validityEnd', sortable: true, isDate: true},
      {name: this.getTranslation('TaskType'), field: 'taskType', sortable: true},
      {name: this.getTranslation('Therapies'), field: 'therapies', sortable: true},
      {name: this.getTranslation('SignatureDate'), field: 'signDate', sortable: true, isDate: true},
      {name: this.getTranslation('Nephrologist'), field: 'nephrologistFullName', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tablePrescription",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsPrescription
    },
    sort:{
      field: "validityStart",
      direction: "desc"
    },
    notes: false
  };

  anyResult: boolean = false;

  constructor(
    private clientService: ClientService,
    private authService: AuthenticationService,
    private router : Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private location: Location
  ) { }


  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;
    this.getPrescriptionList();
  }

  backToList(){   
    this.location.back();
  }

  filterName?: string;
  filterClinic?: any;

  orderDirection?: string = "validityStart_desc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getPrescriptionList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterClinic = value.target.value.idClinic;
      this.getPrescriptionList(); 
    }
    else{
      this.filterClinic = null;
      this.getPrescriptionList(); 
    } 
  }
 
  getPrescriptionList() {
    let input: IPrescriptionGetAllBackOfficeRQ = {
      idPatient: this.id,
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName, 
      idClinicSelected: this.filterClinic,
    }
    this.clientService.prescriptionGetAllBackOffice(new PrescriptionGetAllBackOfficeRQ(input)).subscribe(
      response =>{
        this.prescriptionList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.prescriptionList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderPrescriptionChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getPrescriptionList();
  }

  pagePrescriptionChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getPrescriptionList();
  }

  getUpdatedPrescriptionList(): any{
    return this.getPrescriptionList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToPrescriptionDetail(event: any) {
    if(event.action == 'edit'){
      this.router.navigate(['/prescriptions/detail', event.record.idPrescription]);
    }
  }

  bindButtons(prescriptionList: BackOfficePrescriptionView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let prescription of prescriptionList){
      this.tableConfig.recordButtonConfigs.set(prescription,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}