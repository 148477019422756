import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, CalendarDatePatientSetRQ, ICalendarDatePatientSetRQ, AuthenticationService, EntityRQ } from 'src/app/core';
import { BackOfficeCalendarDatePatientViewCustom } from 'src/app/core/models/BackOfficeCalendarDatePatientView-custom.model';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-calendar-date-patient-edit',
  templateUrl: './calendar_date_patient-edit.component.html',
  styleUrls: ['./calendar_date_patient-edit.component.css']
})
export class CalendarDatePatientEditComponent implements OnInit {
  id?: string;
  idCalendarDatePatient?: string;
  idCalendarDate?: string;
  idPatient?: string;
  idCreated?: string;
  calendarDatePatientDetails?: BackOfficeCalendarDatePatientViewCustom;
  loading: boolean = false;

  validationErrors: any [] = [];

  isCalendarValid: boolean = true;

  publicHolidaysParam: string = this.utilsService.getParametersByKey("flow__year_holiday_dates").parameterValue!;
  publicHolidaysArray: Array<Date> = new Array<Date>();

  formCalendarDatePatientAdd: FormGroup = this.fb.group({
    calendarName: [null],
    deliveryDate: [null, Validators.required],
    pickingDate: [null, Validators.required],
    dueDate: [null, Validators.required],
    closingWindowDate: [null],
    openingWindowDate: [null],
  });

  formCalendarDatePatientInfo={
    key: 'formCalendarDatePatientInfo',
    input:
    [
      { name: this.getTranslation('CALENDAR_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'calendarName', value: this.formCalendarDatePatientAdd.get("calendarName")?.value, disabled: true, readOnly: true },
    ],
    deliveryDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_DELIVERY_DATE'),
      optional: false,
      inputName: 'deliveryDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      class: '',
      holidays: new Array<Date>(),
      datePattern: ["1","2","3","4","5"]
    },
    pickingDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_PICKING'),
      optional: false,
      inputName: 'pickingDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      class: '',
      holidays: new Array<Date>(),
      datePattern: ["1","2","3","4","5"]
    },
    dueDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_DUE_DATE'),
      optional: false,
      inputName: 'dueDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      class: '',
      holidays: new Array<Date>(),
      datePattern: ["1","2","3","4","5"]
    },
    closingWindowDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_ORDER_CLOSING_WINDOW'),
      optional: false,
      inputName: 'closingWindowDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      disabled: true,
    },
    openingWindowDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_ORDER_OPENING_WINDOW'),
      optional: false,
      inputName: 'openingWindowDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      disabled: true,
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_COMMON_SAVE'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private utilsService: UtilsService,
    private authService: AuthenticationService,
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !(form.valid && this.isCalendarValid);
      });
    this.addBtnConfig.disabled = !(form.valid && this.isCalendarValid);
  }

  inputValues(event: any){

    this.formCalendarDatePatientAdd.controls[event.target.name].setValue(event.target.value);

    this.checkCalendarValidity();
    
    this.calcOrderWindow();

    this.formValidation(this.formCalendarDatePatientAdd);
  }

  calcOrderWindow(){

    var dueDate = new Date(this.formCalendarDatePatientAdd.get('dueDate')?.value);    

    if(dueDate != undefined)
    {
      var openingWindowDate = new Date();
      openingWindowDate.setDate(dueDate.getDate() - parseInt(this.utilsService.getParametersByKey("flow__order_editable_by_patient__days_before_due_date")!.parameterValue!));
      this.formCalendarDatePatientInfo.openingWindowDatePickerConfig.value = openingWindowDate;
      this.formCalendarDatePatientInfo!.openingWindowDatePickerConfig = {...this.formCalendarDatePatientInfo!.openingWindowDatePickerConfig};

      var closingWindowDate = new Date();
      closingWindowDate.setDate(dueDate.getDate() - parseInt(this.utilsService.getParametersByKey("flow__order_editable_by_patient__days_before_due_date_end")!.parameterValue!));
      this.formCalendarDatePatientInfo.closingWindowDatePickerConfig.value = closingWindowDate;
      this.formCalendarDatePatientInfo!.closingWindowDatePickerConfig = {...this.formCalendarDatePatientInfo!.closingWindowDatePickerConfig};
    }
  }

  checkCalendarValidity(){

    var isValid = false;

    var dueDate = new Date(this.formCalendarDatePatientAdd.get('dueDate')?.value);
    var pickingDate = new Date(this.formCalendarDatePatientAdd.get('pickingDate')?.value);
    var deliveryDate = new Date(this.formCalendarDatePatientAdd.get('deliveryDate')?.value);
    
    //DATES ARE VALID
    if (new Date() > dueDate){
      this.formCalendarDatePatientInfo.dueDatePickerConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_IN_PAST')
    }
    else if (dueDate > pickingDate){
      this.formCalendarDatePatientInfo.dueDatePickerConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_NOT_COMPLY')
    }
    else if (pickingDate > deliveryDate){
      this.formCalendarDatePatientInfo.pickingDatePickerConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_PICKING_DATE_NOT_COMPLY')
    }
    else{
      isValid = true;
      this.formCalendarDatePatientInfo.dueDatePickerConfig.errorMessage = '';
      this.formCalendarDatePatientInfo.dueDatePickerConfig.class = '';
      this.formCalendarDatePatientInfo.pickingDatePickerConfig.errorMessage = '';
      this.formCalendarDatePatientInfo.pickingDatePickerConfig.class = '';
      this.formCalendarDatePatientInfo.deliveryDatePickerConfig.errorMessage = '';
      this.formCalendarDatePatientInfo.deliveryDatePickerConfig.class = '';
    }

    //CHECK DATE IS ON BANK HOLIDAY
    var isDueDateDisabled = this.isDayDisabled(dueDate);
    var isPickingDateDisabled = this.isDayDisabled(pickingDate);
    var isDeliveryDateDisabled = this.isDayDisabled(deliveryDate);

    if (!isDueDateDisabled){
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("CALENDAR_DUE_DATE_ERROR"));
    }
    if (!isPickingDateDisabled){
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("CALENDAR_PICKING_ERROR"));
    }
    if (!isDeliveryDateDisabled){
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("CALENDAR_DELIVERY_DATE_ERROR"));
    }

    this.isCalendarValid = isValid && !isDueDateDisabled && !isPickingDateDisabled && !isDeliveryDateDisabled;
  }

  public isDayDisabled = (date: Date) => {
    return this.publicHolidaysArray.some(x => x.getDate() == date.getDate() && x.getMonth() == date.getMonth() && x.getFullYear() == date.getFullYear());
  }


  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    this.editService.showEditSnackBar = isEdited;
    if(this.idCalendarDatePatient != undefined)
      this.router.navigate(['/calendar-date-patient/detail', this.idCalendarDatePatient]);
    else
      this.router.navigate(['/patients/detail', this.idPatient]);
  }

  ngOnInit(): void {

    this.setCalendarHolidays();

    this.id = this.route.snapshot.paramMap.get('id')?.toString(); 
    this.idPatient = this.route.snapshot.paramMap.get('id')?.toString(); 

    this.getCalendarDetail();
  }

  getCalendarDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.calendarDatePatientGet(data).subscribe(
      response =>{

        this.calendarDatePatientDetails = BackOfficeCalendarDatePatientViewCustom.fromJS(response, (key)=> this.getTranslation(key));;

        if(this.calendarDatePatientDetails == undefined)
          return;

        this.idCalendarDatePatient = response.idCalendarDatePatient;
        this.idCalendarDate = response.idCalendarDate;
        this.idPatient = response.idPatient;

        this.formCalendarDatePatientAdd.patchValue({
          calendarName: this.calendarDatePatientDetails.fullCalendarName,
          deliveryDate: response.deliveryDate,
          pickingDate: response.pickingDate,
          dueDate: response.dueDate,
          closingWindowDate: response.closingWindowDate,
          openingWindowDate: response.openingWindowDate,
        });

        this.formCalendarDatePatientInfo!.input[0].value = this.formCalendarDatePatientAdd.get("calendarName")?.value;

        this.formCalendarDatePatientInfo!.deliveryDatePickerConfig!.value = this.calendarDatePatientDetails.deliveryDate;
        this.formCalendarDatePatientInfo!.deliveryDatePickerConfig = {...this.formCalendarDatePatientInfo!.deliveryDatePickerConfig};

        this.formCalendarDatePatientInfo!.pickingDatePickerConfig!.value = this.calendarDatePatientDetails.pickingDate;
        this.formCalendarDatePatientInfo!.pickingDatePickerConfig = {...this.formCalendarDatePatientInfo!.pickingDatePickerConfig};
        
        this.formCalendarDatePatientInfo!.dueDatePickerConfig!.value = this.calendarDatePatientDetails.dueDate;
        this.formCalendarDatePatientInfo!.dueDatePickerConfig = {...this.formCalendarDatePatientInfo!.dueDatePickerConfig};

        this.formCalendarDatePatientInfo!.closingWindowDatePickerConfig!.value = this.calendarDatePatientDetails.closingWindowDate;
        this.formCalendarDatePatientInfo!.closingWindowDatePickerConfig = {...this.formCalendarDatePatientInfo!.closingWindowDatePickerConfig};

        this.formCalendarDatePatientInfo!.openingWindowDatePickerConfig!.value = this.calendarDatePatientDetails.openingWindowDate;
        this.formCalendarDatePatientInfo!.openingWindowDatePickerConfig = {...this.formCalendarDatePatientInfo!.openingWindowDatePickerConfig};

        if (this.calendarDatePatientDetails.isDeliveryDateReviewNeeded){
          this.isCalendarValid = false;
          this.formCalendarDatePatientInfo!.deliveryDatePickerConfig!.class = "error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("CALENDAR_DELIVERY_DATE_ERROR"));
        }
        if (this.calendarDatePatientDetails.isPickingDateReviewNeeded){
          this.isCalendarValid = false;
          this.formCalendarDatePatientInfo!.pickingDatePickerConfig!.class = "error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("CALENDAR_PICKING_ERROR"));
        }
        if (this.calendarDatePatientDetails.isDueDateReviewNeeded){
          this.isCalendarValid = false;
          this.formCalendarDatePatientInfo!.dueDatePickerConfig!.class = "error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("CALENDAR_DUE_DATE_ERROR"));
        }

        this.getDefaultByCountry(this.calendarDatePatientDetails.countryIsoCode!);

        this.formValidation(this.formCalendarDatePatientAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  saveCalendarDatePatient(){
    if (this.formCalendarDatePatientAdd.valid) {

      this.loading = true;
      let calendarDatePatient: ICalendarDatePatientSetRQ;
      calendarDatePatient = {
        idCalendarDatePatient: this.idCalendarDatePatient,
        idCalendarDate: this.idCalendarDate,
        idPatient: this.idPatient,
        deliveryDate: this.formCalendarDatePatientAdd.get('deliveryDate')?.value,
        pickingDate: this.formCalendarDatePatientAdd.get('pickingDate')?.value,
        dueDate: this.formCalendarDatePatientAdd.get('dueDate')?.value,
      }
      this.clientService.calendarDatePatientSet(new CalendarDatePatientSetRQ(calendarDatePatient)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){

            this.idCreated = response.idEntity;

            this.modalService.setModalData({ 
              title: 'CALENDAR_ORDER_CHANGED_SUCCESSFULLY',
              actionToDo: () => 
              {
                this.router.navigate(['/calendar-date-patient/detail', this.idCreated]);
              }
            });
            this.ngxModal.getModal('successModal').open();
          }
          else{
            if(response?.errorType){

              if(response?.errorType == "ERROR_CALENDAR_ORDER_PATIENT_ALREADY_EXISTS"){
                this.formCalendarDatePatientInfo.deliveryDatePickerConfig.class = "input-form error";
                this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_CALENDAR_ORDER_PATIENT_ALREADY_EXISTS"));
              }
            }
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  getDefaultByCountry(countryIsoCode: string){
    if(this.authService.currentUserValue.countryIsoCode == null)
    {
      this.utilsService.getParameterByCountry("flow__year_holiday_dates", countryIsoCode!).pipe().subscribe(ret => {
        this.publicHolidaysParam = ret;

        this.setCalendarHolidays();
      });
    }
  }

  setCalendarHolidays(){
    this.publicHolidaysArray = this.publicHolidaysParam.split('|').map((x: string) => {
      var dateParts = x.split("/");
      return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0])); 
    });

    this.formCalendarDatePatientInfo.deliveryDatePickerConfig.holidays = this.publicHolidaysArray;
    this.formCalendarDatePatientInfo.pickingDatePickerConfig.holidays = this.publicHolidaysArray;
    this.formCalendarDatePatientInfo.dueDatePickerConfig.holidays = this.publicHolidaysArray;
  }
}
