import { ISubCategoryGroup, ProductCluster, SubCategoryGroup } from "../services";

export class SubCategoryGroupCustom implements SubCategoryGroup {
    categoryName?: string | undefined;
    subCategoryName?: string | undefined;
    subCategoryKey?: string | undefined;
    productClusters?: ProductCluster[] | undefined;
    isExpanded?: boolean = false;

    constructor(data?: ISubCategoryGroup) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.categoryName = _data["categoryName"];
            this.subCategoryName = _data["subCategoryName"];
            this.subCategoryKey = _data["subCategoryKey"];
            this.isExpanded = false;
            if (Array.isArray(_data["productClusters"])) {
                this.productClusters = [] as any;
                for (let item of _data["productClusters"])
                    this.productClusters!.push(ProductCluster.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SubCategoryGroup {
        data = typeof data === 'object' ? data : {};
        let result = new SubCategoryGroup();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["categoryName"] = this.categoryName;
        data["subCategoryName"] = this.subCategoryName;
        data["subCategoryKey"] = this.subCategoryKey;
        data["isExpanded"] = this.isExpanded;
        if (Array.isArray(this.productClusters)) {
            data["productClusters"] = [];
            for (let item of this.productClusters)
                data["productClusters"].push(item.toJSON());
        }
        return data;
    }
}
