import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from 'src/app/core';
import diff from 'simple-text-diff'
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-table-history',
  templateUrl: './table-history.component.html',
  styleUrls: ['./table-history.component.css']
})
export class TableHistoryComponent implements OnInit {

  @Input() tableConfig: any;
  @Output() onClick = new EventEmitter<any>();
  @Output() pageChanged = new EventEmitter<any>();

  selectedOption?: string;
  columns: string[] = [];
  skipColumns: string[] = ["VersionDate", "MethodName", "synchDate", "idEntry"];

  keys: any = [];

  constructor(
    private translate: TranslateService,
    private smartModal : NgxSmartModalService,
    ) { }

  ngOnInit(): void {
    this.selectedOption ='id';

    if(this.tableConfig.gridData == null)
      return;
      
    for (var i = 0; i < this.tableConfig.gridData.length; i++) {
      var rowHash = this.tableConfig.gridData[i];
      for (var key in rowHash) {
        if (!this.columns.some(x => x==key)) {          
          this.columns.push(key);
        }

        if(this.tableConfig.gridData[i][key] == null)
          this.tableConfig.gridData[i][key] = "";
      }
    }
  }

  calcDiff(oldValue: any, newValue: any, columnName: any){
    if(this.skipColumns.includes(columnName) || (newValue == "" && oldValue == ""))
      return newValue;
     
    if(newValue instanceof Array)
      return diff.diffPatch(JSON.stringify(oldValue), JSON.stringify(newValue)).after;
    else
      return diff.diffPatch(oldValue, newValue).after;
  }

  selectedRecords: any = [];

  toggleItemInArr(arr: any, item: any) {
    const index = arr.indexOf(item);
    index === - 1 ? arr.push(item) : arr.splice(index, 1);
  }
  
  selectRecord(record: any, event: any) {
    if (!event.ctrlKey) {
      this.selectedRecords = [];
      this.compareBtnConfig.disabled = true;
    }

    if(this.selectedRecords.length >= 5)
      return;
  
    this.toggleItemInArr(this.selectedRecords, record);

    if(this.selectedRecords.length >= 2)
      this.compareBtnConfig.disabled = false;
  }
  
  isRecordSelected(record: any) {
    return this.selectedRecords.includes(record);
  }

  isDate(value:string){
    return (Date.parse(value) || Date.parse(value)==0);
  }

  isBoolean(value:string){
    return Boolean(value);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  compareBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('Compare'),
    disabled : true
  }

  compareVersions(){
    this.smartModal.getModal('compareEntityModal').setData(this.selectedRecords, true).open();
  }

  handlePageChange(event: any) {
    this.tableConfig.pagination.currentPage = event;
    this.pageChanged.emit(event);
  }

  updatePage() {
    this.tableConfig.pagination.currentPage = 1;
  }
}

