import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService, BackOfficeNephrologistView, BackOfficePatientView, BaseGetAllRQ, ClientService, ClinicRSV2, ClinicSetRQ, ClinicUnloadingPointSetRQ, EntityRQ, IBaseGetAllRQ, IClinicSetRQ, IClinicUnloadingPointSetRQ } from 'src/app/core';
import { ClinicUnloadingPointRSCustom } from 'src/app/core/models/ClinicUnloadingPointRS-custom.model';
import { BackOfficeNurseViewCustom } from 'src/app/core/models/backOfficeNurseView-custom.model';
import { DateService } from 'src/app/core/services/date.service';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-clinic-detail',
  templateUrl: './clinic-detail.component.html',
  styleUrls: ['./clinic-detail.component.css']
})
export class ClinicDetailComponent implements OnInit {
  id?: string;
  clinicDetail?: ClinicRSV2;

  unloadingPoints?: ClinicUnloadingPointRSCustom[];
  newUnloadingPoint: ClinicUnloadingPointRSCustom = new ClinicUnloadingPointRSCustom();

  nurseList! : BackOfficeNurseViewCustom[];  
  nephrologistList! : BackOfficeNephrologistView[];  
  patientList! : BackOfficePatientView[];  
  totalItemsNurse: number = 0;
  totalItemsNephrologis: number = 0;
  totalItemsPatient: number = 0;
  isClinicDeactivated = false;


  loading: boolean = true;

  filterNurseName?: string;
  filterNephrologistName?: string;
  filterPatientName?: string;
  filterClinic?: any;

  orderNurseDirection?: string = "Surname_asc";
  orderNephrologistDirection?: string = "Surname_asc";
  orderPatientDirection?: string = "Surname_asc";

  currentNursePage: number = 1;
  currentNephrologistPage: number = 1;
  currentPatientPage: number = 1;

  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  btnNurseConfig = {
    bclass : 'btn btn-primary add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_NURSE'),
    width: '30px',
    height: '30px',
    type : 'add'
  }

  btnPatientConfig = {
    bclass : 'btn btn-primary add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_PATIENT'),
    width: '30px',
    height: '30px',
    type : 'add'
  }

  btnNephrologistConfig = {
    bclass : 'btn btn-primary add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_NEPHROLOGIST'),
    width: '30px',
    height: '30px',
    type : 'add'
  }
  isPharmacyRelatedToClinic = false;

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private editService: EditServiceService,
    private dateService: DateService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private authService: AuthenticationService, 
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString(); 
    this.filterClinic = this.id;

    this.getClinicDetail();

    this.getNurseList();
    this.getNephrologistList();
    this.getPatientList();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/clinics");
  }

  EditClinic() {
    this.router.navigate(['/clinics/edit', this.id]);
  }

  EditSettings() {
    this.router.navigate(['/clinics/security', this.id]);
  }

  getClinicDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.clinicGetV2(data).subscribe(
      async response =>{
        this.clinicDetail = response;
        this.isClinicDeactivated = this.clinicDetail.dateDeactivated !== undefined;
        this.unloadingPoints = this.clinicDetail.unloadingPoints?.map(v => new ClinicUnloadingPointRSCustom(v));

        const data = await this.utilsService.getParameterByCountry("flow_trigger__is_pharmacy_related_to_clinic", this.clinicDetail.countryIsoCode!).toPromise();
        this.isPharmacyRelatedToClinic = data == "True";

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  tableNurseConfig =  {
    actions: true,
    gridData: this.nurseList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
      {name: this.getTranslation('LABEL_STATUS'), field: 'registrationCodeStatus'},
      {name: this.getTranslation('LABEL_ISACTIVE_FILTEREDCLINIC'), field: 'createInFilterClinic'}
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableNurse",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsNurse
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  tableNephrologistConfig =  {
    actions: true,
    gridData: this.nephrologistList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
      {name: this.getTranslation('LABEL_STATUS'), field: 'statusDocuSign'},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tableNephrologist",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsNephrologis
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  tablePatientConfig =  {
    actions: true,
    gridData: this.patientList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      id: "tablePatient",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsPatient
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  searchNurseConfig = { placeholder: this.getTranslation('SEACH_PLH_NURSE')}
  searchNephrologistConfig = { placeholder: this.getTranslation('SEACH_PLH_NEPHROLOGIST')}
  searchPatientConfig = { placeholder: this.getTranslation('SEACH_PLH_PATIENT')}

  searchNurseFilterKeyUp(value:any){
    this.filterNurseName = value;
    this.currentNursePage = 1;
    this.getNurseList();
  }

  searchNephrologistFilterKeyUp(value:any){
    this.filterNephrologistName = value;
    this.currentNephrologistPage = 1;
    this.getNephrologistList();
  }

  searchPatientFilterKeyUp(value:any){
    this.filterPatientName = value;
    this.currentPatientPage = 1;
    this.getPatientList();
  }

  getNurseList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentNursePage,
      sortOrder: this.orderNurseDirection,
      searchString: this.filterNurseName, 
      idClinicSelected: this.filterClinic
    }
    this.clientService.nurseGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.nurseList = response.list || [];
        for(let nurse of this.nurseList){
          if(nurse.registrationCodeStatus == "PLANNED"){
            nurse.registrationCodeStatus = this.getTranslation("NS_" + nurse.registrationCodeStatus) + this.dateService.dateParse(nurse.datePlanned);
          }
          else{
            nurse.registrationCodeStatus = this.getTranslation("NS_" + nurse.registrationCodeStatus);
          }
          nurse.createInFilterClinic = nurse.isActiveInClinic ? this.getTranslation("LABEL_CREATED") : "-";
        }
        this.tableNurseConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableNurseConfig.pagination.totalItems = response.count || 0;
        this.tableNurseConfig.pagination.currentPage = this.currentNursePage;
        this.tableNurseConfig.gridData = this.nurseList;
        this.tableNurseConfig.recordButtonConfigs = new Map();
        for(let nurse of this.nurseList){
          this.tableNurseConfig.recordButtonConfigs.set(nurse,[
            {
              bclass : 'btn edit-button',
              type : 'edit'
            }
          ]);
        };
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getNephrologistList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentNephrologistPage,
      sortOrder: this.orderNephrologistDirection,
      searchString: this.filterNephrologistName, 
      idClinicSelected: this.filterClinic
    }
    this.clientService.nephrologistGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.nephrologistList = response.list || [];
        for(let nephrologist of this.nephrologistList){
          nephrologist.statusDocuSign = this.getTranslation("NEPHROLOGIST_STATUS_" + nephrologist.statusDocuSign);
        }
        this.tableNephrologistConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableNephrologistConfig.pagination.totalItems = response.count || 0;
        this.tableNephrologistConfig.pagination.currentPage = this.currentNephrologistPage;
        this.tableNephrologistConfig.gridData = this.nephrologistList;
        this.tableNephrologistConfig.recordButtonConfigs = new Map();
        for(let nephrologist of this.nephrologistList){
          this.tableNephrologistConfig.recordButtonConfigs.set(nephrologist,[
            {
              bclass : 'btn edit-button',
              type : 'edit'
            }
          ]);
        };
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getPatientList() {
    const input = new BaseGetAllRQ({
      pageIndex: this.currentPatientPage,
      sortOrder: this.orderPatientDirection,
      searchString: this.filterPatientName, 
      idClinicSelected: this.filterClinic
    })
    this.clientService.patientGetAllBackOffice(input).subscribe(
      response =>{
        this.patientList = response.list || [];
        this.tablePatientConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tablePatientConfig.pagination.totalItems = response.count || 0;
        this.tablePatientConfig.pagination.currentPage = this.currentPatientPage;
        this.tablePatientConfig.gridData = this.patientList;
        this.tablePatientConfig.recordButtonConfigs = new Map();
        for(let patient of this.patientList){
          this.tablePatientConfig.recordButtonConfigs.set(patient,[
            {
              bclass : 'btn edit-button',
              type : 'edit'
            }
          ]);
        };
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }


  patientAdd() {
    this.router.navigate(['/patients/add']);
  }

  nurseAdd() {
    this.router.navigate(['/nurses/add']);
  }

  nephrologistAdd() {
    this.router.navigate(['/nephrologists/add']);
  }

  orderNurseChanged(orderDirection: string){
    this.orderNurseDirection = orderDirection;
    this.getNurseList();
  }

  orderNephrologistChanged(orderDirection: string){
    this.orderNephrologistDirection = orderDirection;
    this.getNephrologistList();
  }

  orderPatientChanged(orderDirection: string){
    this.orderPatientDirection = orderDirection;
    this.getPatientList();
  }

  pageNurseChanged(pageNumber: any){
    this.currentNursePage = Number.parseInt(pageNumber);
    this.getNurseList();
  }

  pageNephrologistChanged(pageNumber: any){
    this.currentNephrologistPage = Number.parseInt(pageNumber);
    this.getNephrologistList();
  }

  pagePatientChanged(pageNumber: any){
    this.currentPatientPage = Number.parseInt(pageNumber);
    this.getPatientList();
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

  EditClinicName() {
    this.ngxModal.getModal('clinicNameModal').setData(this.clinicDetail?.shipToClinicName, true).open();
  }

  setClinicName(event: any){

    this.loading = true;

    let input: IClinicSetRQ;
    input = {
      idClinic: this.clinicDetail?.idClinic,
      clinicName: event.clinicName
    }
    this.clientService.clinicSetName(new ClinicSetRQ(input)).subscribe(
      response =>{

        this.loading = false;

        this.getClinicDetail();
      } ,
      error=> console.log(error)
    );
    
    this.getClinicDetail();
  }

  ShowClinicListing(){
    this.ngxModal.getModal('clinicListingModal').setData({ idClinic: this.clinicDetail?.idClinic }).open();
  }

  GoToNurseDetail(event: any) {
    this.router.navigate(['/nurses/detail', event.record.idNurse]);
  }

  GoToNephrologistDetail(event: any) {
    this.router.navigate(['/nephrologists/detail', event.record.idNephrologist]);
  }
  
  GoToPatientDetail(event: any) {
    this.router.navigate(['/patients/detail', event.record.idPatient]);
  }

  editUnloadingPoint(unloadingPoint: any){
    unloadingPoint.isEditMode = true;
  }

  setUnloadingPoint(unloadingPoint: any){
    let input: IClinicUnloadingPointSetRQ = {
      idClinicUnloadingPoint: unloadingPoint.idClinicUnloadingPoint,
      idClinic: this.id,
      name: unloadingPoint.name
    }
    this.clientService.clinicUnloadingPointSet(new ClinicUnloadingPointSetRQ(input)).subscribe(
      response =>{
        if(response){
          this.newUnloadingPoint = new ClinicUnloadingPointRSCustom();
          this.getClinicDetail();
        }
      } ,
      error=> console.log(error)
    );
  }

  deleteUnloadingPoint(unloadingPoint: any){

    if(unloadingPoint.dateDeleted){
      this.clientService.clinicUnloadingPointUnDelete(unloadingPoint.idClinicUnloadingPoint).subscribe(
        response =>{
          this.getClinicDetail();
        } ,
        error=> console.log(error)
      );
    }
    else{
      this.clientService.clinicUnloadingPointDelete(unloadingPoint.idClinicUnloadingPoint).subscribe(
        response =>{
          this.getClinicDetail();
        } ,
        error=> console.log(error)
      );
    }
  }


  deactivateClinic(){
    this.modalService.setModalData({ 
      title: this.getTranslation('CLINIC_DEACTIVATION_MODAL_TITLE'),
      description: this.getTranslation('CLINIC_DEACTIVATION_MODAL_TEXT'),
      commentVisible: false,
      actionBtnConfig: {
        bclass: 'btn btn-primary add-patient-button',
        text: this.getTranslation('BTN_CONFIRM'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: async () => {     
        const data = new EntityRQ({ idEntity: this.id });      
        const isDeactivated = await this.clientService.clinicDeactivate(data).toPromise();
        window.location.reload()
        this.ngxModal.getModal('requestModal').close();
      }
    })
    this.ngxModal.getModal('requestModal').open();
  }
  

  reactivateClinic(){
    this.modalService.setModalData({ 
      title: this.getTranslation('CLINIC_REACTIVATION_MODAL_TITLE'),
      description: this.getTranslation('CLINIC_REACTIVATION_MODAL_TEXT'),
      commentVisible: false,
      actionBtnConfig: {
        bclass: 'btn btn-primary add-patient-button',
        text: this.getTranslation('BTN_CONFIRM'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: async () => {       
        const data = new EntityRQ({ idEntity: this.id });    
        const isDeactivated = await this.clientService.clinicReactivate(data).toPromise();
        window.location.reload();
        this.ngxModal.getModal('requestModal').close();
      }
    })
    this.ngxModal.getModal('requestModal').open();
  }


  activateSyncBtnConfig = {
    bclass : 'btn btn-primary add-list-button',
    icon : 'plus',
    text : this.getTranslation('CLINIC_SYNC_ACTIVATION'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  activateSync(){
    this.modalService.setModalData({ 
      title: this.getTranslation('CLINIC_SYNC_ACTIVATION_MODAL_TITLE'),
      description: this.getTranslation('CLINIC_SYNC_ACTIVATION_MODAL_DESC'),
      commentVisible: false,
      actionBtnConfig: {
        bclass: 'btn btn-primary add-patient-button',
        text: this.getTranslation('BTN_CONFIRM'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: async () => {     
        const data = new EntityRQ({ idEntity: this.id });      
        await this.clientService.clinicSyncActivation(data).toPromise();
        window.location.reload()
        this.ngxModal.getModal('requestModal').close();
      }
    })
    this.ngxModal.getModal('requestModal').open();
  }
}
