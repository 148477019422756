<div *ngIf="anyResult else noResultContent">
    <div class="row mt-2">
        <div class="col-lg-4 col-md-auto">
            <!--<app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)">
            </app-search-field>-->
        </div>
    </div>
    <app-table [tableConfig]="tableConfig" (orderChanged)="orderPrescriptionChanged($event)"
        (onClick)="GoToPrescriptionDetail($event)" (pageChanged)="pagePrescriptionChanged($event)"></app-table>
</div>
<ng-template #noResultContent>
    <div class="text-center noResult">
        <svg-icon class="icon" src="../../../../../assets/icons/task-prescription-icon.svg"></svg-icon>
        <p class="alert">{{ 'No Prescriptions' | translate }}</p>
        <!--<p class="desc">{{ 'No Prescriptions' | translate }}</p>-->
    </div>
</ng-template>