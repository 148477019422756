import {Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() buttonConfig: any;
  @Output() onClick : EventEmitter<any> = new EventEmitter<any>();

  onClickButton(event: any){
    if(this.buttonConfig.disabled == false){
      this.onClick.emit(event);
    }
  }



  constructor() { }

  ngOnInit(): void {
  }

}

export enum Action{
  edit,
  delete,
  reservation,
  add
}
