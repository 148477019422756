import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, BackOfficeClinicView, BaseGetAllRQ, ClientService, IBaseGetAllRQ, NurseGetAllBackOfficeRS, NurseRS, Patient} from 'src/app/core';
import { BackOfficeNurseViewCustom } from 'src/app/core/models/backOfficeNurseView-custom.model';
import { NgbDateCustomParserFormatter } from 'src/app/core/services/date-formatter.service';
import { DateService } from 'src/app/core/services/date.service';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-nurse-list',
  templateUrl: './nurse-list.component.html',
  styleUrls: ['./nurse-list.component.css']
})
export class NurseListComponent implements OnInit {
  nurseList! : BackOfficeNurseViewCustom[];  
  clinicList! : BackOfficeClinicView[];
  loading: boolean = true;
  record?: any;
  totalItemsNurse: number = 0;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_NURSE'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_NURSE_V2'), value: '' }

  autocompleteConfig = {
    name: this.getTranslation('FILTER_CLINIC'),
    attr: 'clinicName' as const,
    array: this.clinicList,
    numberToShow: 6,
    value : null as any,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_CLINIC'),
  }

  tableConfig =  {
    actions: true,
    gridData: this.nurseList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
      {name: this.getTranslation('CENTER_GROUP_LABEL'), field: 'clinicName'},
      {name: this.getTranslation('ORDERMANAGER_STATUS'), field: 'registrationCodeStatus'},
      {name: this.getTranslation('LABEL_ISACTIVE_FILTEREDCLINIC'), field: 'createInFilterClinic', isHidden: true}
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsNurse
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private dateService: DateService,
    private router: Router,
    private editService: EditServiceService
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getFiltersBySession();

    this.getNurseList();

    this.getClinicList();
  }

  filterName?: string = '';
  filterClinic?: string = '';

  orderDirection?: string = "Surname_asc";
  currentPage: number = 1;

  anyResult: boolean = false;


  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getNurseList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterClinic = value.target.value.idClinic;
    }
    else{      
      this.filterClinic = '';      
    } 

    this.tableConfig.headerList[4].isHidden = this.filterClinic == null;
    this.getNurseList(); 
  }
 
  getNurseList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName, 
      idClinicSelected: this.filterClinic,
    }
    this.clientService.nurseGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.nurseList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.nurseList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderNurseChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getNurseList();
  }

  pageNurseChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getNurseList();
  }

  getClinicList() {
    let input: IBaseGetAllRQ = {
      pageIndex: undefined,
      sortOrder: "ClinicName_asc",
      searchString: "",
      city: ""
    }
    this.clientService.clinicGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        response.list?.forEach(x => x.clinicName = x.sapUuid + " " + x.clinicName);
        this.clinicList = response.list || [];
        this.autocompleteConfig.array = this.clinicList;
      } ,
      error=> console.log(error)
    );
  }

  getUpdatedNurseList(): any{
    return this.nurseList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToNurseDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/nurses/detail', event.record.idNurse]);
      this.saveFilters();
    }
  }

  nurseAdd() {
    this.router.navigate(['/nurses/add']);
  }

  bindButtons(nurseList: BackOfficeNurseViewCustom[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let nurse of nurseList){
      if(nurse.registrationCodeStatus == "PLANNED"){
        nurse.registrationCodeStatus = this.getTranslation("NS_" + nurse.registrationCodeStatus) + this.dateService.dateParse(nurse.datePlanned);;
      }
      else{
        nurse.registrationCodeStatus = this.getTranslation("NS_" + nurse.registrationCodeStatus);
      }
      nurse.createInFilterClinic = nurse.isActiveInClinic ? this.getTranslation("LABEL_CREATED") : "-";
      this.tableConfig.recordButtonConfigs.set(nurse,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };

    //ASSIGN PREVIOUS DROPDOWN FILTER
    var clinicSelected = JSON.parse(window.localStorage.getItem('filterApplied') || '{}');
    this.filterClinic = clinicSelected?.idClinic ?? '';
    this.autocompleteConfig.value = clinicSelected;

    //ASSIGN PREVIOUS SORT ORDER
    this.orderDirection = window.localStorage.getItem('sortOrder') || this.orderDirection;

    //ASSIGN PREVIOUS LIST PAGE VISITED    
    this.currentPage = Number(window.localStorage.getItem('currentPage')) || 1;
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);

    //SET PREVIOUS DROPDOWN FILTER
    window.localStorage.setItem('filterApplied', JSON.stringify(this.autocompleteConfig.value || '{}'));

    //SET PREVIOUS SORT ORDER
    window.localStorage.setItem('sortOrder', this.orderDirection!);

    //SET PREVIOUS LIST PAGE VISITED
    window.localStorage.setItem('currentPage', this.currentPage!.toString());
  }

}