import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminUserRS, ClientService, EntityRQ } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.css']
})
export class AdminDetailComponent implements OnInit {
  id?: string;
  adminDetail?: AdminUserRS;
  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  loading: boolean = true;

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getAdminDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/admins");
  }

  EditAdmin() {
    this.router.navigate(['/admins/edit', this.id]);
  }

  getAdminDetail(){
    this.loading = true;
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.adminUserGet(data).subscribe(
      response =>{
        this.adminDetail = response;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  DeactivateAdmin(){

    this.modalService.setModalData({ 
      title: (this.adminDetail?.dateDeactivated) ? "ADMIN_DETAIL_REACTIVATE_MODAL_TITLE" : "ADMIN_DETAIL_DEACTIVATE_MODAL_TITLE",
      description: (this.adminDetail?.dateDeactivated) ? "ADMIN_DETAIL_REACTIVATE_MODAL_DESC" : "ADMIN_DETAIL_DEACTIVATE_MODAL_DESC",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.adminDetail?.dateDeactivated ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.adminDetail?.dateDeactivated ? 
          this.getTranslation('ADMIN_DETAIL_REACTIVATE_MODAL_BTN') : this.getTranslation('ADMIN_DETAIL_DEACTIVATE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        
        if(this.adminDetail?.dateDeactivated){
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.adminUserReactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
        else{
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.adminUserDeactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
      }
     })

    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.adminDetail?.dateDeactivated) ? "ADMIN_DETAIL_REACTIVATE_MODAL_SUCCESS" : "ADMIN_DETAIL_DEACTIVATE_MODAL_SUCCESS",
      actionToDo: () => {
        this.getAdminDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

}
