<div class="autocomplete-container">
  <input
    [type]="searchBarConfig.type"
    id="search-bar"
    class="search-bar empty"
    [ngClass]="searchBarConfig.class"
    [placeholder]="searchBarConfig.placeholder"
    [attr.name]="searchBarConfig.inputName"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
    [inputFormatter]="formatterInput"
    [resultFormatter]="formatter"
    [editable]='false'
    [focusFirst]='false'
    (selectItem)="emit($event)"
    (keyup)="addClass($event)"
    (click)="show()"
    [disabled]="searchBarConfig.disabled"/>
</div>
<img src="../../../assets/icons/close.svg" id="close-icon" class="close-icon" (click)="clear()">
