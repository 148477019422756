import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() toggleButtonConfig: any;
  @Output() radioOutput : EventEmitter<any> = new EventEmitter<any>();

  onChange(){
    this.toggleButtonConfig.value = !this.toggleButtonConfig.value;
    this.radioOutput.emit(event);
  }

}
