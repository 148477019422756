<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ 'RELEASE_NOTE_ADD_TITLE' | translate}}</h2>

        <form [formGroup]="formReleaseNoteAdd">

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formReleaseNoteInfo?.autocompleteReleaseNoteTypeConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formReleaseNoteInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formReleaseNoteInfo.input[1]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>            

            <div class="row my-2">
                <div class="col-md-12 col-12">
                    <app-input [inputConfig]="formReleaseNoteInfo.input[2]" [formControlInput]="formReleaseNoteAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div *ngFor="let country of countryList; let i = index" [attr.data-index]="i">
                <app-checkbox [checked]="country.isChecked" (check)="checkCountry(country)" [text]="country.countryName"></app-checkbox>

                <div *ngIf="country.isChecked">
                    <app-input [inputConfig]="formReleaseNoteInfo.input[i+3]" [formControlInput]="formReleaseNoteAdd" (keyup)="inputCountryValues($event)"></app-input>
                </div>
            </div>

            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>

            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveReleaseNote()"></app-button>
                </div>
            </div>

            <br/>

        </form>
    </div>
</ng-template>