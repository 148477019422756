<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{'SIDEBAR_ADMINS' | translate}}</h2>
            </div>
            <div class="col-6">
                <app-button (click)="adminAdd()" [buttonConfig]="btnConfig"></app-button>
            </div>
        </div>

        <div *ngIf="anyResult else noResultContent">
            <div class="row mt-2">
                <div class="col-lg-4 col-md-auto">
                    <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)">
                    </app-search-field>
                </div>
                <div class="col-lg-4 col-md-auto"></div>
                <div class="col-lg-4 col-md-auto">
                    <app-autocomplete-dropdown [autocompleteConfig]="autocompleteConfig"
                        (selected)="applyFilter($event)"></app-autocomplete-dropdown>
                </div>
            </div>
            <app-table [tableConfig]="tableConfig" (orderChanged)="orderAdminChanged($event)"
                (onClick)="GoToAdminDetail($event)" (pageChanged)="pageAdminChanged($event)"></app-table>
        </div>

        <ng-template #noResultContent>
            <div class="text-center noResult">
                <svg-icon class="icon" src="../../../../../assets/icons/applicationuser-figure.svg"></svg-icon>
                <p class="alert">{{ 'ADMIN_USER_NORESULT' | translate }}</p>
                <p class="desc">{{ 'ADMIN_USER_NORESULT_DESC' | translate }}</p>
            </div>
        </ng-template>
    </ng-template>
</div>