import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() checked?: boolean;
  @Input() isEnabled?: boolean = true;
  @Input() text: any;
  @Output() check : EventEmitter<any> = new EventEmitter<any>();
  @Input() extraClass?: string

  emitCheckValue(){
    if(this.isEnabled){
      this.checked = !this.checked;
      this.checked ? this.check.emit(true) : this.check.emit(false);
    }
  }

}
