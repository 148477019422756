import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from 'src/app/core/services/navbar.service';
import { TabService } from 'src/app/core/services/tab.service';
import { AuthenticationService, ClientService, UserIdentityGetLoggedUserRS} from 'src/app/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { EditServiceService } from 'src/app/core/services/edit.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(
    private authService : AuthenticationService,
    private tabService: TabService,
    private navbarService : NavbarService,
    private translate: TranslateService,
    private smartModal : NgxSmartModalService,
    private clientService: ClientService,
    private router: Router,
    private editService: EditServiceService,
  ) { }

  profileConfiguration: any;
  userInitials?: string = ''
  userCompleteName?: string = ''
  role?: string = ''
  userEmail?: string = ''
  user: UserIdentityGetLoggedUserRS = {} as UserIdentityGetLoggedUserRS;
  
  environment = environment;

  ngOnInit(): void {
    Object.assign(this.user, this.authService.currentUserValue);
    this.authService.currentUser.subscribe(
      x=>{
        if(JSON.stringify(x) != '{}'){
          this.userInitials = (x.name?.charAt(0) + x.surname!.charAt(0));
          this.userCompleteName =  x.name + " " + x.surname;
          this.role = this.getTranslation("ROLE_" + x.role!);
          this.userEmail = x.email;
        }

        this.profileConfiguration ={
          title:[
            {width: 100, text: this.userCompleteName, size: 1.125, class:'edit-title p-0 mb-0 mt-1'},
            {width: 100, text: this.role, size: 1.125, class:'edit-title p-0 mb-0 mt-1'},
            {width: 100, text: this.getTranslation('MY_INFORMATION_LABEL'), size: 1.125, class:'edit-title p-0 m-0'},
            {width: 100, text: this.getTranslation('LANGUAGE_SETTINGS_LABEL'), size: 1.125, class:'edit-title p-0 m-0'},
            {width: 100, text: this.getTranslation('PASSWORD_SETTINGS_LABEL'), size: 1.125, class:'edit-title p-0 m-0'},
            {width: 100, text: this.getTranslation('FAQ_SETTINGS_LABEL'), size: 1.125, class:'edit-title p-0 m-0'},
            {width: 100, text: this.getTranslation('LOGOUT_LABEL'), size: 1.125, class:'edit-title p-0 m-0'},
          ],
          buttons:[
            {
              bclass : 'btn edit-button p-inh',
              type : 'edit'
            },
            {
              bclass : 'btn edit-button bg-trp p-inh',
              type : 'edit'
            },
            {
              bclass : 'btn edit-button p-inh',
              type : 'logout'
            },
          ]
        }
      }
    )
  }

  logout(){
    this.tabService.emptyTabs();
    this.authService.logout(false);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  openProfileInformation(operation: any){
    this.navbarService.setProfileInfoType(operation);
    this.smartModal.getModal('patientProfileModal').open();
  }

  changePasscode(){
    this.editService.showTemporaryPasswordTemplate = false;
    this.router.navigate(['/changePassCode']);    
  }

  openFAQ(){
    this.smartModal.getModal('faqModal').open();
  }
}
