import * as _ from "lodash"
import { FilterEmit } from "src/app/shared/filters/filters.component"


/**
 * Check if there are rome filter selected
 * @param data 
 * @returns 
 */
export function isSomeFilterSelected(data: FilterEmit){
    if(!data || data.currentFiltersList.length <= 0) return false
    return data.currentFiltersList.length > 0;
}

export function executeFilter(data: FilterEmit, fullArray: any[]){
    let finalArray: any[] = []
    data.currentFiltersList.forEach(filter=>{
      finalArray = getTaskListWithFilterApplied(fullArray, finalArray,filter,filter.filterKey)
    })
    if(!isSomeFilterSelected(data) && finalArray.length <= 0) return fullArray
    return finalArray;
  }

function getTaskListWithFilterApplied(
    fullArray: any[], 
    filterArray:any[], 
    filter: {filterName: string, values: any[], customProjection?: ((object: any, key: any) => boolean)},
    key: string, 
  ){
    let isFilteredArrayEmpty = filterArray.length <= 0
    let temporary: any[] = []
    filter.values.forEach(val=>{
      if(isFilteredArrayEmpty){
        filterArray = cloneArrayBasedOnValue(fullArray,filterArray,val,key, filter.customProjection);
      }else{
        temporary = cloneArrayBasedOnValue(filterArray,temporary,val,key, filter.customProjection)
      }
    })
    if(!isFilteredArrayEmpty){
      filterArray = _.cloneDeep(temporary);
    }
    return _.cloneDeep(filterArray)
  }


function cloneArrayBasedOnValue(arrayWhereFilter: any[], arrayToConcat: any[], value: any, key: string, customProjection?: ((object: any, key: any) => boolean)){
    if(customProjection){
        return _.cloneDeep(arrayToConcat.concat(arrayWhereFilter.filter(x=> customProjection(x, value))))
    }
    return _.cloneDeep(arrayToConcat.concat(arrayWhereFilter.filter(x=> x[key] === value)))
}
