import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css']
})
export class RadioButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() radioButtonConfig: any;
  @Output() radioOutput : EventEmitter<any> = new EventEmitter<any>();

  configIsArray?:boolean;
  onChange(event : any){
    if(!this.radioButtonConfig.disabled){
      this.radioButtonConfig.value = event.target.value;
      this.radioOutput.emit(event);
    }
  }

}
