import { RadioButtonInterface } from './../../../../core/models/radiobuttons.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-simple-radiobuttons',
  templateUrl: './simple-radiobuttons.component.html',
  styleUrls: ['./simple-radiobuttons.component.css']
})
export class SimpleRadiobuttonsComponent implements OnInit, RadioButtonInterface {

  constructor() { }

  @Input() radioButtonConfig!: RadioButtonConfig;
  @Output() onRadioSelect = new EventEmitter();
  isFocusedValue!: any;

  ngOnInit(): void {
    this.isFocusedValue = null
  }

  emitValue(value: any): void {
    this.onRadioSelect.emit({target:{name: this.radioButtonConfig.radioButtonName, value: value}});
  }

  getBoxClass(): string {
    let finalClass = ''
    finalClass = this.radioButtonConfig.extraClass || '';
    if(this.radioButtonConfig.disabled){
      finalClass = finalClass + ' disabled';
    }
    return finalClass;
  }

  isSelected(value: any): any {
    
  }

  onInputFocus(val: any){
    this.isFocusedValue = val
  }

}

export class RadioButtonConfig{
  radioButtonName!: string
  withDivider?: boolean = true
  selectedValue!: any
  values!: RadioButtonValues[]
  extraClass?: string
  disabled?: boolean = false
  withLessMargin?: boolean = false
}


export class RadioButtonValues{
  label!: string
  value!: any
  underText?: string
  sideText?: string
}