import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService, BackOfficeDemoUserView, DemoUserRSV2, INurseDeactivateRQ, NurseDeactivateRQ, IPatientDeactivateRQ, PatientDeactivateRQ, INurseSendRegistrationCodeRQ, NurseSendRegistrationCodeRQ, IPatientNewAppInvitationRQ, PatientNewAppInvitationRQ, EntityRQ } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-demo-user-detail',
  templateUrl: './demo_user-detail.component.html',
  styleUrls: ['./demo_user-detail.component.css']
})
export class DemoUserDetailComponent implements OnInit {
  id?: string;
  demoUserDetail?: DemoUserRSV2;
  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  isPatientVisible: boolean = false;
  isNurseVisible: boolean = false;

  loading: boolean = true;

  nurseAppAvailable: boolean = false;
  nursePlannedModal: boolean = true;

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private editService: EditServiceService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getDemoUserDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/demo-users");
  }

  EditDemoUser() {
    this.router.navigate(['/demo-users/edit', this.id]);
  }

  getDemoUserDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.demoUserGetV2(data).subscribe(
      response =>{
        this.demoUserDetail = response;

        this.nurseAppAvailable = !this.utilsService.getParametersAppBeforeAuth().some((x: { parameterKey: string; countryIsoCode: string | undefined; }) => x.parameterKey == 'nurse_app_available' && x.countryIsoCode == this.demoUserDetail?.countryIsoCode);

        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
      window.location.reload();
    }, 5000);
  }

  btnConfigCreateDemo = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_ADD_DEMO_USER'),
    width: '30px',
    height: '30px'
  }

  DeactivateDemoUser(){
    
    this.modalService.setModalData({ 
      title: (this.demoUserDetail?.dateDeactivated) ? "DEMO_USER_DETAIL_REACTIVATE_MODAL_TITLE" : "DEMO_USER_DETAIL_DEACTIVATE_MODAL_TITLE",
      description: (this.demoUserDetail?.dateDeactivated) ? "DEMO_USER_DETAIL_REACTIVATE_MODAL_DESC" : "DEMO_USER_DETAIL_DEACTIVATE_MODAL_DESC",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.demoUserDetail?.dateDeactivated ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.demoUserDetail?.dateDeactivated ? 
          this.getTranslation('DEMO_USER_DETAIL_REACTIVATE_MODAL_BTN') : this.getTranslation('DEMO_USER_DETAIL_DEACTIVATE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        
        if(!this.demoUserDetail?.dateDeactivated){
          //DEACTIVATE NURSE
          let input: INurseDeactivateRQ = {
            idNurse: this.demoUserDetail?.idNurse,
          }

          this.clientService.nurseDeactivate(new NurseDeactivateRQ(input)).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
        else{
          const data = new EntityRQ({ idEntity: this.demoUserDetail!.idNurse });
          this.clientService.nurseReactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
      }
     })

    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.demoUserDetail?.dateDeactivated) ? "DEMO_USER_DETAIL_REACTIVATE_MODAL_SUCCESS" : "DEMO_USER_DETAIL_DEACTIVATE_MODAL_SUCCESS",
      actionToDo: () => {
        this.getDemoUserDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

  showPatient(){
    this.isPatientVisible = !this.isPatientVisible;
  }

  showNurse(){
    this.isNurseVisible = !this.isNurseVisible;
  }

  createDemoNurse(event: any = null){
    this.loading = true;
    let input: INurseSendRegistrationCodeRQ = {
      idNurse: this.demoUserDetail?.idNurse,
      datePlanned: event
    }
    this.clientService.backOfficeNurseSendRegistrationCode(new NurseSendRegistrationCodeRQ(input)).subscribe(
      response =>{
        this.loading = false;

        this.getDemoUserDetail();

        this.snackText = event == undefined ? this.getTranslation("NURSE_DETAIL_CODE_SENT") : this.getTranslation("NURSE_DETAIL_CODE_PLANNED");

        this.ShowSnackBar();
      } ,
      error=> console.log(error)
    );
  }

  showPlanningCodeMode(isNurse: boolean = true){  
    this.nursePlannedModal = isNurse;
    this.ngxModal.getModal('planRegistrationCodeModal').open();
  }

  createDemoPatient(event: any = null){
    this.loading = true;
    let input: IPatientNewAppInvitationRQ = {
      idPatient: this.demoUserDetail?.idPatient,
      datePlanned: event
    }
    this.clientService.patientNewAppInvitation(new PatientNewAppInvitationRQ(input)).subscribe(
      response =>{
        this.loading = false;

        this.getDemoUserDetail();

        this.snackText = event == undefined ? this.getTranslation("NURSE_DETAIL_CODE_SENT") : this.getTranslation("NURSE_DETAIL_CODE_PLANNED");

        this.ShowSnackBar();
      } ,
      error=> console.log(error)
    );
  }

  planSendCode(event: any = null){
    if(event.isNurse){
      this.createDemoNurse(event.datePlanned);
    }
    else{
      this.createDemoPatient(event.datePlanned);
    }
  }
}
