<div *ngIf="alertConfig.msg !== ''">
  <div [ngClass]="alertConfig.aclass" role="alert">
    <div class="row">
      <div class="col-11">
        <h5>{{alertConfig.title}}</h5>
      </div>
      <div class="col-1" *ngIf="alertConfig.icon" [ngClass]="alertConfig.icon.class">
        <svg-icon src="../../../assets/icons/warning.svg"></svg-icon>
      </div>
    </div>
    <div class="row pl-3">
      {{alertConfig.msg}}
    </div>
    <div *ngIf="alertConfig.buttons">
      <div *ngIf="!alertConfig.invertButton; else normal" class="alert-buttons">
        <button *ngIf="!alertConfig.dontClose" [ngClass]="alertConfig.buttons[0].class" [value]="alertConfig.buttons[0].value" (click)="emitValue($event)" data-dismiss="modal" aria-label="Close" data-toggle="modal" [attr.data-target]="alertConfig.toggle">{{alertConfig.buttons[0].text}}</button>
        <button *ngIf="alertConfig.dontClose" [ngClass]="alertConfig.buttons[0].class" [value]="alertConfig.buttons[0].value" (click)="emitValue($event)">{{alertConfig.buttons[0].text}}</button>
        <button [ngClass]="alertConfig.buttons[1].class" [value]="alertConfig.buttons[1].value" (click)="emitValue($event)">{{alertConfig.buttons[1].text}}</button>
      </div>
      <ng-template #normal>
        <div *ngIf="alertConfig.noModal; else withModal">
          <div class="alert-buttons">
            <button [ngClass]="alertConfig.buttons[0].class" [value]="alertConfig.buttons[0].value" (click)="emitValue($event)">{{alertConfig.buttons[0].text}}</button>
            <button *ngIf="!alertConfig.dontClose" [ngClass]="alertConfig.buttons[1].class" [value]="alertConfig.buttons[1].value" (click)="emitValue($event)">{{alertConfig.buttons[1].text}}</button>
            <button *ngIf="alertConfig.dontClose" [ngClass]="alertConfig.buttons[1].class" [value]="alertConfig.buttons[1].value" (click)="emitValue($event)">{{alertConfig.buttons[1].text}}</button>
          </div>
        </div>
        <ng-template #withModal>
          <div class="alert-buttons">
            <button [ngClass]="alertConfig.buttons[0].class" [value]="alertConfig.buttons[0].value" (click)="emitValue($event)">{{alertConfig.buttons[0].text}}</button>
            <button *ngIf="!alertConfig.dontClose" [ngClass]="alertConfig.buttons[1].class" [value]="alertConfig.buttons[1].value" (click)="emitValue($event)" data-dismiss="modal" aria-label="Close" data-toggle="modal" [attr.data-target]="alertConfig.toggle">{{alertConfig.buttons[1].text}}</button>
            <button *ngIf="alertConfig.dontClose" [ngClass]="alertConfig.buttons[1].class" [value]="alertConfig.buttons[1].value" (click)="emitValue($event)">{{alertConfig.buttons[1].text}}</button>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div *ngIf="alertConfig.singleButton" class="single-button">
      <button [ngClass]="alertConfig.singleButton.class" [value]="alertConfig.singleButton.value" data-dismiss="modal" aria-label="Close" data-toggle="modal">{{alertConfig.singleButton.text}}</button>
    </div>
  </div>
</div>
