import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import diff from 'simple-text-diff'

@Component({
  selector: 'app-compare-entities-modal',
  templateUrl: './compare-entities-modal.component.html',
  styleUrls: ['./compare-entities-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CompareEntitiesModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private smartModal: NgxSmartModalService
  ) { }

  selectedRecords = [];
  skipColumns: string[] = ["VersionDate", "synchDate", "idEntry"];
  keys: any = [];
  subscriptions: Subscription[] = []

  ngOnInit(): void {
    this.subscriptions = []
  }

  ngAfterViewInit(): void {
    let modalDataSubscription = this.smartModal.getModal('compareEntityModal').onOpen.subscribe(()=>{

      this.selectedRecords = this.smartModal.getModal('compareEntityModal').getData();

      this.selectedRecords.sort((a: any, b: any) => {

        if(a.hasOwnProperty("VersionDate")){
          return new Date(a["VersionDate"]).getTime() - new Date(b["VersionDate"]).getTime()
        }

        if(a.hasOwnProperty("synchDate")){
          return new Date(a["synchDate"]).getTime() - new Date(b["synchDate"]).getTime()
        }

        return a - b;
      });

      var firstObj = this.selectedRecords[0];
      var lastObj = this.selectedRecords[this.selectedRecords.length - 1];

      var keysArrays = Object.keys(firstObj).concat(Object.keys(lastObj));
      this.keys = keysArrays.filter((item, pos) => keysArrays.indexOf(item) === pos);
    })

    this.subscriptions.push(modalDataSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  closeModal(){
    this.smartModal.getModal('compareEntityModal').close();
  }

  calcDiff(oldValue: any, newValue: any, columnName: any){
    if(this.skipColumns.includes(columnName) || (newValue == "" && oldValue == ""))
      return newValue;
      
    return diff.diffPatch(oldValue, newValue).after;
  }
}
