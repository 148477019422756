import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService, EntityRQ, NotificationRS } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.css']
})
export class NotificationDetailComponent implements OnInit {
  id?: string;
  notificationDetail?: NotificationRS;
  showSnackBar?: boolean;
  snackText?: string;

  loading: boolean = true;

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private ngxModal: NgxSmartModalService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getNotificationDetail();
  }

  backToList(){
    this.router.navigateByUrl("/notifications");
  }
  
  getNotificationDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.notificationGet(data).subscribe(
      response =>{
        this.notificationDetail = response;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getAttachment(){
    
    const data = new EntityRQ({ idEntity: this.notificationDetail?.attachment });
    this.clientService.notificationAttachmentDownload(data).subscribe(
      response =>{
        
        var blob = this.utilsService.base64toBlob(response.stream);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = response?.fileName ?? "";
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }
  
  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
