import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService, EntityRQ, ReleaseNoteGetBackOfficeRS } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-release-note-detail',
  templateUrl: './release_note-detail.component.html',
  styleUrls: ['./release_note-detail.component.css']
})
export class ReleaseNoteDetailComponent implements OnInit {
  id?: string;
  releaseNoteDetail?: ReleaseNoteGetBackOfficeRS;

  loading: boolean = true;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    text : this.getTranslation('RELEASE_NOTE_PUBLISH'),
    width: '50px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private editService: EditServiceService,
    private modalService: NxgCustomModalService,
    private ngxModal: NgxSmartModalService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getReleaseNoteDetail();
  }

  backToList(){
    this.router.navigateByUrl("/release-notes");
  }

  EditAdmin() {
    this.router.navigate(['/release-notes/edit', this.id]);
  }

  getReleaseNoteDetail(){
    this.loading = true;
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.releaseNoteGet(data).subscribe(
      response =>{
        this.releaseNoteDetail = response;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  setDateReleased(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.releaseNoteSetDateReleased(data).subscribe(
      response =>{
        this.ShowSuccessModal();
      } ,
      error=> console.log(error)
    );
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: "RELEASE_NOTE_PUBLISH_MODAL_SUCCESS",
      actionToDo: () => {
        this.getReleaseNoteDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }

}
