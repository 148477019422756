import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-solutions-table',
  templateUrl: './solutions-table.component.html',
  styleUrls: ['./solutions-table.component.css']
})
export class SolutionsTableComponent implements OnInit {

  @Output() solutionEventEdit = new EventEmitter<any>();
  @Output() solutionEventDelete = new EventEmitter<any>();
  @Output() solutionEventAdd = new EventEmitter<any>();
  @Input() solutionsTableConfig: any = {} as any || [];

  CAPDList?: any[] = [];
  APDList?: any[] = [];

  constructor(
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    if(this.solutionsTableConfig.solutionsListSaved.length > 0){
      this.CAPDList = this.solutionsTableConfig.solutionsListSaved.filter((x: { treatmentForm: string; })=> x.treatmentForm === 'CAPD');
      this.APDList = this.solutionsTableConfig.solutionsListSaved.filter((x: { treatmentForm: string; })=> x.treatmentForm === 'APD');
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.solutionsTableConfig.solutionsListSaved.length > 0){
      this.CAPDList = this.solutionsTableConfig.solutionsListSaved.filter((x: { treatmentForm: string; })=> x.treatmentForm === 'CAPD');
      this.APDList = this.solutionsTableConfig.solutionsListSaved.filter((x: { treatmentForm: string; })=> x.treatmentForm === 'APD');
    }
  }
  solutionEventEmit(action: string, value: any, flag: boolean, event: any){
    switch (action){
      case 'add':
        this.solutionEventAdd.emit()
      break;
      case 'edit':
        this.solutionEventEdit.emit(value);
      break;
      case 'delete':
        this.solutionEventDelete.emit({value, flag, event})
      break;
      default:
        this.solutionEventEdit.emit(value)
    }
  }

  isNumber(val : any): boolean{
    return typeof val === 'number';
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
