<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="clinicSettings">

            <form [formGroup]="formSecurityAdd" class="my-4">

            <p>{{ clinicSettings?.clinicName }}</p>

            <h2>{{ 'CLINIC_SECURITY_SETTINGS' | translate }}</h2>

            <p>{{ 'CLINIC_SECURITY_SETTINGS_NOTE' | translate }}</p>

            <br/>

            <!-- APPLICATION SECTION -->
            <b>{{ 'CLINIC_SECURITY_APPLICATION' | translate }}</b>

            <div class="row my-2">
                <div class="col-12">
                    <app-checkbox [isEnabled]="false" [checked]="isWebApp" (check)="checkWebApp()" [text]="getTranslation('CLINIC_SECURITY_WEB')"></app-checkbox>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-12">
                    <app-checkbox [isEnabled]="false" [checked]="isMobileApp" (check)="checkMobileApp()" [text]="getTranslation('CLINIC_SECURITY_MOBILE')"></app-checkbox>
                </div>
            </div>
            
            <hr/>
            <br/>

            <!-- TWO FACTOR SECTION -->
            <div class="row my-2">
                <div class="col-md-4 col-8">
                    <b>{{ 'CLINIC_SECURITY_TWOFACTOR' | translate }}</b>
                </div>
                <div class="col m-auto">
                    <app-info-tooltip [description]="'CLINIC_SECURITY_TWOFACTOR_NOTE' | translate"></app-info-tooltip>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-8">
                    <div class="col-12"><app-radio-button [radioButtonConfig]="formSecurityInfo?.radioButtonIsTrusted" (radioOutput)="inputValues($event)"></app-radio-button></div>
                </div>
                <div class="col m-auto">
                    <i *ngIf="!isTrustedDescVisible" (click)="isTrustedDescVisible = true" class="fas fa-chevron-down edit_link"></i>
                    <i *ngIf="isTrustedDescVisible" (click)="isTrustedDescVisible = false" class="fas fa-chevron-up edit_link"></i>
                </div>
            </div>

            <div *ngIf="isTrustedDescVisible" class="row my-2">
                <div class="col-md-4 col-4">
                    <hr/>
                    <p class="desc-gray" [innerHtml]="'CLINIC_SECURITY_TRUSTED_NOTE' | translate"></p>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-8">
                    <div class="col-12"><app-radio-button [radioButtonConfig]="formSecurityInfo?.radioButtonIsOTP" (radioOutput)="inputValues($event)"></app-radio-button></div>
                </div>
                <div class="col m-auto">
                    <i *ngIf="!isOTPDescVisible" (click)="isOTPDescVisible = true" class="fas fa-chevron-down edit_link"></i>
                    <i *ngIf="isOTPDescVisible" (click)="isOTPDescVisible = false" class="fas fa-chevron-up edit_link"></i>

                    &nbsp;&nbsp;
                    <app-warning-tooltip [description]="'LABEL_NOT_RECOMMENDED' | translate"></app-warning-tooltip>
                </div>
            </div>

            <div *ngIf="isOTPDescVisible" class="row my-2">
                <div class="col-md-4 col-4">
                    <hr/>
                    <p class="desc-gray">{{ 'CLINIC_SECURITY_OTP_NOTE' | translate }}</p>
                </div>
            </div>

            <hr/>
            <p class="desc-gray col-5">{{ 'CLINIC_SECURITY_TWOFACTOR_DESC' | translate }}</p>

            <br/><br/>

            <!-- PASSWORD LENGHT SECTION -->
            <div class="row my-2">
                <div class="col-md-4 col-8">
                    <b>{{ 'CLINIC_SECURITY_PASSWORD' | translate }}</b>
                </div>
                <div class="col m-auto">
                    <app-info-tooltip [description]="'CLINIC_SECURITY_PASSWORD_NOTE' | translate"></app-info-tooltip>
                </div>
            </div>
            
            <div class="col-12"><app-radio-button [radioButtonConfig]="formSecurityInfo?.radioButtonPassword" (radioOutput)="inputValues($event)"></app-radio-button></div>

            <br/><br/>

            <!-- PASSWORD DURATION SECTION -->
            <div class="row my-2">
                <div class="col-md-4 col-8">
                    <b>{{ 'CLINIC_SECURITY_DURATION' | translate }}</b>
                </div>
                <div class="col m-auto">
                    <app-info-tooltip [description]="'CLINIC_SECURITY_DURATION_NOTE' | translate"></app-info-tooltip>
                </div>
            </div>
            
            <div class="row my-2">
                <div class="col-md-4 col-8">
                    <app-radio-button [radioButtonConfig]="formSecurityInfo?.radioButtonDuration" (radioOutput)="inputValues($event)">
                    </app-radio-button>
                </div>
                <div class="col-md-8 col-4">
                    <app-warning-tooltip style="position: absolute; bottom: 0px;" [description]="'CLINIC_SECURITY_DURATION_NOT_RECOMMENDED' | translate"></app-warning-tooltip>                    
                </div>
            </div>
            <br/><br/>

            <!-- PRESCRIPTION SECTION -->
            <b>{{ 'CLINIC_SECURITY_PRESCRIPTION' | translate }}</b>
            
            <div class="row my-2">
                <div class="col-3">
                    {{ 'CLINIC_SECURITY_SIGNATURE' | translate }}
                </div>
                <div class="col-3">
                    <app-toggle [toggleButtonConfig]="formSecurityInfo?.toggleButtonConfig" (radioOutput)="checkDefaultSettings()"></app-toggle>
                </div>
            </div>

            <hr/>

            <p class="desc-gray col-5">{{ 'CLINIC_SECURITY_SIGNATURE_NOTE' | translate }}</p>
            
            <br/>

            <p *ngIf="!clinicSettings.isSignatureNeededEnabled" class="desc-gray col-5"><i>{{ countryBasedTranslation }}</i></p>

            
            <br/><br/>

            <!-- AUTOMATIC LOGOUT SECTION -->
            <div *ngIf="isWebApp">
                
                <div class="row my-2">
                    <div class="col-md-4 col-8">
                        <b>{{ 'CLINIC_SECURITY_LOGOUT' | translate }}</b>
                        <br/>
                        <b>{{ 'CLINIC_SECURITY_WEB' | translate }}</b>
                    </div>
                    <div class="col m-auto">
                        <app-info-tooltip [description]="'CLINIC_SECURITY_LOGOUT_NOTE' | translate"></app-info-tooltip>
                    </div>
                </div>

                <div class="col-12"><app-radio-button [radioButtonConfig]="formSecurityInfo?.radioButtonLogout" (radioOutput)="inputValues($event)"></app-radio-button></div>

                <br/><br/>
            </div>

            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="saveBtnConfig" (click)="saveClinic()"></app-button>
                </div>
            </div>

            <br/>
        </form>
    </div>

</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>