<ngx-smart-modal #patientProfileModal identifier="patientProfileModal" [ngClass]="modalType" class="status-modal" id="pr-modal" [closable]="false" [dismissable]="false" [backdrop]="true" [dismissable]="false">
  <div class="modal-header">

    <h2>{{modalTitle}}</h2>

    <app-round-button [type]="'CLOSE'" (onClick)="closeModal()"></app-round-button>
  </div>
  <div [ngSwitch]="modalType" [ngClass]="modalType + 'class'">
      <!-- MY INFORMATIONS MODAL CONTENT -->
      <div *ngSwitchCase="'userInfo'">
          <div class="container-fluid">
              <div style="text-align: right;">
                <app-button [buttonConfig]="actionsButton[1]" (click)="editMode()"></app-button>
              </div>

              <form [formGroup]="formContactEdit">
                  
                <h4 *ngIf="!isEditMode">{{email}}</h4>

                <app-input style="width: 100%;" *ngIf="isEditMode" [inputConfig]='formContactInfo.input[0]' [formControlInput]="formContactEdit" (keyup)="inputValues($event)"></app-input>

                <br/>

                <h4 *ngIf="!isEditMode">{{mobilePrefix + " " + mobile}}</h4>
                
                <div *ngIf="isEditMode" class="row my-2">
                  <div class="col-md-4 col-4" style="margin-top: 5px;">
                    <app-autocomplete-dropdown [autocompleteConfig]="formContactInfo.autocompleteCountryPhonePrefixConfig" (selected)="inputValues($event)"></app-autocomplete-dropdown>
                  </div>
                  <div class="col-md-8 col-8">
                    <app-input [inputConfig]='formContactInfo.input[1]' (keyup)="inputValues($event)"></app-input>
                  </div>
                </div>

                <app-error-box *ngIf="this.validationError"
                  [text]="validationError"></app-error-box>

                <br/><br>

                <div *ngIf="isEditMode" class="row row justify-content-center">
                  <app-button [buttonConfig]="saveBtnConfig" (click)="saveContacts()"></app-button>
                </div>

              </form>

              <br/>

              <div>
                <h2>{{ 'ROLE_' + role | translate }}</h2>
                <div class="release-content">
                  <ul class="ml-4">
                    <li *appHasRole="['SUPPORT_USER']">{{ 'SIDEBAR_REQUESTS' | translate }}</li>
                    <li>
                      {{ 'SIDEBAR_CLINICS' | translate }}:
                      <ol>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']">{{ 'BTN_ADD_CLINIC' | translate }}</li>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']">{{ 'COMMON_EDIT_DATA' | translate }}</li>
                      </ol>
                    </li>
                    <li>{{ 'SIDEBAR_NURSES' | translate }}:
                      <ol>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']">{{ 'BTN_ADD_NURSE' | translate }}</li>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']">{{ 'COMMON_EDIT_DATA' | translate }}</li>
                      </ol>
                    </li>
                    <li>{{ 'SIDEBAR_NEPHROLOGIST' | translate }}:
                      <ol>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']">{{ 'BTN_ADD_NEPHROLOGIST' | translate }}</li>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']">{{ 'COMMON_EDIT_DATA' | translate }}</li>
                      </ol>
                    </li>
                    <li>{{ 'SIDEBAR_PATIENTS' | translate }}:
                      <ol>
                        <li *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']">{{ 'COMMON_EDIT_DATA' | translate }}</li>
                      </ol>
                    </li>
                    <li *appHasRole="['SUPER_ADMIN']">{{ 'SIDEBAR_ADMINS' | translate }}</li>
                    <li *appHasRole="['SUPER_ADMIN','ADMIN','SUPPORT_USER']">{{ 'SIDEBAR_SPECIALISTS' | translate }}:
                      <ol>
                        <li>{{ 'BTN_ADD_SPECIALIST' | translate }}</li>
                        <li>{{ 'COMMON_EDIT_DATA' | translate }}</li>
                      </ol>
                    </li>
                    <li *appHasRole="['SUPER_ADMIN','ADMIN','SUPPORT_USER']">{{ 'SIDEBAR_SUPPORT_USERS' | translate }}:
                      <ol>
                        <li>{{ 'BTN_ADD_SUPPORT_USER' | translate }}</li>
                        <li>{{ 'COMMON_EDIT_DATA' | translate }}</li>
                      </ol>
                    </li>
                    <li *ngIf="isDemoUserManagementEnabled">{{ 'SIDEBAR_DEMO_USERS' | translate }}</li>
                    <li *appHasRole="['SUPER_ADMIN','ADMIN','CUSTOMER_SERVICE_USER']">{{ 'SIDEBAR_CALENDARS' | translate }}
                      <ol>
                        <li>{{ 'CALENDAR_ADD_TITLE' | translate }}</li>
                        <li>{{ 'CALENDAR_EDIT_TITLE' | translate }}</li>
                      </ol>
                    </li>
                    <li *appHasRole="['SUPER_ADMIN']">{{ 'SIDEBAR_NOTIFICATIONS' | translate }}</li>
                    <li *appHasRole="['SUPER_ADMIN','ADMIN']">{{ 'SIDEBAR_REPORTS' | translate }}</li>
                    <li *appHasRole="['SUPER_ADMIN','ADMIN']">{{ 'SIDEBAR_COUNTRIES' | translate }}:
                      <ol>
                        <li *appHasRole="['SUPER_ADMIN']">{{ 'BTN_ADD_COUNTRY' | translate }}</li>
                        <li *appHasRole="['SUPER_ADMIN']">{{ 'COMMON_EDIT_DATA' | translate }}</li>
                        <li>{{ 'COUNTRY_RESPONSIBLE_ADD_TITLE' | translate }}</li>
                      </ol>
                    </li>
                    <li *appHasRole="['SUPER_ADMIN','ADMIN']">{{ 'SIDEBAR_REQUESTS' | translate }}</li>
                  </ul>

                  <br/>
                </div>
              </div>
          </div>
      </div>
  
      <!-- LANGUAGE SETTINGS MODAL CONTENT -->
      <div *ngSwitchCase="'language'">
          <div class="container-fluid">
            <app-row [rowClass]="'mt-2'">
              <app-text [fontSizePx]="18" [text]="'CHOOSE_LANGUAGE_SUBTITLE_LABEL' | translate"></app-text>
            </app-row>
            <app-row [rowClass]="'mt-4'">
              <app-simple-radiobuttons (onRadioSelect)="getLanguageRadio($event)" [radioButtonConfig]="languageBlock?.radioButton"></app-simple-radiobuttons>
            </app-row>
          </div>
          <div class="row mt-2 text-center d-flex flex-row justify-content-around">
            <app-button [buttonConfig]="nextBtnConfig" (click)="changeLanguage()"></app-button>
          </div>
      </div>

      <!-- DEFAULT SWITCH -->
      <div *ngSwitchDefault>output2</div>
  </div>
  </ngx-smart-modal>
  
