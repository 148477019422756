import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ROUTER_INITIALIZER } from '@angular/router';
import { isEmpty } from 'lodash';
import { Role } from '../enum/roles';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService : AuthenticationService
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;

    if (currentUser && currentUser.role ) {
      
      //check if passcode is temporary
      if(currentUser.isPassCodeTemporary){
        if(route.url.find(x => x.path == "changePassCode")){
          return true;
        }

        this.router.navigate(['/changePassCode']);
        return false;
      }
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {

        if (currentUser.role == Role.AppSpecialist || currentUser.role == Role.SuperAdmin || currentUser.role == Role.Admin || currentUser.role == Role.CustomerService){
          this.router.navigate(['/clinics']);
          return true;
        }
        else{
          // role not authorised so redirect to home page
          this.router.navigate(['/requests']);
          return false;
        }        
      }
      return true;
    }
    
    if(route.url.find(x => x.path != "login")){
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    }
    return false;
}


}
