<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'CLINIC_ADD_TITLE' : 'CLINIC_EDIT_TITLE') | translate}}</h2>

        <div *ngIf="!isClinicSelected">

            <b>{{ 'CLINIC_ADD_DESC' | translate}}</b>

            <br/><br/>

            <div class="border-box p-4 col-6">
                <div class="row">
                <div class="col-auto">
                    <i style="color:black" class="fas fa-exclamation-circle"></i>
                </div>
                <div class="col-auto">
                    <b>{{ 'CLINIC_ADD_WARNING' | translate}}</b>
                </div>
                </div>
            </div>
        </div>

        <br/><br/>

        <form [formGroup]="formClinicAdd">

            <div *ngIf="!isClinicSelected && !isAddingNewSapClinic" class="row my-2">
                <div class="col-md-4">
                    <app-search-field [searchConfig]="formClinicInfo.searchNameConfig" (searchEvent)="searchNameFilter($event)" ></app-search-field>
                </div>
                <div class="col-md-3" >
                    <app-search-field [searchConfig]="formClinicInfo.searchSapCodeConfig" (searchEvent)="searchSapCodeFilter($event)" ></app-search-field>
                </div>
                <div class="col-md-3" >
                    <app-search-field [searchConfig]="formClinicInfo.searchCityConfig" (searchEvent)="searchCityFilter($event)" ></app-search-field>
                </div>
                <div class="d-flex align-items-center justify-content-end" *appHasRole="['SUPER_ADMIN','ADMIN']" [ngClass]="isAddingNewSapClinic ? 'col-md-12' : 'col-md-2'">
                    <app-button [buttonConfig]="addSAPClinigBtnConfig" (click)="isAddingNewSapClinic = true" ></app-button>
                </div>
                
            </div>
            
            <ng-container *ngIf="isAddingNewSapClinic && !isSapClinicChecked">
                <form class="row mt-2 mb-4" [formGroup]="formNewClinicFromSap">
                    <div class="col-md-4 my-1">
                        <app-input [inputConfig]="newSapClinicCodesInput[0]" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-4 my-1">
                        <app-input [inputConfig]="newSapClinicCodesInput[1]" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-4 my-1">
                        <app-input [inputConfig]="newSapClinicCodesInput[2]" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center mt-4">
                        <a (click)="resetSapSyncDataStatus()" class="mr-4"><i class="fas fa-chevron-left"></i><u class="edit_link"> {{ 'CLINIC_ADD_BACKRESULTS' | translate }} </u></a>
                        <app-button [buttonConfig]="continueButton" (click)="checkForSapClinicByCodes()"></app-button>
                    </div>
                </form>
            </ng-container>


            <ng-container *ngIf="isSapClinicChecked">
                <div class="row my-3" *ngFor="let inputList of sapSyncInputConfig; let index = index">
                    <div class="col-2 px-2">
                        <app-input [inputConfig]="inputList[0]" (keyup)="onNewSapSyncClinicDataInputValues($event, index)" ></app-input>
                    </div>
                    <div class="col-3 px-2">
                        <app-input [inputConfig]="inputList[1]" (keyup)="onNewSapSyncClinicDataInputValues($event, index)" ></app-input>
                    </div>
                    <div class="col-2 px-2">
                        <app-input [inputConfig]="inputList[2]" (keyup)="onNewSapSyncClinicDataInputValues($event, index)" ></app-input>
                    </div>
                    <div class="col-2 px-2">
                        <app-input [inputConfig]="inputList[3]" (keyup)="onNewSapSyncClinicDataInputValues($event, index)" ></app-input>
                    </div>
                    <div class="col-1 px-2">
                        <app-input [inputConfig]="inputList[4]" (keyup)="onNewSapSyncClinicDataInputValues($event, index)" ></app-input>
                    </div>
                    <div class="col-2 px-2" *appHasRole="['SUPER_ADMIN']">
                        <app-autocomplete-dropdown [autocompleteConfig]="autocompleteCountryConfigs[index]" (selected)="onNewSapSyncClinicDataInputValues($event,index)" ></app-autocomplete-dropdown>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-end align-items-center mt-4">
                    <span *ngIf="sapSyncCreationErrorMessage">{{sapSyncCreationErrorMessage}}</span>
                    <a (click)="resetSapSyncDataStatus()" class="mr-4"><i class="fas fa-chevron-left"></i><u class="edit_link"> {{ 'CLINIC_ADD_BACKRESULTS' | translate }} </u></a>
                    <app-button [buttonConfig]="saveButton" (click)="isAddingNewSapClinic = true" (click)="saveNewSapSyncClinic()"></app-button>
                </div>
            </ng-container>

            
            <app-table *ngIf="anyResult && !isClinicSelected && !isAddingNewSapClinic" [tableConfig]= "tableConfig" (orderChanged)="orderClinicChanged($event)" (onClick)="GoToClinicDetail($event)" (pageChanged)="pageClinicChanged($event)"></app-table>
           
            <!--FORM RESULT-->
            <div *ngIf="isClinicSelected">
                
                <div *ngIf="hierarchyClinic! && hierarchyClinic!.children!.length > 0">
                    <tree-node [node]="hierarchyClinic!"></tree-node>
                </div>

                <hr/>
                
                <div class="row my-2">
                    <b class="col-md-2 col-2">{{ 'LABEL_SAPCODE' | translate}}</b>
                    
                    <b class="col-md-6 col-6">{{ 'LABEL_CLINIC' | translate}}</b>
                
                    <b class="col-md-4 col-4">{{ 'CLINIC_DETAIL_ADDRESS' | translate}}</b>
                </div>

                <!-- SHIP TO CLINIC -->
                <div class="row my-2">
                    <b class="col-md-2 col-2">{{ 'CLINIC_SHIP_TO' | translate}}</b>
                </div>
                
                <div class="row my-2">
                    <div class="col-md-2 col-2">
                        <app-input [inputConfig]="formClinicInfo.input[0]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-6 col-6" [ngbTooltip]="formClinicInfo.input[1].value">
                        <app-input [inputConfig]="formClinicInfo.input[1]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-4 col-4">
                        <app-input [inputConfig]="formClinicInfo.input[2]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                </div>

                <!-- SOLD TO CLINIC -->
                <div class="row my-2">
                    <b class="col-md-2 col-2">{{ 'CLINIC_SOLD_TO' | translate}}</b>
                </div>

                <div class="row my-2">
                    <div class="col-md-2 col-2">
                        <app-input [inputConfig]="formClinicInfo.input[3]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-6 col-6" [ngbTooltip]="formClinicInfo.input[4].value">
                        <app-input [inputConfig]="formClinicInfo.input[4]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-4 col-4">
                        <app-input [inputConfig]="formClinicInfo.input[5]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                </div>

                <!-- PAYER CLINIC -->
                <div class="row my-2">
                    <b class="col-md-2 col-2">{{ 'CLINIC_PAYER' | translate}}</b>
                </div>

                <!-- PAYER CLINIC -->
                <div class="row my-2">
                    <div class="col-md-2 col-2">
                        <app-input [inputConfig]="formClinicInfo.input[6]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-6 col-6" [ngbTooltip]="formClinicInfo.input[7].value">
                        <app-input [inputConfig]="formClinicInfo.input[7]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                    <div class="col-md-4 col-4">
                        <app-input [inputConfig]="formClinicInfo.input[8]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                </div>
                
                <br/>
                
                <div class="row my-2">
                    <div class="col-md-4 col-4">
                        <app-input [inputConfig]="formClinicInfo.input[9]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                    </div>
                </div>

                <hr/>
                <div class="row my-4" *ngIf="isPharmacyRelatedToClinic">
                    <div class="col-md-4 col-10">
                        <app-autocomplete-dropdown [autocompleteConfig]="pharmacyAutocomplete" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                    </div>
                </div>

                <div class="row my-2">
                    <div class="col-md-4 col-12">
                    <app-radio-button [radioButtonConfig]="formClinicInfo.radioButton" (radioOutput)="inputValues($event)"></app-radio-button>
                </div>
                </div>

                <div class="row my-4">
                    <div class="col-md-4 col-10">
                        <app-autocomplete-dropdown [autocompleteConfig]="formClinicInfo.autocompleteCultureConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                        <span class="info-text mt-2">{{'CLINIC_ADD_CULTURE_NOTE' | translate}}</span>
                    </div>
                </div>
                
                <div *ngIf="isPDEnabled" class="row my-2">
                    <div class="col-md-auto">
                        <app-checkbox [checked]="isInCenterOrderingEnabled" (check)="checkIsInCenterOrderingEnabled()" [text]="getTranslation('CLINIC_ADD_IPD')"></app-checkbox>
                    </div>
                    <div class="col m-auto">
                        <app-info-tooltip style="margin-top: 1.05rem;align-self: center;display: block;" [description]="'CLINIC_ADD_IPD_NOTE' | translate"></app-info-tooltip>
                    </div>
                </div>

                <div *ngIf="isHDEnabled" class="row my-2">
                    <div class="col-md-auto">
                        <app-checkbox [checked]="isInCenterHDOrderingEnabled" (check)="checkIsInCenterHDOrderingEnabled()" [text]="getTranslation('CLINIC_ADD_HD')"></app-checkbox>
                    </div>
                    <div class="col m-auto">
                        <app-info-tooltip style="margin-top: 1.05rem;align-self: center;display: block;" [description]="'CLINIC_ADD_HD_NOTE' | translate"></app-info-tooltip>
                    </div>
                </div>

                <div *ngIf="isHHDEnabled" class="row my-2">
                    <div class="col-md-auto">
                        <app-checkbox [checked]="isInCenterHHDOrderingEnabled" (check)="checkIsInCenterHHDOrderingEnabled()" [text]="getTranslation('CLINIC_ADD_HHD')"></app-checkbox>
                    </div>
                    <div class="col m-auto">
                        <app-info-tooltip style="margin-top: 1.05rem;align-self: center;display: block;" [description]="'CLINIC_ADD_HHD_NOTE' | translate"></app-info-tooltip>
                    </div>
                </div>

                <br/>

                <!-- HD LOGISTIC SERVICE & LOADING EQUIPMENT SECTION -->
                <div *ngIf="isInCenterHDOrderingEnabled">
                    <div class="row my-2">
                        <div class="col-md-4 col-8">
                            <app-input [inputConfig]="formClinicInfo.input[10]" [formControlInput]="formClinicAdd" (keyup)="inputValues($event)"></app-input>
                        </div>
                    </div>
                    
                    <br/>

                    <div class="row my-2">
                        <div class="col-md-4 col-8">
                            <b>{{ 'CLINIC_LOADING_EQUIPMENT' | translate }}</b>
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-md-4 col-8">
                            <app-radio-button [radioButtonConfig]="formClinicInfo.radioButtonLoadingEquipmentByContract" (radioOutput)="inputValues($event)"></app-radio-button>
                        </div>
                    </div>
                </div>

                <br/>

                <app-error-box *ngIf="this.validationError"
                    [text]="validationError"></app-error-box>
                
                <div class="row mt-5">
                    <div class="col-10 d-flex flex-row">
                        <app-button [buttonConfig]="addBtnConfig" (click)="saveClinic()"></app-button>
                    </div>
                </div>
                
                <br/>

                <a *ngIf="id == undefined" (click)="this.isClinicSelected = false"><i class="fas fa-chevron-left"></i><u class="edit_link"> {{ 'CLINIC_ADD_BACKRESULTS'
                    | translate }} </u></a>
            </div>
            
            <br/>

        </form>
    </div>
</ng-template>