import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { BackOfficeCalendarDateView, CalendarDateGetAllBackOfficeRQ, ClientService } from 'src/app/core';
import { BackOfficeCalendarViewCustom } from 'src/app/core/models/BackOfficeCalendarView-custom.model';

@Component({
  selector: 'app-assing-calendar-list',
  templateUrl: './assing-calendar-list.component.html',
  styleUrls: ['./assing-calendar-list.component.css'],
  encapsulation: ViewEncapsulation.None
  
})
export class AssingCalendarListComponent implements OnInit {

  constructor(
    private clientService: ClientService
  ) { }

  @Input() calendars!: BackOfficeCalendarViewCustom[];

  header = [
    {name: '', field: 'checkbox' },
    {name: 'CALENDAR_NAME', field: 'calendarName' },
    {name: 'CALENDAR_DESCRIPTION', field: 'calendarDescription' },
    {name: 'CALENDAR_DELIVERY_DATE', field: 'deliveryDateLabel' },
    {name: 'CALENDAR_FREQUENCY', field: 'frequencyLabel' },
    {name: 'CALENDAR_PICKING', field: 'pickingWeekDayLabel' },
    {name: 'CALENDAR_DUE_DATE', field: 'dueDateLabel' },
  ]

  infoSpinner = true;
  currentCalendarInfo: { [key : string] : BackOfficeCalendarDateView[] } = {};
  openedPanel = ''


  ngOnInit(): void {
  }


  async getCalendarInfo(id: string){
    if(!this.currentCalendarInfo[id]){
      this.infoSpinner = true
      const inputData = new CalendarDateGetAllBackOfficeRQ({ idCalendar: id, pageIndex: 1, sortOrder: "deliveryDate_asc" })
      const calendar = await this.clientService.calendarDateGetAllBackOffice(inputData).toPromise();
      this.currentCalendarInfo[id] = calendar.list!;
      this.infoSpinner = false
    }
  }

  isChecked = (id: string) => this.calendars.some(x=> x.idCalendar === id && x.isSelected)

  onCheck(calendar: BackOfficeCalendarViewCustom){
    this.calendars.forEach(x => x.isSelected = false);
    
    calendar.isSelected = !calendar.isSelected;
  }

  isSelected = (id: string) => this.calendars.some(x=> x.idCalendar === id && x.isSelected)

  panelChange(event: NgbPanelChangeEvent){
    if(!event.nextState) this.openedPanel = '';
    else this.openedPanel = event.panelId;
  }

}
