
<button [type]="buttonConfig?.type ? buttonConfig?.type : 'button'" [ngClass]="buttonConfig?.bclass" [attr.data-target]="'#' + buttonConfig?.dataTarget"  [disabled]="buttonConfig?.disabled"  [attr.data-toggle]="buttonConfig?.dataToggle" [attr.data-dismiss]="buttonConfig?.dataDismiss" (click)="onClickButton($event)">
        <div [ngSwitch]="buttonConfig?.type">
          <div class="button-container" *ngSwitchCase="['add']">
            <div class="button-plus">
              <svg-icon class="add-icon" src="../../../assets/icons/cross-icon.svg"></svg-icon>
            </div>
            <div class="button-text">
              {{ buttonConfig?.text}}
            </div>
          </div>
          <div *ngSwitchCase="['edit']">
            <svg-icon class="openPatientPageBtn" src="../../../assets/icons/major-icon.svg"></svg-icon>
            {{ buttonConfig?.text}}
          </div>
          <div *ngSwitchCase="['pencil']">
            <div class="button-plus edit">
              <svg-icon src="../../../assets/icons/pencil-lg-icon.svg"></svg-icon>
            </div>
            <div class="button-text">
              {{ buttonConfig?.text}}
            </div>
          </div>
          <div *ngSwitchCase="['delete']">
            <div class="button-plus delete">
              <svg-icon src="../../../assets/icons/delete-icon.svg"></svg-icon>
            </div>
            <div class="button-text">
              {{ buttonConfig?.text}}
            </div>
          </div>
          <div *ngSwitchCase="['close']">
            <div class="button-plus close">
              <svg-icon src="../../../assets/icons/close.svg"></svg-icon>
            </div>
          </div>
          <div *ngSwitchCase="['logout']">
            <div class="button-plus logout">
              <svg-icon src="../../../assets/icons/logout-icon.svg"></svg-icon>
            </div>
            <div>
              {{ buttonConfig?.text}}
            </div>
          </div>
          <div *ngSwitchCase="['info']" (mouseenter)="onClickButton($event)" (mouseleave)="onClickButton($event)" class="info-hover">
            <div class="button-info" >
              <svg-icon src="../../../assets/icons/support-icon.svg"></svg-icon>
            </div>
          </div>
          <div *ngSwitchCase="['prescription']">
            <svg-icon src="../../../assets/icons/major-icon.svg"></svg-icon>
            {{ buttonConfig?.text}}
          </div>
          <div *ngSwitchCase="['new']">
            <div class="button-plus">
              <svg-icon class="add-icon" src="../../../assets/icons/cross-icon.svg"></svg-icon>
            </div>
            <div class="button-text" *ngIf="buttonConfig?.text">
              {{ buttonConfig?.text}}
            </div>
          </div>
          <div *ngSwitchDefault>
            <span>{{ buttonConfig?.text}}</span>
          </div>
        </div>
</button>


