import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BaseGetAllRQ, ClientService, EntityRQ, IBaseGetAllRQ, IPatientSetPersonalDetailsRQ, PatientRS, PatientSetPersonalDetailsRQ } from 'src/app/core';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.css']
})
export class PatientEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  patientDetails?: PatientRS;
  firstOrderNeeded = true;

  loading: boolean = true;

  clinicList!: any | [];
  countryPhonePrefixList!: any | [];
  validationError!: any;

  formPatientAdd: FormGroup = this.fb.group({
    idClinic: [null, Validators.required],
    sapUuid: [null],
    name: [null, Validators.required],
    surname: [null, Validators.required],
    //gender: [null, Validators.required],
    birthDate: [null, Validators.required],
    /*email: [null, [Validators.required, Validators.email]],
    mobileNumberPrefix: [null, Validators.required],
    mobileNumber: [null, Validators.required],
    phoneNumberPrefix: [null],
    phoneNumber: [null],
    deliveryAddress: [null, Validators.required],
    deliveryAptNr: [null],
    deliveryFloor: [null],
    deliveryCity: [null, Validators.required],
    deliveryZipCode: [null, Validators.required],
    deliveryCountry: [null, Validators.required],
    deliveryComments: [null],
    socialSecurityNumber: [null],
    healthInsuranceCardNumber: [null]*/
  });

  formPatientInfo={
    key: 'formPatientInfo',
    input:
    [
      { name: this.getTranslation('LABEL_SAPUUID'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'sapUuid', value: this.formPatientAdd.get("sapUuid")?.value },
      { name: this.getTranslation('LABEL_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'name', value: this.formPatientAdd.get("name")?.value },
      { name: this.getTranslation('LABEL_SURNAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'surname', value: this.formPatientAdd.get("surname")?.value },
      /*{ name: this.getTranslation('LABEL_EMAIL'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'email', value: this.formPatientAdd.get("email")?.value },
      { name: this.getTranslation('LABEL_LANDLINE'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'phoneNumber', value: this.formPatientAdd.get("phoneNumber")?.value },
      { name: this.getTranslation('LABEL_MOBILE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'mobileNumber', value: this.formPatientAdd.get("mobileNumber")?.value },

      { name: this.getTranslation('PATIENT_DETAIL_ADDRESS'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryAddress', value: this.formPatientAdd.get("deliveryAddress")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_APTNR'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryAptNr', value: this.formPatientAdd.get("deliveryAptNr")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_FLOOR'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryFloor', value: this.formPatientAdd.get("deliveryFloor")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_CITY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryCity', value: this.formPatientAdd.get("deliveryCity")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_ZIPCODE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryZipCode', value: this.formPatientAdd.get("deliveryZipCode")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_COUNTRY'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryCountry', value: this.formPatientAdd.get("deliveryCountry")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_COMMENTS'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'deliveryComments', value: this.formPatientAdd.get("deliveryComments")?.value },
    
      { name: this.getTranslation('PATIENT_DETAIL_SOCIALSECURITYNR'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'socialSecurityNumber', value: this.formPatientAdd.get("socialSecurityNumber")?.value },
      { name: this.getTranslation('PATIENT_DETAIL_HEALTHINSURANCENR'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'healthInsuranceCardNumber', value: this.formPatientAdd.get("healthInsuranceCardNumber")?.value },*/
    ],
    autocompleteClinicConfig: {
      name: this.getTranslation('LABEL_CLINIC'),
      attr: 'clinicName' as const,
      array: this.clinicList,
      numberToShow: 6,
      prefix: false,
      value : null as any,
      inputName : 'idClinic',
      class: 'form-control autocomplete-input',
      disabled: true
    },
    autocompleteCountryPhonePrefixConfig: {
      name: this.getTranslation('LABEL_COUNTRYPREFIX'),
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      value: null as any,
      inputName: 'phoneNumberPrefix',
      prefix: '+',
      class: 'form-control autocomplete-input'
    },
    autocompleteCountryMobilePrefixConfig: {
      name: this.getTranslation('LABEL_COUNTRYPREFIX'),
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      value: null as any,
      inputName: 'mobileNumberPrefix',
      prefix: '+',
      class: 'form-control autocomplete-input'
    },
    radioButton:
    {
      id: 'genderRadio',
      count: '2',
      name: 'gender',
      labels: [this.getTranslation('MALE_LABEL'), this.getTranslation('FEMALE_LABEL')],
      values: ['M', 'F'],
      selected: '' as any,
      class: 'form4'
    },
    datePickerConfig: {
      labelName: this.getTranslation('NURSE_ADD_BIRTHDATE'),
      optional: false,
      inputName: 'birthDate',
      value: undefined as any,
      maxDate: new Date(),
      errorMessage: '',
      disabled: true
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_ADDPATIENT'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private utilService: UtilsService,
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !form.valid;
      });
    this.addBtnConfig.disabled = !form.valid;
  }

  inputValues(event: any){
    if (event.target.name === 'gender') {
      this.formPatientInfo.radioButton.selected = event.target.value;
    }
    
    this.formPatientAdd.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.formPatientAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList() {
    if(!this.id){
      this.router.navigateByUrl('/patients');
    }
    else{
      this.router.navigate(['/patients/detail', this.id]);
    }
  }
  
  ngOnInit(): void {
    this.clinicList = this.getAllClinic();

    this.countryPhonePrefixList = this.getAllCountryPhonePrefix();

    //let regexSocialSecurityNumber = this.utilService.getParametersByKey("regex_validation_social_security_number").parameterValue;
    //this.formPatientAdd.controls["socialSecurityNumber"].setValidators([Validators.pattern(regexSocialSecurityNumber)]);
    
    //let regexHealthInsuranceCardNumber = this.utilService.getParametersByKey("regex_validation_health_insurance_card_number").parameterValue;
    //this.formPatientAdd.controls["healthInsuranceCardNumber"].setValidators([Validators.pattern(regexHealthInsuranceCardNumber)]);

    this.id = this.route.snapshot.paramMap.get('id')?.toString();
    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
      this.getPatientDetail();
    }  

    this.loading = false;
  }

  getPatientDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.patientGet(data).subscribe(
      response =>{

        this.patientDetails = response;

        this.formPatientAdd.patchValue({
          sapUuid: response.sapUuid,
          name: response.name,
          surname: response.surname,
          //gender: response.gender,
          birthDate: response.birthDate,
          //email: response.emailAddress,
          //phoneNumber: response.phoneNumber,
          //mobileNumber: response.mobileNumber,

          //deliveryAddress: response.deliveryAddress,
          //deliveryAptNr: response.deliveryApartmentNumber,
          //deliveryFloor: response.deliveryFloorString,
          //deliveryCity: response.deliveryCity,
          //deliveryZipCode: response.deliveryZipCode,
          //deliveryCountry: response.deliveryCountry,
          //deliveryComments: response.deliveryComments,
          
          idClinic: { idClinic: response.idClinic },
          //phoneNumberPrefix: { phonePrefix: response.phoneNumberCountryPrefix, countryIsoCode: response.phoneNumberCountryPrefixCode ?? null },
          //mobileNumberPrefix: { phonePrefix: response.mobileNumberCountryPrefix, countryIsoCode: response.mobileNumberCountryPrefixCode ?? null }
        });

        this.formPatientInfo!.input[0].value = this.formPatientAdd.get("sapUuid")?.value;
        this.formPatientInfo!.input[1].value = this.formPatientAdd.get("name")?.value;
        this.formPatientInfo!.input[2].value = this.formPatientAdd.get("surname")?.value;
        /*this.formPatientInfo!.input[3].value = this.formPatientAdd.get("email")?.value;
        this.formPatientInfo!.input[4].value = this.formPatientAdd.get("phoneNumber")?.value;
        this.formPatientInfo!.input[5].value = this.formPatientAdd.get("mobileNumber")?.value;

        this.formPatientInfo!.input[6].value = this.formPatientAdd.get("deliveryAddress")?.value;
        this.formPatientInfo!.input[7].value = this.formPatientAdd.get("deliveryAptNr")?.value;
        this.formPatientInfo!.input[8].value = this.formPatientAdd.get("deliveryFloor")?.value;
        this.formPatientInfo!.input[9].value = this.formPatientAdd.get("deliveryCity")?.value;
        this.formPatientInfo!.input[10].value = this.formPatientAdd.get("deliveryZipCode")?.value;
        this.formPatientInfo!.input[11].value = this.formPatientAdd.get("deliveryCountry")?.value;
        this.formPatientInfo!.input[12].value = this.formPatientAdd.get("deliveryComments")?.value;

        this.formPatientInfo!.input[13].value = this.formPatientAdd.get("socialSecurityNumber")?.value;
        this.formPatientInfo!.input[14].value = this.formPatientAdd.get("healthInsuranceCardNumber")?.value;*/

        this.formPatientInfo!.datePickerConfig!.value = this.patientDetails.birthDate;
        this.formPatientInfo!.datePickerConfig = {...this.formPatientInfo!.datePickerConfig};
        //this.formPatientInfo!.radioButton.selected = this.patientDetails.gender;

        this.formPatientInfo!.autocompleteClinicConfig.value = { idClinic: this.patientDetails.idClinic, clinicName: this.patientDetails.clinicName }
        /*this.formPatientInfo!.autocompleteCountryPhonePrefixConfig.value = this.patientDetails.phoneNumberCountryPrefix ? 
        { 
          phonePrefix: this.patientDetails.phoneNumberCountryPrefix ?? null,
          countryIsoCode: this.patientDetails.phoneNumberCountryPrefixCode ?? null
        } : null as any;
        this.formPatientInfo!.autocompleteCountryMobilePrefixConfig.value = this.patientDetails.mobileNumberCountryPrefix ? 
        { 
          phonePrefix: this.patientDetails.mobileNumberCountryPrefix ?? null,
          countryIsoCode: this.patientDetails.mobileNumberCountryPrefixCode ?? null
        } : null as any;*/

        //this.formPatientInfo!.radioButton = {...this.formPatientInfo!.radioButton}

        this.formPatientInfo!.autocompleteClinicConfig = {...this.formPatientInfo!.autocompleteClinicConfig}
        //this.formPatientInfo!.autocompleteCountryPhonePrefixConfig = {...this.formPatientInfo!.autocompleteCountryPhonePrefixConfig}
        //this.formPatientInfo!.autocompleteCountryMobilePrefixConfig = {...this.formPatientInfo!.autocompleteCountryMobilePrefixConfig}

        this.formValidation(this.formPatientAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }


  getAllClinic(): any{
    let input: IBaseGetAllRQ = {
      pageIndex: undefined,
      sortOrder: "ClinicName_asc",
      searchString: "",
      city: ""
    }
    this.clientService.clinicGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.formPatientInfo.autocompleteClinicConfig.array = response.list;
      } ,
      error=> console.log(error)
    );
  }

  getAllCountryPhonePrefix() {
    this.clientService.countryPhonePrefixGetAll().subscribe(
      response => {
        this.formPatientInfo.autocompleteCountryPhonePrefixConfig.array = response;
        this.formPatientInfo.autocompleteCountryMobilePrefixConfig.array = response;
      },
      error => console.log(error)
    )
  }

  checkFirstOrderNeeded(){
    this.firstOrderNeeded = !this.firstOrderNeeded;
  }

  savePatient(){
    if (this.formPatientAdd.valid) {

      this.loading = true;
      /*let patient: IPatientSetRQ = {
        idPatient: this.id,
        idClinic: this.formPatientAdd.get('idClinic')?.value?.idClinic,
        sapUuid: this.formPatientAdd.get('sapUuid')?.value,
        name: this.formPatientAdd.get('name')?.value,
        surname: this.formPatientAdd.get('surname')?.value,
        gender: this.formPatientAdd.get('gender')?.value,
        birthDate: this.formPatientAdd.get('birthDate')?.value,
        emailAddress: this.formPatientAdd.get('email')?.value,
        phoneNumberCountryPrefixCode: this.formPatientAdd.get('phoneNumberPrefix')?.value?.countryIsoCode ?? null,
        phoneNumberCountryPrefix: this.formPatientAdd.get('phoneNumberPrefix')?.value.phonePrefix,
        phoneNumber: this.formPatientAdd.get('phoneNumber')?.value,
        mobileNumberCountryPrefixCode: this.formPatientAdd.get('mobileNumberPrefix')?.value?.countryIsoCode ?? null,
        mobileNumberCountryPrefix: this.formPatientAdd.get('mobileNumberPrefix')?.value.phonePrefix,
        mobileNumber: this.formPatientAdd.get('mobileNumber')?.value,

        deliveryAddress: this.formPatientAdd.get('deliveryAddress')?.value,
        deliveryApartmentNumber: this.formPatientAdd.get('deliveryAptNr')?.value,
        deliveryFloor: this.formPatientAdd.get('deliveryFloor')?.value,
        deliveryCity: this.formPatientAdd.get('deliveryCity')?.value,
        deliveryZipCode: this.formPatientAdd.get('deliveryZipCode')?.value,
        deliveryCountry: this.formPatientAdd.get('deliveryCountry')?.value,
        deliveryComments: this.formPatientAdd.get('deliveryComments')?.value,

        socialSecurityNumber: this.formPatientAdd.get('socialSecurityNumber')?.value,
        healthInsuranceCardNumber: this.formPatientAdd.get('healthInsuranceCardNumber')?.value,

        isFirstOrderNeeded: this.firstOrderNeeded,
        isFinalized: true,
      }
      /*this.clientService.patientBackOfficeSet(new PatientSetRQ(patient)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){
            
            this.idCreated = response.idEntity;

            if(!this.id){
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList()
            }
          }
          else{
            if(response?.errorType)
              this.validationError = this.getTranslation(response?.errorType);
          }
        },
        error=> {
          this.loading = false;
          this.validationError = this.getTranslation("ERROR_GENERIC");
        }
      )*/
    }
  }

  saveDetails(){
    if (this.formPatientAdd.valid) {

      this.loading = true;
      let patient: IPatientSetPersonalDetailsRQ = {
        idPatient: this.id,
        name: this.formPatientAdd.get('name')?.value,
        surname: this.formPatientAdd.get('surname')?.value,
        birthDate: this.formPatientAdd.get('birthDate')?.value,
      }
      this.clientService.patientBackOfficeSetPersonalDetails(new PatientSetPersonalDetailsRQ(patient)).subscribe(
        response =>{
          this.loading = false;
          this.backToList()
        },
        error=> {
          this.loading = false;
          this.validationError = this.getTranslation("ERROR_GENERIC");
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(); }
     });
    this.ngxModal.getModal('quitModal').open();
  }
}
