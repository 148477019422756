import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private datePipe: DatePipe,
    private translate: TranslateService,
    private util: UtilsService
  ) {
    this.currentLang = this.translate.currentLang;
    this.offset = new Date().getTimezoneOffset();
   }

   currentLang: string;
   offset: number =0;

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15.05.2000*
   */
  dateParse(date: Date | undefined, isDateDue: boolean = false): string{
    if(!date) { return ""; }
    const customFormat = this.util.getParametersByKey('format_date').parameterValue;
    return this.datePipe.transform(date, customFormat ,this.timeZoneCalculation())!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15.05.2000 | HH:mm*
   */
   dateParseWithHour(date: Date | undefined, isDateDue: boolean = false): string{
    if(!date) { return ""; }
    const customFormat = this.util.getParametersByKey('format_date').parameterValue;
    return this.datePipe.transform(date, customFormat + " | HH:mm" ,this.timeZoneCalculation())!;
  }

  dateParseWithoutTimeZone(date: Date | undefined, isDateDue: boolean = false): string{
    if(!date) { return ""; }
    const customFormat = this.util.getParametersByKey('format_date').parameterValue;
    return this.datePipe.transform(date, customFormat , "+0000")!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15 May 2000*
   */
  simpleDateParse(date: Date | undefined, isDateDue: boolean = false, useUTC: boolean = false): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date, 'dd MMMM yyyy',  useUTC ? "+0000" : this.timeZoneCalculation(), this.currentLang)!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15 May 2000*
   */
  simpleDateParseWithoutYear(date: Date | undefined): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date, 'dd MMMM', this.timeZoneCalculation(), this.currentLang)!;
  }

    /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15 May 2000*
   */
  singleDaydateParse(date: Date | undefined, isDateDue: boolean = false): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date, 'd MMMM yyyy',this.timeZoneCalculation())!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *05/06/2000*
   * @deprecated As of release 4.1, replaced by {@link com.github.noraui.application.steps.WaitSteps#waitVisibilityOf(String, List)}
   */
  barDateParse(date: Date | undefined, isDateDue: boolean = false, useUTC: boolean = false): string{
    if(!date) { return ""; }
    const customFormat = this.util.getParametersByKey('format_date').parameterValue;
    return this.datePipe.transform(date, customFormat , useUTC ? "+0000" : this.timeZoneCalculation())!;
  }


  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *Friday, 15. May*
   */
  orderDateParse(date: Date | undefined, isDateDue: boolean = false, useUTC: boolean = false): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date,'EEEE, d. MMMM', useUTC ? "+0000" : this.timeZoneCalculation(), this.currentLang)!;
  }


  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *Friday, 15 May*
   */
  dateWithDayDateParse(date: Date | undefined, isDateDue: boolean = false): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date,'EEEE, d MMMM', this.timeZoneCalculation(), this.currentLang)!;
  }


  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *May 15, 2000*
   */
  dropOutDateParse(date: Date | undefined, isDateDue: boolean = false, useUTC: boolean = false): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date,'MMMM d, yyyy', useUTC ? "+0000" : this.timeZoneCalculation(), this.currentLang)!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15 May*
   */
  historyDateParse(date: Date | undefined, isDateDue: boolean = false): string{
    if(!date) { return ""; }
    return this.datePipe.transform(date,'d MMMM', this.timeZoneCalculation(), this.currentLang)!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15.06.2000 - 15:30 PM*
   */
  orderSuccessDateParse(date: Date | undefined, isDateDue: boolean = false, useUTC: boolean = false):string {
    if(!date) { return ""; }
    return this.datePipe.transform(date, 'dd.MM.yyyy - hh:mm a',useUTC ? "+0000" : this.timeZoneCalculation(), this.currentLang)!;
  }

  
  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15-06-2000*
   */
  registrationDateParse(date: Date | undefined, isDateDue: boolean = false):string {
    if(!date) { return ""; }
    const customFormat = this.util.getParametersByKey('format_date').parameterValue;
    return this.datePipe.transform(date, customFormat ,this.timeZoneCalculation(),this.currentLang)!;
  }


  /**
   * @param date 
   * @param isDateDue
   * @returns date with format *15. June, 18:30 PM*
   */
  orderStatusDateParse(date: Date | undefined, isDateDue: boolean = false):string {
    if(!date) { return ""; }
    return this.datePipe.transform(date, 'd. MMMM, hh:mm a',this.timeZoneCalculation(),this.currentLang)!;
  }

  /**
   * @param date 
   * @param isDateDue
   * @returns date with custom format
   * @deprecated replaced by custom parameter get from parameters after auth
   */
  customDateParse(date: Date | undefined, format: string, isDateDue: boolean = false):string {
    if(!date) { return ""; }
    return this.datePipe.transform(date, format,this.timeZoneCalculation(),this.currentLang)!;
  }






  /*----------- Utils Function ------------*/
  isOurDateInFuture(date: Date | undefined): boolean{
    return date! > new Date();
  }

  getDayDifference(date: Date | undefined, isDueDate: boolean = false): number{
    let today = new Date();
    if(date){
      var Difference_In_Time = date.getTime()! - today.getTime();
      var dayDifference = Difference_In_Time / (1000 * 3600 * 24);
      return Math.ceil(dayDifference);
    }
    return -1;
  }

  getTimeDifferenceLabel(date: Date | undefined, isDueDate: boolean = false): string{
    if(!date){
      return "";
    }
    let today = new Date();
    let dueDate = new Date(date!);
    var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
    if(dueDate){
      var date1_ms = dueDate?.getTime();
      var date2_ms = today.getTime();
      var difference_ms = date1_ms! - date2_ms;
      if(difference_ms > 0){
        if(Math.floor(difference_ms / ONE_WEEK)<= 1){
          return this.pluralDate(this.getTranslation('IN_DAY_TIME_LABEL'),this.getTranslation('IN_DAYS_TIME_LABEL'),this.getDayDifference(dueDate,isDueDate))
        }else{
          return this.pluralDate(this.getTranslation('IN_WEEK_TIME_LABEL'),this.getTranslation('IN_WEEKS_TIME_LABEL'),Math.floor(difference_ms / ONE_WEEK))
        }
      }
      else{
        let pos_difference_ms = Math.abs(difference_ms);
        if(Math.abs(pos_difference_ms) >= (1000 * 3600 * 24)){
          return Math.floor((pos_difference_ms) / (1000 * 3600 * 24)) + " " + this.getTranslation('DAYS_OVERDUE');
        }
        else{
          return this.getTranslation('LABEL_EXPIRED');
        }
      }
    }
    return "NOT AVAILABLE";
  }

  isDateExpired(date: Date | undefined, isDueDate: boolean = false): boolean{
    let data = new Date(date!)
    let today = new Date();
    return today > data;
  }


  getTablesTimeDifference(dateInput: Date | undefined, isDueDate :boolean = false){
    if(!dateInput){
      return;
    }
    let difference;
    let today = new Date();
    let date = new Date(dateInput!);
    difference = Math.ceil((date.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));

    //Manage only days
    if(difference == 0){
      difference = 1;
    }

    if(difference > 0){
      return this.pluralDate(this.getTranslation('IN_DAY_LABEL'),this.getTranslation('IN_DAYS_LABEL'),difference)
    } else {
      return this.pluralDate(this.getTranslation('FOR_DAY_LABEL'),this.getTranslation('FOR_DAYS_LABEL'), Math.abs(difference))
    }
  }

  pluralDate(singular: string, plural: string, difference: any){
    return Math.abs(difference) == 1 ? singular.replace('{0}', difference) : plural.replace('{0}', difference);
  }

  isDate(value:string){
    return (Date.parse(value) || Date.parse(value)==0);
  }

  /* Geneal Function */
  timeZoneCalculation():string{
    var internalOffset  = new Date().getTimezoneOffset();
    let o = Math.abs(internalOffset);
    return (internalOffset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ("00" + (o % 60)).slice(-2);
  }


  convertFromStringToDate(responseDate: any): Date | null{
    try {
        if (responseDate != null) {
            if (typeof (responseDate) === 'string') {
                if (String(responseDate.indexOf('T') >= 0)) {
                    responseDate = responseDate.split('T')[0];
                }
                if (String(responseDate.indexOf('+') >= 0)) {
                    responseDate = responseDate.split('+')[0];
                }
            }

            responseDate = new Date(responseDate);
            const newDate = new Date(responseDate.getFullYear(), responseDate.getMonth(), responseDate.getDate(), 0, 0, 0);
            const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;

            const finalDate: Date = new Date(newDate.getTime() - userTimezoneOffset);
            return finalDate;
        } else {
            return null;
        }
    } catch (error) {
        return responseDate;
    }
}

  isDateValid(date : Date){
    return (
      date instanceof Date &&  !isNaN(date.getTime()) && date > new Date("1900-01-01")
    )
  }

    /**
   * Get the number of days in any particular month
   * @param  {number} m The month (valid: 0-11)
   * @param  {number} y The year
   * @return {number}   The number of days in the month
   */
     private daysInMonth = (m: number, y: number): number => {
      switch (m) {
          case 1 :
          return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
          case 8 : case 3 : case 5 : case 10 : return 30;
          default : return 31
      }
    };
  
    /**
    * Check if a date is valid
    * @param  {[type]}  d The day
    * @param  {[type]}  m The month
    * @param  {[type]}  y The year
    * @return {Boolean}   Returns true if valid
    */
    isValidDate = (d: number, m: number, y: number): boolean => {
      m = parseInt(m.toString(), 10) - 1;
      return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y) && y > 1900;
    };


  isSameDate(firstDate: Date, secondDate: Date){
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    )
  }


  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
