<div>
  <app-date-picker [datePickerConfig]="durationConfig.datePickerConfig[0]" (resultDate)="ShowSecondDatePicker($event)"></app-date-picker>
</div>
<div *ngIf="showEnd && (durationConfig.datePickerConfig[0].value)">
  <app-date-picker [datePickerConfig]="durationConfig.datePickerConfig[1]" (resultDate)="emit($event)"></app-date-picker>
  <div class="error-message" *ngIf="durationConfig.errorMessage">{{durationConfig.errorMessage}}</div>
  <div class="btn-wrapper">
    <div class="row">
      <div class="col">
        <app-button [buttonConfig]="oneYearBtnConfig" (click)="selectEnd('1 year')"></app-button>
      </div>
      <div class="col">
        <app-button [buttonConfig]="EndOfTheYearConfig" (click)="selectEnd('End of year')"></app-button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-button [buttonConfig]="sixMonthsConfig" (click)="selectEnd('6 months')"></app-button>
      </div>
      <div class="col">
        <app-button [buttonConfig]="oneMonthConfig" (click)="selectEnd('1 month')"></app-button>
      </div>
    </div>
  </div>
</div>
