import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNoSpecialChars]'
})
export class NoSpecialCharsDirective {

  // Define the characters you want to block
  private specialChars: RegExp = /[<>\^*\)\(]+/g;

  constructor(private el: ElementRef) {}

  @Input() avoidSanitizing!: boolean;

  // Listen for input events on the element
  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    if(!this.avoidSanitizing){
        const input = this.el.nativeElement;
        const cleanedValue = input.value.replace(this.specialChars, '');
        if (cleanedValue !== input.value) {
          input.value = cleanedValue;
          event.preventDefault();
        }
    }
  }

  // Listen for paste events on the element
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    if(!this.avoidSanitizing){
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData!.getData('text');
        if (this.specialChars.test(pastedText)) {
          event.preventDefault();
          const cleanedText = pastedText.replace(this.specialChars, '');
          document.execCommand('insertText', false, cleanedText);
        }
    }
  }

}