import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, CalendarDateSetRQ, ICalendarDateSetRQ, AuthenticationService, EntityRQ } from 'src/app/core';
import { BackOfficeCalendarDateViewCustom } from 'src/app/core/models/BackOfficeCalendarDateView-custom.model';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-calendar-date-edit',
  templateUrl: './calendar_date-edit.component.html',
  styleUrls: ['./calendar_date-edit.component.css']
})
export class CalendarDateEditComponent implements OnInit {
  id?: string;
  calendarDateDetails?: BackOfficeCalendarDateViewCustom;
  loading: boolean = false;

  validationErrors: any [] = [];

  isCalendarValid: boolean = true;

  publicHolidaysParam: string = this.utilsService.getParametersByKey("flow__year_holiday_dates").parameterValue!;
  publicHolidaysArray: Array<Date> = new Array<Date>();

  formCalendarDateAdd: FormGroup = this.fb.group({
    calendarName: [null, Validators.required],
    deliveryDate: [null, Validators.required],
    pickingDate: [null, Validators.required],
    dueDate: [null, Validators.required],
    closingWindowDate: [null, Validators.required],
    openingWindowDate: [null, Validators.required],
  });

  formCalendarDateInfo={
    key: 'formCalendarDateInfo',
    input:
    [
      { name: this.getTranslation('CALENDAR_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'calendarName', value: this.formCalendarDateAdd.get("calendarName")?.value, disabled: true, readOnly: true },
    ],
    deliveryDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_DELIVERY_DATE'),
      optional: false,
      inputName: 'deliveryDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      class: '',
      holidays: new Array<Date>(),
      datePattern: ["1","2","3","4","5"]
    },
    pickingDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_PICKING'),
      optional: false,
      inputName: 'pickingDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      class: '',
      holidays: new Array<Date>(),
      datePattern: ["1","2","3","4","5"]
    },
    dueDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_DUE_DATE'),
      optional: false,
      inputName: 'dueDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      class: '',
      holidays: new Array<Date>(),
      datePattern: ["1","2","3","4","5"]
    },
    closingWindowDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_ORDER_CLOSING_WINDOW'),
      optional: false,
      inputName: 'closingWindowDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      disabled: true,
    },
    openingWindowDatePickerConfig: {
      labelName: this.getTranslation('CALENDAR_ORDER_OPENING_WINDOW'),
      optional: false,
      inputName: 'openingWindowDate',
      value: undefined as any,
      minDate: new Date(),
      errorMessage: '',
      disabled: true,
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('CALENDAR_ADD_TITLE'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private utilsService: UtilsService,
    private authService: AuthenticationService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !(form.valid && this.isCalendarValid);
      });
    this.addBtnConfig.disabled = !(form.valid && this.isCalendarValid);
  }

  inputValues(event: any){

    this.formCalendarDateAdd.controls[event.target.name].setValue(event.target.value);

    this.checkCalendarValidity();

    this.calcOrderWindow();

    this.formValidation(this.formCalendarDateAdd);
  }

  calcOrderWindow(){

    var dueDate = new Date(this.formCalendarDateAdd.get('dueDate')?.value);    

    if(dueDate != undefined)
    {
      var openingWindowDate = new Date();
      openingWindowDate.setDate(dueDate.getDate() - parseInt(this.utilsService.getParametersByKey("flow__order_editable_by_patient__days_before_due_date")!.parameterValue!));
      this.formCalendarDateInfo.openingWindowDatePickerConfig.value = openingWindowDate;
      this.formCalendarDateInfo!.openingWindowDatePickerConfig = {...this.formCalendarDateInfo!.openingWindowDatePickerConfig};

      var closingWindowDate = new Date();
      closingWindowDate.setDate(dueDate.getDate() - parseInt(this.utilsService.getParametersByKey("flow__order_editable_by_patient__days_before_due_date_end")!.parameterValue!));
      this.formCalendarDateInfo.closingWindowDatePickerConfig.value = closingWindowDate;
      this.formCalendarDateInfo!.closingWindowDatePickerConfig = {...this.formCalendarDateInfo!.closingWindowDatePickerConfig};
    }
  }

  checkCalendarValidity(){

    var isValid = false;

    var dueDate = new Date(this.formCalendarDateAdd.get('dueDate')?.value);
    var pickingDate = new Date(this.formCalendarDateAdd.get('pickingDate')?.value);
    var deliveryDate = new Date(this.formCalendarDateAdd.get('deliveryDate')?.value);
    
    //DATES ARE VALID
    if (new Date() > dueDate){
      this.formCalendarDateInfo.dueDatePickerConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_IN_PAST')
    }
    else if (dueDate > pickingDate){
      this.formCalendarDateInfo.dueDatePickerConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_NOT_COMPLY')
    }
    else if (pickingDate > deliveryDate){
      this.formCalendarDateInfo.pickingDatePickerConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_PICKING_DATE_NOT_COMPLY')
    }
    else{
      isValid = true;
      this.formCalendarDateInfo.dueDatePickerConfig.errorMessage = '';
      this.formCalendarDateInfo.dueDatePickerConfig.class = '';
      this.formCalendarDateInfo.pickingDatePickerConfig.errorMessage = '';
      this.formCalendarDateInfo.pickingDatePickerConfig.class = '';
      this.formCalendarDateInfo.deliveryDatePickerConfig.errorMessage = '';
      this.formCalendarDateInfo.deliveryDatePickerConfig.class = '';
    }

    //CHECK DATE IS ON BANK HOLIDAY
    var isDueDateDisabled = this.isDayDisabled(dueDate);
    var isPickingDateDisabled = this.isDayDisabled(pickingDate);
    var isDeliveryDateDisabled = this.isDayDisabled(deliveryDate);

    if (!isDueDateDisabled){
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("CALENDAR_DUE_DATE_ERROR"));
    }
    if (!isPickingDateDisabled){
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("CALENDAR_PICKING_ERROR"));
    }
    if (!isDeliveryDateDisabled){
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("CALENDAR_DELIVERY_DATE_ERROR"));
    }

    this.isCalendarValid = isValid && !isDueDateDisabled && !isPickingDateDisabled && !isDeliveryDateDisabled;
  }

  public isDayDisabled = (date: Date) => {
    return this.publicHolidaysArray.some(x => x.getDate() == date.getDate() && x.getMonth() == date.getMonth() && x.getFullYear() == date.getFullYear());
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    this.editService.showEditSnackBar = isEdited;
    this.router.navigate(['/calendar-dates/detail', this.id]);
  }

  ngOnInit(): void {

    this.setCalendarHolidays();

    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
      this.getCalendarDetail();
    }
  }

  getCalendarDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.calendarDateGet(data).subscribe(
      response =>{

        this.calendarDateDetails = BackOfficeCalendarDateViewCustom.fromJS(response, (key)=> this.getTranslation(key));;

        this.formCalendarDateAdd.patchValue({
          calendarName: this.calendarDateDetails.fullCalendarName,
          deliveryDate: response.deliveryDate,
          pickingDate: response.pickingDate,
          dueDate: response.dueDate,
          closingWindowDate: response.closingWindowDate,
          openingWindowDate: response.openingWindowDate,
        });

        this.formCalendarDateInfo!.input[0].value = this.formCalendarDateAdd.get("calendarName")?.value;

        this.formCalendarDateInfo!.deliveryDatePickerConfig!.value = this.calendarDateDetails.deliveryDate;
        this.formCalendarDateInfo!.deliveryDatePickerConfig = {...this.formCalendarDateInfo!.deliveryDatePickerConfig};

        this.formCalendarDateInfo!.pickingDatePickerConfig!.value = this.calendarDateDetails.pickingDate;
        this.formCalendarDateInfo!.pickingDatePickerConfig = {...this.formCalendarDateInfo!.pickingDatePickerConfig};
        
        this.formCalendarDateInfo!.dueDatePickerConfig!.value = this.calendarDateDetails.dueDate;
        this.formCalendarDateInfo!.dueDatePickerConfig = {...this.formCalendarDateInfo!.dueDatePickerConfig};

        this.formCalendarDateInfo!.closingWindowDatePickerConfig!.value = this.calendarDateDetails.closingWindowDate;
        this.formCalendarDateInfo!.closingWindowDatePickerConfig = {...this.formCalendarDateInfo!.closingWindowDatePickerConfig};

        this.formCalendarDateInfo!.openingWindowDatePickerConfig!.value = this.calendarDateDetails.openingWindowDate;
        this.formCalendarDateInfo!.openingWindowDatePickerConfig = {...this.formCalendarDateInfo!.openingWindowDatePickerConfig};

        if (this.calendarDateDetails.isDeliveryDateReviewNeeded){
          this.isCalendarValid = false;
          this.formCalendarDateInfo!.deliveryDatePickerConfig!.class = "error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("CALENDAR_DELIVERY_DATE_ERROR"));
        }
        if (this.calendarDateDetails.isPickingDateReviewNeeded){
          this.isCalendarValid = false;
          this.formCalendarDateInfo!.pickingDatePickerConfig!.class = "error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("CALENDAR_PICKING_ERROR"));
        }
        if (this.calendarDateDetails.isDueDateReviewNeeded){
          this.isCalendarValid = false;
          this.formCalendarDateInfo!.dueDatePickerConfig!.class = "error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("CALENDAR_DUE_DATE_ERROR"));
        }

        this.getDefaultByCountry(this.calendarDateDetails.countryIsoCode!);

        this.formValidation(this.formCalendarDateAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  saveCalendarDate(){
    if (this.formCalendarDateAdd.valid) {

      this.loading = true;
      let calendarDate: ICalendarDateSetRQ;
      calendarDate = {
        idCalendarDate: this.id,
        deliveryDate: this.formCalendarDateAdd.get('deliveryDate')?.value,
        pickingDate: this.formCalendarDateAdd.get('pickingDate')?.value,
        dueDate: this.formCalendarDateAdd.get('dueDate')?.value,
      }
      this.clientService.calendarDateSet(new CalendarDateSetRQ(calendarDate)).subscribe(
        response =>{

          this.loading = false;

          if(response){

            this.modalService.setModalData({ 
              title: 'CALENDAR_ORDER_CHANGED_SUCCESSFULLY',
              actionToDo: () => 
              {
                this.router.navigate(['/calendar-dates/detail', this.id]);
              }
            });
            this.ngxModal.getModal('successModal').open();
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  getDefaultByCountry(countryIsoCode: string){
    if(this.authService.currentUserValue.countryIsoCode == null)
    {
      this.utilsService.getParameterByCountry("flow__year_holiday_dates", countryIsoCode!).pipe().subscribe(ret => {
        this.publicHolidaysParam = ret;

        this.setCalendarHolidays();
      });
    }
  }

  setCalendarHolidays(){
    this.publicHolidaysArray = this.publicHolidaysParam.split('|').map((x: string) => {
      var dateParts = x.split("/");
      return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0])); 
    });

    this.formCalendarDateInfo.deliveryDatePickerConfig.holidays = this.publicHolidaysArray;
    this.formCalendarDateInfo.pickingDatePickerConfig.holidays = this.publicHolidaysArray;
    this.formCalendarDateInfo.dueDatePickerConfig.holidays = this.publicHolidaysArray;
  }
}
