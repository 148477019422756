import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { AuthenticationService, ClientService, DemoUserRSV2, DemoUserSetRQ, EntityRQ, IDemoUserSetRQ } from 'src/app/core';
import { Role } from 'src/app/core/enum/roles';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-demo-user-edit',
  templateUrl: './demo_user-edit.component.html',
  styleUrls: ['./demo_user-edit.component.css']
})
export class DemoUserEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  demoUserDetails?: DemoUserRSV2;
  loading: boolean = true;

  countryList!: any | [];
  countryPhonePrefixList!: any | [];
  cultureList!: any | [];
  validationErrors: any[] = [];

  selectedCountry!: any;
  emailAddressIsValid: boolean = true;
  previousEmail: any = "";

  parametersNurseAppAvailability: any = [];
  nurseAppAvailable: boolean = true;
  nurseAppAccessRequired: boolean = true;
  patientAppAccessRequired: boolean = true;

  isPDEnabled = this.utilsService.getParametersByKey("flow_trigger__is_pd_enabled")!.parameterValue === "True";
  isHHDEnabled = this.utilsService.getParametersByKey("flow_trigger__is_hhd_enabled")!.parameterValue === "True";
  isTherapyModalityVisible = true; 

  successModalDescription: string = "";

  formDemoUserAdd: FormGroup = this.fb.group({
    name: [null, Validators.required],
    surname: [null, Validators.required],
    countryIsoCode: [null, Validators.required],
    cultureIsoCode: [null, Validators.required],
    email : [null, [Validators.required, Validators.pattern(new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/))]],
    phonePrefix : [null],
    phoneNumber : [null],
    therapyModality: ['PD', Validators.required]
  });

  formDemoUserInfo={
    key: 'formDemoUserInfo',
    input:
    [
      { name: this.getTranslation('LABEL_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'name', value: this.formDemoUserAdd.get("name")?.value, maxLength: 64 },
      { name: this.getTranslation('LABEL_SURNAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'surname', value: this.formDemoUserAdd.get("surname")?.value, maxLength: 64 },
      { name: this.getTranslation('NURSE_ADD_WORKMAIL'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'email', value: this.formDemoUserAdd.get("email")?.value, maxLength: 256 },
      { name: this.getTranslation('LABEL_MOBILE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'phoneNumber', value: this.formDemoUserAdd.get("phoneNumber")?.value, maxLength: 16 },
    ],
    autocompleteCountryConfig: {
      name: this.getTranslation('LABEL_COUNTRY'),
      attr: 'timeZoneName' as const,
      array: this.countryList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'countryIsoCode',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('COMMON_SELECT_COUNTRY'),
    },
    autocompleteCountryPhonePrefixConfig: {
      name: this.getTranslation('LABEL_COUNTRYPREFIX'),
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      prefix: '+',
      value : null as any,
      inputName : 'phonePrefix',
      class: 'form-control autocomplete-input',
      disabled: true
    },
    autocompleteCultureConfig: {
      name: this.getTranslation('LABEL_CULTURE'),
      attr: 'cultureName' as const,
      array: this.cultureList,
      numberToShow: 6,
      prefix: false,
      value : null as any,
      inputName : 'cultureIsoCode',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('COMMON_SELECT_LANGUAGE'),
    },
    radioButton:
    {
      id: 'therapyModalityRadio',
      count: '2',
      name: 'therapyModality',
      labels: ['PD','HHD'],
      values: ['PD','HHD'],
      selected : 'PD',
      class: 'form4'
    }
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_ADD_DEMO_USER'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private authService: AuthenticationService,
    private utilsService: UtilsService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled =  !(form.valid && this.emailAddressIsValid && (this.nurseAppAccessRequired || this.patientAppAccessRequired));
      });
    this.addBtnConfig.disabled = !(form.valid && this.emailAddressIsValid && (this.nurseAppAccessRequired || this.patientAppAccessRequired));
  }

  inputValues(event: any){
    this.formDemoUserAdd.controls[event.target.name].setValue(event.target.value);

    if (event.target.name === 'phoneNumber') {
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT"));
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"));
    }

    if (event.target.name === 'countryIsoCode') {
      this.checkNurseAppAvailableForCountry(this.formDemoUserAdd.get('countryIsoCode')?.value?.countryIsoCode);

      this.utilsService.getParameterByCountry("flow_trigger__is_pd_enabled", this.formDemoUserAdd.get('countryIsoCode')?.value?.countryIsoCode).pipe().subscribe(ret => {
        this.isPDEnabled = ret == "True";

        this.updateTherapyModalities();
      });

      this.utilsService.getParameterByCountry("flow_trigger__is_hhd_enabled", this.formDemoUserAdd.get('countryIsoCode')?.value?.countryIsoCode).pipe().subscribe(ret => {
        this.isHHDEnabled = ret == "True";

        this.updateTherapyModalities();
      });      
    }    

    if(event.target.name === 'therapyModality'){
      this.formDemoUserInfo.radioButton.selected = event.target.value;
      this.formDemoUserAdd.controls[event.target.name].setValue(event.target.value);
    }

    this.formValidation(this.formDemoUserAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/demo-users');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/demo-users/detail', this.id]);
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    this.parametersNurseAppAvailability = this.utilsService.getParametersAppBeforeAuth().filter((x: { parameterKey: string; }) => x.parameterKey == 'nurse_app_available');

    this.checkNurseAppAvailableForCountry(this.authService.currentUserValue.countryIsoCode!);

    this.countryList = this.getAllCountry();
    this.cultureList = this.getAllCulture();
    this.authService.getAllCountryPhonePrefix(this.id, this.formDemoUserInfo, this.formDemoUserAdd);

    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE');
      this.getDemoUserDetail();
    }
    else{
      this.patientAppAccessRequired = true;
      this.formDemoUserAdd.controls["phonePrefix"].setValidators([Validators.required]);
      this.formDemoUserAdd.controls["phoneNumber"].setValidators([Validators.required]);
      this.formDemoUserAdd.controls['phonePrefix'].updateValueAndValidity();
      this.formDemoUserAdd.controls['phoneNumber'].updateValueAndValidity();
    }
    
    if(this.authService.currentUserValue.countryIsoCode != undefined){

      this.updateTherapyModalities();

      this.formDemoUserAdd.controls["countryIsoCode"].setValidators([]);
    }
    
    this.loading = false;
  }

  ngAfterViewInit() {
    this.formDemoUserAdd.get("email")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      if(this.previousEmail != this.formDemoUserAdd.get("email")?.value)
        this.checkEmailAddressIsValid();
    });
  }

  getDemoUserDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.demoUserGetV2(data).subscribe(
      response =>{

        this.demoUserDetails = response;
        this.previousEmail = response.emailAddress;

        this.formDemoUserAdd.patchValue({
          name: response.name,
          surname: response.surname,
          email: response.emailAddress,
          phoneNumber: response.mobileNumber,
          countryIsoCode: { countryIsoCode: response.countryIsoCode },
          cultureIsoCode: { cultureIsoCode: response.cultureIsoCode },
          phonePrefix: { phonePrefix: response.mobileNumberCountryPrefix, countryIsoCode: response.mobileNumberCountryPrefixCode ?? null },
          therapyModality: response.therapyModality,
        });

        this.formDemoUserInfo!.input[0].value = this.formDemoUserAdd.get("name")?.value;
        this.formDemoUserInfo!.input[1].value = this.formDemoUserAdd.get("surname")?.value;
        this.formDemoUserInfo!.input[2].value = this.formDemoUserAdd.get("email")?.value;
        this.formDemoUserInfo!.input[3].value = this.formDemoUserAdd.get("phoneNumber")?.value;

        this.formDemoUserInfo!.radioButton.selected = response.therapyModality!;
        this.formDemoUserInfo!.radioButton = {...this.formDemoUserInfo!.radioButton}

        let countryName: string = this.getTranslation("COUNTRY_" + response.countryIsoCode);
        if(countryName.startsWith("COUNTRY_")){
          countryName = response.countryIsoCode!;
        }
        this.formDemoUserInfo!.autocompleteCountryConfig.value = { 
          countryIsoCode: this.demoUserDetails.countryIsoCode,
          timeZoneName: countryName
        }

        let cultureName: string = this.getTranslation("CULTURE_" + response.cultureIsoCode);
        if(cultureName.startsWith("CULTURE_")){
          cultureName = response.cultureIsoCode!;
        }
        this.formDemoUserInfo!.autocompleteCultureConfig.value = { 
          cultureIsoCode: this.demoUserDetails.cultureIsoCode,
          cultureName: cultureName
        }
        
        this.formDemoUserInfo!.autocompleteCountryPhonePrefixConfig.value = this.demoUserDetails.mobileNumberCountryPrefix ? 
        { 
          phonePrefix: this.demoUserDetails.mobileNumberCountryPrefix ?? null,
          countryIsoCode: this.demoUserDetails.mobileNumberCountryPrefixCode ?? null
        } : null as any;
        
        this.formDemoUserInfo!.autocompleteCountryConfig = {...this.formDemoUserInfo!.autocompleteCountryConfig}
        this.formDemoUserInfo!.autocompleteCultureConfig = {...this.formDemoUserInfo!.autocompleteCultureConfig}
        this.formDemoUserInfo!.autocompleteCountryPhonePrefixConfig = {...this.formDemoUserInfo!.autocompleteCountryPhonePrefixConfig}
        
        if(this.demoUserDetails?.accountType?.includes("PATIENT"))
        {
          this.patientAppAccessRequired = true;
          this.formDemoUserAdd.controls["phonePrefix"].setValidators([Validators.required]);
          this.formDemoUserAdd.controls["phoneNumber"].setValidators([Validators.required]);
          this.formDemoUserAdd.controls['phonePrefix'].updateValueAndValidity();
          this.formDemoUserAdd.controls['phoneNumber'].updateValueAndValidity();
        }
        else{
          this.patientAppAccessRequired = false;
        }

        this.formValidation(this.formDemoUserAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  getAllCountry(): any{
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{

        response.forEach(element => {
          element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
          if(element.timeZoneName.startsWith("COUNTRY_")){
            element.timeZoneName = element.countryIsoCode;
          }
        });
        this.formDemoUserInfo.autocompleteCountryConfig.array = response;
        this.formDemoUserInfo.autocompleteCountryConfig.disabled = false;
      } ,
      error=> console.log(error)
    );
  }

  getAllCulture(): any{
    this.clientService.cultureGetAll().subscribe(
      response =>{

        response.forEach(element => {
          element.cultureName = this.getTranslation("CULTURE_" + element.cultureIsoCode);
          if(element.cultureName.startsWith("CULTURE_")){
            element.cultureName = element.cultureIsoCode;
          }
        });
        this.formDemoUserInfo.autocompleteCultureConfig.array = response;
        this.formDemoUserInfo.autocompleteCultureConfig.disabled = false;
      } ,
      error=> console.log(error)
    );
  }

  saveDemoUser(){
    if (this.formDemoUserAdd.valid) {

      this.selectedCountry = this.formDemoUserAdd.get('countryIsoCode')?.value?.countryIsoCode ?? this.authService.currentUserValue.countryIsoCode;

      this.loading = true;
      let demoUser: IDemoUserSetRQ = {
            idClinic: this.id,
            name: this.formDemoUserAdd.get('name')?.value,
            surname: this.formDemoUserAdd.get('surname')?.value,
            countryIsoCode: this.selectedCountry,
            cultureIsoCode: this.formDemoUserAdd.get('cultureIsoCode')?.value?.cultureIsoCode,
            emailAddress: this.formDemoUserAdd.get('email')?.value,
            mobileNumberCountryPrefixCode: this.formDemoUserAdd.get('phonePrefix')?.value?.countryIsoCode ?? null,
            mobileNumberCountryPrefix: this.formDemoUserAdd.get('phonePrefix')?.value?.phonePrefix ?? null,
            mobileNumber: this.formDemoUserAdd.get('phoneNumber')?.value,
            nurseAppAccessRequired: this.nurseAppAccessRequired,
            patientAppAccessRequired: this.patientAppAccessRequired,
            therapyModality: this.formDemoUserAdd.get('therapyModality')?.value,
          }
      this.clientService.demoUserSet(new DemoUserSetRQ(demoUser)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){
            
            this.idCreated = response.idEntity;

            if(!this.id){
              var message = "";
              if(this.patientAppAccessRequired){
                message += this.getTranslation("DEMO_USER_ADD_SUCCESSFULLY_PATIENTAPP");
              }
              if(this.nurseAppAccessRequired){
                message += this.getTranslation("DEMO_USER_ADD_SUCCESSFULLY_NURSEAPP");
              }
              this.successModalDescription = this.getTranslation("DEMO_USER_ADD_SUCCESSFULLY_DESC") + `<ul>${message}</ul>`;
              
              this.modalService.setModalData({ 
                title: 'DEMO_USER_ADD_SUCCESSFULLY',
                description: this.successModalDescription,
                actionToDo: () => 
                {
                  this.router.navigate(['/demo-users/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              this.utilsService.addValidationError(this.validationErrors, this.getTranslation(response?.errorType));
              if(response?.errorType == "ERROR_DEMOUSER_ALREADY_EXISTS"){
                this.formDemoUserInfo.input[0].class = "input-form error";
                this.formDemoUserInfo.input[1].class = "input-form error";
              }
              else if(response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT" || response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"){
                this.formDemoUserInfo.input[3].class = "input-form error";
                this.formDemoUserInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
              }
            }
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  checkEmailAddressIsValid(){      
    this.utilsService.checkEmailAddressIsValid(this.demoUserDetails?.idNurse, this.formDemoUserAdd.get('email')?.value, "NURSE").pipe(first()).subscribe(
      data => {
        this.emailAddressIsValid = data;
        if(!data) {
          this.formDemoUserInfo.input[2].class = "input-form error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        this.formValidation(this.formDemoUserAdd);
      }
    )
  }

  checkNurseAppAccessRequired(){
    this.nurseAppAccessRequired = !this.nurseAppAccessRequired;
    this.formValidation(this.formDemoUserAdd);
  }

  checkPatientAppAccessRequired(){
    this.patientAppAccessRequired = !this.patientAppAccessRequired;

    //CHANGE VALIDATOR MOBILE NUMBER
    var validators: ValidatorFn | ValidatorFn[] | null = [];

    if(this.patientAppAccessRequired){
      validators = [Validators.required];
    }
    
    this.formDemoUserAdd.controls["phonePrefix"].setValidators(validators);
    this.formDemoUserAdd.controls["phoneNumber"].setValidators(validators);
    this.formDemoUserAdd.controls['phonePrefix'].updateValueAndValidity();
    this.formDemoUserAdd.controls['phoneNumber'].updateValueAndValidity();

    this.formValidation(this.formDemoUserAdd);
  }

  checkNurseAppAvailableForCountry(countryIsoCode: string){
    
    if(countryIsoCode != null){
      this.nurseAppAvailable = !this.parametersNurseAppAvailability.some((x: { countryIsoCode: string | undefined; }) => x.countryIsoCode == countryIsoCode);
      
      if(!this.nurseAppAvailable)
        this.nurseAppAccessRequired = false;
    }
  }

  updateTherapyModalities(){
    this.isTherapyModalityVisible = this.isPDEnabled && this.isHHDEnabled;
        
    if(this.isTherapyModalityVisible == false){

      if(this.isPDEnabled)
      {
        this.formDemoUserInfo!.radioButton.selected = 'PD';
        this.formDemoUserInfo!.radioButton = {...this.formDemoUserInfo!.radioButton}
      }
      else if(this.isHHDEnabled){
        this.formDemoUserInfo!.radioButton.selected = 'HHD';
        this.formDemoUserInfo!.radioButton = {...this.formDemoUserInfo!.radioButton}
      }

      this.formDemoUserAdd.patchValue({ therapyModality: this.formDemoUserInfo!.radioButton.selected });
    }
  }
}
