import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/core';

@Component({
  selector: 'app-invitation-box',
  templateUrl: './invitation-box.component.html',
  styleUrls: ['./invitation-box.component.css']
})
export class InvitationBoxComponent implements OnInit {

  loading: boolean = true;

  constructor(
    private translate: TranslateService,
    private clientService: ClientService) { }

  @Input() title: any;
  @Input() description: any;
  @Input() info: any;
  @Input() plannedDesc: any;
  
  @Input() registrationCodes: any;
  @Input() isNurse: any;

  @Output() onClick : EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick2 : EventEmitter<any> = new EventEmitter<any>();

  sendCode(event: any){
    this.onClick.emit(event);
  }

  sendCodePlanned(event: any){
    this.onClick2.emit(event);
  }
  
  ngOnInit(): void {
  }

  btnConfigSendCode = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_SENDCODE'),
    width: '30px',
    height: '30px'
  }
  
  btnConfigSendCodeNow = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_SEND_NOW'),
    width: '30px',
    height: '30px'
  }

  btnConfigSendCodePlanned = {
    bclass : 'btn btn-primary add-patient-button mt-2',
    text : this.getTranslation('BTN_PLAN_LATER'),
    width: '30px',
    height: '30px'
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }
}
