import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent implements OnInit {
  @Input() SnackText: any;
  @Input() IconSrc: any;
  @Input() ShowSnack: any;

  constructor() { }

  ngOnInit(): void {
  }
}
