<ngx-smart-modal #clinicNameModal identifier="clinicNameModal" [style.height.px]="'900'" [style.width.px]="'1400'" data-backdrop="static" id="clinicNameModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="modal-header">
  
      <h2>{{ 'CLINIC_EDIT_TITLE' | translate}}</h2>
  
      <app-round-button [type]="'CLOSE'" (onClick)="closeModal()"></app-round-button>
    </div>
    <div class="container-fluid">

        <app-input [inputConfig]="formClinicInfo" (keyup)="inputValues($event)"></app-input>

        <div class="w-100 d-flex justify-content-center my-4">
            <app-button [buttonConfig]="okBtnConfig" (onClick)="setClinicName()"></app-button>
        </div>
    </div>
  </ngx-smart-modal>