import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, ISetRequestRQ, RequestTypeEnum, SetRequestRQ, SubRequestTypeEnum } from 'src/app/core';

@Component({
  selector: 'app-forget-password-modal',
  templateUrl: './forget-password-modal.component.html',
  styleUrls: ['./forget-password-modal.component.css']
})
export class ForgetPasswordModalComponent implements OnInit{
  constructor(
    private clientService: ClientService,
    private smartModal : NgxSmartModalService,
    private translate: TranslateService
  ) { }

  emailInput = {name: this.getTranslation('LABEL_EMAIL'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'username', id: 'email-input', value: '', errorMessage: "" }
  
  getEmailValue(event:any){
    if(event.target.value === ''){
      this.sendLinkBtnConfig.disabled = true
    }else{
      this.sendLinkBtnConfig.disabled = false
    }
    this.emailInput.value = event.target.value
  }
  
  ngOnInit(): void {
    this.currentStep = this.routingStep[0];
  }


  routingStep: String[] = ['forget','reset','success']
  currentStep?: String;

  nextBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('LABEL_RESET_PASSWORD'),
    disabled : false,
    id: 'forgot-password-modal-button'
  }
  sendLinkBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('BTN_SENDLINK'),
    disabled : true,
    id: 'reset-link-button'
  }
  doLaterBtnConfig = {
    bclass : 'btn normal-simple-button pad-button',
    type : 'submit',
    text : this.getTranslation('BTN_DOLATER'),
    disabled : false
  }
  okBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('BTN_OK'),
    disabled : false
  }

  goToStep(step: String){
    this.currentStep = step;
  }
  backStep(){
    if(this.currentStep !== 'forget'){
      this.currentStep = this.routingStep[this.routingStep.indexOf(this.currentStep!) -1];
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  sendLinkRequest(){

    let req : ISetRequestRQ = {
      idEntity: undefined, 
      login: this.emailInput.value,
      requestType: RequestTypeEnum.BackOfficeRecoverPassword,
       requestSubType: SubRequestTypeEnum.GenericRequest
    } 
    this.clientService.setRequest(new SetRequestRQ(req)).subscribe(
      res=>{
        this.goToStep("success");
      },
      error=>console.log(error)
    )
  }

}
