<ngx-smart-modal #clinicListingModal identifier="clinicListingModal" [style.height.px]="'900'" [style.width.px]="'1400'" data-backdrop="static" id="compareEntityModal" data-keyboard="false" [closable]="false" [dismissable]="false">
    <div class="modal-header">
  
      <h2>{{ 'CLINIC_LISTING_LABEL' | translate }}</h2>
  
      <app-round-button [type]="'CLOSE'" (onClick)="closeModal()"></app-round-button>
    </div>
    <div class="release-content">

        <ng-container>
            
            <div class="col-lg-4 col-md-auto">
                <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)" ></app-search-field>
            </div>

            <br/>

            <!--CATEGORIES-->
            <div class="col-12 col-lg-12" *ngIf="filteredSubCategoryGroups?.length != 0">
                <ul *ngFor="let group of filteredSubCategoryGroups">
                    <li class="edit_link" (click)="openSubCategory(group)">
                        <b>{{group.subCategoryName}}</b>
                        &nbsp;
                        <i [ngClass]="group.isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                    </li>

                    <br *ngIf="group.isExpanded"/>
                    
                    <!--PRODUCTS-->
                    <table *ngIf="group.isExpanded" class="table">
                        <thead class="thead" >
                            <tr class="table-header">
                            <ng-container *ngFor="let header of headers">
                                <th>
                                <span [appSort]= "group.products" (click)="orderTableChanged(header.field)" data-order="desc" [attr.data-name]="header.field" scope="col">
                                    {{header.name}} <svg-icon src="../../../assets/icons/sort-icon.svg" ></svg-icon>
                                </span>
                                </th>
                            </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of group.products">
                                <td scope="row">{{record.product?.productName}}</td>
                                <td scope="row">{{record.product?.articleNumber}}</td>
                                <td>
                                    <app-checkbox [checked]="record.product?.isSelected"></app-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>
            </div>
        </ng-container>
    </div>
  </ngx-smart-modal>