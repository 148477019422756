<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <h2 class="mt-4">{{ (id == undefined ? isCloneMode ? 'CALENDAR_CLONE' : 'CALENDAR_ADD_TITLE' : 'CALENDAR_EDIT_TITLE') | translate}}</h2>

        <form [formGroup]="formCalendarAdd">

            <!--FORM RESULT-->

            <div class="row my-2">
                <div class="col-md-6 col-10">
                    <app-input [inputConfig]="formCalendarInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div> 
            </div>

            <div class="row my-2">
                <div class="col-md-6 col-10">
                    <app-input [inputConfig]="formCalendarInfo.input[1]" (keyup)="inputValues($event)"></app-input>
                </div> 
            </div>

            <div *isGlobalUser class="row my-2">
                <div class="col-md-6 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompleteCountryConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <hr/>

            <h4> {{ 'CALENDAR_DELIVERY_DATE' | translate }}</h4>
            
            <div class="row my-2">
                <div class="col-md-6 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompleteBaseWeekConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompleteWeekDayConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompleteOrderFrequencyConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>
            
            <br/>

            <div *ngIf="firstDeliveryDate != undefined">
                {{ ('CALENDAR_DELIVERY_DATE_SUGGESTED' | translate) + ' ' + (firstDeliveryDate | DateFormatPipe) }}
            </div>

            <hr/>

            <h4> {{ 'CALENDAR_PICKING' | translate }}</h4>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompletePickingWeekDayConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompletePickingWeekConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <br/>

            <div *ngIf="firstPickingDate != undefined">
                {{ ('CALENDAR_PICKING_DATE_SUGGESTED' | translate) + ' ' + (firstPickingDate | DateFormatPipe) }}
            </div>

            <hr/>

            <h4> {{ 'CALENDAR_DUE_DATE' | translate }}</h4>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompleteDueDateWeekDayConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formCalendarInfo?.autocompleteDueDateWeekConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                </div>
            </div>

            <br/>

            <div *ngIf="firstDueDate != undefined">
                {{ ('CALENDAR_DUE_DATE_SUGGESTED' | translate) + ' ' + (firstDueDate | DateFormatPipe) }}
            </div>            

            <br/><br/>

            <app-error-box *ngIf="this.validationError" [text]="validationError"></app-error-box>
                
            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveCalendar()"></app-button>
                </div>
            </div>
            
            <br/>
        </form>
    </div>
</ng-template>