<div class="simple-radioButtons-box" [ngClass]="getBoxClass()">
    <ng-container *ngFor="let radio of radioButtonConfig.values; let last = last">
        <div class="radiobutton" (click)="emitValue(radio.value)" [class.thin]="radioButtonConfig.withLessMargin" [ngClass]="isFocusedValue === radio.value ? 'focused' : ''">
            <input type="radio" [name]="radioButtonConfig.radioButtonName"  (focus)="onInputFocus(radio.value)" (focusout)="onInputFocus(null)">
            <div class="main-row">
                <div class="normal-circle">
                    <div class="selectet-ball" *ngIf="radioButtonConfig.selectedValue === radio.value"></div>
                </div>
                <app-text [textColor]="this.radioButtonConfig.disabled ? '#898a95' : ''" [fontSizePx]="16" [text]="radio.label" [isSpan]="true" [isBold]="true"></app-text>
                <app-text *ngIf="radio.sideText" [textType]="'GREY'" [fontSizePx]="12" [text]="radio.sideText" [isSpan]="true" [extraClass]="'ml-2'" [extraStyle]="{'font-style' : 'italic'}"></app-text>
            </div>
            <app-text *ngIf="radio.underText" [textType]="'GREY'" [fontSizePx]="12" [text]="radio.underText" [extraStyle]="{'font-style' : 'italic', 'margin-left' : '2.5rem'}"></app-text>
        </div>
        <hr *ngIf="radioButtonConfig.withDivider && !last">
    </ng-container>
</div>
