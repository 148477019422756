import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { BackOfficeCalendarDatePatientView, CalendarDatePatientGetAllBackOfficeRQ, ClientService } from 'src/app/core';
import { DateService } from 'src/app/core/services/date.service';
import { getDayNameFromDayNumber } from 'src/app/core/utils/calendar-utils';

@Component({
  selector: 'app-patient-order-calendar-modal',
  templateUrl: './patient-order-calendar-modal.component.html',
  styleUrls: ['./patient-order-calendar-modal.component.css']
})
export class PatientOrderCalendarModalComponent implements OnInit, AfterViewInit {

  constructor(
    private clientService: ClientService,
    private smartModal: NgxSmartModalService,
    private date: DateService,
    private datePipe: DatePipe
  ) { }


  idPatient: string = ''
  subscriptions: Subscription[] = []
  deliveries: BackOfficeCalendarDatePatientView[] = []
  filteredDeliveries: BackOfficeCalendarDatePatientView[] = []
  loading = true;

  maxDate: NgbDateStruct = {year: (new Date().getFullYear() + 10), month: 1, day: 1 }


  // First prediction obtained
  firstPrediction?: BackOfficeCalendarDatePatientView;
  firstPredictionInMonth?: BackOfficeCalendarDatePatientView;
  otherPredictions?: BackOfficeCalendarDatePatientView[];
  isAccordionOpen = false;

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    let modalDataSubscription = this.smartModal.getModal('patientOrderCalendar').onOpen.subscribe(()=>{
      const { idPatient } = this.smartModal.getModal('patientOrderCalendar').getData();
      this.idPatient = idPatient;

      this.getDeliveries();
    })
    this.subscriptions.push(modalDataSubscription);
  }
  
  updateDeliveryList(e: {month: number, year: number}){
    this.filteredDeliveries = this.deliveries.filter(x=> (x.deliveryDate?.getFullYear() === e.year && x.deliveryDate!.getMonth() === (e.month - 1)));
    this.firstPredictionInMonth = this.filteredDeliveries[0]
    this.otherPredictions = this.filteredDeliveries.slice(1);
  }

  getDeliveries(){
    const request = new CalendarDatePatientGetAllBackOfficeRQ({ idPatient: this.idPatient, pageIndex: 1, sortOrder: "deliveryDate_asc" })
    this.clientService.calendarDatePatientGetAllBackOffice(request).subscribe(
      data=>{
        this.deliveries = data.list ?? [];
        this.loading = false;
        if(this.deliveries.length > 0){
          const finalDate = this.deliveries[this.deliveries.length - 1].closingWindowDate!;
          this.maxDate = this.getStructureFromDate(new Date (finalDate.getFullYear(), finalDate.getMonth() + 1, 0));

          this.firstPrediction = this.deliveries[0]
          this.otherPredictions = this.filteredDeliveries.slice(1);
        }
      }
    )
  }

  getDay = (date: Date) => getDayNameFromDayNumber(date.getDay())

  isFirstPredictionPresentInList = () => (
    this.firstPrediction && 
    this.filteredDeliveries.find(del => del.deliveryDate!.getTime() === this.firstPrediction!.deliveryDate!.getTime())
  )

  getDeliveryDate = (dt: Date) => this.date.simpleDateParseWithoutYear(dt);

  getPlannedRangeDays = (range: {from: Date, to: Date}) => range ? `${range.from.getDate()} - ${range.to.getDate()} ${this.datePipe.transform(range.to, 'MMMM')}` : 'empty range'
  getStructureFromDate = (date: Date | undefined): NgbDateStruct => ({year: date!.getFullYear(), month: date!.getMonth() + 1, day: date!.getDate() })
  
  extraDeliveriesPresent = () => this.otherPredictions!.length > 0


  closeModal(){
    this.smartModal.getModal('patientOrderCalendar').close();
  }
}
