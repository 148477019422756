<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="releaseNoteDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <app-button *ngIf="!releaseNoteDetail.dateReleased" (click)="setDateReleased()" [buttonConfig]="btnConfig"></app-button>

        <h2 class="mt-4">{{ releaseNoteDetail.releaseNoteType + ' (' + releaseNoteDetail.version + ')' }}</h2>       

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'RELEASE_NOTE_TYPE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ releaseNoteDetail.releaseNoteType }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'RELEASE_NOTE_VERSION' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ releaseNoteDetail.version }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'RELEASE_NOTE_FEATURE_INTRODUCED' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ releaseNoteDetail.featuresIntroduced }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ releaseNoteDetail.countries }}
            </div>
        </div>

        <ul *ngFor="let item of releaseNoteDetail.countryTranslations">
            <div class="col-12 col-lg-12">

                <table class="table w-5 mt-5">
                    <tr *ngIf="item.country == 'Generic'">
                        <th width="16.6%" scope="col" *ngFor="let cultureTranslation of item.cultureTranslations"> {{ 'CULTURE_' + cultureTranslation.cultureIsoCode | translate }} </th>
                    </tr>      
                    <th colspan="6">{{ (item.country != 'Generic' ? 'COUNTRY_' + item.country : item.country) | translate }}</th>
                    <tr>
                        <td width="16.6%" *ngFor="let cultureTranslation of item.cultureTranslations"> {{ cultureTranslation.resourceValue }} </td>
                    </tr>
                </table>
            </div>
        </ul>

        <hr class="my-4">


        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ releaseNoteDetail.dateCreated | DateFormatPipe: true }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DATE_RELEASED' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{releaseNoteDetail.dateReleased | DateFormatPipe: true}}
            </div>
        </div>

        <br>

    </div>
</ng-template>