<!-- <div class="input-group mb-3">
    <select class="form-select" id="clmn_name"  [(ngModel)]= "selectedOption">
      <option *ngFor="let header of tableConfig.headerList" [attr.value]="header.field">
        {{header.name}}
      </option>
    </select>
    <input (keypress)="handlePageChange(1)" type="text" class="form-control" id="kwd_search" placeholder="Ricerca per Categoria..." [(ngModel)]="searchText">
  </div> -->

  <table class="table">

    <thead class="thead" >
    <tr class="table-header">
      <ng-container *ngFor="let header of filterVisibleItems()">
        <th *appHasRole="header.role || ['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','SUPPORT_USER','CUSTOMER_SERVICE_USER']">
          <span *ngIf="header.sortable" [appSort]= "tableConfig.gridData" (click)="orderTableChanged(header.field)" data-order="desc" [attr.data-name]="header.field" scope="col">
            {{header.name}} <svg-icon src="../../../assets/icons/sort-icon.svg" ></svg-icon>
          </span>
          <span *ngIf="!header.sortable">
            {{header.name}}
          </span>
        </th>
      </ng-container>
      <th *ngIf="tableConfig.actions"></th>
    </tr>
    </thead>

    <!--
    (click)="onClickButton(record, tableConfig.recordButtonConfigs.get(record.id)[0].type)"
    $event.stopPropagation();
    -->
    <tbody>
    <tr *ngFor="let record of tableConfig.gridData | paginate: tableConfig.pagination" (click)="onClickButton(record, tableConfig.recordButtonConfigs.get(record).pop().type)">
      <ng-container *ngFor="let header of filterVisibleItems()">
        <td *appHasRole="header.role || ['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','SUPPORT_USER','CUSTOMER_SERVICE_USER']" [ngClass]="header.field">
          
          <ng-container [ngSwitch]="header.field">
            <ng-container *ngSwitchCase="'reviewNeeded'">
              <svg-icon *ngIf="record[header.field]" src="../../../assets/icons/error-small-icon.svg" class="d-flex" style="float: right"></svg-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'isDeleted'">
              <span>{{record[header.field] ? ('ACCOUNT_STATUS_DELETED' | translate) : ''}} </span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span *ngIf="header.isDate">{{record[header.field] | DateFormatPipe}}</span>
              <span *ngIf="header.isBoolean">
                <app-checkbox [checked]="record[header.field]"></app-checkbox>
              </span>
              <span [innerHtml]="record[header.field]" *ngIf="!header.isDate && !header.isBoolean">{{record[header.field]}} </span>
            </ng-container>
          </ng-container>
          
        </td> 
      </ng-container>

      <td *ngIf="tableConfig.actions">
        <span *ngFor="let buttonConfig of tableConfig.recordButtonConfigs.get(record)" class="table-btn-wrapper">
          <ng-container [ngSwitch]="buttonConfig.type">
            <ng-container *ngSwitchCase="'clone'">
              <div (click)="$event.stopPropagation(); onClickButton(record, buttonConfig.type)" class="text-right mr-3 cursor-pointer"><i class="fas fa-clone"></i></div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-button [buttonConfig]="buttonConfig" (onClick)="onClickButton(record, buttonConfig.type)"></app-button>
            </ng-container>
          </ng-container>
        </span>
      </td>
    </tr>
    </tbody>
  </table>
  <app-alert *ngIf="this.tableConfig==null || this.tableConfig.gridData==null || !(this.tableConfig.gridData.length>0)" [alertConfig]="alertConfig"></app-alert>

  <pagination-controls [id]="this.tableConfig.pagination.id" *ngIf="this.tableConfig.gridData !== undefined" nextLabel="{{'BTN_NEXT' | translate}}" previousLabel="{{'BTN_PREVIOUS' | translate}}"
                       (pageChange)="handlePageChange($event)"></pagination-controls>
