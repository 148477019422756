import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isDate } from 'lodash';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService, BackOfficeClinicView, BaseGetAllRQ, CheckEntityIsValidRQ, ClientService, EntityRQ, IBaseGetAllRQ, ICheckEntityIsValidRQ, INurseSetRQ, NurseRS, NurseSetRQ } from 'src/app/core';
import { atLeastOneValidator } from 'src/app/core/validator/least-one-validator';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DateService } from 'src/app/core/services/date.service';
import { Role } from 'src/app/core/enum/roles';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-nurse-edit',
  templateUrl: './nurse-edit.component.html',
  styleUrls: ['./nurse-edit.component.css']
})
export class NurseEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  nurseDetails?: NurseRS;

  loading: boolean = true;
  clinicList!: BackOfficeClinicView[];
  countryPhonePrefixList!: any | [];
  showSnackBar?: boolean;
  snackText?: string;
  validationErrors: any[] = [];

  mobileNumberIsValid: boolean = true;
  emailAddressIsValid: boolean = true;
  nurseIsValid: boolean = true;

  previousEmail: any = "";

  formNurseAdd: FormGroup = this.fb.group({
    clinic: [null, Validators.required],
    name: [null, Validators.required],
    surname: [null, Validators.required],
    gender: [null, Validators.required],
    phonePrefix: [null],
    phoneNumber: [null],
    workEmail: [null, [Validators.required, Validators.pattern(new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/))]],
  });

  formNurseInfo = {
    key: 'formNurseInfo',
    input:
      [
        { name: this.getTranslation('NURSE_ADD_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'name', value: this.formNurseAdd.get("name")?.value, maxLength: 64 },
        { name: this.getTranslation('NURSE_ADD_SURNAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'surname', value: this.formNurseAdd.get("surname")?.value, maxLength: 64 },
        { name: this.getTranslation('LABEL_MOBILE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'phoneNumber', value: this.formNurseAdd.get("phoneNumber")?.value, maxLength: 16 },
        { name: this.getTranslation('NURSE_ADD_WORKMAIL'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'workEmail', disabled: false, value: this.formNurseAdd.get("email")?.value, maxLength: 256 },
      ],
    radioButton:
    {
      id: 'genderRadio',
      count: '2',
      name: 'gender',
      labels: [this.getTranslation('PATIENT_GENDER_M'), this.getTranslation('PATIENT_GENDER_F'), this.getTranslation('PATIENT_GENDER_O')],
      values: ['M', 'F', 'O'],
      selected: '' as any,
      class: 'form4'
    },
    autocompleteCountryPhonePrefixConfig: {
      name: this.getTranslation('LABEL_COUNTRYPREFIX'),
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      value: null as any,
      inputName: 'phonePrefix',
      prefix: '+',
      class: 'form-control autocomplete-input',
      disabled: true,
      readonly: false,
    },
    clinicSelectConfig: {
      name: this.getTranslation('NURSE_ADD_CLINIC'),
      placeholder: this.getTranslation('SEACH_PLH_CLINIC_NAME'),
      attr: 'clinicName' as const,
      array: this.clinicList,
      numberToShow: 6,
      value: null as any,
      inputName: 'clinic',
      class: 'form-control autocomplete-input mb-2',
      disabled: true,
      readonly: false,
      optional: false,
    },
  }

  addBtnConfig = {
    bclass: 'btn normal-button-next',
    type: 'button',
    text: this.getTranslation('BTN_ADDNURSE'),
    disabled: true
  }

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private authService: AuthenticationService,
    private editService: EditServiceService,
    private utilsService: UtilsService,
    private date: DateService,
  ) {

  }



  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();
    
    this.authService.getAllCountryPhonePrefix(this.id, this.formNurseInfo, this.formNurseAdd);  
    
    if (this.id != undefined) {
      this.getNurseDetail();
    }
    else{
      this.getClinicList();
    }
  }

  ngAfterViewInit() {
    this.formNurseAdd.get("workEmail")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      if(this.previousEmail != this.formNurseAdd.get("workEmail")?.value)
        this.checkEmailAddressIsValid();
    });

    this.formNurseAdd.get("phoneNumber")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkMobileIsValid();
    });

    this.formNurseAdd.get("phonePrefix")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkMobileIsValid();
    });

    this.formNurseAdd.get("name")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkNurseIsValid();
    });

    this.formNurseAdd.get("surname")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkNurseIsValid();
    });

    this.formNurseAdd.get("clinic")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkNurseIsValid();
    });
  }

  getNurseDetail() {
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.nurseGet(data).subscribe(
      response => {

        this.nurseDetails = response;
        this.previousEmail = response.emailAddress;

        this.addBtnConfig.text= this.getTranslation('BTN_COMMON_SAVE');

        this.formNurseAdd.patchValue({
          name: response.name,
          surname: response.surname,
          gender: response.gender,
          phoneNumber: response.mobileNumber,
          workEmail: response.emailAddress,
          phonePrefix: { phonePrefix: response.mobileNumberCountryPrefix, countryIsoCode: response.mobileNumberCountryPrefixCode ?? null },
          clinic: { idClinic: response.idClinic },
        });

        this.formNurseInfo!.input[0].value = this.formNurseAdd.get("name")?.value;
        this.formNurseInfo!.input[1].value = this.formNurseAdd.get("surname")?.value;
        this.formNurseInfo!.input[2].value = this.formNurseAdd.get("phoneNumber")?.value;
        this.formNurseInfo!.input[3].value = this.formNurseAdd.get("workEmail")?.value;
        this.formNurseInfo!.clinicSelectConfig.value = { idClinic: this.nurseDetails.idClinic, clinicName: this.nurseDetails.clinicName }
        this.formNurseInfo!.radioButton.selected = this.nurseDetails.gender;

        this.formNurseInfo!.autocompleteCountryPhonePrefixConfig.value = this.nurseDetails.mobileNumberCountryPrefix ? 
        { 
          phonePrefix: this.nurseDetails.mobileNumberCountryPrefix ?? null,
          countryIsoCode: this.nurseDetails.mobileNumberCountryPrefixCode ?? null
        } : null as any;
        this.formNurseInfo!.clinicSelectConfig = {...this.formNurseInfo!.clinicSelectConfig}
        this.formNurseInfo!.radioButton = {...this.formNurseInfo!.radioButton}

        this.formValidation(this.formNurseAdd);

        this.formNurseInfo!.autocompleteCountryPhonePrefixConfig = {...this.formNurseInfo!.autocompleteCountryPhonePrefixConfig}

        this.loading = false;
      },
      error => console.log(error)
    );
  }


  formValidation(form: FormGroup) {
    this.addBtnConfig.disabled = !(form.valid && this.mobileNumberIsValid && this.emailAddressIsValid && this.nurseIsValid);
  }

  inputValues(event: any) {
    if (event.target.name === 'gender') {
      this.formNurseInfo.radioButton.selected = event.target.value;
    }

    if (event.target.name === 'phoneNumber') {
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT"));
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"));
    }

    this.formNurseAdd.controls[event.target.name].setValue(event.target.value);

    this.formValidation(this.formNurseAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/nurses');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/nurses/detail', this.id]);
    }
  }


  addNurse() {
    
    if (this.formNurseAdd.valid) {
      this.loading = true;
      let input: INurseSetRQ = {
        idNurse: this.id,
        name: this.formNurseAdd.get('name')?.value,
        surname: this.formNurseAdd.get('surname')?.value,
        emailAddress: this.formNurseAdd.get('workEmail')?.value,
        idClinic: this.formNurseAdd.get('clinic')?.value.idClinic,
        gender: this.formNurseAdd.get('gender')?.value,
        mobileNumberCountryPrefixCode: this.formNurseAdd.get('phoneNumber')?.value != "" ? 
                                  this.formNurseAdd.get('phonePrefix')?.value?.countryIsoCode ?? null : null,
        mobileNumberCountryPrefix: this.formNurseAdd.get('phoneNumber')?.value != "" ? 
                                  this.formNurseAdd.get('phonePrefix')?.value?.phonePrefix ?? null : null,
        mobileNumber: this.formNurseAdd.get('phoneNumber')?.value ?? null,
        therapyModality: this.formNurseAdd.get('clinic')?.value.isPdenabled ? "PD" : "HHD",
      }

      this.clientService.nurseSet(new NurseSetRQ(input)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){
            
            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'NURSE_ADD_SUCCESSFULLY',
                description: this.formNurseAdd.get('clinic')?.value?.clinicName,
                actionToDo: () => 
                {
                  this.router.navigate(['/nurse/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              this.utilsService.addValidationError(this.validationErrors, this.getTranslation(response?.errorType));

              if(response?.errorType == "ERROR_EMAIL_ALREADY_EXISTS")
                this.formNurseInfo.input[3].class = "input-form error";
              else if(response?.errorType == "ERROR_NURSE_ALREADY_EXISTS"){
                this.formNurseInfo.input[0].class = "input-form error";
                this.formNurseInfo.input[1].class = "input-form error";
                this.formNurseInfo.clinicSelectConfig.class = "form-control autocomplete-input error";
              }
              else if(response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT" || response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"){
                this.formNurseInfo.input[2].class = "input-form error";
                this.formNurseInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
              }
            }
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      );
    }
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() => {
      this.showSnackBar = false;
    }, 5000);
  }

  getClinicList() {
    let input: IBaseGetAllRQ = {
      pageIndex: undefined,
      sortOrder: "ClinicName_asc",
      searchString: "",
      city: ""
    }
    this.clientService.clinicGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response => {
        response.list?.forEach(x => x.clinicName = x.sapUuid + " " + x.clinicName);
        this.clinicList = response.list || [];
        this.formNurseInfo.clinicSelectConfig.array = (response.list ?? []).filter(({ status }) => status !== 'DEACTIVATED');
        this.formNurseInfo.clinicSelectConfig.disabled = false;
        this.loading = false;
      },
      error => console.log(error)
    );
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  checkMobileIsValid(){
    
    if(this.authService.currentUserValue.isDuplicateContactValidationSkipped)
      return;

    this.formNurseInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input";
      
    this.utilsService.checkMobileNumberIsValid(this.nurseDetails?.idUserIdentity, this.formNurseAdd.get('phonePrefix')?.value?.countryIsoCode, this.formNurseAdd.get('phonePrefix')?.value?.phonePrefix, this.formNurseAdd.get('phoneNumber')?.value, "NURSE").pipe(first()).subscribe(
      data => {
        this.mobileNumberIsValid = data;
        if(!data) {
          this.formNurseInfo.input[2].class = "input-form error";
          this.formNurseInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_ALREADY_EXISTS"));
        }
        this.formValidation(this.formNurseAdd);
      }
    )
  }

  checkEmailAddressIsValid(){      
    this.utilsService.checkEmailAddressIsValid(this.nurseDetails?.idUserIdentity, this.formNurseAdd.get('workEmail')?.value, "NURSE").pipe(first()).subscribe(
      data => {
        this.emailAddressIsValid = data;
        if(!data) {
          this.formNurseInfo.input[3].class = "input-form error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        this.formValidation(this.formNurseAdd);
      }
    )
  }

  checkNurseIsValid(){      

    this.formNurseInfo.input[0].class = "input-form";
    this.formNurseInfo.input[1].class = "input-form";
    this.formNurseInfo.clinicSelectConfig.class = "form-control autocomplete-input";

    let input: ICheckEntityIsValidRQ = {
      idEntity: this.nurseDetails?.idNurse,
      name: this.formNurseAdd.get('name')?.value,
      surname: this.formNurseAdd.get('surname')?.value,
      idClinic: this.formNurseAdd.get('clinic')?.value?.idClinic,
    }
      
    return this.clientService.checkNurseIsValid(new CheckEntityIsValidRQ(input)).subscribe(
      data => {
        this.nurseIsValid = data;
        if(!data) {
          this.formNurseInfo.input[0].class = "input-form error";
          this.formNurseInfo.input[1].class = "input-form error";
          this.formNurseInfo.clinicSelectConfig.class = "form-control autocomplete-input error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_NURSE_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_NURSE_ALREADY_EXISTS"));
        }
        this.formValidation(this.formNurseAdd);
      }
    )
  }
}


