<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="clinicDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true">
                <svg-icon src="assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>

        <div *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']" class="row" [class.edit-disabled]="isClinicDeactivated">
            <div class="col-12 text-right">
                <ng-container *ngIf="!clinicDetail.isCmsPatientSyncEnabled">        
                    <app-button  *appHasRole="['SUPER_ADMIN']"
                        (click)="activateSync()" [buttonConfig]="activateSyncBtnConfig"></app-button>
                </ng-container>
                
                <a (click)="EditClinic()" style="padding-left: 3%;" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA'
                        | translate }} </u></a>
            </div>
        </div>

        <div class="mt-4 d-flex flex-row align-items-center">
            <h2>{{ clinicDetail.shipToClinicName }}</h2>
            <div class="ml-5 deactivated-box" *ngIf="isClinicDeactivated">
                <img src="assets/icons/error-small-icon.svg" alt="">
                <span class="ml-3">{{'CLINIC_DEACTIVATED_LABEL' | translate }}</span>
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b *ngIf="!isPharmacyRelatedToClinic">{{ 'CLINIC_DETAIL_TYPE' | translate }}:</b>
                <b *ngIf="isPharmacyRelatedToClinic">{{ 'PHARMACY_DETAIL_TYPE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isFirstPartyClinic ? 'CLINIC_DETAIL_FIRSTPARTY' : 'CLINIC_DETAIL_THIRDPARTY') | translate }}
            </div>
        </div>

        <hr/>

        <div *ngIf="clinicDetail.hierarchyClinic! && clinicDetail.hierarchyClinic!.children!.length > 0">
            <tree-node [node]="clinicDetail.hierarchyClinic!"></tree-node>

            <hr *ngIf="clinicDetail.hierarchyClinic!"/>
        </div>
        
        <!-- SHIP TO CLINIC -->
        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_SHIP_TO' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                SapCode: {{clinicDetail.shipTo || clinicDetail.sapUuid}}
                <br/>
                {{clinicDetail.shipToClinicName}}
                <br/>
                {{clinicDetail.shipToAddress + ", " + clinicDetail.shipToCity + " (" + clinicDetail.shipToZipCode + ")" }}
            </div>
        </div>

        <div *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']" class="row" [class.edit-disabled]="isClinicDeactivated">
            <div class="col-12 text-right">
                <a (click)="EditClinicName()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA'
                        | translate }} </u></a>
            </div>
        </div>

        <!-- SOLD TO CLINIC -->
        <div class="row mt-4" *ngIf="clinicDetail.soldTo != null">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_SOLD_TO' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                SapCode: {{clinicDetail.soldTo}}
                <br/>
                {{clinicDetail.soldToClinicName}}
                <br/>
                {{clinicDetail.soldToAddress + ", " + clinicDetail.soldToCity + " (" + clinicDetail.soldToZipCode + ")" }}
            </div>
        </div>

        <!-- PAYER CLINIC -->
        <div class="row mt-4" *ngIf="clinicDetail.payer != null">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_PAYER' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                SapCode: {{clinicDetail.payer}}
                <br/>
                {{clinicDetail.payerClinicName}}
                <br/>
                {{clinicDetail.payerAddress + ", " + clinicDetail.payerCity + " (" + clinicDetail.payerZipCode + ")" }}
            </div>
        </div>

        <hr/>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_DETAIL_COUNTRY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{clinicDetail.country}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_LANGUAGE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ 'CULTURE_' + clinicDetail.cultureIsoCode | translate }}
            </div>
        </div>

        <div class="row mt-4" *ngIf="isPharmacyRelatedToClinic">
            <div class="col-6 col-lg-3">
                <b>{{ 'PHARMACY_LABEL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{clinicDetail.pharmacyName}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_ADD_IPD' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isInCenterOrderingEnabled ? 'LABEL_ENABLED' : 'LABEL_DISABLED') | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_ADD_HD' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isInCenterHDOrderingEnabled ? 'LABEL_ENABLED' : 'LABEL_DISABLED') | translate }}
            </div>
        </div>

        <div *ngIf="clinicDetail.isInCenterHDOrderingEnabled">
            <div class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{ 'CLINIC_LOGISTIC_SERVICE' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{ clinicDetail.logisticService }}
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{ 'CLINIC_LOADING_EQUIPMENT' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{ ('PACKAGE_TYPE_' + clinicDetail.loadingEquipmentByContract) | translate }}
                </div>
            </div>

            <table style="width: auto !important" class="table w-5 mt-5" [class.edit-disabled]="isClinicDeactivated">
                <h5 class="col-8">{{ 'CLINIC_UNLOADING_POINT' | translate }}</h5>
                <tr>
                    <th scope="col">{{ 'LABEL_NAME' | translate }}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <ng-container *ngFor="let unloadingPoint of unloadingPoints">
                    <tr>
                        <td scope="row" *ngIf="!unloadingPoint.isEditMode" [ngStyle]="{'text-decoration': unloadingPoint.dateDeleted ? 'line-through' : 'none'}">
                            {{ unloadingPoint.name }}
                        </td>
                        <td scope="row" *ngIf="unloadingPoint.isEditMode">
                            <input [(ngModel)]="unloadingPoint.name" type="text" />
                        </td>
                        <td scope="row">
                            <i *ngIf="!unloadingPoint.isEditMode" (click)="editUnloadingPoint(unloadingPoint)" class="fas fa-pencil-alt edit_link"></i>
                            <i *ngIf="unloadingPoint.isEditMode" (click)="setUnloadingPoint(unloadingPoint)" class="fas fa-check edit_link"></i>
                        </td>
                        <td scope="row">
                            <i (click)="deleteUnloadingPoint(unloadingPoint)" [ngClass]="unloadingPoint.dateDeleted ? 'fas fa-redo' : 'fas fa-trash'"></i>
                        </td>
                    </tr>
                </ng-container>
                <tr>
                    <td scope="row" *ngIf="!newUnloadingPoint.isEditMode">
                        <i (click)="editUnloadingPoint(newUnloadingPoint)" class="fas fa-plus edit_link"></i>
                    </td>
                    <td scope="row" *ngIf="newUnloadingPoint.isEditMode">
                        <input [(ngModel)]="newUnloadingPoint.name" type="text" />
                    </td>
                    <td scope="row">
                        <i *ngIf="newUnloadingPoint.isEditMode" (click)="setUnloadingPoint(newUnloadingPoint)" class="fas fa-check edit_link"></i>
                    </td>
                </tr>
            </table>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_ADD_HHD' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isInCenterHHDOrderingEnabled ? 'LABEL_ENABLED' : 'LABEL_DISABLED') | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_SYNC_PATIENTS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isCmsPatientSyncEnabled ? 'LABEL_ENABLED' : 'LABEL_DISABLED') | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_SYNC_PRESCRIPTIONS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isCmsPrescriptionSyncEnabled ? 'LABEL_ENABLED' : 'LABEL_DISABLED') | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'CLINIC_SYNC_ORDERS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (clinicDetail.isCmsOrderSyncEnabled ? 'LABEL_ENABLED' : 'LABEL_DISABLED') | translate }}
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{clinicDetail.dateCreated | DateFormatPipe: true }}
            </div>
        </div>

        <div class="row mt-4" *ngIf="clinicDetail.dateDeleted">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_DELETIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{clinicDetail.dateDeleted | DateFormatPipe: true }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_STATUS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{'CLINIC_STATUS_' + clinicDetail.clinicStatus | translate }}
            </div>
        </div>

        <br/><br/>

        <b>{{ 'CLINIC_SECURITY_SETTINGS' | translate }}:</b>
        <br/><br/>

        <div class="col-12 col-lg-6">
            <div class="border-box">
                <div class="p-4">
                    
                    <div class="row">
                        <b>{{ 'CLINIC_SECURITY_APPLICATION' | translate }}:&nbsp;</b>
                        <p *ngIf="clinicDetail.isNurseAppEnabled">{{ 'CLINIC_SECURITY_MOBILE' | translate }}</p>
                        <p *ngIf="clinicDetail.isNurseAppEnabled && clinicDetail.isNurseWebAppEnabled">;&nbsp;</p>
                        <p *ngIf="clinicDetail.isNurseWebAppEnabled">{{ 'CLINIC_SECURITY_WEB' | translate }}</p>
                    </div>

                    <div class="row">
                        <b>{{ 'CLINIC_SECURITY_TWOFACTOR' | translate }}:&nbsp;</b>
                        <p>{{ (clinicDetail.secondFactorType == 'TRUSTED_MACHINE' ? 'CLINIC_SECURITY_TRUSTED' : 'CLINIC_SECURITY_OTP') | translate }}</p>
                    </div>

                    <div class="row">
                        <b>{{ 'CLINIC_SECURITY_PASSWORD' | translate }}:&nbsp;</b>
                        <p>{{ 'CLINIC_SECURITY_PASSWORD_' + clinicDetail.passwordType | translate }}</p>
                    </div>

                    <div class="row">
                        <b>{{ 'CLINIC_SECURITY_DURATION' | translate }}:&nbsp;</b>
                        <p>{{ 'CLINIC_SECURITY_DURATION_' + clinicDetail.passwordDuration + '_DAYS' | translate }}</p>
                    </div>

                    <div class="row">
                        <b>{{ 'CLINIC_DETAIL_PRESCRIPTION' | translate }}:&nbsp;</b>
                        <p>{{ (clinicDetail.isSignatureNeeded ? 'CLINIC_DETAIL_SIGNATURE_REQUIRED' : 'CLINIC_DETAIL_SIGNATURE_NOT_REQUIRED') | translate }}</p>
                    </div>

                    <div class="row">
                        <b>{{ 'CLINIC_DETAIL_LOGOUT' | translate }}:&nbsp;</b>
                        <p>{{ 'CLINIC_SECURITY_LOGOUT_' + clinicDetail.sessionDuration + '_MINUTES' | translate }}</p>
                    </div>

                    <div class="row" [class.edit-disabled]="isClinicDeactivated">
                        <div class="col-12 text-right">
                            <a (click)="EditSettings()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_SETTINGS' | translate }} </u></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 text-right" [class.edit-disabled]="isClinicDeactivated" >
            <a (click)="ShowClinicListing()" class="text-right"><i class="fas fa-list"></i> <u class="edit_link"> {{ 'CLINIC_LISTING_LABEL' | translate }}</u></a>
        </div>
        
        <hr />
        <br />

        <h2 class="mt-4">{{ 'NURSE_LIST_TITLE' | translate }}</h2>

        <div *ngIf="tableNurseConfig.pagination.totalItems != 0 else noNurse">
            <div class="row mt-2">
                <div class="col-6">
                    <app-search-field [searchConfig]="searchNurseConfig" (searchEvent)="searchNurseFilterKeyUp($event)">
                    </app-search-field>
                </div>
            </div>

            <app-table [tableConfig]="tableNurseConfig" (orderChanged)="orderNurseChanged($event)"
                (onClick)="GoToNurseDetail($event)" (pageChanged)="pageNurseChanged($event)"></app-table>
        </div>
        <ng-template #noNurse>
            <div class="text-center noResult" [class.edit-disabled]="isClinicDeactivated">
                <svg-icon class="icon" src="../../../../../assets/icons/nurse-figure.svg"></svg-icon>
                <p class="alert">{{ 'NURSE_CLINIC_NORESULT' | translate }}</p>
                <app-button *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']" (click)="nurseAdd()" [buttonConfig]="btnNurseConfig" ></app-button>
            </div>
        </ng-template>

        <hr />
        <br />

        <h2 class="mt-4">{{ 'NEPHROLOGIST_LIST_TITLE' | translate }}</h2>

        <div *ngIf="tableNephrologistConfig.pagination.totalItems != 0 else noNephrologist">
            <div class="row mt-2">
                <div class="col-6">
                    <app-search-field [searchConfig]="searchNephrologistConfig"
                        (searchEvent)="searchNephrologistFilterKeyUp($event)"></app-search-field>
                </div>
            </div>

            <app-table [tableConfig]="tableNephrologistConfig" (orderChanged)="orderNephrologistChanged($event)"
                (onClick)="GoToNephrologistDetail($event)" (pageChanged)="pageNephrologistChanged($event)"></app-table>
        </div>
        <ng-template #noNephrologist>
            <div class="text-center noResult" [class.edit-disabled]="isClinicDeactivated">
                <svg-icon class="icon" src="../../../../../assets/icons/nephrologist-figure.svg"></svg-icon>
                <p class="alert">{{ 'NEPHROLOGIST_CLINIC_NORESULT' | translate }}</p>
                <app-button *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST']" (click)="nephrologistAdd()" [buttonConfig]="btnNephrologistConfig" ></app-button>
            </div>
        </ng-template>
        <hr />
        <br />

        <h2 class="mt-4">{{ 'PATIENT_LIST_TITLE' | translate }}</h2>
        <div *ngIf="tablePatientConfig.pagination.totalItems != 0 else noPatient">
            <div class="row mt-2">
                <div class="col-6">
                    <app-search-field [searchConfig]="searchPatientConfig"
                        (searchEvent)="searchPatientFilterKeyUp($event)">
                    </app-search-field>
                </div>
            </div>

            <app-table [tableConfig]="tablePatientConfig" (orderChanged)="orderPatientChanged($event)"
                (onClick)="GoToPatientDetail($event)" (pageChanged)="pagePatientChanged($event)"></app-table>
        </div>

        <ng-template #noPatient>
            <div class="text-center noResult">
                <svg-icon class="icon" src="../../../../../assets/icons/patient-figure.svg"></svg-icon>
                <p class="alert">{{ 'PATIENT_CLINIC_NORESULT' | translate }}</p>
            </div>
        </ng-template>

        <br />

        <h2 class="mt-4">{{ 'CLINIC_DETAIL_IPD' | translate }}</h2>
        <app-ipd-order-list></app-ipd-order-list>

        <ng-container *appHasRole="['SUPER_ADMIN','ADMIN']">
            <div class="row my-4" *ngIf="!isClinicDeactivated">
                <div class="col-12 text-left">
                    <a (click)="deactivateClinic()" class="text-left"><i class="fas fa-clinic-medical"></i><u class="edit_link"> {{ 'DEACTIVATE_CLINIC_LABEL' | translate }} </u></a>
                </div>
            </div>        
            <div class="row my-4" *ngIf="isClinicDeactivated">
                <div class="col-12 text-left">
                    <a (click)="reactivateClinic()" class="text-left"><i class="fas fa-clinic-medical"></i><u class="edit_link"> {{ 'REACTIVATE_CLINIC_LABEL' | translate }} </u></a>
                </div>
            </div> 
        </ng-container>
    </div>

</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>
<app-clinic-name-modal (setClinicNameOut)="setClinicName($event)"></app-clinic-name-modal>
<app-clinic-listing-modal></app-clinic-listing-modal>