<label class="input-lable" *ngIf="inputConfig.name"
  >{{ inputConfig.name }}
  <span class="disabled" *ngIf="inputConfig.optional">{{
    "OPTIONAL_LABEL" | translate
  }}</span
  ><br
/></label>

<input
  *ngIf="inputConfig.type != 'textarea'"
  [type]="inputConfig.type"
  [name]="inputConfig.inputName ? inputConfig.inputName : ''"
  [readOnly]="inputConfig.readOnly"
  [value]="
    inputConfig.value
      ? inputConfig.formatDate
        ? (inputConfig.value | date : 'dd.MM.yyyy')
        : inputConfig.value
      : ''
  "
  [placeholder]="inputConfig.placeholder"
  [ngClass]="
    inputConfig.name ? inputConfig.class : inputConfig.class + ' blank'
  "
  (change)="inputValue($event)"
  [maxLength]="inputConfig.maxLength ? inputConfig.maxLength : 2000"
  (keyup)="inputValue($event)"
  [disabled]="inputConfig.disabled"
  [min]="inputConfig.min ? inputConfig.min : ''"
  [max]="inputConfig.max ? inputConfig.max : ''"
  autocomplete="none"
  [disabled]="inputConfig.disabled"
  [id]="inputConfig.id ? inputConfig.id : ''"
  appNoSpecialChars
  [avoidSanitizing]="avoidSanitizing || false"
/>
<svg-icon
  [src]="passwordIcon"
  class="pass-icon"
  (click)="passwordIconChange()"
  *ngIf="
    inputConfig.passwordIcon !== undefined && inputConfig.passwordIcon !== null
  "
></svg-icon>

<textarea
  *ngIf="inputConfig.type == 'textarea'"
  [name]="inputConfig.inputName ? inputConfig.inputName : ''"
  [readOnly]="inputConfig.readOnly"
  [value]="
    inputConfig.value
      ? inputConfig.formatDate
        ? (inputConfig.value | date : 'dd.MM.yyyy')
        : inputConfig.value
      : ''
  "
  [placeholder]="inputConfig.placeholder"
  [ngClass]="
    inputConfig.name ? inputConfig.class : inputConfig.class + ' blank'
  "
  (change)="inputValue($event)"
  [maxLength]="inputConfig.maxLength ? inputConfig.maxLength : 2000"
  (keyup)="inputValue($event)"
  [disabled]="inputConfig.disabled"
  autocomplete="off"
  appNoSpecialChars
  [avoidSanitizing]="avoidSanitizing || false"
></textarea>

<div class="error-message" *ngIf="inputConfig.errorMessage">
  {{ inputConfig.errorMessage }}
</div>
