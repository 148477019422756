import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {

  constructor() { }

  @Input() text!: any
  @Input() textType?: 'PRIMARY' | 'GREY' | 'BLUE' | 'ERROR' | 'UNDERLINE' = 'PRIMARY'
  @Input() fontSizePx!: number
  @Input() extraClass?: string
  @Input() extraStyle?: {[klass: string ] : any};
  @Input() isBold?: boolean = false
  @Input() id?: string
  @Input() textColor?: string
  @Input() isSpan?: boolean = false
  @Input() avoidTextSelection?: boolean;
  @Input() noWrap?: boolean;

  @Output() onTextClick = new EventEmitter<void>();

  ngOnInit(): void {
  }


  getExtraClass(){
    let finalClass = this.textType?.toLowerCase() + '-text'
    if(this.extraClass){
      finalClass += " " + this.extraClass
    }
    return finalClass
  }


  onClick(){
    this.onTextClick.emit();
  }

  getRemFontSize(pxSize: number){ return pxSize / 16;}

}


export class TextConfiguration{
  text!: any
  textType?: 'PRIMARY' | 'GREY' | 'BLUE' = 'PRIMARY'
  fontSizePx!: number
  extraClass?: string
  extraStyle?: {[klass: string ] : any};
  isBold?: boolean = false
  isSpan?: boolean = false
  id?: string
  textColor?: string
}