import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseGetAllRQ, ClientService, Country, ICalendar } from 'src/app/core';
import { BackOfficeCalendarViewCustom } from 'src/app/core/models/BackOfficeCalendarView-custom.model';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { uniqueObjectsByParameter } from 'src/app/core/utils/array-utils';
import { executeFilter } from 'src/app/core/utils/filter-utils';
import { FilterData, FilterEmit, FilterValue } from 'src/app/shared/filters/filters.component';

@Component({
  selector: 'app-calendar-history',
  templateUrl: './calendar-history.component.html',
  styleUrls: ['./calendar-history.component.css']
})
export class CalendarHistoryComponent implements OnInit {

  calendarList! : BackOfficeCalendarViewCustom[];
  countryList! : Country[];
  
  loading: boolean = true;
  record?: any;
  totalItemsCalendar: number = 0;
  anyResult: boolean = false;

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_CALENDAR')}

  autocompleteConfig = {
    name: this.getTranslation('FILTER_COUNTRY'),
    attr: 'timeZoneName' as const,
    array: this.countryList,
    numberToShow: 6,
    value : null,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_COUNTRY'),
  }

  tableConfig =  {
    actions: true,
    gridData: this.calendarList,
    headerList: [
      {name: this.getTranslation('CALENDAR_NAME'), field: 'calendarName', sortable: false},
      {name: this.getTranslation('CALENDAR_DELIVERY_DATE'), field: 'deliveryDateLabel', sortable: true},
      {name: this.getTranslation('CALENDAR_FREQUENCY'), field: 'frequencyLabel', sortable: true},
      {name: this.getTranslation('CALENDAR_PICKING'), field: 'pickingLabel', sortable: true},
      {name: this.getTranslation('CALENDAR_DUE_DATE'), field: 'dueDateLabel', sortable: true},
      {name: this.getTranslation('LABEL_NOTES'), field: 'isDeleted', sortable: false},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsCalendar
    },
    sort:{
      field: "week",
      direction: "asc"
    },
    notes: false
  };

  filters: FilterData[] = [];

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private router: Router,
    private editService: EditServiceService,
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.getCountryList();

    this.currentPage = 1;
    this.getCalendarList();
  }

  filterName?: string;
  filterCountry?: any;

  orderDirection?: string = "baseWeek_asc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;

    this.currentPage = 1;
    this.getCalendarList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterCountry = value.target.value.countryIsoCode;
      this.getCalendarList(); 
    }
    else{
      this.filterCountry = null;
      this.getCalendarList(); 
    } 
  }
 
  getCalendarList() {
    const inputData = new BaseGetAllRQ({
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName,
      countryIsoCode: this.filterCountry
    })
    this.clientService.calendarHistoryGetAllBackOffice(inputData).subscribe(
      response =>{
        this.calendarList = (response.list || []).map(row => BackOfficeCalendarViewCustom.fromJS(row, (key)=> this.getTranslation(key)));
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.calendarList;
        this.bindButtons(this.tableConfig.gridData);
        this.buildFilters();
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderCalendarChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getCalendarList();
  }

  pageCalendarChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getCalendarList();
  }

  getCountryList() {
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{

        response.forEach(element => {
          element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
          if(element.timeZoneName.startsWith("COUNTRY_")){
            element.timeZoneName = element.countryIsoCode;
          }
        });
        this.countryList = response || [];
        this.autocompleteConfig.array = this.countryList;
      } ,
      error=> console.log(error)
    );
  }

  getUpdatedCalendarList(): any{
    return this.calendarList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToCalendarDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/calendars/detail', event.record.idCalendar]);
    }
  }

  bindButtons(calendarList: ICalendar[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let calendar of calendarList){
      this.tableConfig.recordButtonConfigs.set(calendar,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  quit(){
    this.router.navigateByUrl('/calendars');
  }

  buildFilters(){
    const nameOptions: FilterValue[] = this.calendarList.map(calendar=> ({ label: calendar.calendarName!, key: calendar.calendarName!, selected: false}))
    const nameFilter: FilterData = { filterKey: 'calendarName', filterName: this.getTranslation('LABEL_NAME'), values: nameOptions}
    
    const dueDateOptions: FilterValue[] = this.calendarList.map(calendar=> ({  label: calendar.dueDateLabel!,  key: `${calendar.dueDateWeek},${calendar.dueDateWeekDay}`,  selected: false }))
    const dueDateFilter: FilterData = { 
      filterKey: 'dueDate', 
      filterName: this.getTranslation('CALENDAR_DUE_DATE'), 
      values: dueDateOptions,
      customProjection: (object,value) => {
        const values = value.split(',');
        return values[0] == object['dueDateWeek'] && values[1] == object['dueDateWeekDay']
      }
    }
    
    const pickingDateOptions: FilterValue[] = uniqueObjectsByParameter(
      this.calendarList.map(calendar=> ({ label: calendar.pickingLabel!, key: `${calendar.pickingWeek},${calendar.pickingWeekDay}`, selected: false })), 
      'key'
    );
      
    const pickingDateFilter: FilterData = { 
      filterKey: 'pickingDate', 
      filterName: this.getTranslation('CALENDAR_PICKING'), 
      values: pickingDateOptions,
      customProjection: (object,value) => {
        const values = value.split(',');
        return values[0] == object['pickingWeek'] && values[1] == object['pickingWeekDay']
      }
    }
    
    const deliveryDateOptions: FilterValue[] = [...new Set(this.calendarList.map(calendar=> calendar.deliveryDateLabel))].map(deliveryDateLabel=> ({ label: deliveryDateLabel,  key: deliveryDateLabel,  selected: false }))
    const deliveryDateFilter: FilterData = { filterKey: 'deliveryDateLabel', filterName: this.getTranslation('CALENDAR_DELIVERY_DATE'), values: deliveryDateOptions}

    this.filters = [nameFilter, deliveryDateFilter, pickingDateFilter, dueDateFilter];
  }
  
  onFilterUpdate(event: FilterEmit){
    this.filters = event.updatedData;
    this.tableConfig.gridData = executeFilter(event, this.calendarList);
  }
}