
<button class="round-btn" [id]="id" [disabled]="disabled" [ngClass]="text ? 'with-text' : 'round'" (click)="onBtnClick()" [ngStyle]="getExtraStyle()" [ngSwitch]="type">
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/minor-icon.svg'" *ngSwitchCase="'BACK'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/close.svg'" *ngSwitchCase="'CLOSE'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/major-icon.svg'" *ngSwitchCase="'NEXT'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/logout-icon.svg'" *ngSwitchCase="'LOGOUT'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/cross-icon.svg'" *ngSwitchCase="'PLUS'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/delete-icon.svg'" *ngSwitchCase="'DELETE'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/pencil-lg-icon.svg'" *ngSwitchCase="'PENCIL'"></svg-icon>
    <svg-icon [svgStyle]="setSvgSize()" [src]="'../../../../assets/icons/withdraw-icon.svg'" *ngSwitchCase="'WITHDRAW'"></svg-icon>
    <div *ngIf="text">{{text}}</div>
</button>
