import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { AuthenticationService, CheckEntityIsValidRQ, ClientService, EntityRQ, ICheckEntityIsValidRQ, ISupportUserSetRQ, SupportUserRS, SupportUserSetRQ } from 'src/app/core';
import { Role } from 'src/app/core/enum/roles';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-support-user-edit',
  templateUrl: './support_user-edit.component.html',
  styleUrls: ['./support_user-edit.component.css']
})
export class SupportUserEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  supportUserDetails?: SupportUserRS;
  loading: boolean = true;

  countryList!: any | [];
  countryPhonePrefixList!: any | [];
  cultureList!: any | [];
  validationErrors: any[] = [];

  selectedCountry!: any;
  mobileNumberIsValid: boolean = true;
  emailAddressIsValid: boolean = true;
  supportUserIsValid: boolean = true;
  previousEmail: any = "";

  formSupportUserAdd: FormGroup = this.fb.group({
    name: [null, Validators.required],
    surname: [null, Validators.required],
    countryIsoCode: [null, Validators.required],
    cultureIsoCode: [null, Validators.required],
    email : [null, [Validators.required, Validators.pattern(new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/))]],
    phonePrefix : [null, Validators.required],
    mobileNumber : [null, Validators.required]
  });

  formSupportUserInfo={
    key: 'formSupportUserInfo',
    input:
    [
      { name: this.getTranslation('LABEL_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'name', value: this.formSupportUserAdd.get("name")?.value, maxLength: 64 },
      { name: this.getTranslation('LABEL_SURNAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'surname', value: this.formSupportUserAdd.get("surname")?.value, maxLength: 64 },
      { name: this.getTranslation('LABEL_EMAIL'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'email', value: this.formSupportUserAdd.get("email")?.value, maxLength: 256 },
      { name: this.getTranslation('LABEL_MOBILE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'mobileNumber', value: this.formSupportUserAdd.get("mobileNumber")?.value, maxLength: 16 },
    ],
    autocompleteCountryConfig: {
      name: this.getTranslation('LABEL_COUNTRY'),
      attr: 'timeZoneName' as const,
      array: this.countryList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'countryIsoCode',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('COMMON_SELECT_COUNTRY'),
    },
    autocompleteCountryPhonePrefixConfig: {
      name: this.getTranslation('LABEL_COUNTRYPREFIX'),
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      prefix: '+',
      value : null as any,
      inputName : 'phonePrefix',
      class: 'form-control autocomplete-input',
      disabled: true
    },
    autocompleteCultureConfig: {
      name: this.getTranslation('LABEL_CULTURE'),
      attr: 'cultureName' as const,
      array: this.cultureList,
      numberToShow: 6,
      prefix: false,
      value : null as any,
      inputName : 'cultureIsoCode',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('COMMON_SELECT_LANGUAGE'),
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_ADDSUPPORT_USER'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private authService: AuthenticationService,
    private utilsService: UtilsService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !(form.valid && this.mobileNumberIsValid && this.emailAddressIsValid && this.supportUserIsValid);
      });
    this.addBtnConfig.disabled = !(form.valid && this.mobileNumberIsValid && this.emailAddressIsValid && this.supportUserIsValid);
  }

  inputValues(event: any){
    this.formSupportUserAdd.controls[event.target.name].setValue(event.target.value);

    if (event.target.name === 'mobileNumber') {
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT"));
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"));
    }

    this.formValidation(this.formSupportUserAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/support-users');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/support-users/detail', this.id]);
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    this.countryList = this.getAllCountry();
    this.cultureList = this.getAllCulture();
    this.authService.getAllCountryPhonePrefix(this.id, this.formSupportUserInfo, this.formSupportUserAdd);

    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
      this.getSupportUserDetail();
    }  

    if(this.authService.currentUserValue.countryIsoCode != undefined || this.authService.currentUserValue.role == Role.SuperAdmin){
      this.formSupportUserAdd.controls["countryIsoCode"].setValidators([]);
    }

    this.loading = false;
  }

  ngAfterViewInit() {
    this.formSupportUserAdd.get("email")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      if(this.previousEmail != this.formSupportUserAdd.get("workEmail")?.value)
        this.checkEmailAddressIsValid();
    });

    this.formSupportUserAdd.get("mobileNumber")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkMobileIsValid();
    });

    this.formSupportUserAdd.get("phonePrefix")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkMobileIsValid();
    });

    this.formSupportUserAdd.get("name")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkSupportUserIsValid();
    });

    this.formSupportUserAdd.get("surname")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkSupportUserIsValid();
    });

    this.formSupportUserAdd.get("countryIsoCode")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkSupportUserIsValid();
    });
  }

  getSupportUserDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.supportUserGet(data).subscribe(
      response =>{

        this.supportUserDetails = response;
        this.previousEmail = response.emailAddress;

        this.formSupportUserAdd.patchValue({
          name: response.name,
          surname: response.surname,
          email: response.emailAddress,
          mobileNumber: response.mobileNumber,
          countryIsoCode: { countryIsoCode: response.countryIsoCode },
          cultureIsoCode: { cultureIsoCode: response.cultureIsoCode },
          phonePrefix: { phonePrefix: response.mobileNumberCountryPrefix, countryIsoCode: response.mobileNumberCountryPrefixCode ?? null }
        });

        this.formSupportUserInfo!.input[0].value = this.formSupportUserAdd.get("name")?.value;
        this.formSupportUserInfo!.input[1].value = this.formSupportUserAdd.get("surname")?.value;
        this.formSupportUserInfo!.input[2].value = this.formSupportUserAdd.get("email")?.value;
        this.formSupportUserInfo!.input[3].value = this.formSupportUserAdd.get("mobileNumber")?.value;
        
        let countryName: string = this.getTranslation("COUNTRY_" + response.countryIsoCode);
        if(countryName.startsWith("COUNTRY_")){
          countryName = response.countryIsoCode!;
        }
        this.formSupportUserInfo!.autocompleteCountryConfig.value = { 
          countryIsoCode: this.supportUserDetails.countryIsoCode,
          timeZoneName: countryName
        }

        let cultureName: string = this.getTranslation("CULTURE_" + response.cultureIsoCode);
        if(cultureName.startsWith("CULTURE_")){
          cultureName = response.cultureIsoCode!;
        }
        this.formSupportUserInfo!.autocompleteCultureConfig.value = { 
          cultureIsoCode: this.supportUserDetails.cultureIsoCode,
          cultureName: cultureName
        }
        
        this.formSupportUserInfo!.autocompleteCountryPhonePrefixConfig.value = this.supportUserDetails.mobileNumberCountryPrefix ? 
        { 
          phonePrefix: this.supportUserDetails.mobileNumberCountryPrefix ?? null,
          countryIsoCode: this.supportUserDetails.mobileNumberCountryPrefixCode ?? null
        } : null as any;

        this.formValidation(this.formSupportUserAdd);

        this.formSupportUserInfo!.autocompleteCountryConfig = {...this.formSupportUserInfo!.autocompleteCountryConfig}
        this.formSupportUserInfo!.autocompleteCultureConfig = {...this.formSupportUserInfo!.autocompleteCultureConfig}
        this.formSupportUserInfo!.autocompleteCountryPhonePrefixConfig = {...this.formSupportUserInfo!.autocompleteCountryPhonePrefixConfig}
        
        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  getAllCountry(): any{
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{

        response.forEach(element => {
          element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
          if(element.timeZoneName.startsWith("COUNTRY_")){
            element.timeZoneName = element.countryIsoCode;
          }
        });
        this.formSupportUserInfo.autocompleteCountryConfig.array = response;
        this.formSupportUserInfo.autocompleteCountryConfig.disabled = false;
      } ,
      error=> console.log(error)
    );
  }

  getAllCulture(): any{
    this.clientService.cultureGetAll().subscribe(
      response =>{

        response.forEach(element => {
          element.cultureName = this.getTranslation("CULTURE_" + element.cultureIsoCode);
          if(element.cultureName.startsWith("CULTURE_")){
            element.cultureName = element.cultureIsoCode;
          }
        });
        this.formSupportUserInfo.autocompleteCultureConfig.array = response;
        this.formSupportUserInfo.autocompleteCultureConfig.disabled = false;
      } ,
      error=> console.log(error)
    );
  }

  saveSupportUser(){
    if (this.formSupportUserAdd.valid) {

      this.selectedCountry = this.formSupportUserAdd.get('countryIsoCode')?.value?.countryIsoCode ?? this.authService.currentUserValue.countryIsoCode;

      this.loading = true;
      let supportUser: ISupportUserSetRQ;
          supportUser = {
            idSupportUser: this.id,
            name: this.formSupportUserAdd.get('name')?.value,
            surname: this.formSupportUserAdd.get('surname')?.value,
            countryIsoCode: this.selectedCountry,
            cultureIsoCode: this.formSupportUserAdd.get('cultureIsoCode')?.value?.cultureIsoCode,
            emailAddress: this.formSupportUserAdd.get('email')?.value,
            mobileNumberCountryPrefixCode: this.formSupportUserAdd.get('phonePrefix')?.value?.countryIsoCode ?? null,
            mobileNumberCountryPrefix: this.formSupportUserAdd.get('phonePrefix')?.value?.phonePrefix ?? null,
            mobileNumber: this.formSupportUserAdd.get('mobileNumber')?.value,
          }
      this.clientService.supportUserSet(new SupportUserSetRQ(supportUser)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){

            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'SUPPORT_USER_ADD_SUCCESSFULLY',
                description: this.selectedCountry,
                actionToDo: () => 
                {
                  this.router.navigate(['/support-users/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              this.utilsService.addValidationError(this.validationErrors, this.getTranslation(response?.errorType));

              if(response?.errorType == "ERROR_EMAIL_ALREADY_EXISTS")
                this.formSupportUserInfo.input[2].class = "input-form error";
              else if(response?.errorType == "ERROR_SUPPORT_USER_ALREADY_EXISTS"){
                this.formSupportUserInfo.input[0].class = "input-form error";
                this.formSupportUserInfo.input[1].class = "input-form error";
                this.formSupportUserInfo.autocompleteCountryConfig.class = "form-control autocomplete-input error";
              }
              else if(response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT" || response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"){
                this.formSupportUserInfo.input[3].class = "input-form error";
                this.formSupportUserInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
              }
            }
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  checkMobileIsValid(){
    this.formSupportUserInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input";
      
    this.utilsService.checkMobileNumberIsValid(this.supportUserDetails?.idUserIdentity, this.formSupportUserAdd.get('phonePrefix')?.value?.countryIsoCode, this.formSupportUserAdd.get('phonePrefix')?.value?.phonePrefix, this.formSupportUserAdd.get('mobileNumber')?.value, "SUPPORT_USER").pipe(first()).subscribe(
      data => {
        this.mobileNumberIsValid = data;
        if(!data) {
          this.formSupportUserInfo.input[3].class = "input-form error";
          this.formSupportUserInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_ALREADY_EXISTS"));
        }
        this.formValidation(this.formSupportUserAdd);
      }
    )
  }

  checkEmailAddressIsValid(){      
    this.utilsService.checkEmailAddressIsValid(this.supportUserDetails?.idUserIdentity, this.formSupportUserAdd.get('email')?.value, "SUPPORT_USER").pipe(first()).subscribe(
      data => {
        this.emailAddressIsValid = data;
        if(!data) {
          this.formSupportUserInfo.input[2].class = "input-form error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        this.formValidation(this.formSupportUserAdd);
      }
    )
  }

  checkSupportUserIsValid(){      

    this.formSupportUserInfo.input[0].class = "input-form";
    this.formSupportUserInfo.input[1].class = "input-form";
    this.formSupportUserInfo.autocompleteCountryConfig.class = "form-control autocomplete-input";

    let input: ICheckEntityIsValidRQ = {
      idEntity: this.supportUserDetails?.idSupportUser,
      name: this.formSupportUserAdd.get('name')?.value,
      surname: this.formSupportUserAdd.get('surname')?.value,
      countryIsoCode: this.formSupportUserAdd.get('countryIsoCode')?.value?.countryIsoCode ?? this.authService.currentUserValue.countryIsoCode
    }
      
    return this.clientService.checkSupportUserIsValid(new CheckEntityIsValidRQ(input)).subscribe(
      data => {
        this.supportUserIsValid = data;
        if(!data) {
          this.formSupportUserInfo.input[0].class = "input-form error";
          this.formSupportUserInfo.input[1].class = "input-form error";
          this.formSupportUserInfo.autocompleteCountryConfig.class = "form-control autocomplete-input error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_SUPPORT_USER_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_SUPPORT_USER_ALREADY_EXISTS"));
        } 
        this.formValidation(this.formSupportUserAdd);
      }
    )
  }
}
