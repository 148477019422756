import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { AuthenticationService, BaseGetAllRQ, CheckEntityIsValidRQ, ClientService, EntityRQ, IBaseGetAllRQ, ICheckEntityIsValidRQ, INephrologistSetRQ, NephrologistRS, NephrologistSetRQ } from 'src/app/core';
import { Role } from 'src/app/core/enum/roles';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-nephrologist-edit',
  templateUrl: './nephrologist-edit.component.html',
  styleUrls: ['./nephrologist-edit.component.css']
})
export class NephrologistEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  nephrologistDetails?: NephrologistRS;
  sendPrivacyPolicy = true;
  loading: boolean = true;

  clinicList!: any | [];
  validationErrors: any[] = [];

  emailAddressIsValid: boolean = true;
  nephrologistIsValid: boolean = true;
  previousEmail: any = "";

  isRegistrationNumberIssuerManaged: boolean = false;
  isQualificationManaged: boolean = false;
  badgeNumberError: boolean = false;
  registrationNumberIssuerError: boolean = false;

  formNephrologistAdd: FormGroup = this.fb.group({
    idClinic: [null, Validators.required],
    name: [null, Validators.required],
    surname: [null, Validators.required],
    badgeNumber: [null],
    registrationNumberIssuer: [null],
    qualification: [null],
    email: [null, [Validators.required, Validators.pattern(new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/))]],
    //phoneNumber : [null]
  });

  formNephrologistInfo={
    key: 'formNephrologistInfo',
    input:
    [
      { name: this.getTranslation('LABEL_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'name', value: this.formNephrologistAdd.get("name")?.value, maxLength: 64 },
      { name: this.getTranslation('LABEL_SURNAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'surname', value: this.formNephrologistAdd.get("surname")?.value, maxLength: 64 },
      { name: this.getTranslation('LABEL_EMAIL'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'email', value: this.formNephrologistAdd.get("email")?.value, maxLength: 256 },
      { name: this.getTranslation('NEPHROLOGIST_DETAIL_BADGENUMBER'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'badgeNumber', value: this.formNephrologistAdd.get("badgeNumber")?.value, maxLength: 50, errorMessage: '' },
      { name: this.getTranslation('NEPHROLOGIST_ADD_ISSUEDBY'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'registrationNumberIssuer', value: this.formNephrologistAdd.get("registrationNumberIssuer")?.value, maxLength: 50 },
      { name: this.getTranslation('NEPHROLOGIST_QUALIFICATION'), optional: true, type: 'text', placeholder: '', class: 'input-form', inputName: 'qualification', value: this.formNephrologistAdd.get("qualification")?.value, maxLength: 50 },
      //{ name: this.getTranslation('LABEL_MOBILE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'phoneNumber', value: this.formNephrologistAdd.get("phoneNumber")?.value },
    ],
    autocompleteClinicConfig: {
      name: this.getTranslation('LABEL_CLINIC'),
      placeholder: this.getTranslation('SEACH_PLH_CLINIC_NAME'),
      attr: 'clinicName' as const,
      array: this.clinicList,
      numberToShow: 6,
      prefix: false,
      value : null as any,
      inputName : 'idClinic',
      class: 'form-control autocomplete-input',
      disabled: true,
      readonly: false,
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_ADDNEPHROLOGIST'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !(form.valid && this.emailAddressIsValid && this.nephrologistIsValid && !this.badgeNumberError && !this.registrationNumberIssuerError);
      });
    this.addBtnConfig.disabled = !(form.valid && this.emailAddressIsValid && this.nephrologistIsValid && !this.badgeNumberError && !this.registrationNumberIssuerError);
  }

  inputValues(event: any){
    this.formNephrologistAdd.controls[event.target.name].setValue(event.target.value);

    if(this.isRegistrationNumberIssuerManaged){

      //CHECK BADGE NUMBER
      this.badgeNumberError = !this.formNephrologistAdd.get('badgeNumber')?.value && this.formNephrologistAdd.get('registrationNumberIssuer')?.value;
      this.formNephrologistInfo.input[3].errorMessage = this.badgeNumberError ? this.getTranslation("FIELD_MANDATORY_LABEL") : "";
      
      //CHECK REGISTRATION NUMBER ISSUER
      this.registrationNumberIssuerError = this.formNephrologistAdd.get('badgeNumber')?.value && !this.formNephrologistAdd.get('registrationNumberIssuer')?.value;      
    }
    this.formValidation(this.formNephrologistAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/nephrologists');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/nephrologists/detail', this.id]);
    }
  }
  
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    this.isRegistrationNumberIssuerManaged = this.utilsService.getParametersByKey("flow_trigger__is_nephrologist_registration_number_issuer_managed").parameterValue == 'True';
    this.isQualificationManaged = this.utilsService.getParametersByKey("flow_trigger__is_nephrologist_qualification_managed").parameterValue == 'True';

    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
      this.getNephrologistDetail();
    }
    else{
      this.clinicList = this.getAllClinic();
    }
  }

  ngAfterViewInit() {
    this.formNephrologistAdd.get("email")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      if(this.previousEmail != this.formNephrologistAdd.get("workEmail")?.value)
        this.checkEmailAddressIsValid();
    });

    this.formNephrologistAdd.get("name")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkNephrologistIsValid();
    });

    this.formNephrologistAdd.get("surname")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkNephrologistIsValid();
    });

    this.formNephrologistAdd.get("idClinic")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkNephrologistIsValid();
    });
  }

  getNephrologistDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.nephrologistGet(data).subscribe(
      response =>{

        this.nephrologistDetails = response;
        this.previousEmail = response.emailAddress;

        this.formNephrologistAdd.patchValue({
          name: response.name,
          surname: response.surname,
          badgeNumber: response.badgeNumber,
          registrationNumberIssuer: response.registrationNumberIssuer,
          qualification: response.qualification,
          email: response.emailAddress,
          phoneNumber: response.phoneNumber,
          idClinic: { idClinic: response.idClinic }
        });

        this.formNephrologistInfo!.input[0].value = this.formNephrologistAdd.get("name")?.value;
        this.formNephrologistInfo!.input[1].value = this.formNephrologistAdd.get("surname")?.value;
        this.formNephrologistInfo!.input[2].value = this.formNephrologistAdd.get("email")?.value;
        this.formNephrologistInfo!.input[3].value = this.formNephrologistAdd.get("badgeNumber")?.value;
        this.formNephrologistInfo!.input[4].value = this.formNephrologistAdd.get("registrationNumberIssuer")?.value;
        this.formNephrologistInfo!.input[5].value = this.formNephrologistAdd.get("qualification")?.value;

        //this.formNephrologistInfo!.input[4].value = this.formNephrologistAdd.get("phoneNumber")?.value;
        this.formNephrologistInfo!.autocompleteClinicConfig.value = { idClinic: this.nephrologistDetails.idClinic, clinicName: this.nephrologistDetails.clinicName }

        this.formNephrologistInfo!.autocompleteClinicConfig = {...this.formNephrologistInfo!.autocompleteClinicConfig}

        this.formValidation(this.formNephrologistAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }


  getAllClinic(): any{
    let input: IBaseGetAllRQ = {
      pageIndex: undefined,
      sortOrder: "ClinicName_asc",
      searchString: "",
      city: ""
    }
    this.clientService.clinicGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        response.list?.forEach(x => x.clinicName = x.sapUuid + " " + x.clinicName);
        this.formNephrologistInfo.autocompleteClinicConfig.array = (response.list ?? []).filter(({ status }) => status !== 'DEACTIVATED');
        this.formNephrologistInfo.autocompleteClinicConfig.disabled = false;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  saveNephrologist(){
    if (this.formNephrologistAdd.valid) {

      this.loading = true;
      let nephrologist: INephrologistSetRQ;
          nephrologist = {
            idNephrologist: this.id,
            idClinic: this.formNephrologistAdd.get('idClinic')?.value?.idClinic,
            name: this.formNephrologistAdd.get('name')?.value,
            surname: this.formNephrologistAdd.get('surname')?.value,
            badgeNumber: this.formNephrologistAdd.get('badgeNumber')?.value,
            registrationNumberIssuer: this.formNephrologistAdd.get('registrationNumberIssuer')?.value,
            qualification: this.formNephrologistAdd.get('qualification')?.value,
            emailAddress: this.formNephrologistAdd.get('email')?.value,
            phoneNumber: this.formNephrologistAdd.get('phoneNumber')?.value,
            sendPrivacyPolicy: this.sendPrivacyPolicy
          }
      this.clientService.nephrologistSet(new NephrologistSetRQ(nephrologist)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){
            
            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'NEPHROLOGIST_ADD_SUCCESSFULLY',
                description: this.formNephrologistAdd.get('idClinic')?.value?.clinicName,
                actionToDo: () => 
                {
                  this.router.navigate(['/nephrologists/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              this.utilsService.addValidationError(this.validationErrors, this.getTranslation(response?.errorType));

              if(response?.errorType == "ERROR_EMAIL_ALREADY_EXISTS")
                this.formNephrologistInfo.input[3].class = "input-form error";
              else if(response?.errorType == "ERROR_NEPHROLOGIST_ALREADY_EXISTS"){
                this.formNephrologistInfo.input[0].class = "input-form error";
                this.formNephrologistInfo.input[1].class = "input-form error";
                this.formNephrologistInfo.autocompleteClinicConfig.class = "form-control autocomplete-input error";
              }
            }
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  checkPrivacyPolicy(){
    this.sendPrivacyPolicy = !this.sendPrivacyPolicy;
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }

  checkEmailAddressIsValid(){      
    this.utilsService.checkEmailAddressIsValid(this.nephrologistDetails?.idUserIdentity, this.formNephrologistAdd.get('email')?.value, "NEPHROLOGIST").pipe(first()).subscribe(
      data => {
        this.emailAddressIsValid = data;
        if(!data) {
          this.formNephrologistInfo.input[3].class = "input-form error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_EMAIL_ALREADY_EXISTS"));
        }
        this.formValidation(this.formNephrologistAdd);
      }
    )
  }

  checkNephrologistIsValid(){      

    this.formNephrologistInfo.input[0].class = "input-form";
    this.formNephrologistInfo.input[1].class = "input-form";
    this.formNephrologistInfo.autocompleteClinicConfig.class = "form-control autocomplete-input";

    let input: ICheckEntityIsValidRQ = {
      idEntity: this.nephrologistDetails?.idNephrologist,
      name: this.formNephrologistAdd.get('name')?.value,
      surname: this.formNephrologistAdd.get('surname')?.value,
      idClinic: this.formNephrologistAdd.get('idClinic')?.value?.idClinic,
    }
      
    return this.clientService.checkNephrologistIsValid(new CheckEntityIsValidRQ(input)).subscribe(
      data => {
        this.nephrologistIsValid = data;
        if(!data) {
          this.formNephrologistInfo.input[0].class = "input-form error";
          this.formNephrologistInfo.input[1].class = "input-form error";
          this.formNephrologistInfo.autocompleteClinicConfig.class = "form-control autocomplete-input error";
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_NEPHROLOGIST_ALREADY_EXISTS"));
        }
        else{
          this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_NEPHROLOGIST_ALREADY_EXISTS"));
        }
        this.formValidation(this.formNephrologistAdd);
      }
    )
  }
}
