import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { AuthenticationService } from '.';

@Injectable({
    providedIn: 'root'
})
export class TimeoutService {
    private _count = 0;
    private _timeoutMilliseconds = 3600000; // 60 Min
    private timerSubscription?: Subscription;
    private _timer?: Observable<number>;
    private resetOnTrigger: Boolean = false;
    private lastTime?: number;
    private dateTimer?: Observable<number>;
    private dateTimerSubscription?: Subscription;
    private dateTimerInterval: number = 1000 * 60 * 5;
    private dateTimerTolerance: number = 1000 * 10;
    public timeoutExpired: Subject<number> = new Subject<number>();


    private saveOnTimeOutSubject = new Subject<any>();

    private savedOperationSubject = new Subject<any>();
    private getOperationFinished() { return this.savedOperationSubject.asObservable(); }


    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {
        this.timeoutExpired.subscribe(n => {
            console.log('Session Timeout expired');
            if (!router.url.includes('login')) {
                if (this.saveOnTimeOutSubject.observers.length > 0) {
                    this.getOperationFinished().subscribe({
                        next: () => this.authenticationService.logout(true)
                    })
                } else {
                    this.authenticationService.logout(true)
                }
            }
        });


        this.startTimer();
        this.startDateCompare();

        this.startTimeElapsed();
    }

    private setSubscription() {
        this._timer = timer(this._timeoutMilliseconds);
        this.timerSubscription = this._timer.subscribe(n => {
            this.timerComplete(n);
        });
    }

    private startDateCompare() {
        this.lastTime = (new Date()).getTime();
        this.dateTimer = timer(this.dateTimerInterval); // compare every five minutes
        this.dateTimerSubscription = this.dateTimer.subscribe(n => {
            const currentTime: number = (new Date()).getTime();
            if (currentTime > (this.lastTime! + this.dateTimerInterval + this.dateTimerTolerance)) { // look for 10 sec diff
                //console.log('Looks like the machine just woke up.. ');
            } else {
                //console.log('Machine did not sleep.. ');
            }
            this.dateTimerSubscription?.unsubscribe();
            this.startDateCompare();
        });
    }

    public startTimer() {
        if (this.timerSubscription) {
            this.stopTimer();
        }
        this.setSubscription();
    }

    public stopTimer() {
        this.timerSubscription?.unsubscribe();
    }

    public resetTimer() {
        this.startTimer();
    }


    private timerComplete(n: number) {
        this.timeoutExpired.next(++this._count);


        if (this.resetOnTrigger) {
            this.startTimer();
        }
    }

    startTime!: Date;
    endTime!: Date;

    startTimeElapsed() {
        this.startTime = new Date();
        const alreadyPassed = window.sessionStorage.getItem('timeElapsed')
        if (alreadyPassed) {
            this.startTime = new Date(new Date().getTime() - (parseInt(alreadyPassed) * 60000))
        } else {
            window.sessionStorage.setItem('timeElapsed', '0')
        }
        setInterval(() => {
            this.endTime = new Date();
            var timeDiff = this.endTime.getTime() - this.startTime.getTime(); //get ms
            timeDiff /= 1000; // ms > s
            const minutes = Math.round(Math.round(timeDiff) / 60); // get seconds 
            window.sessionStorage.setItem('timeElapsed', minutes.toString())
        }, 60000)
    };
}