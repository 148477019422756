import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  constructor() { }


  @ViewChild('filter') containerRef!: ElementRef;
  @Input() filters!: FilterData[]
  @Input() customFilterWidthRem?: number
  @Output() onFilterToggle = new EventEmitter<FilterEmit>();
  @Input() extraClass?: string;
  openedDropdown = '';

  ngOnInit(): void {
  }

  isOneFilterSelected(){
    return this.filters.length > 0 && this.filters.map(x=> x.values).reduce((a,b)=> a = a.concat(b)).some(x=> x.selected)
  }

  isAtLeastOneFilterPresent(){
    return this.filters.some(filter=> filter.values.length > 0);
  }

  openDropdown(toOpen: string){
    this.openedDropdown = this.openedDropdown === toOpen ? '' : toOpen;
  }

  getNumberFilterApplied(filterName: string){
    let filter = this.filters.find(x=> x.filterName === filterName);
    const qta = (filter?.values.filter(x=> x.selected).length || 0)

    if(qta > 0) return ` (+${qta})`;
    return '';
  }

  onFilterSelect(filterName: string, filterKey: any){
    /* Update Selected Filter */
    let allFilters = this.filters.find(x=> x.filterName === filterName)!
    let filterItem = allFilters.values.find(y=> y.key === filterKey)!
    filterItem.selected = !filterItem.selected
    if(!allFilters.values.some(x=> x.selected)){
      this.openedDropdown = ''
    }else{
      this.openedDropdown = filterName;
    }
    this.emit()
  }


  /* Reset filter section, not all */
  resetFilterSection(filterName: string){
    let allFilters = this.filters.find(x=> x.filterName === filterName)!
    if(allFilters.values.some(v=> v.selected)){
      allFilters.values.forEach(v=> v.selected = false)
    }
    this.openedDropdown = ''
    this.emit()
  }

  resetAllFilters(){
    this.filters.forEach(filter=>{ 
      filter.values.forEach(f=> {
        f.selected = false
      })
    })
    this.openedDropdown = ''
    this.emit();
  }


  emit(){
    let finalFilters: {filterName: string,filterKey: string, values: any[],  customProjection?: (object: any, key: any) => boolean}[] = [];
    this.filters.forEach(macro=>{
      let fKeys: string[] = []
      macro.values.forEach(fil=>{
        if(fil.selected){
          fKeys.push(fil.key)
        }
      })
      if(fKeys.length > 0){
        finalFilters.push({filterName: macro.filterName, values: fKeys, filterKey: macro.filterKey, customProjection: macro.customProjection })
      }
    })
    this.onFilterToggle.emit({updatedData: this.filters, currentFiltersList: finalFilters})
  }
  

}



export class FilterData{
  filterKey!: string
  filterName!: string
  values!: FilterValue[]
  /**
   * Used to execute a custom filter function to match the condition
   */
  customProjection?: (object: any, key: any) => boolean
}

export class FilterValue{
  label?: string
  key: any 
  selected!: boolean
}

export class FilterEmit{
  updatedData!: FilterData[]
  currentFiltersList!: {filterName: string, filterKey: string ,values: string[], customProjection?: (object: any,key: any)=> boolean}[]
}
