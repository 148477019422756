<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'COUNTRY_RESPONSIBLE_ADD_TITLE' : 'COUNTRY_RESPONSIBLE_EDIT_TITLE') | translate}}</h2>

        <form class="row" [formGroup]="formCountryResponsibleAdd">
            <div class="col-4">
                
                <app-input [inputConfig]="formCountryResponsibleInfo.input[0]" (keyup)="inputValues($event)"></app-input>

                <app-input [inputConfig]="formCountryResponsibleInfo.input[1]" (keyup)="inputValues($event)"></app-input>

                <div class="row row-aligned">
                    <div class="col-md-4 col-4">
                        <app-phoneprefix-dropdown [autocompleteConfig]="formCountryResponsibleInfo?.autocompleteCountryPhonePrefixConfig" (selected)="inputValues($event)" ></app-phoneprefix-dropdown>
                    </div>
                    <div class="col md-8 col-8">
                        <app-input [inputConfig]="formCountryResponsibleInfo.input[3]" (keyup)="inputValues($event)"></app-input>
                    </div>
                </div>

                <app-input [inputConfig]="formCountryResponsibleInfo.input[2]" [formControlInput]="formCountryResponsibleAdd" (keyup)="inputValues($event)"></app-input>
                    
                <br><span><i class="fas fa-exclamation-triangle"></i><b>{{'NURSE_ADD_EMAIL_MUSTPERSONAL_NOCOLLECTIVE' | translate}}</b></span>

                <app-autocomplete-dropdown [autocompleteConfig]="formCountryResponsibleInfo?.autocompleteRoleConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>

                <div>
                    <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
                </div>

                <div class="row mt-5">
                    <div class="col-10 d-flex flex-row">
                        <app-button [buttonConfig]="addBtnConfig" (click)="saveCountryResponsible()"></app-button>
                    </div>
                </div>

                <br/>
            
            </div>
            <div class="col-8 border-box p-4">
                <ul *ngFor="let role of roleList">
                    <li>
                        <b>{{role.title}}</b>
                        <p [innerHTML]="role.description"></p>
                    </li>
                </ul>
            </div>
        </form>        
    </div>
</ng-template>
