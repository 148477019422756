import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, BaseGetAllRQ, ClientService, Country, IBaseGetAllRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.css']
})
export class CountryListComponent implements OnInit {

  countryList! : Country[];
  loading: boolean = true;
  record?: any;
  totalItemsCountry: number = 0;
  anyResult: boolean = false;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_COUNTRY'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_COUNTRY'), value: '' }
  
  tableConfig =  {
    actions: true,
    gridData: this.countryList,
    headerList: [
      {name: this.getTranslation('LABEL_COUNTRY'), field: 'countryIsoCode', sortable: true},
      {name: this.getTranslation('LABEL_COUNTRY'), field: 'countryName', sortable: true},
      {name: this.getTranslation('LABEL_CULTURE'), field: 'cultureCode', sortable: true},
      {name: this.getTranslation('LABEL_CULTURE'), field: 'cultureName', sortable: true},
      {name: this.getTranslation('LABEL_COUNTRYPREFIX'), field: 'phonePrefix', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsCountry
    },
    sort:{
      field: "clinicName",
      direction: "asc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private authService: AuthenticationService,
    private router : Router,
    private translate: TranslateService,
    private editService: EditServiceService,
    private utilsService: UtilsService
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getFiltersBySession();
    
    this.getCountryList();
  }

  filterName?: string;
  filterCountry?: any;

  orderDirection?: string = "clinicname_asc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getCountryList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterCountry = value.target.value.countryIsoCode;
      this.getCountryList(); 
    }
    else{
      this.filterCountry = null;
      this.getCountryList(); 
    } 
  }
 
  getCountryList() {
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{
        this.anyResult = response.length > 0 || false;
        this.countryList = response || [];
        this.tableConfig.pagination.itemsPerPage = response.length || 10;
        this.tableConfig.pagination.totalItems = response.length || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.countryList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderCountryChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getCountryList();
  }

  pageCountryChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getCountryList();
  }

  getUpdatedCountryList(): any{
    return this.getCountryList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToCountryDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/countries/detail', event.record.countryIsoCode]);
      this.saveFilters();
    }
  }

  countryAdd() {
    this.router.navigate(['/countries/add']);
  }

  bindButtons(countryList: Country[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let country of countryList){
      this.tableConfig.recordButtonConfigs.set(country,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  isClosedReportPopup: boolean = true;
  reportModalities = ["Basic version", "Extended version"];

  countryFeatureReportDownload(modalitySelected: any){

    var isFiltered = modalitySelected == "Basic version" ? true : false;
    
    this.clientService.countryFeatureReportDownload(isFiltered).subscribe(
      response =>{

        var blob = this.utilsService.base64toBlob(response.stream);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = response.fileName || '';
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);
  }
}