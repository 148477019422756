import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, BackOfficeApplicationSpecialistView, BaseGetAllRQ, ClientService, Country, IBaseGetAllRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-specialist-list',
  templateUrl: './specialist-list.component.html',
  styleUrls: ['./specialist-list.component.css']
})
export class SpecialistListComponent implements OnInit {

  specialistList! : BackOfficeApplicationSpecialistView[];  
  countryList! : Country[];
  loading: boolean = true;
  record?: any;
  totalItemsSpecialist: number = 0;
  anyResult: boolean = false;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('BTN_ADD_SPECIALIST'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_SPECIALIST'), value: '' }

  autocompleteConfig = {
    name: this.getTranslation('FILTER_COUNTRY'),
    attr: 'timeZoneName' as const,
    array: this.countryList,
    numberToShow: 6,
    value : null as any,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_COUNTRY'),
  }

  tableConfig =  {
    actions: true,
    gridData: this.specialistList,
    headerList: [
      {name: this.getTranslation('LABEL_SURNAME'), field: 'surname', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'name', sortable: true},
      {name: this.getTranslation('LABEL_EMAIL'), field: 'emailAddress', sortable: true},
      {name: this.getTranslation('LABEL_COUNTRY'), field: 'countryIsoCode', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsSpecialist
    },
    sort:{
      field: "Surname",
      direction: "asc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private authService: AuthenticationService,
    private router : Router,
    private translate: TranslateService,
    private editService: EditServiceService
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getFiltersBySession();
    
    this.getSpecialistList();

    this.getCountryList();
  }

  filterName?: string = '';
  filterCountry?: string = '';

  orderDirection?: string = "Surname_asc";
  currentPage: number = 1;


  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getSpecialistList();
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterCountry = value.target.value.countryIsoCode;
      this.getSpecialistList(); 
    }
    else{
      this.filterCountry = '';
      this.getSpecialistList(); 
    } 
  }
 
  getSpecialistList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName, 
      countryIsoCode: this.filterCountry,
    }
    this.clientService.applicationSpecialistGetAllBackOffice(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.anyResult = response.anyResult || false;
        this.specialistList = response.list || [];
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.specialistList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderSpecialistChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getSpecialistList();
  }

  pageSpecialistChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getSpecialistList();
  }

  getCountryList() {
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{

        response.forEach(element => {
          element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
          if(element.timeZoneName.startsWith("COUNTRY_")){
            element.timeZoneName = element.countryIsoCode;
          }
        });
        this.countryList = response || [];
        this.autocompleteConfig.array = this.countryList;
      } ,
      error=> console.log(error)
    );
  }

  getUpdatedSpecialistList(): any{
    return this.specialistList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToSpecialistDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/specialists/detail', event.record.idApplicationSpecialist]);
      this.saveFilters();
    }
  }

  specialistAdd() {
    this.router.navigate(['/specialists/add']);
  }

  bindButtons(specialistList: BackOfficeApplicationSpecialistView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let specialist of specialistList){
      specialist.countryIsoCode = this.getTranslation("COUNTRY_" + specialist.countryIsoCode);

      this.tableConfig.recordButtonConfigs.set(specialist,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };

    //ASSIGN PREVIOUS DROPDOWN FILTER
    this.filterCountry = window.localStorage.getItem('filterApplied') || '';
    this.autocompleteConfig.value = {
      timeZoneName: this.filterCountry,
    }
    this.autocompleteConfig = { ...this.autocompleteConfig };

    //ASSIGN PREVIOUS SORT ORDER
    this.orderDirection = window.localStorage.getItem('sortOrder') || this.orderDirection;

    //ASSIGN PREVIOUS LIST PAGE VISITED    
    this.currentPage = Number(window.localStorage.getItem('currentPage')) || 1;
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);

    //SET PREVIOUS DROPDOWN FILTER
    window.localStorage.setItem('filterApplied', this.filterCountry!);

    //SET PREVIOUS SORT ORDER
    window.localStorage.setItem('sortOrder', this.orderDirection!);

    //SET PREVIOUS LIST PAGE VISITED
    window.localStorage.setItem('currentPage', this.currentPage!.toString());
  }
}