import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';

declare let gtag: Function;
@Injectable()
export class GoogleAnalyticsService {

  constructor(private jwtService: JwtService) { }

  public eventEmitter(eventId: string, bundle: any){
    // let currentUser = this.jwtService.getCurrentUser();
    // if(currentUser){
    //   gtag('set', 'user_properties', {
    //     NurseHash: currentUser?.hashNurse,
    //     ClinicHash: '',
    //     ClinicCountry: currentUser?.clinicCountryIsoCode,
    //     IsOnboardingClinic: currentUser?.isOnboardingClinic
    //   });
    // }
    // gtag('event', eventId, bundle);
  }
}
