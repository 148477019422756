<div class="tip" *ngIf="phoneNumberConfig.showTip && !phoneNumberConfig.errorMessage">
  <span [innerHTML]="'SUGGESTION_MOBILE_NUMBER' | translate"></span>
</div>
<label class="input-lable">{{phoneNumberConfig.name}} <span class="disabled" *ngIf="phoneNumberConfig.optional">{{'OPTIONAL_LABEL'|translate}}</span></label>
<div class="row">
  <div class="col-3">
    <app-autocomplete-dropdown [autocompleteConfig]="phoneNumberConfig.autocompleteConfig" (selected)="handlePrefix($event)" ></app-autocomplete-dropdown>
  </div>
  <div class="col-6">
    <input maxlength="20"
    [readOnly]="phoneNumberConfig.readOnly ? phoneNumberConfig.readOnly : false"
    [ngModel]="phoneNumber"
    placeholder="___-___-____"
    (keydown)="isAllowed($event)"
    (keyup)="formatToPhone($event)"
    [ngClass]="phoneNumberConfig.class"
    />
  </div>
</div>
<span *ngIf="phoneNumberConfig.showTip && !phoneNumberConfig.errorMessage" class="small-info" >{{'INFO_MOBILE_NUMBER'|translate}}</span>

<div class="error-message" *ngIf="phoneNumberConfig.errorMessage">
  {{phoneNumberConfig.errorMessage}}<br>
  {{phoneNumberConfig.errorExample}}
</div>
