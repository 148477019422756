import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { ClientService, IPatientListingCatalogRQ, PatientListingCatalogRQ } from 'src/app/core';
import { SubCategoryGroupCustom } from 'src/app/core/models/subCategoryGroup-custom.model';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-patient-listing-modal',
  templateUrl: './patient-listing-modal.component.html',
  styleUrls: ['./patient-listing-modal.component.css']
})
export class PatientListingModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private smartModal: NgxSmartModalService,
    private utilsService: UtilsService,
  ) { }

  subscriptions: Subscription[] = []
  loading: boolean = true;

  idPatient: string = '';

  totalItemsCatalog = 0;
  
  okBtnConfig = {
    bclass : 'btn button-next',
    type : 'submit',
    text : this.getTranslation('BTN_OK'),
    disabled : false
  }

  headers = [
    {name: this.getTranslation('PRODUCT_NAME'), field: 'productName', sortable: true},
    {name: this.getTranslation('PRODUCT_ARTICLE_NUMBER'), field: 'articleNumber', sortable: false},
    {name: this.getTranslation('PRODUCT_AVAILABLE_IN_CATALOG'), field: 'isSelected', sortable: false, isBoolean: true},
  ];
  pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.totalItemsCatalog
  }
  sort = {
    field: "productName",
    direction: "asc"
  }
  subCategoryGroups!: any;
  filteredSubCategoryGroups!: any;
  orderDirection?: string = "productName_asc";
  currentPage: number = 1;

  filterProductName?: string;
  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_PRODUCT')}

  ngOnInit(): void {
    this.subscriptions = []
  }

  ngAfterViewInit(): void {
    let modalDataSubscription = this.smartModal.getModal('patientListingModal').onOpen.subscribe(()=>{
      var content = this.smartModal.getModal('patientListingModal').getData();
      this.idPatient = content.idPatient;

      this.getPatientListing();
    })
    this.subscriptions.push(modalDataSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  getPatientListing(){

    this.loading = true;

    let input: IPatientListingCatalogRQ;
    input = {
      idPatient: this.idPatient,
      cultureIsoCode: this.utilsService.getDefaultLanguage() ?? "en-GB"
    }
    this.clientService.patientGetListing(new PatientListingCatalogRQ(input)).subscribe(
      response =>{

        this.subCategoryGroups = response.subCategoryGroups!.map(v => new SubCategoryGroupCustom(v));
        this.subCategoryGroups[0].isExpanded = true;
        this.filteredSubCategoryGroups = this.subCategoryGroups;
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderTableChanged(headerField: string){
    this.sort.field = headerField;
    this.sort.direction = this.sort.direction == "asc" ? "desc" : "asc";
  }

  pageCatalogChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
  }

  openSubCategory(subCategoryGroup: any){
    subCategoryGroup.isExpanded = !subCategoryGroup.isExpanded;
  }

  searchFilterKeyUp(value:any){
    this.filterProductName = value;

    this.filteredSubCategoryGroups = this.subCategoryGroups;

    if(this.filterProductName != "")
    {
      this.filteredSubCategoryGroups = this.filteredSubCategoryGroups.filter((x: { products: any; }) => 
        x.products.some((y: any) => y.productName.toLowerCase().includes(this.filterProductName!.toLowerCase()) || 
                                            y.product?.articleNumber?.toLowerCase().includes(this.filterProductName!.toLowerCase())))
        .map((element: { products: any[]; }) => {
          return Object.assign({}, element, {products : element.products.filter(y => y.productName.toLowerCase().includes(this.filterProductName!.toLowerCase()) ||
                                                                                                  y.product?.articleNumber?.toLowerCase().includes(this.filterProductName!.toLowerCase())
          )});
        });
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }

  closeModal(){
    this.smartModal.getModal('patientListingModal').close();
  }
}
