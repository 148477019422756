import { Component, OnInit } from '@angular/core';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-nxg-modal',
  templateUrl: './nxg-modal.component.html',
  styleUrls: ['./nxg-modal.component.css']
})
export class NxgModalComponent implements OnInit {

  constructor(
    private modalService : NxgCustomModalService
  ) { }
  modalData:any = {} as any;

  ngOnInit(): void {
    this.modalService.getModalData()?.subscribe(
      response=>this.modalData = response
    )
  }

}
