import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService, CountryResponsibleRS, EntityRQ } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-country-responsible-detail',
  templateUrl: './country_responsible-detail.component.html',
  styleUrls: ['./country_responsible-detail.component.css']
})
export class CountryResponsibleDetailComponent implements OnInit {
  id?: string;
  countryResponsibleDetail?: CountryResponsibleRS;
  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  loading: boolean = true;

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private editService: EditServiceService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getCountryResponsibleDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigateByUrl("/countries/detail/" + this.countryResponsibleDetail?.countryIsoCode);
  }

  EditCountryResponsible() {
    this.router.navigate(['/country-responsibles/edit', this.id]);
  }

  getCountryResponsibleDetail(){
    this.loading = true;
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.countryResponsibleGet(data).subscribe(
      response =>{
        this.countryResponsibleDetail = response;
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

}
