import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, ClientService, BackOfficeNotificationView, NotificationGetAllRQ, INotificationGetAllRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  notificationList! : BackOfficeNotificationView[];  
  loading: boolean = true;
  record?: any;
  totalItemsNotification: number = 0;

  channelList = [
    { key: 'SMS', value: 'SMS' },
    { key: 'CMS_API', value: 'CMS_API' },
    { key: 'EMAIL', value: 'EMAIL' },
    { key: 'PUSH', value: 'PUSH' },
  ]
  filterChannel?: any;

  editBtnConfig = {
    bclass : 'btn',
    icon : 'chevron-right',
    width: '30px',
    height: '30px',
    type : 'edit'
  }

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_NOTIFICATION'), value: '' }

  autocompleteConfig = {
    name: this.getTranslation('FILTER_CHANNEL'),
    array: this.channelList,
    attr: 'key' as const,
    numberToShow: 6,
    value : null as any,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_CHANNEL'),
  }

  formNotificationAdd: FormGroup = this.fb.group({
    dateStart: [null],
    dateEnd: [null],
  });

  formNotificationInfo = {
    key: 'formNotificationInfo',
    datePickerConfig: 
    [{
      labelName : this.getTranslation('FILTER_DATE_START'),
      class: 'smallDate',
      optional: false,
      inputName: 'dateStart',
      value: undefined,
      maxDate: new Date()
    },
    {
      labelName : this.getTranslation('FILTER_DATE_END'),
      class: 'smallDate',
      optional: false,
      inputName: 'dateEnd',
      value: new Date(),
      maxDate: new Date()
    }]
  }

  tableConfig =  {
    actions: true,
    gridData: this.notificationList,
    headerList: [
      {name: this.getTranslation('LABEL_DATE'), field: 'dateCreated', sortable: true, isDate: true},
      {name: this.getTranslation('LABEL_TO'), field: 'nameIdentityTo', sortable: true},
      {name: this.getTranslation('LABEL_ROLE_TO'), field: 'roleTo', sortable: true},
      {name: this.getTranslation('LABEL_CHANNEL'), field: 'channel', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsNotification
    },
    sort:{
      field: "DateCreated",
      direction: "desc"
    },
    notes: false
  };

  inputValues(event: any){
    this.formNotificationAdd.controls[event.target.name].setValue(event.target.value);

    this.getNotificationList();
  }

  applyFilter(value:any){
    console.log(value);
    if(value.target.value){
      this.filterChannel = value.target.value.key;
      this.getNotificationList(); 
    }
    else{
      this.filterChannel = null;
      this.getNotificationList(); 
    } 
  }

  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router : Router,
    private translate: TranslateService,
    private editService: EditServiceService
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getFiltersBySession();

    this.getNotificationList();
  }

  filterName?: string;

  orderDirection?: string = "DateCreated_desc";
  currentPage: number = 1;
  anyResult: boolean = false;

  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getNotificationList();
  }
 
  getNotificationList() {

    let input: INotificationGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterName,
      channel: this.filterChannel,
      dateStart: this.formNotificationAdd.get('dateStart')?.value,
      dateEnd: this.formNotificationAdd.get('dateEnd')?.value,
    }
    this.clientService.notificationGetAll(new NotificationGetAllRQ(input)).subscribe(
      response =>{
        this.notificationList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.notificationList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderNotificationChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getNotificationList();
  }

  pageNotificationChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getNotificationList();
  }

  getUpdatedNotificationList(): any{
    return this.getNotificationList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToNotificationDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/notifications/detail', event.record.idNotification]);
      this.saveFilters();
    }
  }

  notificationAdd() {
    this.router.navigate(['/notifications/add']);
  }

  bindButtons(notificationList: BackOfficeNotificationView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let notification of notificationList){

      this.tableConfig.recordButtonConfigs.set(notification,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };

    //ASSIGN PREVIOUS DROPDOWN FILTER
    this.filterChannel = window.localStorage.getItem('filterApplied') || '';
    this.autocompleteConfig.value = {
      key: this.filterChannel,
    }
    this.autocompleteConfig = { ...this.autocompleteConfig };

    //ASSIGN PREVIOUS SORT ORDER
    this.orderDirection = window.localStorage.getItem('sortOrder') || this.orderDirection;

    //ASSIGN PREVIOUS LIST PAGE VISITED    

    this.currentPage = Number(window.localStorage.getItem('currentPage')) || 1;
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);

    //SET PREVIOUS DROPDOWN FILTER
    window.localStorage.setItem('filterApplied', this.filterChannel!);

    //SET PREVIOUS SORT ORDER
    window.localStorage.setItem('sortOrder', this.orderDirection!);

    //SET PREVIOUS LIST PAGE VISITED
    window.localStorage.setItem('currentPage', this.currentPage!.toString());
  }
}