<app-input [inputConfig]="passwordInputConfig.input" [avoidSanitizing]="true" (value)="getInputValue($event)"></app-input>
<svg-icon [src]="passwordIcon" class="pass-icon" (click)="passwordIconChange()" *ngIf="passwordInputConfig.passwordIcon !== undefined && passwordInputConfig.passwordIcon !== null"></svg-icon>
<app-title [textConfiguration]="passwordInputConfig.subtitle" *ngIf="passwordInputConfig.subtitle"></app-title>
<div class="restrictions">
    <div class="rest" *ngFor="let rest of passwordInputConfig.restrictions">
        <div class="circle" [ngClass]="rest.class">
            <svg-icon [src]="'../../../../assets/icons/greenV-icon.svg'" *ngIf="rest.class === 'valid'"></svg-icon>
        </div>
        <span class="text" [ngClass]="rest.class">{{rest.text}}</span>
    </div>
</div>
