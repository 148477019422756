import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AddRequestStatusRQ, AuthenticationService, ClientService, EntityRQ, IAddRequestStatusRQ, INurseDeactivateRQ, INurseSendRegistrationCodeRQ, NurseDeactivateRQ, NurseRS, NurseSendRegistrationCodeRQ, RegistrationCodesRS, UserIdentityRequestStatusEnum } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { BackOfficeSupportRequestViewCustom } from 'src/app/core/models/backOfficeSupportRequestView-custom.model';
import { Sort } from 'src/app/shared/utils/sort';
import { Location } from '@angular/common';
import { Role } from 'src/app/core/enum/roles';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from 'src/app/core/services/request.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-nurse-detail',
  templateUrl: './nurse-detail.component.html',
  styleUrls: ['./nurse-detail.component.css']
})
export class NurseDetailComponent implements OnInit {
  id?: string;
  nurseDetail?: any;
  reqSelected?: BackOfficeSupportRequestViewCustom;
  showSnackBar?: boolean;
  snackText?: string;
  isDemoNurse : boolean = false;
  isDeactivable: boolean = false;
  isClinicDeactivated = false;
  isFromRequest = false

  modalSuccessTitle: any;

  loading: boolean = true;
  planningCodeMode: boolean = false;

  constructor(
    private location: Location,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private authService: AuthenticationService,
    private requestService: RequestService,
  ) {}

  EditNurse(){
    this.router.navigate(['/nurses/edit', this.id]);
  }

  ShowSuccessModal(title: string){
    this.modalService.setModalData({ 
      title: title,
      actionToDo: () => {
        this.loading = true;
        this.getNurseDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }


  getTranslation(wordYouNeedToTranslate: string): string {
    return wordYouNeedToTranslate != "" ? this.translate.instant(wordYouNeedToTranslate) : "";
  }

  btnConfigReactive = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_REACTIVE'),
    width: '30px',
    height: '30px'
  }

  btnConfigUnlock = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_UNLOCK'),
    width: '30px',
    height: '30px'
  }

  btnConfigStart = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_START_TASK'),
    width: '30px',
    height: '30px'
  }

  btnConfigResolved = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_RESOLVE'),
    width: '30px',
    height: '30px'
  }

  btnConfigDeactive = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('NURSE_DETAIL_DEACTIVATENURSE'),
    width: '30px',
    height: '30px'
  }

  btnConfigNewCode = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_NEWCODE'),
    width: '30px',
    height: '30px'
  }

  

  showPlanningCodeMode(){    
    this.ngxModal.getModal('planRegistrationCodeModal').open();
  }


  ngOnInit() {
    this.isDemoNurse = this.route.snapshot.data.isDemo;
    this.route.queryParams.subscribe(params => {
      this.isFromRequest = params['fromRequest'] ? params['fromRequest'] == "true" : false;
    });
    this.id = this.route.snapshot.paramMap.get('id')?.toString(); 
    this.getNurseDetail();

    if(this.editService.showEditSnackBar){
      this.snackText = this.getTranslation("SAVED_CHANGES");
      this.ShowSnackBar();
    }
  }

  backToList(){   
    if(this.isFromRequest){
      this.router.navigate(['/requests']);
    }
    else this.router.navigate(['/nurses']);
  }

  getNurseDetail(){
    this.isDeactivable = false;
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.nurseGet(data).subscribe(
      response =>{
        this.nurseDetail = response;
        this.nurseDetail.activeRequests = response.activeRequests?.map(v => new BackOfficeSupportRequestViewCustom(v));
        this.nurseDetail.resolvedRequests = response.resolvedRequests?.map(v => new BackOfficeSupportRequestViewCustom(v));
        this.isClinicDeactivated = response.accountStatus === 'CLINIC_DEACTIVATED';
        if(!this.nurseDetail?.dateDeactivated){
          this.isDeactivable = true;

          if(this.authService.currentUserValue.role == Role.AppSupport || this.authService.currentUserValue.role == Role.SuperAdmin || this.authService.currentUserValue.role == Role.Admin){
            this.nurseDetail.activeRequests.forEach((element: any) => {
              if(element?.requestType == "NurseAccountHelp" && element?.requestSubType == "AccountDeletion"){
                this.isDeactivable  = false;
              }
            });
          }
        }
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getListStatus(request: BackOfficeSupportRequestViewCustom){
    this.clientService.requestStatusGetAll(request.idUserIdentityRequest, null, "dateCreated_desc").subscribe(
      response =>{
        request.listStatus = response.list?.map(v => new BackOfficeSupportRequestViewCustom(v));
        request.listStatus.forEach((element: any) => {
          element.comment = element.comment != "" ? '"' + element.comment + '"' : "------";
          element.fullName = element.fullName != null ? element.fullName : "OMG"
        });
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  sendCode(event: any = null){
    this.loading = true;

    let input : INurseSendRegistrationCodeRQ ={
      datePlanned: event?.datePlanned,
      idNurse: this.nurseDetail?.idNurse
    }

    this.clientService.backOfficeNurseSendRegistrationCode(new NurseSendRegistrationCodeRQ(input)).subscribe(
      response =>{
        this.loading = false;

        this.getNurseDetail();

        this.snackText = event == undefined ? this.getTranslation("NURSE_DETAIL_CODE_SENT") : this.getTranslation("NURSE_DETAIL_CODE_PLANNED");

        this.ShowSnackBar();
      },
      error=> console.log(error)
    );
  }

  deactivateNurse(req = null){
    this.openModal(req, "NURSE_DETAIL_DEACTIVATENURSE_MODAL_TITLE", "NURSE_DETAIL_DEACTIVATENURSE_MODAL_DESC", "NURSE_DETAIL_DEACTIVATENURSE_MODAL_BTN", "deactivate", true);
  }

  reactivateNurse(req = null){
    this.openModal(req, "NURSE_DETAIL_REACTIVATENURSE_MODAL_TITLE", "NURSE_DETAIL_REACTIVATENURSE_MODAL_DESC", "NURSE_DETAIL_REACTIVATENURSE_MODAL_BTN", "reactivate");
  }

  newRegistrationCode(req: any){
    this.openModal(req, "REQUEST_LIST_NEWCODE_MODAL_TITLE", "REQUEST_LIST_NEWCODE_MODAL_DESC", "REQUEST_LIST_NEWCODE_MODAL_BTN", "newRegistrationCode");
  }

  unlock(req: any){
    this.openModal(req, "REQUEST_LIST_UNLOCK_MODAL_TITLE", "REQUEST_LIST_UNLOCK_MODAL_DESC", "REQUEST_LIST_UNLOCK_MODAL_BTN", "unlock");
  }

  startTask(req: any){
    this.openModal(req, "REQUEST_LIST_START_MODAL_TITLE", "", "REQUEST_LIST_START_MODAL_BTN", "start");
  }

  resolved(req: any){
    this.openModal(req, "REQUEST_LIST_RESOLVE_MODAL_TITLE", "REQUEST_LIST_RESOLVE_MODAL_DESC", "REQUEST_LIST_RESOLVE_MODAL_BTN", "resolved");
  }

  addComment(req: any){
    this.openModal(req, "", "", "BTN_COMMON_SAVE", "addComment");
  }

  cancelRequest(req: any){
    this.openModal(req, "REQUEST_CANCEL_MODAL_TITLE", "REQUEST_CANCEL_MODAL_DESC", "REQUEST_CANCEL_MODAL_BTN", "cancelled", true);
  }

  reopenRequest(req: any){
    this.openModal(req, "REQUEST_REOPEN_MODAL_TITLE", "REQUEST_REOPEN_MODAL_DESC", "REQUEST_REOPEN_MODAL_BTN", "reopen");
  }

  requestModalBtnConfig = {
    type: 'button',
    bclass : 'btn btn-primary add-patient-button',
    text : "",
    action: "",
    width: '30px',
    height: '30px'
  }

  openModal(req: any, title: string, desc: string, btnConfirm: string, event: string, isDanger: boolean = false){
    this.reqSelected = req;
    this.requestModalBtnConfig = {
      type: 'button',
      bclass : isDanger ? 'btn btn-primary deactive-button' : 'btn btn-primary add-patient-button',
      text : this.getTranslation(btnConfirm),
      action: event,
      width: '30px',
      height: '30px'
    };

    this.modalService.setModalData({ 
      title: title,
      description: desc,
      commentVisible: req != null,
      actionBtnConfig: this.requestModalBtnConfig,
      actionToDo: (comment: any) => 
      {
        if(event == "deactivate"){
          let input: INurseDeactivateRQ = {
            idNurse: this.id,
            idUserIdentityRequest: this.reqSelected?.idUserIdentityRequest,
            keyStatus: UserIdentityRequestStatusEnum.Resolved, 
            comment: comment
          }
          this.clientService.nurseDeactivate(new NurseDeactivateRQ(input)).subscribe(
            response =>{
              this.ShowSuccessModal("NURSE_DETAIL_DEACTIVATENURSE_MODAL_SUCCESS");
            },
            error=> console.log(error)
          );
        }
        if(event == "reactivate"){
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.nurseReactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal("NURSE_DETAIL_REACTIVATENURSE_MODAL_SUCCESS");
            } ,
            error=> console.log(error)
          );
        }
        if(event == "unlock" && this.reqSelected != null){
          this.requestService.approveRequest(this.reqSelected.idUserIdentityRequest!, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_LIST_UNLOCK_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "newRegistrationCode" && this.reqSelected != null){
          this.requestService.approveRequest(this.reqSelected.idUserIdentityRequest!, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.snackText = this.getTranslation("NURSE_DETAIL_CODE_SENT");
                this.ShowSnackBar();
                this.getNurseDetail();
              }
            }
          )
        }
        if(event == "start" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.InProgress, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_LIST_START_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "resolved" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.Resolved, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_LIST_RESOLVE_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "addComment" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.Commented, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.getListStatus(this.reqSelected!);
              }
            }
          )
        }
        if(event == "cancelled" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.Cancelled, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_CANCEL_MODAL_SUCCESS");
              }
            }
          )
        }
        if(event == "reopen" && this.reqSelected != null){
          this.requestService.addRequestStatus(this.reqSelected.idUserIdentityRequest!, UserIdentityRequestStatusEnum.InProgress, comment).pipe(first()).subscribe(
            data => {
              if(data) {
                this.ShowSuccessModal("REQUEST_REOPEN_MODAL_SUCCESS");
              }
            }
          )
        }
      }
    });
    
    this.ngxModal.getModal('requestModal').open();
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

  openComments(req: any){
    this.getListStatus(req);
    req.isExpanded = !req.isExpanded;
  }
  
}
