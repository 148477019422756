import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-main-button',
  templateUrl: './main-button.component.html',
  styleUrls: ['./main-button.component.css']
})
export class MainButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(this.type === undefined){
      this.type = 'PRIMARY';
    }
  }

  @Input() text!: string;
  @Input() disabled?: boolean = false
  @Input() type?: "PRIMARY" | "SECONDARY" | "CANCEL" | "WHITE" | "UNDERLINE" | "DEACTIVATION" | "INVITATION" | "INVITATION-PRIMARY" = "PRIMARY";
  @Input() extraStyle?: {[klass: string ] : any};
  @Output() onClick = new EventEmitter();
  @Input() width?: any;
  @Input() id? : string;
  @Input() extraClass?: any;
  @Input() buttonType?: 'button' | 'submit' = 'button';
  @Input() fontSizePx?: number;


  getExtraStyle(){
    let extraStyle = this.extraStyle || {} 
    let widthClass: any;
    if(this.width){
      widthClass = {'min-width' : this.width, 'width' : 'fit-content'}
    }else{
      widthClass = {'padding-left' : '4rem', 'padding-right' : '4rem'}
    }
    return {...extraStyle, ...widthClass}
  }


  getButtonClassFromType(){
    let mainClass = this.type?.toLowerCase() + '-button';
    return mainClass + ( ' ' + this.extraClass || '')
  }

  getRemSize(){
    if(this.fontSizePx){
      return this.fontSizePx / 16;
    }
    return 0.875;
  }


  onBtnClick(){
    this.onClick.emit();
  }

}


export class CustomMainButtonConfig{
  text!: string
  disabled?: boolean = false 
  type?: "PRIMARY" | "SECONDARY" | "CANCEL" | "WHITE" | "UNDERLINE" | "DEACTIVATION" | "INVITATION" | "INVITATION-PRIMARY"= "PRIMARY"; 
  extraStyle?: {[klass: string ] : any};
  width?: string
  id?: string
  extraClass?: string
  buttonType?: 'button' | 'submit' = 'button';
  fontSizePx?: number
  valueWhenEmit?: any 
}