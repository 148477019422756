<ngx-smart-modal #checkOTPCode identifier="checkOTPCode" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-header header-edit">
    <div class="container-fluid">
        <div class="text-center">
            <p class="modal-title mt-4">{{'CHECK_OTP_CODE_TITLE' | translate}}</p>
            <p class="modal-description mt-4">{{'CHECK_OTP_CODE_DESC' | translate}}</p>

            <form [formGroup]="formCheckCode">
                <hr/>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <ng-otp-input (onInputChange)="controlOtpValue($event)" [config]="OtpComponentConfiguration"></ng-otp-input>
                    </div>
                </div>

                <br/>

                <div class="row" *ngIf="errorMessage">
                    <div class="col-12" >
                      <div class="error"><span class="error-message">{{errorMessage | translate}}</span></div>
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-12" >
                        <i class="modal-description mt-4">{{'LABEL_OTP_NOTRECEIVED' | translate}}</i>
                        <br/>
                        <app-text class="edit_link" (click)="resendOtp()" [text]="otpMessage" [fontSizePx]="16" [isBold]="true" [textType]="otpResendDisabled ? 'GREY' : 'BLUE'"></app-text>
                    </div>
                </div>
                
                
            </form> 
        </div>
    </div>
</div>
<div class="modal-body mt-2 text-center d-flex flex-row justify-content-around">
    <app-button (click)="checkOTPAndLogin()" [buttonConfig]="btnConfig" ></app-button>
</div>
</ngx-smart-modal>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" SnackText="{{'LABEL_OTP_SENT' | translate}}"></app-snackbar>