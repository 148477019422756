<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="nephrologistDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <div class="row" [class.edit-disabled]="isClinicDeactivated">
            <div *ngIf="!nephrologistDetail.dateDeactivated" class="col-12 text-right">
                <a (click)="EditNephrologist()" class="text-right"><i class="fas fa-pencil-alt"></i><u class="edit_link"> {{ 'COMMON_EDIT_DATA' | translate }} </u></a>
            </div>
        </div>

        <h2 class="mt-4">{{ nephrologistDetail.name  + " " + nephrologistDetail.surname}}</h2>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_CLINIC' | translate }}</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.clinicName}}
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_NAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.name}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.surname}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ID' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.idNephrologist}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NEPHROLOGIST_DETAIL_BADGENUMBER' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.badgeNumber}}
            </div>
        </div>

        <div *ngIf="isRegistrationNumberIssuerManaged" class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NEPHROLOGIST_ADD_ISSUEDBY' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.registrationNumberIssuer}}
            </div>
        </div>

        <div *ngIf="isQualificationManaged" class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NEPHROLOGIST_QUALIFICATION' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.qualification}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_WORKMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.emailAddress}}
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nephrologistDetail.dateCreated | DateFormatPipe: true}}
            </div>
        </div>

        <div *ngIf="nephrologistDetail.dateDeactivated">
            <div  class="row mt-4"  >
                <div class="col-6 col-lg-3">
                    <b>{{ 'LABEL_DEACTIVATIONDATE' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{nephrologistDetail.dateDeactivated | DateFormatPipe: true}}
                </div>
            </div>

            <br>

            <i>{{ 'NEPHROLOGIST_REGISTER_AGAIN' | translate}}</i>
        </div>

        <hr/>       

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NEPHROLOGIST_DETAIL_STATUS' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ 'NEPHROLOGIST_STATUS_' + nephrologistDetail.statusDocuSign | translate }}
            </div>
        </div>

        <br/><br/>

        <table class="table mt-2">

            <h5>{{ 'NEPHROLOGIST_DETAIL_OMGSTATUS' | translate }}</h5>
            <tr scope="row">
                <th scope="col">{{ 'NEPHROLOGIST_DETAIL_ACCOUNT_DATECREATED' | translate }}</th>
                <th scope="col">{{ 'NEPHROLOGIST_DETAIL_ACCOUNT_STATUS' | translate }}</th>
            </tr>
            <tr>
                <td>{{nephrologistDetail.accountDateCreated | DateFormatPipe: true}}</td>
                <td>{{ 'ACCOUNT_STATUS_' + nephrologistDetail.accountStatus | translate }}</td>
            </tr>
        </table>

        <div *ngIf="!nephrologistDetail.dateDeactivated" [class.edit-disabled]="isClinicDeactivated">

            <div *appHasRole="['SUPER_ADMIN','ADMIN','APPLICATION_SPECIALIST','CUSTOMER_SERVICE_USER']" class="col-12 col-lg-6">
                <div class="mt-5" *ngIf="nephrologistDetail.statusDocuSign == 'WAITING' || nephrologistDetail.statusDocuSign == 'ON_HOLD' || nephrologistDetail.statusDocuSign == 'REJECTED'">
                    <div class="border-box p-4">
                        <h5>{{ 'NEPHROLOGIST_DETAIL_SENDINVITE_TITLE' | translate }}</h5>
                        <p class="desc-italic mt-4">{{ 'NEPHROLOGIST_DETAIL_SENDINVITE_DESC' | translate }}</p>

                        <div class="text-center mt-5">
                            <app-button (click)="sendPolicy()" [buttonConfig]="btnConfig" ></app-button>
                            <p class="desc-italic mt-2">{{ 'NEPHROLOGIST_DETAIL_SENDINVITE_NOTE' | translate }}</p>
                        </div>
                    </div> 
                </div>
            </div>

            <br/>            
        </div>

        <div class="col-12 text-left" [class.edit-disabled]="isClinicDeactivated">
            <a (click)="DeactivateNephrologist()" class="text-right"><i class="fas fa-user-slash"></i> <u class="edit_link">
                {{ this.nephrologistDetail.dateDeactivated ? ('NEPHROLOGIST_DETAIL_REACTIVATE' | translate) : ('NEPHROLOGIST_DETAIL_DEACTIVATE' | translate) }}</u></a>
        </div>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'" [SnackText]="snackText"></app-snackbar>