<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
          </button>

        <h2 class="mt-4">{{ (id == undefined ? 'NEPHROLOGIST_ADD_TITLE' : 'NEPHROLOGIST_EDIT_TITLE') | translate}}</h2>

        <form [formGroup]="formNephrologistAdd">

            <div class="row my-2">
                <div class="col-md-6 col-10">
                    <app-autocomplete-dropdown [autocompleteConfig]="formNephrologistInfo?.autocompleteClinicConfig" (selected)="inputValues($event)" ></app-autocomplete-dropdown>
                    <span class="info-text mt-2">{{'NEPHROLOGIST_ADD_CLINIC_NOTE' | translate}}</span>
                    <hr class="w-100">
                </div>
            </div>
            
            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[1]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[2]" [formControlInput]="formNephrologistAdd" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[3]" (keyup)="inputValues($event)"></app-input>
                </div>
                <div class="col-2">
                    <br>
                    <app-info-tooltip [description]="'NEPHROLOGIST_ADD_INFO_BADGENUMBER' | translate"></app-info-tooltip>
                </div>
            </div>

            <div *ngIf="isRegistrationNumberIssuerManaged" class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[4]" (keyup)="inputValues($event)"></app-input>
                    <p [ngClass]="registrationNumberIssuerError ? 'info-text-error' : 'info-text'" [innerHTML]="'NEPHROLOGIST_ADD_ISSUEDBY_ERROR' | translate"></p>
                </div>                
            </div>

            <div *ngIf="isQualificationManaged" class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[5]" (keyup)="inputValues($event)"></app-input>
                </div>                
            </div>
            
            <!--<div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formNephrologistInfo.input[4]" (keyup)="inputValues($event)"></app-input>
                </div>
            </div>-->

            <div *ngIf="id == undefined" class="row my-2">
                <div class="col-md-4 col-10">
                    <app-checkbox [checked]="sendPrivacyPolicy" (check)="checkPrivacyPolicy()" [text]="getTranslation('NEPHROLOGIST_ADD_PRIVACY_POLICY')"></app-checkbox>
                </div>
                <div class="col-2">
                    <br>
                    <app-info-tooltip [description]="'NEPHROLOGIST_ADD_INFO_PRIVACYPOLICY' | translate"></app-info-tooltip>
                </div>
            </div>

            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>

            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveNephrologist()"></app-button>
                </div>
            </div>

            <br/>

        </form>
    </div>
</ng-template>