<ngx-smart-modal #myModal identifier="myModal" data-backdrop="static" data-keyboard="false" [closable]="false" [dismissable]="false">
  <div class="modal-header">
    <div class="row">
      <div class="col-6">
        <div>
          <div>
            <h5 class="modal-title" id="exampleModalLongTitle">{{modalData.title}}<br>
              <!-- <span class="patient-name">name</span> -->
            </h5>
          </div>
        </div>
      </div>
      <div class="col-6">
        <button class="close" (click)="myModal.close()">
          <span aria-hidden="true">
            <svg-icon [src]="'../../../../../assets/icons/close.svg'"></svg-icon>
          </span>
        </button>
      </div>
    </div>
  </div>
  <p>{{modalData.msg}}</p>
  </ngx-smart-modal>    
