import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService, CountryResponsibleRS, CountryResponsibleSetRQ, ClientService, ICountryResponsibleSetRQ, EntityRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-country-responsible-edit',
  templateUrl: './country_responsible-edit.component.html',
  styleUrls: ['./country_responsible-edit.component.css']
})
export class CountryResponsibleEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  countryResponsibleDetails?: CountryResponsibleRS;
  loading: boolean = true;

  countryPhonePrefixList!: any | [];
  roleList!: any | [];
  validationErrors: any[] = [];

  selectedCountry: any;
  selectedRole: any;

  formCountryResponsibleAdd: FormGroup = this.fb.group({
    name: [null, Validators.required],
    surname: [null, Validators.required],
    email : [null, Validators.pattern(new RegExp(/^[^\s]+@[^\s]+\.[^\s]+$/))],
    phonePrefix : [null],
    mobileNumber : [null],
    role: [null, Validators.required]
  });

  formCountryResponsibleInfo={
    key: 'formCountryResponsibleInfo',
    input:
    [
      { name: this.getTranslation('LABEL_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'name', value: this.formCountryResponsibleAdd.get("name")?.value, maxLength: 64 },
      { name: this.getTranslation('LABEL_SURNAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'surname', value: this.formCountryResponsibleAdd.get("surname")?.value, maxLength: 64},
      { name: this.getTranslation('LABEL_EMAIL'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'email', value: this.formCountryResponsibleAdd.get("email")?.value, maxLength: 256 },
      { name: this.getTranslation('LABEL_MOBILE'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'mobileNumber', value: this.formCountryResponsibleAdd.get("mobileNumber")?.value, maxLength: 16 },
    ],
    autocompleteCountryPhonePrefixConfig: {
      name: this.getTranslation('LABEL_COUNTRYPREFIX'),
      attr: 'phonePrefix' as const,
      array: this.countryPhonePrefixList,
      numberToShow: 6,
      prefix: '+',
      value : null as any,
      inputName : 'phonePrefix',
      class: 'form-control autocomplete-input',
      disabled: true
    },
    autocompleteRoleConfig: {
      name: this.getTranslation('LABEL_ROLE'),
      attr: 'title' as const,
      array: this.roleList,
      numberToShow: 6,
      prefix: false,
      value : null as any,
      inputName : 'role',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('LABEL_ROLE'),
    },
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('COUNTRY_RESPONSIBLE_ADD_TITLE'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private authService: AuthenticationService,
    private utilsService: UtilsService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !form.valid;
      });
    this.addBtnConfig.disabled = !form.valid;
  }

  inputValues(event: any){
    this.formCountryResponsibleAdd.controls[event.target.name].setValue(event.target.value); 

    if (event.target.name === 'mobileNumber') {
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT"));
      this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"));
    }
    
    this.formValidation(this.formCountryResponsibleAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/countries/detail/' + this.selectedCountry);
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/country-responsibles/detail', this.id]);
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();
    this.selectedCountry = this.route.snapshot.paramMap.get('countryIsoCode')?.toString();
    this.selectedRole = this.route.snapshot.paramMap.get('role')?.toString();
    
    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
      this.getCountryResponsibleDetail();
    }

    this.getAllRole();

    this.authService.getAllCountryPhonePrefix(this.id, this.formCountryResponsibleInfo, this.formCountryResponsibleAdd);
  
    this.loading = false;
  }

  getCountryResponsibleDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.countryResponsibleGet(data).subscribe(
      response =>{

        this.countryResponsibleDetails = response;
        this.selectedCountry = response.countryIsoCode;

        this.formCountryResponsibleAdd.patchValue({
          name: response.name,
          surname: response.surname,
          email: response.emailAddress,
          mobileNumber: response.mobileNumber,
          phonePrefix: { phonePrefix: response.mobileNumberCountryPrefix, countryIsoCode: response.mobileNumberCountryPrefixCode ?? null },
          role: { role: response.role },
        });

        this.formCountryResponsibleInfo!.input[0].value = this.formCountryResponsibleAdd.get("name")?.value;
        this.formCountryResponsibleInfo!.input[1].value = this.formCountryResponsibleAdd.get("surname")?.value;
        this.formCountryResponsibleInfo!.input[2].value = this.formCountryResponsibleAdd.get("email")?.value;
        this.formCountryResponsibleInfo!.input[3].value = this.formCountryResponsibleAdd.get("mobileNumber")?.value;

        this.formCountryResponsibleInfo!.autocompleteCountryPhonePrefixConfig.value = this.countryResponsibleDetails.mobileNumberCountryPrefix ? 
        { 
          phonePrefix: this.countryResponsibleDetails.mobileNumberCountryPrefix ?? null,
          countryIsoCode: this.countryResponsibleDetails.mobileNumberCountryPrefixCode ?? null
        } : null as any;

        this.selectedRole =  this.countryResponsibleDetails.role;

        this.formCountryResponsibleInfo!.autocompleteCountryPhonePrefixConfig = {...this.formCountryResponsibleInfo!.autocompleteCountryPhonePrefixConfig}
        this.formCountryResponsibleInfo!.autocompleteRoleConfig = {...this.formCountryResponsibleInfo!.autocompleteRoleConfig}

        this.formValidation(this.formCountryResponsibleAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  getAllRole(): any{
    this.clientService.countryRoleGetAll().subscribe(
      response =>{

        this.roleList = response;
        this.formCountryResponsibleInfo.autocompleteRoleConfig.array = response;
        this.formCountryResponsibleInfo.autocompleteRoleConfig.disabled = false;
        
        let correctRole = this.formCountryResponsibleInfo.autocompleteRoleConfig.array.filter((x: { title: any; }) => x.title == this.selectedRole)[0];

        if(correctRole != undefined){
          this.formCountryResponsibleAdd.patchValue({
            role: { role: correctRole.role, title: correctRole.title },
          });
          this.formCountryResponsibleInfo!.autocompleteRoleConfig.value = { role: correctRole.role, title: correctRole.title };
          this.formCountryResponsibleInfo!.autocompleteRoleConfig = {...this.formCountryResponsibleInfo!.autocompleteRoleConfig};
        }
      },
      error=> console.log(error)
    );
  }

  saveCountryResponsible(){
    if (this.formCountryResponsibleAdd.valid) {

      this.loading = true;
      let input: ICountryResponsibleSetRQ;
      input = {
            idCountryResponsible: this.id,
            name: this.formCountryResponsibleAdd.get('name')?.value,
            surname: this.formCountryResponsibleAdd.get('surname')?.value,
            countryIsoCode: this.selectedCountry,
            emailAddress: this.formCountryResponsibleAdd.get('email')?.value,
            mobileNumberCountryPrefixCode: this.formCountryResponsibleAdd.get('mobileNumber')?.value != undefined ?
              this.formCountryResponsibleAdd.get('phonePrefix')?.value?.countryIsoCode ?? null : null,
            mobileNumberCountryPrefix: this.formCountryResponsibleAdd.get('mobileNumber')?.value != undefined ? 
              this.formCountryResponsibleAdd.get('phonePrefix')?.value?.phonePrefix : null,
            mobileNumber: this.formCountryResponsibleAdd.get('mobileNumber')?.value,
            role: this.formCountryResponsibleAdd.get('role')?.value?.role
          }
      this.clientService.countryResponsibleSet(new CountryResponsibleSetRQ(input)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){

            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'COUNTRY_RESPONSIBLE_ADD_SUCCESSFULLY',
                description: this.selectedCountry,
                actionToDo: () => 
                {
                  this.router.navigate(['/country-responsibles/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              this.utilsService.addValidationError(this.validationErrors, this.getTranslation(response?.errorType));

              if(response?.errorType == "ERROR_EMAIL_ALREADY_EXISTS")
                this.formCountryResponsibleInfo.input[2].class = "input-form error";
              else if(response?.errorType == "ERROR_COUNTRY_RESPONSIBLE_ALREADY_EXISTS"){
                this.formCountryResponsibleInfo.input[0].class = "input-form error";
                this.formCountryResponsibleInfo.input[1].class = "input-form error";
              }
              else if(response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_FORMAT" || response?.errorType == "ERROR_MOBILE_NUMBER_NOT_VALID_LANDLINE_NUMBER"){
                this.formCountryResponsibleInfo.input[3].class = "input-form error";
                this.formCountryResponsibleInfo.autocompleteCountryPhonePrefixConfig.class = "form-control autocomplete-input error";
              }
            }
              
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }
}
