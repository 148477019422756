import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AssignCalendarRQ, AuthenticationService, BaseGetAllRQ, ClientService, IAssignCalendarRQ, IOrderSetWindowRQ, IPatientDeactivateRQ, IPatientNewAppInvitationRQ, OrderSetWindowRQ, PatientDeactivateRQ, PatientNewAppInvitationRQ, PatientRS, RegistrationCodesRS, UserIdentityRequestStatusEnum } from 'src/app/core';
import { BackOfficeSupportRequestViewCustom } from 'src/app/core/models/backOfficeSupportRequestView-custom.model';
import { Location } from '@angular/common';
import { RequestService } from 'src/app/core/services/request.service';
import { first } from 'rxjs/operators';
import { Role } from 'src/app/core/enum/roles';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { BackOfficeCalendarViewCustom } from 'src/app/core/models/BackOfficeCalendarView-custom.model';
import { FilterData, FilterEmit, FilterValue } from 'src/app/shared/filters/filters.component';
import { uniqueObjectsByParameter } from 'src/app/core/utils/array-utils';
import { executeFilter } from 'src/app/core/utils/filter-utils';

@Component({
  selector: 'app-patient-assign-calendar',
  templateUrl: './patient-assign-calendar.component.html',
  styleUrls: ['./patient-assign-calendar.component.css']
})
export class PatientAssignCalendarComponent implements OnInit {

  constructor(
    private location: Location,
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private requestService: RequestService,
    private authService: AuthenticationService,
    private utilsService: UtilsService,
  ) { }

  idPatient: string | undefined;
  idPreviousCalendar: string | undefined;

  calendarList! : BackOfficeCalendarViewCustom[];
  allCalendarList! : BackOfficeCalendarViewCustom[];
  spinner = true;

  filters: FilterData[] = [];
  
  saveBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('BTN_COMMON_SAVE'),
    disabled : false
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      params => {
        this.idPatient = params["id1"]
        this.idPreviousCalendar = params["id2"]
    });

    this.spinner = true
    this.getCalendars();
    this.spinner = false
  }

  async getCalendars(){
    const inputData = new BaseGetAllRQ({
      pageIndex: 1,
      sortOrder: "baseWeek_asc"
    })
    const res = await this.clientService.calendarGetAllBackOffice(inputData).toPromise();
    this.calendarList = (res.list || []).map(row => BackOfficeCalendarViewCustom.fromJS(row, (key)=> this.getTranslation(key)));
    this.allCalendarList = this.calendarList;

    this.buildFilters();
    
    var preselecteCalendar = this.calendarList.filter(x => x.idCalendar == this.idPreviousCalendar)[0];
    if(preselecteCalendar != undefined){
      preselecteCalendar.isSelected = true;
    }
  }

  showAssignCalendarModal(){
  
    if(this.idPreviousCalendar != undefined){

      this.modalService.setModalData({ 
        title: 'CALENDAR_PATIENT_ASSIGN_MODAL_TITLE',
        description: 'CALENDAR_PATIENT_ASSIGN_MODAL_DESC',
        actionBtnConfig: {
          bclass: 'btn btn-primary add-patient-button',
          text: this.getTranslation('CALENDAR_PATIENT_ASSIGN_MODAL_BTN'),
          action: 'deactivate',
          width: '30px',
          height: '30px'
        },
        actionToDo: () => 
        {
          this.assignCalendar();
        }
      });
      this.ngxModal.getModal('requestModal').open();
    }
    else{
      this.assignCalendar();
    }
  }

  assignCalendar(){
    
    if(this.calendarList.some(x => x.isSelected)){

      var idCalendarSelected = this.calendarList.filter(x => x.isSelected)[0];

      let calendar: IAssignCalendarRQ;
      calendar = {
        idPatient: this.idPatient,
        idCalendar: idCalendarSelected.idCalendar
      }

      this.clientService.assignCalendar(new AssignCalendarRQ(calendar)).subscribe(
        response =>{

          if(response){
            this.modalService.setModalData({ 
              title: 'CALENDAR_PATIENT_ASSIGNED_SUCCESSFULLY',
              actionToDo: () => 
              {
                this.router.navigate(['/patients/detail', this.idPatient]);
              }
            });
            this.ngxModal.getModal('successModal').open();
          }
      })
    }
  }

  buildFilters(){
    const nameOptions: FilterValue[] = uniqueObjectsByParameter(
      this.calendarList.map(calendar=> ({ label: calendar.calendarName!, key: calendar.calendarName!, selected: false})),
      'key'
    )
    const nameFilter: FilterData = { filterKey: 'calendarName', filterName: this.getTranslation('LABEL_NAME'), values: nameOptions};
    

    const dueDateOptions: FilterValue[] = uniqueObjectsByParameter(
      this.calendarList.map(calendar=> ({  label: calendar.dueDateLabel!,  key: `${calendar.dueDateWeek},${calendar.dueDateWeekDay}`,  selected: false })),
      'key'
    )

    const dueDateFilter: FilterData = { 
      filterKey: 'dueDate', 
      filterName: this.getTranslation('CALENDAR_DUE_DATE'), 
      values: dueDateOptions,
      customProjection: (object,value) => {
        const values = value.split(',');
        return values[0] == object['dueDateWeek'] && values[1] == object['dueDateWeekDay']
      }
    }
    
    const pickingDateOptions: FilterValue[] = uniqueObjectsByParameter(
      this.calendarList.map(calendar=> ({ label: calendar.pickingLabel!, key: `${calendar.pickingWeek},${calendar.pickingWeekDay}`, selected: false })), 
      'key'
    );
      
    const pickingDateFilter: FilterData = { 
      filterKey: 'pickingDate', 
      filterName: this.getTranslation('CALENDAR_PICKING'), 
      values: pickingDateOptions,
      customProjection: (object,value) => {
        const values = value.split(',');
        return values[0] == object['pickingWeek'] && values[1] == object['pickingWeekDay']
      }
    }
    
    const deliveryDateOptions: FilterValue[] = [...new Set(this.calendarList.map(calendar=> calendar.deliveryDateLabel))].map(deliveryDateLabel=> ({ label: deliveryDateLabel,  key: deliveryDateLabel,  selected: false }))
    const deliveryDateFilter: FilterData = { filterKey: 'deliveryDateLabel', filterName: this.getTranslation('CALENDAR_DELIVERY_DATE'), values: deliveryDateOptions}

    this.filters = [nameFilter, deliveryDateFilter, pickingDateFilter, dueDateFilter];
  }
  
  onFilterUpdate(event: FilterEmit){
    this.filters = event.updatedData;
    this.calendarList = executeFilter(event, this.allCalendarList);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
