<div id="order-delivery-dp" style="touch-action: none;">
    <ngb-datepicker #dp [dayTemplate]="customDay" [maxDate]="maxDate" [minDate]="getFirstDateAvailableFromFirstDelivery()"  [footerTemplate]="footerTemplate"  (navigate)="monthChanged($event)"></ngb-datepicker>
</div>


<ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
  <ng-container>
    <div class="custom-day" [ngClass]="getDayClass(date,currentMonth)">
      <div class="day">{{ date.day }}</div>
    </div>
      <svg-icon [src]="plannedOrderIcon" *ngIf="isDeliveryDate(date) && (!isHoliday(date) && !isDifferentMonth(date, currentMonth))" class="d-flex justify-content-center mt-1" ></svg-icon>
      <svg-icon [src]="pickingIcon" *ngIf="isPickingDate(date) && (!isDifferentMonth(date, currentMonth))" class="d-flex justify-content-center mt-1" ></svg-icon>
      <svg-icon [src]="dueDateIcon" *ngIf="isDueDate(date) && (!isHoliday(date) && !isDifferentMonth(date, currentMonth))" class="d-flex justify-content-center mt-1" ></svg-icon>
  </ng-container>
  </ng-template>

  <ng-template #footerTemplate>
    <app-text [text]="'CALENDAR_FOOTER_HINT_TEXT' | translate" textType="GREY" [fontSizePx]="14" extraClass="px-3 py-2 text-center font-italic"></app-text>
</ng-template>
