import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditServiceService {

  editSubject?: Subject<any>;
  updateDataSubject?: Subject<any>;
  updatePrescriptionListSubject?: Subject<any>;
  modalSubject?: Subject<any>;
  updatedByPrescriptionDetails?: boolean = false;
  updateTasks?: boolean = false;
  invitationSubject?: Subject<any>;
  reminderSubject?: Subject<any>;

  constructor() {
    this.editSubject = new Subject<any>();
    this.updateDataSubject = new Subject<any>();
    this.modalSubject = new Subject<any>();
    this.updatePrescriptionListSubject = new Subject<any>();
    this.invitationSubject = new Subject<any>();
    this.reminderSubject = new Subject<any>();
  }

  // Patient Edit
  setEditConfig(values: any){
    this.editSubject?.next(values);
  }
  getEditConfig(){
    return this.editSubject?.asObservable();
  }

  setSnackBar(values: any){
    this.reminderSubject?.next(values);
  }
  showSnackBar(){
    return this.reminderSubject?.asObservable();
  }

  readyToUpdateData(){
    this.updateDataSubject?.next(true)
  }
  needsToUpdateData(){
    return this.updateDataSubject?.asObservable();
  }
  setUpdateTasks(flag: boolean){
    this.updateTasks = flag;
  }
  readyToUpdateTasks(){
    if(this.updateTasks){
      this.updateDataSubject?.next(true);
    }
  }
  needsToUpdateTasks(){
    return this.updateDataSubject?.asObservable();
  }
  setUpdatedByPrescriptionDetails(flag: boolean){
    this.updatedByPrescriptionDetails = flag;
  }

  // Prescription List
  readyToUpdatePrescriptionList(){
    if(this.updatedByPrescriptionDetails){
      this.updatePrescriptionListSubject?.next(true)
    }
  }
  needsToUpdatePrescriptionList(){
    return this.updatePrescriptionListSubject?.asObservable();
  }

  // Customizable Modal
  setModalConfig(data: any){
    this.modalSubject?.next(data);
  }
  getModalConfig(){
    return this.modalSubject?.asObservable();
  }


  // Invitation
  setInvitationModalData(data: any){
    this.invitationSubject?.next(data);
  }

  getInvitationModalData(){
    return this.invitationSubject?.asObservable();
  }

  isEdited: boolean = false;

   set showEditSnackBar(value: boolean) {
      this.isEdited = value;
   }

   get showEditSnackBar(): boolean {
       return this.isEdited;
   }

   isPasscodeTemporary: boolean = true;

   set showTemporaryPasswordTemplate(value: boolean) {
      this.isPasscodeTemporary = value;
   }

   get showTemporaryPasswordTemplate(): boolean {
       return this.isPasscodeTemporary;
   }
}
