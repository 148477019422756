import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClientService, CalendarSetRQ, ICalendarSetRQ, AuthenticationService, EntityRQ } from 'src/app/core';
import { BackOfficeCalendarViewCustom } from 'src/app/core/models/BackOfficeCalendarView-custom.model';
import { BaseWeekGetRSCustom } from 'src/app/core/models/baseWeekGetRS-custom.model';
import { DayOfWeek, WeekBeforeDelivery } from 'src/app/core/models/dayOfWeek';
import { DateService } from 'src/app/core/services/date.service';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { getDayNameFromDayNumber } from 'src/app/core/utils/calendar-utils';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.css']
})
export class CalendarEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  calendarDetails?: BackOfficeCalendarViewCustom;
  loading: boolean = false;
  isCloneMode: boolean = false;

  firstDeliveryDate?: Date | undefined;
  firstPickingDate?: Date | undefined;
  firstDueDate?: Date | undefined;

  isCalendarValid: boolean = false;

  selectedCountry!: any;

  countryList!: any | [];
  baseWeekList!: BaseWeekGetRSCustom[];

  frequencyList: DayOfWeek[] = [];

  daysList: DayOfWeek[] = [
    { key: 1, value: getDayNameFromDayNumber(1) },
    { key: 2, value: getDayNameFromDayNumber(2) },
    { key: 3, value: getDayNameFromDayNumber(3) },
    { key: 4, value: getDayNameFromDayNumber(4) },
    { key: 5, value: getDayNameFromDayNumber(5) }
  ];
  pickingDays: DayOfWeek[] = [
    { key: 1, value: getDayNameFromDayNumber(1) },
    { key: 2, value: getDayNameFromDayNumber(2) },
    { key: 3, value: getDayNameFromDayNumber(3) }
  ];

  pickingWeeks: WeekBeforeDelivery[] = [
    { key: 0, value: this.getTranslation('CALENDAR_SAME_WEEK') },
    { key: -1, value: this.getTranslation('CALENDAR_ONE_WEEK_BEFORE') }
  ];

  dueDateWeeks: WeekBeforeDelivery[] = [
    { key: -1, value: this.getTranslation('CALENDAR_ONE_WEEK_BEFORE') },
    { key: -2, value: this.getTranslation('CALENDAR_TWO_WEEK_BEFORE') },
  ];  

  validationError!: any;

  formCalendarAdd: FormGroup = this.fb.group({
    calendarName: [null, Validators.required],
    calendarDescription: [null, Validators.required],
    countryIsoCode : [null, Validators.required],

    baseWeek: [null, Validators.required],
    deliveryWeekDay: [null, Validators.required],
    orderFrequency: [null, Validators.required],

    pickingWeekDay: [null, Validators.required],
    pickingWeek: [null, Validators.required],

    dueDateWeekDay: [null, Validators.required],
    dueDateWeek: [null, Validators.required],    
  });

  formCalendarInfo={
    key: 'formCalendartInfo',
    input:
    [
      { name: this.getTranslation('CALENDAR_NAME'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'calendarName', value: this.formCalendarAdd.get("calendarName")?.value },
      { name: this.getTranslation('CALENDAR_DESCRIPTION'), optional: false, type: 'text', placeholder: '', class: 'input-form', inputName: 'calendarDescription', value: this.formCalendarAdd.get("calendarDescription")?.value },
    ],
    autocompleteCountryConfig: {
      name: this.getTranslation('LABEL_COUNTRY'),
      attr: 'timeZoneName' as const,
      array: this.countryList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'countryIsoCode',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('COMMON_SELECT_COUNTRY'),
      errorMessage: '',
    }, 
    //DELIVERY DATE   
    autocompleteBaseWeekConfig: {
      name: this.getTranslation('CALENDAR_BASE_WEEK'),
      attr: 'deliveryWeekLabel' as const,
      array: this.baseWeekList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'baseWeek',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('CALENDAR_SELECT_BASE_WEEK'),
      errorMessage: '',
    },    
    autocompleteWeekDayConfig: {
      name: this.getTranslation('CALENDAR_WEEK_DAY'),
      attr: 'value' as const,
      array: this.daysList,
      numberToShow: 5,
      prefix: false,
      value:  null as any,
      inputName : 'deliveryWeekDay',
      class: 'form-control autocomplete-input',
      placeholder: this.getTranslation('CALENDAR_SELECT_WEEK_DAY'),
      errorMessage: '',
    },
    autocompleteOrderFrequencyConfig: {
      name: this.getTranslation('CALENDAR_FREQUENCY'),
      attr: 'value' as const,
      array: this.frequencyList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'orderFrequency',
      class: 'form-control autocomplete-input',
      disabled: true,
      placeholder: this.getTranslation('CALENDAR_SELECT_FREQUENCY'),
      errorMessage: '',
    },
    //PICKING 
    autocompletePickingWeekDayConfig: {
      name: this.getTranslation('CALENDAR_WEEK_DAY'),
      attr: 'value' as const,
      array: this.pickingDays,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'pickingWeekDay',
      class: 'form-control autocomplete-input',
      placeholder: this.getTranslation('CALENDAR_SELECT_WEEK_DAY'),
      errorMessage: '',
    },
    autocompletePickingWeekConfig: {
      name: this.getTranslation('CALENDAR_WEEK'),
      attr: 'value' as const,
      array: this.pickingWeeks,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'pickingWeek',
      class: 'form-control autocomplete-input',
      placeholder: this.getTranslation('CALENDAR_SELECT_WEEK_BEFORE'),
      errorMessage: '',
    },
    //DUE DATE
    autocompleteDueDateWeekDayConfig: {
      name: this.getTranslation('CALENDAR_WEEK_DAY'),
      attr: 'value' as const,
      array: this.daysList,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'dueDateWeekDay',
      class: 'form-control autocomplete-input',
      placeholder: this.getTranslation('CALENDAR_SELECT_WEEK_DAY'),
      errorMessage: '',
    },
    autocompleteDueDateWeekConfig: {
      name: this.getTranslation('CALENDAR_WEEK'),
      attr: 'value' as const,
      array: this.dueDateWeeks,
      numberToShow: 6,
      prefix: false,
      value: null as any,
      inputName : 'dueDateWeek',
      class: 'form-control autocomplete-input',
      placeholder: this.getTranslation('CALENDAR_SELECT_WEEK_BEFORE'),
      errorMessage: '',
    }
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('CALENDAR_ADD_TITLE'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private dateService: DateService,
    private authService: AuthenticationService,
    private utilsService: UtilsService
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !(form.valid && this.isCalendarValid);
      });
    this.addBtnConfig.disabled = !(form.valid && this.isCalendarValid);
  }

  inputValues(event: any){

    this.formCalendarAdd.controls[event.target.name].setValue(event.target.value);

    this.calcDeliveryDate();
    this.calcPickingDate();
    this.calcDueDate();

    this.formValidation(this.formCalendarAdd);
  }

  ngAfterViewInit() {
    this.formCalendarAdd.get("calendarDescription")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {      
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("baseWeek")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {      
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("deliveryWeekDay")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("orderFrequency")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("pickingWeekDay")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("pickingWeek")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("dueDateWeekDay")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkCalendarValidity();
    });

    this.formCalendarAdd.get("dueDateWeek")?.valueChanges.pipe(debounceTime(800), distinctUntilChanged()).subscribe(x => {
      this.checkCalendarValidity();
    });
  }

  calcDeliveryDate(){

    var deliveryDateStart = new Date(this.formCalendarAdd.get('baseWeek')?.value?.deliveryDateStart);
    var deliveryWeekDay = this.formCalendarAdd.get('deliveryWeekDay')?.value?.key;

    if(deliveryDateStart != undefined && deliveryWeekDay != undefined)
    {
      var firstDate = new Date(deliveryDateStart);

      this.firstDeliveryDate = this.getDateFromDayOfWeek(firstDate, deliveryWeekDay);
    }
    else
    {
      this.firstDeliveryDate = undefined;
    }
  }

  calcPickingDate(){

    var deliveryDateStart = new Date(this.formCalendarAdd.get('baseWeek')?.value?.deliveryDateStart);
    var pickingWeek = this.formCalendarAdd.get('pickingWeek')?.value?.key;
    var pickingWeekDay = this.formCalendarAdd.get('pickingWeekDay')?.value?.key;      

    if (deliveryDateStart != undefined && pickingWeek != undefined && pickingWeekDay != undefined)
    {
      var firstDate = new Date(deliveryDateStart);
      if(pickingWeek == -1){
        firstDate.setDate(firstDate.getDate() -7);
      }

      this.firstPickingDate = this.getDateFromDayOfWeek(firstDate, pickingWeekDay);
    }
    else
    {
      this.firstPickingDate = undefined;
    }
  }

  calcDueDate(){

    var deliveryDateStart = new Date(this.formCalendarAdd.get('baseWeek')?.value?.deliveryDateStart);
    var dueDateWeek = this.formCalendarAdd.get('dueDateWeek')?.value?.key;
    var dueDateWeekDay = this.formCalendarAdd.get('dueDateWeekDay')?.value?.key;      

    if (deliveryDateStart != undefined && dueDateWeek != undefined && dueDateWeekDay != undefined)
    {
      var firstDate = new Date(deliveryDateStart);
      firstDate.setDate(firstDate.getDate() - (dueDateWeek * -7));

      this.firstDueDate = this.getDateFromDayOfWeek(firstDate, dueDateWeekDay);
    }
    else
    {
      this.firstDueDate = undefined;
    }
  }

  checkCalendarValidity(){

    if (this.id == undefined && this.isCloneMode == false && new Date() >= this.firstDueDate!){
      this.isCalendarValid = false;      
      this.formCalendarInfo.autocompleteDueDateWeekConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_IN_PAST')
      this.formCalendarInfo.autocompleteDueDateWeekDayConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_IN_PAST')
    }
    else if (this.firstDueDate! >= this.firstPickingDate!){
      this.isCalendarValid = false;
      this.formCalendarInfo.autocompleteDueDateWeekConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_NOT_COMPLY')
      this.formCalendarInfo.autocompleteDueDateWeekDayConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_DUE_DATE_NOT_COMPLY')
    }
    else if (this.firstPickingDate! >= this.firstDeliveryDate!){
      this.isCalendarValid = false;
      this.formCalendarInfo.autocompletePickingWeekConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_PICKING_DATE_NOT_COMPLY')
      this.formCalendarInfo.autocompletePickingWeekDayConfig.errorMessage = this.getTranslation('ERROR_CALENDAR_PICKING_DATE_NOT_COMPLY')
    }
    else{
      this.isCalendarValid = true;
      this.formCalendarInfo.autocompleteDueDateWeekConfig.errorMessage = '';
      this.formCalendarInfo.autocompleteDueDateWeekDayConfig.errorMessage = '';
      this.formCalendarInfo.autocompletePickingWeekConfig.errorMessage = '';
      this.formCalendarInfo.autocompletePickingWeekDayConfig.errorMessage = '';
      this.formCalendarInfo.autocompleteBaseWeekConfig.errorMessage = '';
      this.formCalendarInfo.autocompleteWeekDayConfig.errorMessage = '';
    }
    this.formValidation(this.formCalendarAdd);
  }

  StringFormat = (str: string, ...args: string[]) =>
        str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

  getDateFromDayOfWeek(firstDate: any, weekOfDay: any){
    for (var i = 0; i < 7; i++)
    {
      if(firstDate.getDay() == weekOfDay)
        break;
      else
        firstDate.setDate(firstDate.getDate() + 1);
    }

    return new Date(firstDate);
  }

  getAllCountry(): any{
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{

        response.forEach(element => {
          element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
          if(element.timeZoneName.startsWith("COUNTRY_")){
            element.timeZoneName = element.countryIsoCode;
          }
        });
        this.formCalendarInfo.autocompleteCountryConfig.array = response;
        this.formCalendarInfo.autocompleteCountryConfig.disabled = false;
      } ,
      error=> console.log(error)
    );
  }

  getAllBaseWeek(): any{
    this.clientService.baseWeekGetAll(this.id).subscribe(
      response =>{

        this.baseWeekList = (response.list || []).map(row => BaseWeekGetRSCustom.fromJS(row, (key)=> this.getTranslation(key)));

        this.formCalendarInfo.autocompleteBaseWeekConfig.array = this.baseWeekList;
        this.formCalendarInfo.autocompleteBaseWeekConfig.disabled = false;
      },
      error=> console.log(error)
    );
  }

  getFrequencyList(){
    var frequencyParam = this.utilsService.getParametersByKey("order_frequency_weeks")?.parameterValue!.split("|");

    this.frequencyList = frequencyParam.map((element: any) => {
      var frequencyItem: DayOfWeek = {
        key: element, 
        value: element + " " + this.getTranslation("LABEL_WEEKS")
      }
      return frequencyItem;
    });

    this.formCalendarInfo.autocompleteOrderFrequencyConfig.array = this.frequencyList;
    this.formCalendarInfo.autocompleteOrderFrequencyConfig.disabled = false;
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/calendars');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/calendars/detail', this.id]);
    }
  }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();

    this.getAllCountry();
    this.getAllBaseWeek();
    this.getFrequencyList();

    if(this.authService.currentUserValue.countryIsoCode != undefined){
      this.formCalendarAdd.controls["countryIsoCode"].setValidators([]);
    }

    await new Promise(f => setTimeout(f, 1000));  
    
    if(this.id != undefined){
      this.addBtnConfig.text = this.getTranslation('BTN_COMMON_SAVE'),
      this.getCalendarDetail();

      //CLONE MODE => id = undefined
      this.isCloneMode = this.route.snapshot.url[1].path === "clone";
      if(this.isCloneMode){        
        this.id = undefined;
      }
    }
  }

  getCalendarDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.calendarGet(data).subscribe(
      response =>{

        this.calendarDetails = BackOfficeCalendarViewCustom.fromJS(response, (key)=> this.getTranslation(key));

        this.formCalendarAdd.patchValue({
          calendarName: this.calendarDetails!.calendarName!,
          calendarDescription: this.calendarDetails!.calendarDescription,
          baseWeek: { week: this.calendarDetails.baseWeek, 
                      year: this.calendarDetails?.baseYear, 
                      deliveryDateStart: this.calendarDetails?.baseWeekStartDay,
                      deliveryDateEnd: this.calendarDetails?.baseWeekEndDay,
                      deliveryWeekLabel: this.calendarDetails?.deliveryWeekLabel },
          countryIsoCode: { countryIsoCode: response.countryIsoCode },
          
          deliveryWeekDay: { key: response.deliveryDateWeekDay },
          orderFrequency: { key: response.deliveryDateFrequency },

          pickingWeekDay: { key: response.pickingWeekDay },
          pickingWeek: { key: response.pickingWeek},

          dueDateWeekDay: { key: response.dueDateWeekDay },
          dueDateWeek: { key: response.dueDateWeek},
        });

        //COUNTRY
        let countryName: string = this.getTranslation("COUNTRY_" + response.countryIsoCode);
        if(countryName.startsWith("COUNTRY_")){
          countryName = response.countryIsoCode!;
        }
        this.formCalendarInfo!.autocompleteCountryConfig.value = { 
          countryIsoCode: this.calendarDetails.countryIsoCode,
          timeZoneName: countryName
        }


         //BASE WEEK
        this.formCalendarInfo!.autocompleteBaseWeekConfig.value = { 
          week: this.calendarDetails.baseWeek,
          year: this.calendarDetails.baseYear,
          deliveryWeekLabel: this.baseWeekList.filter(x => x.year == this.calendarDetails?.baseYear &&
                                                         x.week == this.calendarDetails?.baseWeek)[0]?.deliveryWeekLabel,
        }

        //DELIVERY WEEK DAY
        this.formCalendarInfo!.autocompleteWeekDayConfig.value = { 
          key: this.calendarDetails.deliveryDateWeekDay,
          value: this.daysList.filter(x => x.key == this.calendarDetails?.deliveryDateWeekDay)[0]?.value
        }

        //DELIVERY FREQUENCY
        this.formCalendarInfo!.autocompleteOrderFrequencyConfig.value = { 
          key: this.calendarDetails.deliveryDateFrequency,
          value: this.frequencyList.filter(x => x.key == this.calendarDetails?.deliveryDateFrequency)[0]?.value
        }

        //PICKING WEEK DAY
        this.formCalendarInfo!.autocompletePickingWeekDayConfig.value = { 
          key: this.calendarDetails.pickingWeekDay,
          value: this.pickingDays.filter(x => x.key == this.calendarDetails?.pickingWeekDay)[0]?.value
        }
        //PICKING WEEK
        this.formCalendarInfo!.autocompletePickingWeekConfig.value = { 
          key: this.calendarDetails.pickingWeek,
          value: this.pickingWeeks.filter(x => x.key == this.calendarDetails?.pickingWeek)[0]?.value
        }

        //DUE WEEK DAY
        this.formCalendarInfo!.autocompleteDueDateWeekDayConfig.value = { 
          key: this.calendarDetails.dueDateWeekDay,
          value: this.daysList.filter(x => x.key == this.calendarDetails?.dueDateWeekDay)[0]?.value
        }
        //PICKING WEEK
        this.formCalendarInfo!.autocompleteDueDateWeekConfig.value = { 
          key: this.calendarDetails.dueDateWeek,
          value: this.dueDateWeeks.filter(x => x.key == this.calendarDetails?.dueDateWeek)[0]?.value
        }
        
        this.formCalendarInfo!.input[0].value = this.formCalendarAdd.get("calendarName")?.value;
        this.formCalendarInfo!.input[1].value = this.formCalendarAdd.get("calendarDescription")?.value;

        this.formCalendarInfo!.autocompleteCountryConfig = {...this.formCalendarInfo!.autocompleteCountryConfig}

        this.formCalendarInfo!.autocompleteBaseWeekConfig = {...this.formCalendarInfo!.autocompleteBaseWeekConfig}

        this.formCalendarInfo!.autocompleteOrderFrequencyConfig = {...this.formCalendarInfo!.autocompleteOrderFrequencyConfig}
        this.formCalendarInfo!.autocompleteWeekDayConfig = {...this.formCalendarInfo!.autocompleteWeekDayConfig}

        this.formCalendarInfo!.autocompletePickingWeekDayConfig = {...this.formCalendarInfo!.autocompletePickingWeekDayConfig}
        this.formCalendarInfo!.autocompletePickingWeekConfig = {...this.formCalendarInfo!.autocompletePickingWeekConfig}

        this.formCalendarInfo!.autocompleteDueDateWeekDayConfig = {...this.formCalendarInfo!.autocompleteDueDateWeekDayConfig}
        this.formCalendarInfo!.autocompleteDueDateWeekConfig = {...this.formCalendarInfo!.autocompleteDueDateWeekConfig}

        this.calcDeliveryDate();
        this.calcPickingDate();
        this.calcDueDate();
      
        this.formValidation(this.formCalendarAdd);

        this.loading = false;
      },
      error=> console.log(error)
    );
  }

  saveCalendar(){
    if (this.formCalendarAdd.valid) {

      this.selectedCountry = this.formCalendarAdd.get('countryIsoCode')?.value?.countryIsoCode ?? this.authService.currentUserValue.countryIsoCode;
      
      this.loading = true;
      const calendar = new CalendarSetRQ({
        idCalendar: this.id,
        countryIsoCode: this.selectedCountry,
        calendarDescription:  this.formCalendarAdd.get('calendarDescription')?.value,

        baseYear: this.formCalendarAdd.get('baseWeek')?.value.year,
        baseWeek: this.formCalendarAdd.get('baseWeek')?.value.week,
        deliveryDateWeekDay: this.formCalendarAdd.get('deliveryWeekDay')?.value.key,
        deliveryDateFrequency: this.formCalendarAdd.get('orderFrequency')?.value.key,

        pickingWeekDay: this.formCalendarAdd.get('pickingWeekDay')?.value.key,
        pickingWeek: this.formCalendarAdd.get('pickingWeek')?.value.key,

        dueDateWeekDay: this.formCalendarAdd.get('dueDateWeekDay')?.value?.key,
        dueDateWeek: this.formCalendarAdd.get('dueDateWeek')?.value.key,

        isCloneMode: this.isCloneMode,
        calendarName: this.formCalendarAdd.get('calendarName')?.value,
      })

      this.clientService.calendarSet(calendar).subscribe(
        response =>{

          this.loading = false;

          if(response.success){

            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'CALENDAR_ADD_SUCCESSFULLY',
                description: this.formCalendarInfo.autocompleteWeekDayConfig.value?.value + " - " +
                  this.formCalendarInfo.autocompleteOrderFrequencyConfig.value?.value,
                actionToDo: () => 
                {
                  this.router.navigate(['/calendars/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){

              if(response?.errorType == "ERROR_CALENDAR_DUE_DATE_IN_PAST" || response?.errorType == "ERROR_CALENDAR_DUE_DATE_NOT_COMPLY")
              {
                this.formCalendarInfo.autocompleteDueDateWeekConfig.errorMessage = this.getTranslation(response?.errorType);
                this.formCalendarInfo.autocompleteDueDateWeekDayConfig.errorMessage = this.getTranslation(response?.errorType);
              }
              else if (response?.errorType == "ERROR_CALENDAR_PICKING_DATE_NOT_COMPLY"){
                this.formCalendarInfo.autocompletePickingWeekConfig.errorMessage = this.getTranslation(response?.errorType);
                this.formCalendarInfo.autocompletePickingWeekDayConfig.errorMessage = this.getTranslation(response?.errorType);
              }
              else if (response?.errorType == "ERROR_CALENDAR_ALREADY_EXISTS"){
                this.showCalendarAlreadyExistsModal();
              }
            }
          }
        },
        error=> {
          this.loading = false;
          this.validationError = this.getTranslation("ERROR_GENERIC");
        }
      )
    }
  }

  showCalendarAlreadyExistsModal(){
    this.modalService.setModalData({ 
      title: 'ERROR_CALENDAR_ALREADY_EXISTS_MODAL_TITLE',
      description: 'ERROR_CALENDAR_ALREADY_EXISTS_MODAL_DESC',
      commentVisible: false,
      actionBtnConfig: {
        bclass: 'btn btn-primary add-patient-button',
        text: this.getTranslation('ERROR_CALENDAR_ALREADY_EXISTS_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        this.backToList(false);
      }
    });     
    this.ngxModal.getModal('requestModal').open();
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }
}
