import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.css']
})
export class RowComponent implements OnInit {

  constructor() { }

  @Input() rowClass?: string;
  @Input() colSize? : number;
  @Input() colClass?: string;

  @Input() colMDSize? : number
  @Input() colSMSize? : number

  @Input() rowType?: string = "BOTH"

  ngOnInit(): void {}


  getColumnStyle(){
    let defaultSize = this.colSize ? 'col-' + this.colSize : 'col-12';
    let mdSize = this.colMDSize ? 'col-md-' + this.colMDSize : this.colSize ? 'col-md-' + this.colSize : 'col-md-12';
    let smSize = this.colSMSize ? 'col-sm-' + this.colSMSize : this.colSize ? 'col-sm-' + this.colSize : 'col-sm-12';
    let extraClass = this.colClass ? this.colClass : ''
    
    return defaultSize + ' ' + mdSize + ' ' + smSize + ' ' + extraClass;
  }

}
