import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-secondary',
  templateUrl: './table-secondary.component.html',
  styleUrls: ['./table-secondary.component.css']
})
export class TableSecondaryComponent implements OnInit {

  @Input() tableConfig: any;
  @Output() onClick = new EventEmitter<any>();
  selectedOption? : string;
  searchText? : string;

  alertConfig = {
    aclass: 'alert alert-dark m-4',
    msg: this.getTranslation('LOADING_LABEL')
  }

  constructor(
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.selectedOption ='id';
  }

  ngOnChanges(changes: SimpleChanges){
    if(this.tableConfig.orders && this.tableConfig.gridData){
      this.tableConfig.gridData = this.tableConfig.gridData.filter((elem: any) => this.isDueOrder(elem)==true);
    }
      /*this.tableConfig.gridData.forEach((element: { birthDate: any; }) => {
        element.birthDate = null;
      });*/

    //this.tableConfig.gridData[1].reviewOrderIdOrder = true;
    //console.log(this.tableConfig)
  }

  handlePageChange(event: any) {
    this.tableConfig.pagination.currentPage = event;
  }
  onClickButton(record: any, action: any, event: any){
    if(event?.type != 'click' || action != 'info'){
      this.onClick.emit({record,action});
    }  }

  isDate(value:string){
    return (Date.parse(value) || Date.parse(value)==0);
  }

  updatePage() {
    this.tableConfig.pagination.currentPage = 1;
  }

  checkValidityDate(date: Date){
    let today = new Date();
    today.setDate(today.getDate() + 31);
    return today <= date;
  }
  expired(date: Date){
    return new Date() > date;
  }
  dueColor(record: any){
    if(record && record.dateDue && this.tableConfig.orders){
      if(!record.reviewOrderIdOrder){
        let today = new Date();
        let orangeDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+6);
        let redDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+4);
        if(record.dateDue<=orangeDate && record.dateDue>redDate){
          return 'orange';
        }
        if(record.dateDue<=redDate){
          return 'red';
        } else return 'green';
      } else {
        return 'purple'
      }
    }
    return '';
  }
  getDueDateWarning(record: any){
    let difference;
    let today = new Date();
    let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
    let afterMonth = new Date(today.getFullYear(), today.getMonth()+1, today.getDate());
    let afterWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
    if(record.dateDue>=today && record.dateDue<afterMonth){
      if(record.dateDue<=afterWeek){
        if(record.dateDue<=tomorrow){
          difference = Math.ceil((record.dateDue.getTime() - today.getTime()) / (60 * 60 * 1000));
          return this.getTranslation('IN_TIME_LABEL') +' '+difference+' '+this.pluralDate(this.getTranslation('SINGLE_HOUR_LABEL'),this.getTranslation('MULTIPLE_HOURS_LABEL'),difference)
        } else {
          difference = Math.ceil((record.dateDue.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));
          return this.getTranslation('IN_TIME_LABEL') +' '+difference+' '+this.pluralDate(this.getTranslation('SINGLE_DAY_LABEL'),this.getTranslation('MULTIPLE_DAY_LABEL'),difference)
        }
      } else {
        difference = Math.ceil((record.dateDue.getTime() - today.getTime()) / (7 * 24 * 60 * 60 * 1000));
        return this.getTranslation('IN_TIME_LABEL') +' '+difference+' '+this.pluralDate(this.getTranslation('SINGLE_WEEK_LABEL'),this.getTranslation('MULTIPLE_WEEK_LABEL'),difference)
      }
    }
    return '';
  }
  getPatientDueDateWarning(record: any){
    let difference;
    let today = new Date();
    let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
    let afterMonth = new Date(today.getFullYear(), today.getMonth()+1, today.getDate());
    let afterWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
    if(record.dateDue>=today && record.dateDue<afterMonth){
      if(record.dateDue<=afterWeek){
        if(record.dateDue<=tomorrow){
          difference = Math.round((record.dateDue.getTime() - today.getTime()) / (60 * 60 * 1000));
          return difference+' '+this.pluralDate(this.getTranslation('SINGLE_HOUR_LABEL'),this.getTranslation('MULTIPLE_HOURS_LABEL'),difference)+' '+this.getTranslation('CHECK_PATIENT_ORDER_LABEL');
        } else {
          difference = Math.round((record.dateDue.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));
          return difference+' '+this.pluralDate(this.getTranslation('SINGLE_DAY_LABEL'),this.getTranslation('MULTIPLE_DAY_LABEL'),difference)+' '+this.getTranslation('CHECK_PATIENT_ORDER_LABEL');
        }
      } else {
        difference = Math.round((record.dateDue.getTime() - today.getTime()) / (7 * 24 * 60 * 60 * 1000));
        return difference+' '+this.pluralDate(this.getTranslation('SINGLE_WEEK_LABEL'),this.getTranslation('MULTIPLE_WEEK_LABEL'),difference)+' '+this.getTranslation('CHECK_PATIENT_ORDER_LABEL');
      }
    }
    return '';
  }
  pluralDate(singular: string, plural: string, difference: any){
    return difference>1 ? plural : singular;
  }
  isDueOrder(record: any){
    let flag;
    if(record){
      if(record.dateDue){
        flag = record.dateDue > new Date();
      } else if(record.dateDue==undefined){
        flag = false;
      } else flag = true;
    }
    return flag;
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}
