import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminGetParameterByCountryRQ, AdminGetParameterByCountryRS, CheckEmailAddressIsValidRQ, CheckMobileNumberIsValidRQ, ClientService,   IAdminGetParameterByCountryRQ,   ICheckEmailAddressIsValidRQ,   ICheckMobileNumberIsValidRQ,   ITranslationGetRQ, LanguageGetAllRS, ParameterGetRS, ReleaseNoteGetRQ, Translation, TranslationGetRQ } from './client.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import diff from 'simple-text-diff'

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  getNurseAppFromLangCode(lang: string) {
    throw new Error('Method not implemented.');
  }
  
  allTranslationsLoadedSubject: Subject<boolean>;
  allLanguages: LanguageGetAllRS[] = [];

  constructor(
    private clientService: ClientService,
    private cookieService: CookieService,
    private ngxModal: NgxSmartModalService
    ) { 
      this.allTranslationsLoadedSubject = new Subject<boolean>();
    }

  getAllAvailableLanguages(): Observable<any>{
    return this.clientService.getAllLanguages().pipe(
      map(
        (languages: LanguageGetAllRS[]) => {
          this.allLanguages = languages;
          let result: string[] = [];
          languages.forEach(element => {
            result.push(element?.isoCode?.substring(0,2)!)
          });
          return result;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }

  getParametersAppBeforeAuth(){
    return JSON.parse(window.sessionStorage.getItem('parametersBeforeAuth') || '');
  }

  getParametersAppAfterAuth(){
    return JSON.parse(window.sessionStorage.getItem('parametersAfterAuth') || '') as ParameterGetRS[];
  }

  getAllTranslationsByLangCode(lang: string): Observable<any>{
    let language: ITranslationGetRQ;
    language = {
      langCode: lang
    }

    return this.clientService.getBackOfficeFromLangCOde(new TranslationGetRQ(language)).pipe(
      map(
        (translations: Translation[]) => {
          let result: any = '';
          let i = 0;
          for (let index = 0; index < translations.length; index++) {
            const element = translations[index];
            let key: string = element.resourceKey!;
            let value: string = element.resourceValue!;
            result = result + ' "' + key + '"' + ' : ' + '"' + value.replace('"', '\"') + '",';
          }
          result = result.slice(0, -1);
          result = '{ ' + result + ' }';
          return result;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }

  getParametersByKey(key: string){
    let parameters = this.getParametersAppAfterAuth();
    let result = new ParameterGetRS();
    parameters.forEach(element  => {
      if(element && element.parameterKey === key){
        result = element;
      }
    });
    return result;
  }

  getBeforeParametersByKey(key: string): any{
    let parameters = this.getParametersAppBeforeAuth();
    let result;
    parameters.forEach((element: any)  => {
      if(element && element.parameterKey === key){
        result = element;
      }
    });
    return result;
  }

  getParameterByCountry(parameterKey: string, countryIsoCode: string): Observable<string>{
    
    let input: IAdminGetParameterByCountryRQ = {
      parameterKey: parameterKey,
      countryIsoCode: countryIsoCode,
    }
      
    return this.clientService.adminGetParameterByCountry(new AdminGetParameterByCountryRQ(input)).pipe(
      map(
        (x: AdminGetParameterByCountryRS) => {
          return x.parameterValue!;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }


  checkMobileNumberIsValid(idUserIdentity: any, mobileNumberCountryPrefixCode: any, mobileNumberCountryPrefix: any, mobileNumber: string,  role: string): Observable<boolean>{
    
    let input: ICheckMobileNumberIsValidRQ = {
      idUserIdentity: idUserIdentity,
      mobileNumberCountryPrefixCode: mobileNumberCountryPrefixCode, 
      mobileNumberCountryPrefix: mobileNumberCountryPrefix,
      mobileNumber: mobileNumber,
      role: role
    }
      
    return this.clientService.checkMobileNumberIsValid(new CheckMobileNumberIsValidRQ(input)).pipe(
      map(
        (x: boolean) => {
          return x;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }


  checkEmailAddressIsValid(idUserIdentity: any, emailAddress: string, role: string): Observable<boolean>{
    
    let input: ICheckEmailAddressIsValidRQ = {
      idUserIdentity: idUserIdentity,
      emailAddress: emailAddress,
      role: role
    }
      
    return this.clientService.checkEmailAddressIsValid(new CheckEmailAddressIsValidRQ(input)).pipe(
      map(
        (x: boolean) => {
          return x;
        },
        (error: any)=>{
          console.log(error);
          return error;
        }
      )
    )
  }

  addValidationError(validationErrors:string[], msg:string) {
    const index: number = validationErrors.indexOf(msg);
    if (index === -1) {
        validationErrors.unshift(msg);
    }
  }

  removeValidationError(validationErrors:string[], msg:string) {
    const index: number = validationErrors.indexOf(msg);
    if (index !== -1) {
      validationErrors.splice(index, 1);
    }
  }


  checkReleaseNote(){
    let newDate = sessionStorage.getItem('releaseNotesDate') ? new Date(sessionStorage.getItem('releaseNotesDate')!) : null;
    if(newDate !== null){
      let langCode = this.cookieService.get('defaultLang');
      this.clientService.getReleaseNotes(new ReleaseNoteGetRQ({dateTimeLastLogin: newDate, cultureIsoCode: langCode})).subscribe(
        res=>{
          if(res.releaseNote !== ''){
            this.ngxModal.getModal('releaseNotesModal').setData(res.releaseNote).open();
          }
          sessionStorage.removeItem('releaseNotesDate');
        },
        err=>console.log(err)
      )
    }
  }

  setAllTranslationsLoadedSubject(value : boolean){
    this.allTranslationsLoadedSubject.next(value);
  }

  getAllTranslationsLoadedSubject(){
    return this.allTranslationsLoadedSubject.asObservable();
  }

  getDefaultLanguage(){
    return window.localStorage.getItem('defaultLang') || null;
  }

  setDefaultLanguage(lang: string){
    window.localStorage.setItem('defaultLang', lang)
  }

  buildHtmlTable(myList:any[], skipColumns:any[]) {
    let columns:string[];
    columns=[];
    let res = '<table class="table"><thead>';
    let headerTr = '';

    for (var i = 0; i < myList.length; i++) {
      var rowHash = myList[i];
      for (var key in rowHash) {
        if (!columns.some(x => x==key)) {          
          columns.push(key);
          headerTr += "<th>" + key + "</th>";
        }
      }
    }
    res += '<tr class="table-header">' + headerTr + "</tr></thead><tbody>";
    for (var i = 0; i < myList.length; i++) {
      let row = "";
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        var cellValue = myList[i][columns[colIndex]];

        var previousCellValue = cellValue;

        if(i > 0){
          previousCellValue = myList[i-1][columns[colIndex]];
        }
        
        if (cellValue == null) cellValue = "";

        var nclass = '';
        if(cellValue != "" && previousCellValue != cellValue && !skipColumns.includes(columns[colIndex])){
          nclass = 'td_yellow';
          cellValue = diff.diffPatch(previousCellValue, cellValue).after;
        }

        row += '<td nowrap class="' + nclass + '">' + cellValue + "</td>";
      }
      res += "<tr>" + row + "</tr>";
    }
    res += "</tbody></table>";
    return res;
  }

  getHiddenMail(mail: string) { return mail.slice(0, 3) + '••••' + mail.slice(mail.indexOf('@')) }
  
  getHiddenPhoneNumber(phone: string | undefined) {
    if(!phone) return "";
  
    return phone.slice(0, 2) + phone.slice(2).replace(/.(?=..)/g, '*');
  }

  base64toBlob(base64Data: any) {
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays);
  }

  clearFilterStorage(){
    window.localStorage.setItem("searchApplied", '');
    window.localStorage.setItem("searchApplied2", '');
    window.localStorage.setItem("filterApplied", '');
    window.localStorage.setItem("sortOrder", '');
    window.localStorage.setItem('currentPage', '');
    window.localStorage.setItem('requestStatus', '[]');
    window.localStorage.setItem('requestAppTypes', '[]');
  }
}
