import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, ClientService, EntityRQ, NephrologistRS } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Role } from 'src/app/core/enum/roles';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nephrologist-detail',
  templateUrl: './nephrologist-detail.component.html',
  styleUrls: ['./nephrologist-detail.component.css']
})
export class NephrologistDetailComponent implements OnInit {
  id?: string;
  nephrologistDetail?: NephrologistRS;
  showSnackBar?: boolean;
  snackText?: string;

  loading: boolean = true;

  isRegistrationNumberIssuerManaged: boolean = false;
  isQualificationManaged: boolean = false;
  
  isClinicDeactivated = false;

  btnConfig = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('NEPHROLOGIST_DETAIL_SENDINVITE_BTN_DESC'),
    width: '30px',
    height: '30px',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString();  
    this.getNephrologistDetail();

    this.isRegistrationNumberIssuerManaged = this.utilsService.getParametersByKey("flow_trigger__is_nephrologist_registration_number_issuer_managed").parameterValue == 'True';
    this.isQualificationManaged = this.utilsService.getParametersByKey("flow_trigger__is_nephrologist_qualification_managed").parameterValue == 'True';

    if(this.editService.showEditSnackBar){
      this.snackText = this.getTranslation("SAVED_CHANGES");
      this.ShowSnackBar();
    }
  }

  backToList(){   
    this.router.navigate(['/nephrologists']);
  }

  EditNephrologist() {
    this.router.navigate(['/nephrologists/edit', this.id]);
  }

  getNephrologistDetail(){
    this.loading = true;
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.nephrologistGet(data).subscribe(
      response =>{
        this.nephrologistDetail = response;

        this.isClinicDeactivated = response.accountStatus === 'CLINIC_DEACTIVATED';
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  sendPolicy(){
    this.loading = true;
    
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.nephrologistChangeStatus(data).subscribe(
      response =>{

        this.loading = false;

        this.getNephrologistDetail();

        this.snackText = this.getTranslation("NEPHROLOGIST_DETAIL_POLICY_SENT");

        this.ShowSnackBar();
      } ,
      error=> console.log(error)
    );
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
      window.location.reload();
    }, 5000);
  }

  DeactivateNephrologist(){

    this.modalService.setModalData({ 
      title: (this.nephrologistDetail?.dateDeactivated) ? "NEPHROLOGIST_DETAIL_REACTIVATE_MODAL_TITLE" : "NEPHROLOGIST_DETAIL_DEACTIVATE_MODAL_TITLE",
      description: (this.nephrologistDetail?.dateDeactivated) ? "NEPHROLOGIST_DETAIL_REACTIVATE_MODAL_DESC" : "NEPHROLOGIST_DETAIL_DEACTIVATE_MODAL_DESC",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.nephrologistDetail?.dateDeactivated ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.nephrologistDetail?.dateDeactivated ? 
          this.getTranslation('NEPHROLOGIST_DETAIL_REACTIVATE_MODAL_BTN') : this.getTranslation('NEPHROLOGIST_DETAIL_DEACTIVATE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        
        if(this.nephrologistDetail?.dateDeactivated){
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.nephrologistReactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
        else{
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.nephrologistDeactivate(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
      }
     })
     
    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.nephrologistDetail?.dateDeactivated) ? "NEPHROLOGIST_DETAIL_REACTIVATE_MODAL_SUCCESS" : "NEPHROLOGIST_DETAIL_DEACTIVATE_MODAL_SUCCESS",
      actionToDo: () => {
        this.getNephrologistDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }
}
