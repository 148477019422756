import { Location } from '@angular/common';
import { AfterContentChecked, Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ITab } from 'src/app/core/models/tab.model';
import { TabService } from 'src/app/core/services/tab.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnInit,AfterContentChecked {


  constructor(
    private tabService : TabService,
    private router: Router
  ) { }

  ngAfterContentChecked(): void {
    this.mainContentWidth = document.getElementById('mainContent')?.offsetWidth;
  }

  trunc: boolean = false;

  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    this.mainContentWidth = document.getElementById('mainContent')?.offsetWidth;
    if(((document.getElementById('mainContent')!.offsetWidth) < 1000)){
      this.trunc = true
    }else{
      this.trunc = false
    }
  }

  trigger() {
    window.dispatchEvent(new Event('resize'));
  }

  tabs: any[] = [];
  counter = this.tabs.length + 1;
  active?: number = 0
  mainContentWidth: any;
  tabDisplay: any;

  ngOnInit(): void {
    this.tabService.getStyles()?.subscribe(
      response=> this.tabDisplay = response.display
    )
    this.tabs = this.tabService.tabs
    this.tabService.countObs?.subscribe(
      count=> {
        this.active = count;
        if((this.tabs.length> this.tabService.maxTabNumber!/2) || ((document.getElementById('mainContent')!.offsetWidth) < 1000)){
          this.trunc = true;
        }else{
          this.trunc = false;
        }
      }
    )
  }

  goToTab(tab: any){
    this.tabService.setActiveTabId(tab.idPatient, true);
    this.tabService.showTabs();
  }


  closeTab(index: number, event: Event) {
    this.tabService.deleteTab(index);
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  truncate(name: string){
      return name.length >= 9 ? name.split(" ")[0].substring(0,5) + '...' : name;
  }

  startingTrunc(name: string){
    return name.length >= 18 ? name.split(" ")[0].substring(0,12) + '...' : name;
  }

}
