<div class="filter-box" #filter [ngClass]="extraClass || ''">
    <div class="filter-icon" *ngIf="filters && filters.length > 0 && isAtLeastOneFilterPresent()">
        <svg-icon [src]="'assets/icons/filter-icon.svg'" [class.filled]="isOneFilterSelected()" class="d-flex"></svg-icon>
    </div>
    <ng-container *ngFor="let filter of filters">
        <div class="filter">
            <div class="text" (click)="openDropdown(filter.filterName)">
                <app-text [textType]="getNumberFilterApplied(filter.filterName) !== '' ? 'BLUE' : 'PRIMARY'" [text]="(filter.filterName | translate) + (getNumberFilterApplied(filter.filterName))" 
                    [fontSizePx]="12" [isBold]="true"  [noWrap]="true" [avoidTextSelection]="true" 
                >
                </app-text>
                <div class="arrow" [class.arrow-open]="openedDropdown === filter.filterName">
                    <svg-icon [src]="'../../../assets/icons/down-icon.svg'" class="d-flex"></svg-icon>
                </div>
            </div>
            <div class="dropdown" [class.opened]="openedDropdown === filter.filterName">
                <div class="items">
                    <div class="drop-item" *ngFor="let item of filter.values" (click)="onFilterSelect(filter.filterName, item.key)">
                        <div class="checkbox" [class.filled]="item.selected"><svg-icon [src]="'../../../../../assets/icons/check-white-icon.svg'" *ngIf="item.selected"></svg-icon></div>
                        <app-text [text]="item.label" [fontSizePx]="11" [extraStyle]="{ 'overflow': 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap' }"></app-text>
                    </div>
                </div>
                <div class="reset-filter">
                    <app-round-button [type]="'CLOSE'" [text]="'CLEAR_ALL_BUTTON_LABEL' | translate" [extraClass]="{'background-color': 'transparent'}" (onClick)="resetFilterSection(filter.filterName)"></app-round-button>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="reset-close" *ngIf="isOneFilterSelected()">
        <app-round-button [type]="'CLOSE'" [extraClass]="{'background-color' : 'transparent', 'border' : 'none'}" [text]="'CLEAR_ALL_BUTTON_LABEL' | translate" (onClick)="resetAllFilters()"></app-round-button>
    </div>
</div>