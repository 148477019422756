import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BackOfficeReleaseNoteView, BaseGetAllRQ, ClientService, Country, IBaseGetAllRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';

@Component({
  selector: 'app-release-note-list',
  templateUrl: './release_note-list.component.html',
  styleUrls: ['./release_note-list.component.css']
})
export class ReleaseNoteListComponent implements OnInit {

  releaseNoteList! : BackOfficeReleaseNoteView[];
  loading: boolean = true;
  record?: any;
  totalItemsReleaseNote: number = 0;
  anyResult: boolean = false;

  btnConfig = {
    bclass : 'btn btn-primary float-right add-list-button',
    icon : 'plus',
    text : this.getTranslation('RELEASE_NOTE_ADD_TITLE'),
    width: '30px',
    height: '30px',
    type : 'add',
    dataToggle : 'modal',
    dataTarget : 'modalForm'
  }

  tableConfig =  {
    actions: true,
    gridData: this.releaseNoteList,
    headerList: [
      {name: this.getTranslation('RELEASE_NOTE_TYPE'), field: 'releaseNoteType', sortable: true},
      {name: this.getTranslation('RELEASE_NOTE_VERSION'), field: 'version', sortable: true},
      {name: this.getTranslation('LABEL_COUNTRY'), field: 'countries', sortable: true,},
      {name: this.getTranslation('RELEASE_NOTE_FEATURE_INTRODUCED'), field: 'featuresIntroduced', sortable: true},
      {name: this.getTranslation('LABEL_DATE_RELEASED'), field: 'dateReleased', sortable: true, isDate: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsReleaseNote
    },
    sort:{
      field: "version",
      direction: "asc"
    },
    notes: false
  };

  constructor(
    private clientService: ClientService,
    private router : Router,
    private translate: TranslateService,
    private editService: EditServiceService
  ) { }


  ngOnInit(): void {
    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getReleaseNoteList();
  }

  orderDirection?: string = "releaseNoteType_asc";
  currentPage: number = 1;
 
  getReleaseNoteList() {
    let input: IBaseGetAllRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: undefined,
      countryIsoCode: undefined,
    }
    this.clientService.releaseNoteGetAll(new BaseGetAllRQ(input)).subscribe(
      response =>{
        this.anyResult = response.anyResult || false;
        this.releaseNoteList = response.list || [];
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.releaseNoteList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  orderReleaseNoteChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getReleaseNoteList();
  }

  pageReleaseNoteChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getReleaseNoteList();
  }

  getUpdatedReleaseNoteList(): any{
    return this.releaseNoteList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToReleaseNoteDetail(event: any) {
    if(event.action == 'edit'){
      this.editService.showEditSnackBar = false;
      this.router.navigate(['/release-notes/detail', event.record.idReleaseNote]);
    }
  }

  releaseNoteAdd() {
    this.router.navigate(['/release-notes/add']);
  }

  bindButtons(releaseNoteList: BackOfficeReleaseNoteView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let releaseNote of releaseNoteList){

      this.tableConfig.recordButtonConfigs.set(releaseNote,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }
}