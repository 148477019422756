<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid" *ngIf="nurseDetail">

        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true">
                <svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon>
            </span>
        </button>

        <div class="row">
            <div [class.edit-disabled]="isClinicDeactivated" *ngIf="!nurseDetail.dateDeactivated && !isDemoNurse" class="col-12 text-right">
                <a (click)="EditNurse()" class="text-right"><i class="fas fa-pencil-alt"></i> <u class="edit_link">{{
                        'COMMON_EDIT_DATA' |
                        translate }}</u></a>
            </div>
        </div>
        <div class="trialTag" *ngIf="nurseDetail.idRealClinic">
            <a>{{ 'COMMON_TRIAL_MODE' | translate }}</a>
        </div>
        
        <div class="demoTag" *ngIf="isDemoNurse">
            <a>{{ 'COMMON_DEMO_MODE' | translate }}</a>
        </div>

        <h2 class="mt-4">{{ nurseDetail.name + " " + nurseDetail.surname}}</h2>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_CLINIC' | translate }}:</b>
            </div>
            <b *ngIf="nurseDetail.idRealClinic" class="col-6 col-lg-9">
                {{ nurseDetail.clinicName }}
            </b>
            <div *ngIf="!nurseDetail.idRealClinic" class="col-6 col-lg-9">
                <div *ngFor="let clinic of nurseDetail.nursesClinics">
                    <p [ngStyle]="{ 'font-weight': clinic.isClinicMain ? 'bold' : 'normal' } ">{{clinic.clinicName}}</p>
                </div>
            </div>
        </div>

        <hr class="my-4">

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_NAME' | translate }}:</b>
            </div>
            <div class="col-9 col-md-6">
                {{nurseDetail.name}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_SURNAME' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nurseDetail.surname}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ID' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nurseDetail.idNurse}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'PATIENT_GENDER' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ (nurseDetail.gender != null ? 'PATIENT_GENDER_' + nurseDetail.gender : '') | translate }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_WORKMAIL' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nurseDetail.emailAddress}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_MOBILE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{ nurseDetail.mobileNumberCountryPrefix != null ? nurseDetail.mobileNumberCountryPrefix + " " + nurseDetail.mobileNumber : '' }}
            </div>
        </div>

        <hr class="my-4">


        <div class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'NURSE_DETAIL_CREATIONDATE' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nurseDetail.dateCreated | DateFormatPipe: true}}
            </div>
        </div>

        <div *ngIf="nurseDetail.dateOnBoardingEnd" class="row mt-4">
            <div class="col-6 col-lg-3">
                <b>{{ 'LABEL_ONBOARDINGEND' | translate }}:</b>
            </div>
            <div class="col-6 col-lg-9">
                {{nurseDetail.dateOnBoardingEnd | DateFormatPipe: true}}
            </div>
        </div>

        <br />


        <div *ngIf="nurseDetail.dateDeactivated">
            <div class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{ 'NURSE_DETAIL_DATEDEACTIVATION' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{nurseDetail.dateDeactivated | DateFormatPipe: true}}
                </div>
            </div>
            <div *ngIf="nurseDetail.supportUserDeactivation != null" class="row mt-4">
                <div class="col-6 col-lg-3">
                    <b>{{ 'NURSE_DETAIL_AGENT' | translate }}:</b>
                </div>
                <div class="col-6 col-lg-9">
                    {{nurseDetail.supportUserDeactivation}}
                </div>
            </div>

            <br />
            <i>{{ 'NURSE_REGISTER_AGAIN' | translate}}</i>
        </div>

        <br />
        <hr />

        <table class="table mt-5">

            <h5 class="col-8">{{ 'NURSE_DETAIL_ORDERMANAGER' | translate }}</h5>
            <tr>
                <th scope="col">{{ 'NURSE_DETAIL_ACCOUNT_DATECREATED' | translate }}</th>
                <th scope="col">{{ 'NURSE_DETAIL_ACCOUNT_STATUS' | translate }}</th>
                <th *appHasRole="['SUPPORT_USER']" scope="col">{{ 'NURSE_DETAIL_ACCOUNT_ACCESS' | translate }}</th>
            </tr>
            <tr>
                <td scope="col">{{nurseDetail.accountDateCreated | DateFormatPipe: true}}</td>
                <td scope="col">{{ 'ACCOUNT_STATUS_' + nurseDetail.accountStatus | translate }}</td>
                <td *appHasRole="['SUPPORT_USER']" scope="col">{{ 'ACCOUNT_ACCESS_' + nurseDetail.accountAccess | translate }}</td>
            </tr>
        </table>

        <div *ngIf="!nurseDetail.dateDeactivated" [class.edit-disabled]="isClinicDeactivated">
            <app-invitation-box [title]="'NURSE_DETAIL_INVITE_TITLE'" [description]="'NURSE_DETAIL_INVITE_DESC'" 
                                [info]="'NURSE_DETAIL_SENDCODE_NOW_DESC'" [plannedDesc]="'NURSE_DETAIL_SENDCODE_PLANNED_DESC'"
                [isNurse]="true" (onClick)="sendCode()" (onClick2)="showPlanningCodeMode()" [registrationCodes]="nurseDetail.registrationCodes"></app-invitation-box>
        </div>
        
        <!-- SUPPORT USER/ADMIN SECTION -->
        <div *appHasRole="['SUPER_ADMIN','ADMIN','SUPPORT_USER']" class="row">

            <!-- ACTIVE REQUESTS -->
            <div class="col-12 col-lg-12">
                <div class="mt-8" *ngIf="this.nurseDetail.activeRequests?.length != 0">

                    <table class="table w-5 mt-5">
                        <h5 class="col-8">{{ 'LABEL_ACTIVE_REQUESTS' | translate }}</h5>
                        <tr>
                            <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REQUEST' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REASON' | translate }}</th>
                            <th scope="col">{{ 'LABEL_USERCOMMENT' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_STATUS' | translate }}</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                        <ng-container *ngFor="let req of nurseDetail.activeRequests">
                            <tr>
                                <td scope="row">{{req.dateCreated | DateFormatPipe}}</td>
                                <td *ngIf="req.requestType == 'NurseAccountHelp'" scope="row">{{ 'REQ_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'NurseAccountHelp'" scope="row">{{ 'REQ_' + req.requestType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType == 'NurseAccountHelp'" scope="row">{{ 'REASON_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'NurseAccountHelp' && req.requestType != 'NurseAppSupport'" scope="row">{{ 'REASON_' + req.requestType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType == 'NurseAppSupport'" scope="row">
                                    <ul *ngIf="req.classification != null">
                                        <li *ngFor="let class of req.classification!.split('|')">
                                            {{ 'RI_' + class | uppercase | translate }}
                                        </li>
                                    </ul>
                                </td>
                                <td scope="row">{{req.requestDescription}}</td>
                                <td scope="row">{{ 'REQUEST_STATUS_' + req.status | uppercase | translate }}</td>
                                <td *ngIf="!nurseDetail.dateDeactivated" [class.edit-disabled]="isClinicDeactivated">
                                    <app-button
                                        *ngIf="req.status == 'New' && (req.requestType == 'NurseUnlockAndRecoverPasscode' || req.requestType == 'NurseUnlockAndRecoverPassword')"
                                        [buttonConfig]="btnConfigUnlock" (click)="unlock(req)"></app-button>
                                    <app-button *ngIf="req.status == 'New' && (req.requestType == 'NurseAppSupport' || req.requestType == 'NurseAccountHelp' && req.requestSubType == 'GenericRequest')"
                                        [buttonConfig]="btnConfigStart" (click)="startTask(req)"></app-button>
                                    <app-button
                                        *ngIf="req.status == 'InProgress' && (req.requestType == 'NurseAppSupport' || req.requestType == 'NurseAccountHelp' && req.requestSubType == 'GenericRequest')"
                                        [buttonConfig]="btnConfigResolved" (click)="resolved(req)"></app-button>
                                    <app-button
                                        *ngIf="req.status == 'New' && req.requestType == 'NurseNewRegistrationCode'"
                                        [buttonConfig]="btnConfigNewCode" (click)="newRegistrationCode(req)">
                                    </app-button>
                                    <app-button *ngIf="req.status == 'New' && req.requestType == 'NurseAccountHelp' && req.requestSubType == 'AccountDeletion'"
                                        [buttonConfig]="btnConfigDeactive" (click)="deactivateNurse(req)"></app-button>
                                </td>
                                <td [class.edit-disabled]="isClinicDeactivated">
                                    <i *ngIf="!req.isExpanded && req.status != 'New'" (click)="openComments(req)" class="fas fa-chevron-down edit_link"></i>
                                </td>
                                <td [class.edit-disabled]="isClinicDeactivated">
                                    <i *ngIf="req.isExpanded" (click)="openComments(req)" class="fas fa-chevron-up edit_link"></i>
                                </td>
                                <td *ngIf="!nurseDetail.dateDeactivated" [class.edit-disabled]="isClinicDeactivated">    
                                    <i *ngIf="req.requestType == 'NurseAppSupport' || req.requestType == 'NurseAccountHelp'" (click)="cancelRequest(req)" class="fas fa-trash-alt edit_link"></i>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8" *ngIf="req.isExpanded && req.status != 'New'">
                                    <table>
                                        <tbody>
                                            <tr scope="row">
                                                <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                                                <th scope="col">{{ 'NURSE_DETAIL_AGENT' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_STATUSHISTORY' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_COMMENTS' | translate }}</th>
                                            </tr>

                                            <tr *ngFor="let status of req.listStatus">
                                                <td>{{status.dateCreated | DateFormatPipe: true}}</td>
                                                <td>{{status.fullName}}</td>
                                                <td>{{ 'STATUS_TO_' + status.status | uppercase | translate}}</td>
                                                <td>{{status.comment}}</td>
                                            </tr>

                                            <tr scope="row">
                                                <td colspan="4">
                                                    <div class="text-right">
                                                        <button
                                                            style="background-color: white; border-color: gray; padding: 10px;"
                                                            (click)="addComment(req)">
                                                            {{ 'BTN_ADDCOMMENT' | translate }}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                        </ng-container>

                    </table>
                </div>
            </div>

            <!-- RESOLVED REQUESTS -->
            <div class="col-12 col-lg-12">
                <div class="mt-8" *ngIf="this.nurseDetail.resolvedRequests?.length != 0">

                    <table class="table w-5 mt-5">
                        <h5 class="col-8">{{ 'LABEL_RESOLVED_REQUESTS' | translate }}</h5>
                        <tr>
                            <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REQUEST' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_REASON' | translate }}</th>
                            <th scope="col">{{ 'LABEL_USERCOMMENT' | translate }}</th>
                            <th scope="col">{{ 'NURSE_DETAIL_STATUS' | translate }}</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                        <ng-container *ngFor="let req of nurseDetail.resolvedRequests">
                            <tr>
                                <td scope="row">{{req.dateCreated | DateFormatPipe}}</td>
                                <td *ngIf="req.requestType == 'NurseAccountHelp'" scope="row">{{ 'REQ_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'NurseAccountHelp'" scope="row">{{ 'REQ_' + req.requestType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType == 'NurseAccountHelp'" scope="row">{{ 'REASON_' + req.requestType + "_" + req.requestSubType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType != 'NurseAccountHelp'  && req.requestType != 'NurseAppSupport'" scope="row">{{ 'REASON_' + req.requestType | uppercase | translate }}</td>
                                <td *ngIf="req.requestType == 'NurseAppSupport'" scope="row">
                                    <ul *ngIf="req.classification != null">
                                        <li *ngFor="let class of req.classification.split('|')">
                                            {{ 'RI_' + class | uppercase | translate }}
                                        </li>
                                    </ul>
                                </td>
                                <td scope="row">{{req.requestDescription}}</td>
                                <td scope="row">{{ 'REQUEST_STATUS_' + req.status | uppercase | translate }}</td>
                                <td [class.edit-disabled]="isClinicDeactivated"
                                    ><i *ngIf="!req.isExpanded && req.status != 'New'" (click)="openComments(req)" class="fas fa-chevron-down edit_link"></i>
                                </td>
                                <td [class.edit-disabled]="isClinicDeactivated">    
                                    <i *ngIf="req.isExpanded" (click)="openComments(req)" class="fas fa-chevron-up edit_link"></i>
                                </td>
                                <td *ngIf="!nurseDetail.dateDeactivated" [class.edit-disabled]="isClinicDeactivated">    
                                    <i *ngIf="req.requestType == 'NurseAppSupport'" (click)="reopenRequest(req)" class="fas fa-undo-alt edit_link"></i>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6" *ngIf="req.isExpanded && req.status != 'New'">
                                    <table>
                                        <tbody>
                                            <tr scope="row">
                                                <th scope="col">{{ 'LABEL_DATE' | translate }}</th>
                                                <th scope="col">{{ 'NURSE_DETAIL_AGENT' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_STATUSHISTORY' | translate }}</th>
                                                <th scope="col">{{ 'LABEL_COMMENTS' | translate }}</th>
                                            </tr>

                                            <tr *ngFor="let status of req.listStatus">
                                                <td>{{status.dateCreated | DateFormatPipe: true}}</td>
                                                <td>{{status.fullName}}</td>
                                                <td>{{ 'STATUS_TO_' + status.status | uppercase | translate}}</td>
                                                <td>{{status.comment}}</td>
                                            </tr>

                                            <tr scope="row">
                                                <td colspan="4">
                                                    <div class="text-right">
                                                        <button
                                                            style="background-color: white; border-color: gray; padding: 10px;"
                                                            (click)="addComment(req)">
                                                            {{ 'BTN_ADDCOMMENT' | translate }}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                        </ng-container>

                    </table>
                </div>
            </div>
        </div>

        <br/>

        <div class="row" *ngIf="isDeactivable" [class.edit-disabled]="isClinicDeactivated">
            <div class="col-12 text-left">
                <a (click)="deactivateNurse()" class="text-right"><i class="fas fa-user-slash"></i><u class="edit_link">{{'NURSE_DETAIL_DEACTIVATENURSE' | translate }}</u></a>
            </div>
        </div>

        <div class="row" *ngIf="nurseDetail.dateDeactivated" [class.edit-disabled]="isClinicDeactivated">
            <div class="col-12 text-left">
                <a (click)="reactivateNurse()" class="text-right"><i class="fas fa-user-slash"></i><u class="edit_link">{{'NURSE_DETAIL_REACTIVATENURSE' | translate }}</u></a>
            </div>
        </div>
    </div>
</ng-template>

<app-snackbar [ShowSnack]="showSnackBar" [IconSrc]="'../../../../../../assets/icons/greenV-icon.svg'"
    [SnackText]="snackText"></app-snackbar>

<app-plan-registration-code (planSendCodeOut)="sendCode($event)"></app-plan-registration-code>