<table class="table" *ngIf="!tableConfig.noTasks">

  <thead class="thead">
    <tr class="table-header">
      <ng-container *ngFor="let header of tableConfig.headerList">
        <th [attr.data-name]="header.field">
          {{header.name}}
        </th>
      </ng-container>
      <th *ngIf="tableConfig.actions"></th>
    </tr>
  </thead>

  <tbody *ngIf="tableConfig && tableConfig.gridData && tableConfig.gridData[0]">
    <ng-container *ngFor="let record of tableConfig.gridData">
      <tr class="table-row" [style.height]="tableConfig.rowHeight">
        <td *ngFor="let header of tableConfig.headerList">

          <ng-container [ngSwitch]="header.field">

            <!-- TASK-LIST : DATE DUE-->
            <ng-container *ngSwitchCase="'dateDue'">
              <div class="due-date mb-1" [class]="dueColor(record)" id="due-date-task">
                {{getDueDateWarning(record)}}
              </div>
            </ng-container>

            <!-- TASK-LIST : TASK TYPE-->
            <ng-container *ngSwitchCase="'taskType'">
              <div class="task-type-div">
                <div class="mr-1">
                  <svg-icon [src]="getTaskIcon(record)"></svg-icon>
                </div>
                <div class="due-date mb-1">
                  <span>{{getTaskName(record)}}</span>
                </div>
              </div>

            </ng-container>

            <!-- TASK-LIST : IN CHARGE-->
            <ng-container *ngSwitchCase="'nephrologistFullName'">
              <div>
                <span class="gray">{{record[header.field]}}</span>
              </div>
              <div class="change-neph" *ngIf="record.taskType == 'PrescriptionEditSignature' || record.taskType == 'PrescriptionRenewSignature' ||
              record.taskType == 'PrescriptionSignature'" (click)="onClickButton(record, 'nephrologist', $event)" data-toggle="modal" data-target="#genericModal">
                {{'CHANGE_NEPHROLOGIST_LABEL'|translate}}
              </div>
            </ng-container>

            <!-- TASK-LIST : STATUS -->
            <ng-container *ngSwitchCase="'taskStatusForLabel'" class="notes-table">
              <div *ngIf="dueColor(record)!='red'">
                <ng-container [ngSwitch]="record['taskStatusForLabel']">
                  <div *ngSwitchCase="'InProgress'">
                    <svg-icon class="notes-bookmark bm-purple" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                    <div class="notes">
                      <span class="task-status task-status-progress">{{'LABEL_TASK_IN_PROGRESS'|translate}}<br></span>
                    </div>
                  </div>
                  <div *ngSwitchCase="'Pending'" class="notes-div">
                    <svg-icon class="notes-bookmark bm-orange" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                    <div class="notes">
                      <span class="task-status task-status-pending">{{'LABEL_TASK_PENDING'|translate}}<br></span>
                    </div>
                  </div>
                  <div *ngSwitchCase="'New'">
                    <svg-icon class="notes-bookmark bm-blue" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                    <div class="notes">
                      <span class="task-status task-status-new">{{'LABEL_TASK_NEW'|translate}}<br></span>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="dueColor(record)=='red'">
                <svg-icon class="notes-bookmark bm-red" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                <div class="notes">
                  <span class="task-status task-status-priority">{{'PRIORITY_TASK_LABEL'|translate}}<br></span>
                </div>
              </div>
            </ng-container>

            <!-- DEFAULT -->
            <ng-container *ngSwitchDefault>
              <span *ngIf="isDate(record[header.field])">{{record[header.field] | DateFormatPipe}} </span>
              <span *ngIf="!isDate(record[header.field])">{{record[header.field]}} </span>
            </ng-container>

          </ng-container>
        </td>

        <td *ngIf="tableConfig.actions" class="button-table">
          <div class="button-wrap">
            <span *ngFor="let buttonConfig of tableConfig.recordButtonConfigs.get(record.id)" class="table-btn-wrapper">
              <app-button [buttonConfig]="buttonConfig" (onClick)="onClickButton(record, buttonConfig.type, $event)">
              </app-button>
              <div *ngIf="buttonConfig.type=='info' && tableConfig.recordInfoConfigs" style="position: relative;">
                <app-info-box
                  [infoBoxConfig]="tableConfig.recordInfoConfigs.get(record.id)"></app-info-box>
              </div>

            </span>
          </div>
        </td>
      </tr>
    </ng-container>

  </tbody>
</table>

<div class="empty-table" *ngIf="this.tableConfig.gridData == undefined || !this.tableConfig.gridData[0] || !this.tableConfig || !this.tableConfig.gridData">
  <span *ngIf="tableConfig.noTasks">
    <img class="image-empty" src="../../../assets//images/no-tasks-note.png">
    <div class="message-empty">
      <p [innerHTML]="'EMPTY_TASK_LIST_NOTE' | translate"></p>
    </div>
  </span>
  <span *ngIf="!tableConfig.noTasks">
    <img class="image-empty" src="../../../assets//images/no-tasks-note.png">
    <div class="message-empty">
      <p [innerHTML]="'SEARCHING_NO_TASKS_MESSAGE' | translate"></p>
    </div>
  </span>

</div>
