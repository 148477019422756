<ngx-smart-modal #quitModal identifier="quitModal" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-header header-edit">
    <div class="container-fluid">
        <div class="text-center">
            <p class="modal-title mt-4">{{'QUIT_FORM_MODAL_TITLE' | translate}}</p>
            <p class="modal-description mt-4">{{'QUIT_FORM_MODAL_DESC' | translate}}</p>
        </div>
    </div>
</div>
<div class="modal-body mt-2 text-center d-flex flex-row justify-content-around">
    <app-button (click)="onCancel()" [buttonConfig]="cancelBtnConfig"></app-button>
    <app-button (click)="onClick()" [buttonConfig]="quitBtnConfig"></app-button>
</div>
</ngx-smart-modal>