import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-plan-registration-code',
  templateUrl: './plan-registration-code.component.html',
  styleUrls: ['./plan-registration-code.component.css']
})
export class PlanRegistrationCodeComponent implements OnInit {
  @Input() title: any;
  @Input() description: any;
  @Input() isNurse: any;
  @Output() planSendCodeOut : EventEmitter<any> = new EventEmitter<any>();

  constructor( private translate : TranslateService,
                private fb: FormBuilder,
                private ngxModal : NgxSmartModalService) { }

  ngOnInit(): void {
  }

  formPlanDate: FormGroup = this.fb.group({
    datePlannedSend:[null, Validators.required]
  })

  inputValues(event: any){
    this.formPlanDate.controls[event.target.name].setValue(event.target.value);
    this.formValidation(this.formPlanDate);
  }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.btnConfigConfirm.disabled = !form.valid;
      });
    this.btnConfigConfirm.disabled = !form.valid;
  }
  
  btnConfigConfirm = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_CONFIRM'),
    width: '30px',
    height: '30px',
    disabled: true
  }

  datePickerConfig = {
    labelName: this.getTranslation('NURSE_DETAIL_SELECT_PLANNED_DATE'), 
    optional: false, 
    inputName: 'datePlannedSend', 
    minDate: new Date(Date.now() + 24*60*60*1000),
    class: 'm-auto'
  }


  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  planSendCode(){
    this.ngxModal.getModal('planRegistrationCodeModal').close();
    let datePlanned : Date = this.formPlanDate.controls['datePlannedSend'].value;
    this.planSendCodeOut.emit({isNurse: this.isNurse, datePlanned: datePlanned});
  }
}
