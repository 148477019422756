import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticateByIdPassCodeRS, AuthenticateByLoginPasswordRS, UserIdentityGetLoggedUserRS } from './client.service';

@Injectable()
export class JwtService {

  logoutSubject: Subject<any>;
  constructor(){
    this.logoutSubject = new Subject<any>();
  }

  getCurrentToken(): String{
    return window.sessionStorage['accessToken'];
  }

  saveToken(token: AuthenticateByLoginPasswordRS) {
    window.sessionStorage['accessToken'] = token.userAuthEntity?.accessToken;
    window.sessionStorage['refreshToken'] = token.userAuthEntity?.refreshToken;
  }

  destroyToken() {
    window.sessionStorage.removeItem('accessToken');
    window.sessionStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('currentUser');
  }


  saveCurrentUser(user: UserIdentityGetLoggedUserRS) {
    window.sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  getCurrentUser(){
    if(window.sessionStorage.getItem('currentUser'))
      return JSON.parse(window.sessionStorage.getItem('currentUser') || '');
  }

  userExist(){ return window.sessionStorage.getItem('currentUser') !== null;}

  sessionExist():boolean{
    if(window.sessionStorage.length>2){
      return true;
    }
    return false;
  }

  removeUser(){
    window.sessionStorage.removeItem('currentUser');
  }

  sendToLogout(){
    this.logoutSubject.next(true);
  }

}
