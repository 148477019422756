import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AuthenticationService, BackOfficeSupportRequestView, BackOfficeSupportUserView, ClientService, Country, ISupportRequestGetAllBackOfficeRQ, SupportRequestGetAllBackOfficeRQ } from 'src/app/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css']
})
export class RequestListComponent implements OnInit {

  countryList! : Country[];

  requestList! : BackOfficeSupportRequestView[];  
  statusList: Array<MultiSelectItem> = [
    { item_id:"New", item_text: this.getTranslation("REQUEST_STATUS_NEW") },
    { item_id:"InProgress", item_text: this.getTranslation("REQUEST_STATUS_INPROGRESS") },
    { item_id:"Resolved", item_text: this.getTranslation("REQUEST_STATUS_RESOLVED") }];

  selectedStatus: Array<MultiSelectItem> = [];

  appTypeList: Array<MultiSelectItem> = [
    { item_id:"MyOrders App", item_text: "MyOrders App" },
    { item_id:"OrderManager App", item_text: "OrderManager App" },
    { item_id:"OrderManager Web", item_text: "OrderManager Web" }];

  selectedTypes: Array<MultiSelectItem> = [];

  loading: boolean = true;
  record?: any;
  totalItemsRequest: number = 0;
  
  showSnackBar?: boolean;
  snackText?: string;

  anyResult: boolean = false;

  searchConfig = { placeholder: this.getTranslation('SEACH_PLH_USER'), value: '' }
  searchConfig2 = { placeholder: this.getTranslation('SEACH_PLH_ID_REQUEST'), value: '' }

  placeholderStatus = this.getTranslation('LABEL_STATUS');

  tableConfig =  {
    actions: true,
    gridData: this.requestList,
    headerList: [
      {name: this.getTranslation('LABEL_DATE'), field: 'dateCreated', sortable: true, isDate: true},
      {name: this.getTranslation('LABEL_APPTYPE'), field: 'appType', sortable: true},
      {name: this.getTranslation('LABEL_NAME'), field: 'fullName', sortable: true},
      {name: this.getTranslation('LABEL_REQUEST_TYPE'), field: 'requestType', sortable: true},
      {name: this.getTranslation('LABEL_STATUS'), field: 'status', sortable: true},
    ],
    recordButtonConfigs: new Map(),
    pagination: {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalItemsRequest
    },
    sort:{
      field: "dateCreated",
      direction: "desc"
    },
    notes: false
  };

  autocompleteConfig = {
    name: this.getTranslation('FILTER_COUNTRY'),
    attr: 'timeZoneName' as const,
    array: this.countryList,
    numberToShow: 6,
    value : null as any,
    inputName : 'deliveryCountryRegionCode',
    class: 'form-control autocomplete-input',
    disabled: false,
    filterIsVisible: true,
    placeholder: this.getTranslation('LABEL_COUNTRY'),
  }

  selectedItems: MultiSelectItem[] = [];
  dropdownSettings:IDropdownSettings={};
  
  constructor(
    private clientService: ClientService,
    private authService: AuthenticationService,
    private router : Router,
    private translate: TranslateService,
    private utilsService: UtilsService,
  ) { }


  ngOnInit(): void {

    this.tableConfig = this.getTableConfig();

    this.currentPage = 1;

    this.getFiltersBySession();
    
    this.getCountryList();
    
    this.getRequestList();

    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: false
    };

    this.utilsService.checkReleaseNote();
  }

  filterName?: string = '';
  filterRequest?: string = '';  
  filterCountry?: string = '';

  orderDirection?: string = "DateCreated_desc";
  currentPage: number = 1;

  getUpdatedRequestList(): any{
    return this.getRequestList;
  }

  getTableConfig() {
    return this.tableConfig;
  }

  GoToRequestDetail(event: any) {
    if(event.action == 'edit'){
      if(event.record.idPatient != null)
        this.router.navigate(['/patients/detail', event.record.idPatient],{ queryParams:{ 'fromRequest': true } });
      if(event.record.idNurse != null)
        this.router.navigate(['/nurses/detail', event.record.idNurse], { queryParams:{ 'fromRequest': true } });

      this.saveFilters();
    }
  }
 
  getRequestList() {

    let filterStatus: string[] = [];
    this.selectedStatus.forEach(element => {
      filterStatus.push(element.item_id);
    });

    let filterAppType: string[] = [];
    this.selectedTypes.forEach(element => {
      filterAppType.push(element.item_id);
    });
    let input: ISupportRequestGetAllBackOfficeRQ = {
      pageIndex: this.currentPage,
      sortOrder: this.orderDirection,
      searchString: this.filterRequest ?? this.filterName,
      countryIsoCode: this.filterCountry,
      appType: filterAppType,
      status: filterStatus
    }
    this.clientService.supportRequestGetAllBackOffice(new SupportRequestGetAllBackOfficeRQ(input)).subscribe(
      response =>{

        this.requestList = response.list || [];
        this.anyResult = response.anyResult || false;
        this.tableConfig.pagination.itemsPerPage = response.pageSize || 10;
        this.tableConfig.pagination.totalItems = response.count || 0;
        this.tableConfig.pagination.currentPage = this.currentPage;
        this.tableConfig.gridData = this.requestList;
        this.bindButtons(this.tableConfig.gridData);
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  applyFilter(value:any){
    if(value.target.value){
      this.filterCountry = value.target.value.countryIsoCode;
      this.getRequestList(); 
    }
    else{
      this.filterCountry = '';
      this.getRequestList(); 
    } 
  }

  searchFilterKeyUp(value:any){
    this.filterName = value;
    this.currentPage = 1;
    this.getRequestList();
  }

  searchFilterKeyUp2(value:any){
    this.filterRequest = value;
    this.currentPage = 1;
    this.getRequestList();
  }

  orderRequestChanged(orderDirection: string){
    this.orderDirection = orderDirection;
    this.getRequestList();
  }

  pageRequestChanged(pageNumber: any){
    this.currentPage = Number.parseInt(pageNumber);
    this.getRequestList();
  }

  bindButtons(requestList: BackOfficeSupportRequestView[]){
    this.tableConfig.recordButtonConfigs = new Map();
    for(let request of requestList){
      request.requestType = (request.requestType != "NurseAccountHelp" && request.requestType != "PatientAccountHelp") ? 
        this.getTranslation("REQ_" + request.requestType?.toUpperCase()) : 
        this.getTranslation("REQ_" + request.requestType?.toUpperCase() + "_" + request.requestSubType?.toUpperCase());

      request.status = this.getTranslation("REQUEST_STATUS_" + request.status?.toUpperCase());

      this.tableConfig.recordButtonConfigs.set(request,[
        {
          bclass : 'btn edit-button',
          type : 'edit'
        }
      ]
    );
    }
  }


  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  refresh(): void {
    window.location.reload();
  }  

  getCountryList() {
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{
        
        response.forEach(element => {
          element.timeZoneName = this.getTranslation("COUNTRY_" + element.countryIsoCode);
          if(element.timeZoneName.startsWith("COUNTRY_")){
            element.timeZoneName = element.countryIsoCode;
          }
        });
        this.countryList = response || [];
        this.autocompleteConfig.array = this.countryList;
      } ,
      error=> console.log(error)
    );
  }

  getFiltersBySession(){
    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterName = window.localStorage.getItem('searchApplied') || '';
    this.searchConfig = { placeholder: this.searchConfig.placeholder, value: this.filterName };
    this.searchConfig = { ...this.searchConfig };

    //ASSIGN PREVIOUS SEARCH FILTER
    this.filterRequest = window.localStorage.getItem('searchApplied2') || '';
    this.searchConfig2 = { placeholder: this.searchConfig2.placeholder, value: this.filterRequest };
    this.searchConfig2 = { ...this.searchConfig2 };

    //ASSIGN PREVIOUS DROPDOWN FILTER
    this.filterCountry = window.localStorage.getItem('filterApplied') || '';
    this.autocompleteConfig.value = {
      timeZoneName: this.filterCountry,
    }
    this.autocompleteConfig = { ...this.autocompleteConfig };

    //ASSIGN PREVIOUS SORT ORDER
    this.orderDirection = window.localStorage.getItem('sortOrder') || this.orderDirection;

    //ASSIGN PREVIOUS LIST PAGE VISITED    

    this.currentPage = Number(window.localStorage.getItem('currentPage')) || 1;
  
    //ASSIGN PREVIOUS REQUEST STATUS
    this.selectedStatus = JSON.parse(window.localStorage.getItem('requestStatus') || '');

    //ASSIGN PREVIOUS REQUEST TYPES    
    this.selectedTypes = JSON.parse(window.localStorage.getItem('requestAppTypes') || '');
  }

  saveFilters(){
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied', this.filterName!);
    
    //SET PREVIOUS SEARCH FILTER
    window.localStorage.setItem('searchApplied2', this.filterRequest!);

    //SET PREVIOUS DROPDOWN FILTER
    window.localStorage.setItem('filterApplied', this.filterCountry!);

    //SET PREVIOUS SORT ORDER
    window.localStorage.setItem('sortOrder', this.orderDirection!);

    //SET PREVIOUS LIST PAGE VISITED
    window.localStorage.setItem('currentPage', this.currentPage!.toString());

    //SET PREVIOUS REQUEST STATUS
    window.localStorage.setItem('requestStatus', JSON.stringify(this.selectedStatus!));

    //SET PREVIOUS REQUEST STATUS
    window.localStorage.setItem('requestAppTypes', JSON.stringify(this.selectedTypes!));
  }
}

export class MultiSelectItem {
  item_id!: string;
  item_text!: string;
}