import { Component, OnInit, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserIdentityGetLoggedUserRS } from './core';
import { AuthenticationService } from './core/services/authentication.service';
import { TabService } from './core/services/tab.service';
import { UtilsService } from './core/services/utils.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SidebarService } from './core/services/sidebar.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Location, registerLocaleData } from '@angular/common';
import { LocaleService } from './core/services/locale.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from './core/services/language.service';
import { NavbarService } from './core/services/navbar.service';
import {environment} from '../environments/environment';
import { filter, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []
})

@Injectable({
  providedIn: 'root'
})

export class AppComponent implements OnInit{
  title = 'WebBackOffice';

  currentUser?: UserIdentityGetLoggedUserRS;
  allAvailableLanguages?: string[];
  currentUrl?: string;
  loading: boolean = true;
  mainContentOpacity: any;
  translationLoaded: boolean = false;
  viewSidebar?: BehaviorSubject<boolean> = this.sideBarService.ViewSidebar;

  environment = environment;
  subscriptions!: Subscription[]

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private tabService: TabService,
    private sideBarService: SidebarService,
    private navbarService: NavbarService,
    private locale: LocaleService,
    private cookieService: CookieService,
    private languageService: LanguageService,
  ) {
    translate.setDefaultLang('de');
  }

  ngOnInit(): void {
    let translationSubject = this.utilsService.getAllTranslationsLoadedSubject().subscribe(x => {
      this.translationLoaded = x;
    });

    // this.sideBarService.ViewSidebar.subscribe( data => {
    //   this.viewSidebar = data;
    // });

    this.authService.currentUser.subscribe(x=> {this.currentUser = x});

    // Get all available languages
    let languagesSubscription = this.utilsService.getAllAvailableLanguages().subscribe(
      (response: any) => {
        this.navbarService.updateSidebarLabel();
        this.languageService.allAvailableLanguages = response;      

        let browserLang: string = this.translate.getBrowserLang();
        
        if (this.languageService.allAvailableLanguages?.includes(browserLang)) {
          this.translate.setDefaultLang(this.translate.getBrowserLang());
        }
        else{
          this.translate.setDefaultLang('en');
        }
        
        this.preloadTranslations();
      }
    )

    this.subscriptions?.push(translationSubject, languagesSubscription);
    
    this.tabService.getStyles()?.subscribe(
      response=> this.mainContentOpacity = response.opacity
    )

    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        if (
          (events[1].urlAfterRedirects.startsWith('/nurses/detail/') ||
           events[1].urlAfterRedirects.startsWith('/nephrologists/detail/') ||
           events[1].urlAfterRedirects.startsWith('/patients/detail/')
          ) && //currents URL
          (events[0].urlAfterRedirects.startsWith('/clinics/detail/') || events[0].urlAfterRedirects == '/requests')) //previous URL
        {
          //CLEAR FILTERS
          this.utilsService.clearFilterStorage();
        }
      });

      // Clear filters if some are applied, and user refresh the page changing the url manually 
      this.router.events
      .pipe(
        filter((x) => x instanceof NavigationEnd),
        startWith(null),
        pairwise()
      )
      .subscribe((event) => {
        if(!event[0] && ((event[1] as any).url as string).includes('/clinics') ){
          this.utilsService.clearFilterStorage();
        }
      })
  }

  preloadTranslations() {
    let currentLang = this.getLangUser();

    let languageSelected = this.utilsService.allLanguages.find(z => z.isoCode?.includes(currentLang));

    this.utilsService.getAllTranslationsByLangCode(languageSelected?.isoCode || "en-GB").subscribe(res => {
      this.cookieService.set('defaultLang', languageSelected?.isoCode || "en-GB");
      this.translate.setTranslation(currentLang, JSON.parse(res), true);     
      setTimeout(()=>{
        this.utilsService.setAllTranslationsLoadedSubject(true);
        this.loading = false;
      });
    })
  }

  getLangUser(): string{
    let currentLang = this.translate.getDefaultLang();
    let currentUser = JSON.parse(window.sessionStorage?.getItem('currentUser') || '{}');
    if(currentUser && currentUser?.nurseCultureIsoCode){
      // IF LOGGED GET THE CULTURE ISO CODE
      let nurseLang = currentUser.nurseCultureIsoCode.slice(0, 2);
      if(this.languageService.allAvailableLanguages?.find(x => x == nurseLang)){
        currentLang = nurseLang;
        this.locale.registerCulture(currentUser?.nurseCultureIsoCode);

        this.utilsService.setDefaultLanguage(currentUser?.nurseCultureIsoCode);
      }
    }else{
      const defaultLangFromStorage = this.utilsService.getDefaultLanguage();
      // IF IN COOKIES GET THE LAST CULTURE ISO CODE
      if(defaultLangFromStorage){
        let nurseLang = defaultLangFromStorage.slice(0, 2);
        if(this.languageService.allAvailableLanguages?.find(x => x == nurseLang)){
          currentLang = nurseLang;
          this.locale.registerCulture(defaultLangFromStorage);
        }
      }else  if(this.languageService.allAvailableLanguages?.find(x => x == this.translate.getDefaultLang())){
        // IF BROWSER LANG GET CULTURE ISO CODE
        currentLang = this.translate.getDefaultLang();
        this.locale.registerCulture(this.translate.getDefaultLang());
      }
    }
    this.translate.use(currentLang);

    this.localeInitializer(currentLang)

    return currentLang;
  }


  //IMPORTANT --> To add new language add in Webpack include comment
  //We need to do this otherwise the app download all locales available and 
  localeInitializer(localeId: string): Promise<any> {
    return import(
      /* webpackInclude: /(it|sv|de|es|en)\.js$/ */
      `@angular/common/locales/${localeId}.js`
      ).then(module => registerLocaleData(module.default));
  }


  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub=>sub.unsubscribe());
  }

}
