<div class="content-list">
    <div *ngIf="this.loading ; else list" class="spinner-box">
        <spinner></spinner>
        <p>{{'LOADING' | translate}}</p>
    </div>
    <ng-template #list>
    
        <div class="row mt-2">
            <div class="col-6">
                <h2>{{ 'REQUEST_LIST_TITLE' | translate }}</h2>
            </div>
        </div>
        <div *ngIf="anyResult else noResultContent">
            <div class="row mt-2">
                <div class="col-lg-5 col-md-auto">
                    <app-search-field [searchConfig]="searchConfig" (searchEvent)="searchFilterKeyUp($event)" ></app-search-field>
                </div>
                <div class="col-lg-2 col-md-auto"></div>
                <div class="col-lg-5 col-md-auto">
                    <app-search-field [searchConfig]="searchConfig2" (searchEvent)="searchFilterKeyUp2($event)" ></app-search-field>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6 col-md-auto">

                    <h6>{{ 'FILTER_STATUS' | translate }}</h6>

                    <ng-multiselect-dropdown
                        class="custom-font-size"
                        placeholder="{{ 'FILTER_STATUS' | translate}}"
                        [settings]="dropdownSettings"
                        [data]="statusList"
                        [(ngModel)]="selectedStatus"
                        (onSelect)="getRequestList()"
                        (onDeSelect)="getRequestList()"
                        (onSelectAll)="getRequestList()"
                        (onDeSelect)="getRequestList()">
                    </ng-multiselect-dropdown>

                    <!--<app-autocomplete-dropdown [autocompleteConfig]="autocompleteStatusConfig" (selected)="applyStatusFilter($event)"></app-autocomplete-dropdown>-->
                </div>            
                <div class="col-lg-6 col-md-auto">     

                    <h6>{{ 'FILTER_APPTYPE' | translate }}</h6>

                    <ng-multiselect-dropdown
                        class="custom-font-size"
                        placeholder="{{ 'FILTER_APPTYPE' | translate}}"
                        [settings]="dropdownSettings"
                        [data]="appTypeList"
                        [(ngModel)]="selectedTypes"
                        (onSelect)="getRequestList()"
                        (onDeSelect)="getRequestList()"
                        (onSelectAll)="getRequestList()"
                        (onDeSelect)="getRequestList()">
                    </ng-multiselect-dropdown>

                    <!--<app-autocomplete-dropdown [autocompleteConfig]="autocompleteTypeConfig" (selected)="applyAppTypeFilter($event)"></app-autocomplete-dropdown>-->
                </div>
            </div>
            <div *isGlobalUser class="row mt-2">
                <div class="col-lg-6 col-md-auto">
                    <app-autocomplete-dropdown [autocompleteConfig]="autocompleteConfig"
                        (selected)="applyFilter($event)"></app-autocomplete-dropdown>
                </div>
            </div>
            <br/>
            <app-table [tableConfig]= "tableConfig" (orderChanged)="orderRequestChanged($event)" (onClick)="GoToRequestDetail($event)" (pageChanged)="pageRequestChanged($event)"></app-table>
        </div>
        
        <ng-template #noResultContent>
            <div class="text-center noResult">
                <svg-icon class="icon" src="../../../../../assets/icons/request-figure.svg"></svg-icon>
                <p class="alert">{{ 'REQUEST_NORESULT' | translate }}</p>
                <p class="desc">{{ 'REQUEST_NORESULT_DESC' | translate }}</p>
                <div class="col-12 text-center">
                    <a (click)="refresh()" class="text-right"><i class="fas fa-redo"></i> <u> {{'LABEL_REFRESH' | translate }}</u></a>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
