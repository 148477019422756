import { CustomMainButtonConfig } from "src/app/shared/buttons/main-button/main-button.component";
import { DatePickerConfig } from "src/app/shared/form/date-picker/date-picker.component";
import { ChipsConfig } from "src/app/shared/form/radios/chips/chips.component";
import { RadioButtonConfig, RadioButtonValues } from "src/app/shared/form/radios/simple-radiobuttons/simple-radiobuttons.component";

export class Components{

    /**
     * 
     * @param radioButtonName Hidden name usually used to store the value in the form
     * -  By default it has a ``DIVIDER`` between radios
     * -  If not provided, it has undefined as selected value
     * @param values array of {@link RadioButtonValues RadioButtonValues}
     * @param selectedValue selected value used to store the user selection or to prefill the radiobuttons
     * @param customization **WARNING**: override the above parameters if one of them is set here 
     * @returns 
     */
    static RadioButton(radioButtonName: string, values: RadioButtonValues[], selectedValue?: any, customization?: Partial<RadioButtonConfig>){
        const radio = new RadioButtonConfig();
        radio.radioButtonName = radioButtonName;
        radio.values = values
        radio.withDivider = true
        radio.selectedValue = selectedValue ?? undefined
        if(customization) Object.assign(radio, customization);
        return radio;
    }
    
    /**
    * Implementation of NgBootstap DatePicker. All the references {@link https://ng-bootstrap.github.io/#/components/datepicker/examples here}.
    * ``MANDATORY`` by default
    * @param inputName - Usually used to store the value in the form
    * @param label - Shown above the input
    * @param customization **WARNING**: override the above parameters if one of them is set here 
    * @returns 
    */
    static DatePicker(inputName: string, label: string,customization?: Partial<DatePickerConfig>){
        const dp = new DatePickerConfig();
        dp.inputName = inputName
        dp.labelName = label
        dp.value = undefined
        dp.optional = false
        dp.maxDate = undefined
        dp.minDate = undefined
        if(customization) Object.assign(dp,customization)
        return dp
    }

    /**
    * Main Button
    * @param text
    * @param disabled 
    * @param customization **WARNING**: override the above parameters if one of them is set here 
    * @returns 
    */
    static Button(text: string, disabled: boolean,customization?: Partial<CustomMainButtonConfig>){
        const btn = new CustomMainButtonConfig();
        btn.text = text;
        btn.disabled = disabled
        if(customization) Object.assign(btn,customization)
        return btn
    }
}