import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnInit {

  @Input() numberInputConfig: any;
  @Output() quantity: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  increase(){
    let input = document.querySelector("#"+this.numberInputConfig.name) as HTMLInputElement;
    let value = parseInt(input.value);
    if(input.value && value>=this.numberInputConfig.min){
      input.value = (parseInt(input.value)+this.numberInputConfig.step).toString();
    } else {
      input.value = this.numberInputConfig.min.toString();
    }
    let e = {
      target: {
        name: this.numberInputConfig.name,
        value: parseInt(input.value)
      }
    }
    this.emit(e);
  }

  decrease(){
    let input = document.querySelector("#"+this.numberInputConfig.name) as HTMLInputElement;
    let value = parseInt(input.value);
    if(input.value && value>this.numberInputConfig.min && (parseInt(input.value)-this.numberInputConfig.step)>=this.numberInputConfig.min){
      input.value = (parseInt(input.value)-this.numberInputConfig.step).toString();
    } else {
      input.value = this.numberInputConfig.min.toString();
    }
    let e = {
      target: {
        name: this.numberInputConfig.name,
        value: parseInt(input.value)
      }
    }
    this.emit(e);
  }

  emit(event: any){
    this.quantity.emit(event);
  }

}
