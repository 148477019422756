<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">

        <button class="circular-button back" (click)="quit()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <h2 class="mt-4">{{ 'CALENDAR_ORDER_DETAILS' | translate}}</h2>

        <br/>

        <form [formGroup]="formCalendarDateAdd">

            <!--FORM RESULT-->

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-input [inputConfig]="formCalendarDateInfo.input[0]" (keyup)="inputValues($event)"></app-input>
                </div> 
            </div>

            <hr/>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-date-picker (resultDate)="inputValues($event)" [datePickerConfig]="formCalendarDateInfo!.deliveryDatePickerConfig"></app-date-picker>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-date-picker (resultDate)="inputValues($event)" [datePickerConfig]="formCalendarDateInfo!.pickingDatePickerConfig"></app-date-picker>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-date-picker (resultDate)="inputValues($event)" [datePickerConfig]="formCalendarDateInfo!.dueDatePickerConfig"></app-date-picker>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-date-picker (resultDate)="inputValues($event)" [datePickerConfig]="formCalendarDateInfo!.closingWindowDatePickerConfig"></app-date-picker>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-4 col-10">
                    <app-date-picker (resultDate)="inputValues($event)" [datePickerConfig]="formCalendarDateInfo!.openingWindowDatePickerConfig"></app-date-picker>
                </div>
            </div>
            
            <div>
                <app-error-box *ngFor="let error of this.validationErrors" [text]="error"></app-error-box>
            </div>
                
            <div class="row mt-5">
                <div class="col-10 d-flex flex-row">
                    <app-button [buttonConfig]="addBtnConfig" (click)="saveCalendarDate()"></app-button>
                </div>
            </div>

            <br/>
        </form>
    </div>
</ng-template>