<div class="login-container">
    <div id="formContent">
      <!-- Icon -->
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 mt-2 mb-5">
            <h3>{{'CHANGE_PASSCODE_TITLE' | translate}}</h3>
            <p class="desc-gray" [innerHTML]="descriptionText"></p>
          </div>
        </div>
        <!-- Login Form -->
        <form (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div *ngIf='!showTemporaryTemplate' class="row">
              <div class="col-4 mb-3">
                <app-input [inputConfig]="passwordStep.currentPasswordConfig" (value)="getPasswordInput($event,'OLD_PASSWORD')"></app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-4 mb-3">
                <app-password-input [passwordInputConfig]="passwordStep.newPasswordConfig" (pwdEmit)="getPasswordInput($event,'NEW_PASSWORD')"></app-password-input>
              </div>
            </div>
            <div class="row">
              <div class="col-4 mb-4">
                <app-input [inputConfig]="passwordStep.confirmPasswordInput" (value)="getPasswordInput($event,'REPEAT_PASSWORD')"></app-input>
              </div>
            </div>

            <div class="col-12 mt-2 mb-1">
              <b>{{'CHANGE_PASSCODE_NOTE1' | translate}}</b>
              <p class="desc-gray bold">{{'CHANGE_PASSCODE_NOTE2' | translate}}</p>
            </div>

            <br/><br/>
            
            <div class="row">
              <div class="col-4">
                <app-button [buttonConfig]="changePassBTN"></app-button>
              </div>
            </div>
          </div>  
        </form>
      </div>
  
    </div>
  </div>