import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {
  title: any;
  description: any;
  info: any;
  actionToDo : any;

  constructor( private translate : TranslateService,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService) { }

  ngOnInit(): void {

    this.modalService.getModalData()?.subscribe(
      response => {
        this.title = this.getTranslation(response.title);
        this.description = this.getTranslation(response.description);
        this.info = this.getTranslation(response.info);
        this.actionToDo = response.actionToDo;
      }
    )
  }

  btnMainModal = {
    bclass : 'btn btn-primary add-patient-button',
    text : this.getTranslation('BTN_OK'),
    width: '30px',
    height: '30px'
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    if(wordYouNeedToTranslate == undefined || wordYouNeedToTranslate == "")
      return "";
    return this.translate.instant(wordYouNeedToTranslate);
  }

  closeModal(event: any){
    if(event.target.value === 'understood' || event.target.value === 'close'){
      this.ngxModal.getModal('successModal').close();
      this.actionToDo();
    }
  }
}
