
<div>
        <ng-container *ngFor="let radio of radioButtonConfig.values ; let x = index">
            <div [ngClass]="radioButtonConfig.class" class="radio-box" (click)="onChange({target:{name:radioButtonConfig.name, value: radioButtonConfig.values[x]}})">
                <!-- <input type="radio" [id]="radioButtonConfig.id + x" [name]="radioButtonConfig.name" [value]="radioButtonConfig.values[x]" [checked]="radioButtonConfig.selected === radioButtonConfig.values[x]"> -->
                <div [ngClass]="radioButtonConfig.disabled ? 'normal-circle-disabled' : 'normal-circle'">
                    <div [ngClass]="radioButtonConfig.disabled ? 'selectet-ball-disabled' : 'selectet-ball'" *ngIf="radioButtonConfig.selected === radioButtonConfig.values[x]"></div>
                </div>
                <div>
                    <label [for]="radioButtonConfig.id">{{radioButtonConfig.labels[x]}}</label>
                    <br/>
                    <label *ngIf="radioButtonConfig.descriptions" style="color: #737482;">{{radioButtonConfig.descriptions[x]}}</label>
                </div>              
            </div>

            <hr *ngIf="radioButtonConfig.divider && radioButtonConfig.divider[x]">
        </ng-container>
</div>
