import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, EntityRQ } from 'src/app/core';
import { BackOfficeCalendarDateViewCustom } from 'src/app/core/models/BackOfficeCalendarDateView-custom.model';
import { DateService } from 'src/app/core/services/date.service';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';

@Component({
  selector: 'app-calendar-date-detail',
  templateUrl: './calendar_date-detail.component.html',
  styleUrls: ['./calendar_date-detail.component.css']
})
export class CalendarDateDetailComponent implements OnInit {
  id?: string;
  idCalendar?: string;
  calendarDateDetail?: BackOfficeCalendarDateViewCustom;

  loading: boolean = true;

  showSnackBar?: boolean;
  snackText: string = this.getTranslation("SAVED_CHANGES");

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private editService: EditServiceService,
    private dateService: DateService,
    private ngxModal: NgxSmartModalService,
    private modalService: NxgCustomModalService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')?.toString(); 

    this.getCalendarDateDetail();

    if(this.editService.showEditSnackBar)
      this.ShowSnackBar();
  }

  backToList(){
    this.router.navigate(["/calendars/detail/", this.idCalendar]);
  }

  EditCalendarDate() {
    this.router.navigate(['/calendar-dates/edit', this.id]);
  }

  getCalendarDateDetail(){
    const data = new EntityRQ({ idEntity: this.id });
    this.clientService.calendarDateGet(data).subscribe(
      response =>{
        this.calendarDateDetail = BackOfficeCalendarDateViewCustom.fromJS(response, (key)=> this.getTranslation(key));
        
        this.idCalendar = response.idCalendar;
        
        this.loading = false;
      } ,
      error=> console.log(error)
    );
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  ShowSnackBar() {
    this.showSnackBar = true;
    setTimeout(() =>{
      this.showSnackBar = false;
    }, 5000);
  }

  DeleteCalendarDate(){

    this.modalService.setModalData({ 
      title: (this.calendarDateDetail?.dateDeleted) ? "CALENDAR_ORDER_RESTORE_MODAL_TITLE" : "CALENDAR_ORDER_DELETE_MODAL_TITLE",
      commentVisible: false,
      actionBtnConfig: {
        bclass: this.calendarDateDetail?.dateDeleted ? 'btn btn-primary add-patient-button' : 'btn btn-primary deactive-button',
        text: this.calendarDateDetail?.dateDeleted ? 
          this.getTranslation('CALENDAR_ORDER_RESTORE_MODAL_BTN') : this.getTranslation('CALENDAR_ORDER_DELETE_MODAL_BTN'),
        action: 'deactivate',
        width: '30px',
        height: '30px'
      },
      actionToDo: () => { 
        
        if(this.calendarDateDetail?.dateDeleted){
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.calendarDateUnDelete(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
        else{
          const data = new EntityRQ({ idEntity: this.id });
          this.clientService.calendarDateDelete(data).subscribe(
            response =>{
              this.ShowSuccessModal();
            } ,
            error=> console.log(error)
          );
        }
      }
     })
     
    this.ngxModal.getModal('requestModal').open();
  }

  ShowSuccessModal(){
    this.modalService.setModalData({ 
      title: (this.calendarDateDetail?.dateDeleted) ? "CALENDAR_ORDER_RESTORED_SUCCESSFULLY" : "CALENDAR_ORDER_DELETED_SUCCESSFULLY",
      actionToDo: () => {
        this.getCalendarDateDetail();
      }
    });
    this.ngxModal.getModal('successModal').open();
  }
}
