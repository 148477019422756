import { IBaseWeekGetRS } from "../services";

export class BaseWeekGetRSCustom implements IBaseWeekGetRS {
    year?: number;
    week?: number;
    deliveryDateStart?: Date;
    deliveryDateEnd?: Date;
    deliveryWeekLabel?: string | undefined;

    constructor(data?: IBaseWeekGetRS) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.year = _data["year"];
            this.week = _data["week"];
            this.deliveryDateStart = _data["deliveryDateStart"] ? new Date(_data["deliveryDateStart"].toString()) : <any>undefined;
            this.deliveryDateEnd = _data["deliveryDateEnd"] ? new Date(_data["deliveryDateEnd"].toString()) : <any>undefined;
        }
    }

    static fromJS(data: IBaseWeekGetRS, translate: (key: string) => string){
        const custom = new BaseWeekGetRSCustom();

        // Assign base values
        for (var property in data) {
            if (data.hasOwnProperty(property))
                (<any>custom)[property] = (<any>data)[property];
        }

        custom.deliveryWeekLabel = this.StringFormat(translate("CALENDAR_BASE_WEEK_FORMAT"), data?.week!.toString(), 
                                                data?.deliveryDateStart!.toLocaleDateString(),
                                                data?.deliveryDateEnd!.toLocaleDateString());
        
        return custom;       
    }

    static StringFormat = (str: string, ...args: string[]) =>
        str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["year"] = this.year;
        data["week"] = this.week;
        data["deliveryDateStart"] = this.deliveryDateStart ? this.deliveryDateStart.toISOString() : <any>undefined;
        data["deliveryDateEnd"] = this.deliveryDateEnd ? this.deliveryDateEnd.toISOString() : <any>undefined;
        return data;
    }
}