<div *ngIf="tableConfig.gridData != undefined && tableConfig.gridData.length > 0">
  <div style="overflow-x:auto;">

    <table class="table compareTable">

      <thead class="thead" >
      <tr class="table-header">
        <th></th>
        <ng-container *ngFor="let header of columns">
          <th>
            <span>
              {{header}}
            </span>
          </th>
        </ng-container>
        
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let record of tableConfig.gridData | paginate: tableConfig.pagination; let i=index" (click)="selectRecord(record, $event)" [class.active]="isRecordSelected(record)">
        <td>{{i}}</td>
        <ng-container *ngFor="let header of columns">
          <td nowrap *ngIf="i == 0">
            <span [innerHtml]="record[header]">{{record[header]}} </span>
          </td>
          <td [ngStyle]="{'background-color': !skipColumns.includes(header) && record[header] != tableConfig.gridData[i-1][header] ? 'yellow' : null}" nowrap *ngIf="i > 0">
            <span [innerHtml]="calcDiff(tableConfig.gridData[i-1][header], record[header], header)">{{calcDiff(tableConfig.gridData[i-1][header], record[header], header)}} </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>

    <pagination-controls [id]="this.tableConfig.pagination.id" *ngIf="this.tableConfig.gridData !== undefined" nextLabel="{{'BTN_NEXT' | translate}}" previousLabel="{{'BTN_PREVIOUS' | translate}}"
        (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>

  <br/>
  <app-button [buttonConfig]="compareBtnConfig" (click)="compareVersions()"></app-button>
</div>