<div *ngIf="this.loading ; else content" class="spinner-box">
    <spinner></spinner>
    <p>{{'LOADING' | translate}}</p>
</div>
<ng-template #content>
    <div class="container-fluid">
        
        <button class="circular-button back" (click)="backToList()">
            <span aria-hidden="true"><svg-icon src="../../../../../assets/icons/back-icon.svg"></svg-icon></span>
        </button>

        <h2 class="mt-4">Prescription Details</h2>

        <!-- HISTORY SYNCRONIZATIONS -->
        <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
            <ng-container ngbNavItem [ngbNavItem]="'ENTITY_VERSIONS'">
                <a ngbNavLink>
                    <app-text [text]="'PRESCRIPTION ENTITY VERSIONS'|translate" [textType]="activeTab === 'ENTITY_VERSIONS' ? 'BLUE' : 'GREY' " [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>                    
                    <app-table-history [tableConfig]="tableEntityVersionConfig"></app-table-history>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem [ngbNavItem]="'CMS_SYNCRONIZATIONS'">
                <a ngbNavLink>
                    <app-text [text]="'PRESCRIPTION CMS SYNCRONIZATIONS'|translate" [textType]="activeTab === 'CMS_SYNCRONIZATIONS' ? 'BLUE' : 'GREY'" [fontSizePx]="18" [isBold]="true"></app-text>
                </a>
                <ng-template ngbNavContent>
                    <app-table-history [tableConfig]="tableHistorySynchronizationConfig"></app-table-history>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</ng-template>