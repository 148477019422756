import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ClientService, IReleaseNoteSetRQ, ReleaseNoteSetRQ } from 'src/app/core';
import { EditServiceService } from 'src/app/core/services/edit.service';
import { NxgCustomModalService } from 'src/app/core/services/nxg-custom-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { InputComponent } from 'src/app/shared/form/input/input.component';

@Component({
  selector: 'app-release-note-edit',
  templateUrl: './release_note-edit.component.html',
  styleUrls: ['./release_note-edit.component.css']
})
export class ReleaseNoteEditComponent implements OnInit {
  id?: string;
  idCreated?: string;
  loading: boolean = false;

  countryList: ReleaseNoteCountry[] = [];

  releaseNoteTypes: ReleaseNoteType[] = [
    { id: 1, type: "WEBNURSE" },
    { id: 2, type: "NURSE" },
    { id: 3, type: "PATIENT" },
    { id: 4, type: "BACKOFFICE"}
  ];

  validationErrors: any[] = [];
  
  formReleaseNoteAdd: FormGroup = this.fb.group({
    version: [null, Validators.required],
    featuresIntroduced: [null],
    genericFeaturesContent: ['Minor improvements and bug fixes', Validators.required],
    releaseNoteType: [null, Validators.required],
    countryFeaturesContents: this.fb.array([]),
  });

  formReleaseNoteInfo={
    key: 'formReleaseNoteInfo',
    input:
    [
      { name: this.getTranslation('RELEASE_NOTE_VERSION'), optional: false, type: 'text', placeholder: '1.0001', class: 'input-form', inputName: 'version', value: this.formReleaseNoteAdd.get("version")?.value, maxLength: 64},
      { name: this.getTranslation('RELEASE_NOTE_FEATURE_INTRODUCED'), optional: false, type: 'email', placeholder: '', class: 'input-form', inputName: 'featuresIntroduced', value: this.formReleaseNoteAdd.get("featuresIntroduced")?.value, maxLength: 256 },
      { name: this.getTranslation('Content'), optional: false, type: 'textarea', placeholder: '', class: 'input-form', inputName: 'genericFeaturesContent', value: this.formReleaseNoteAdd.get("genericFeaturesContent")?.value, maxLength: 5000 },
    ],
    autocompleteReleaseNoteTypeConfig: {
      name: this.getTranslation('RELEASE_NOTE_TYPE'),
      array: this.releaseNoteTypes,
      attr: 'type' as const,
      numberToShow: 6,
      prefix: false,
      value : "WEBNURSE" as any,
      inputName : 'releaseNoteType',
      class: 'form-control autocomplete-input',
      disabled: false,
      placeholder: this.getTranslation('RELEASE_NOTE_TYPE'),
    }
  }

  addBtnConfig  = {
    bclass : 'btn normal-button-next',
    type : 'button',
    text : this.getTranslation('RELEASE_NOTE_ADD_TITLE'),
    disabled : true
  }
  
  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private ngxModal : NgxSmartModalService,
    private modalService: NxgCustomModalService,
    private editService: EditServiceService,
    private utilsService: UtilsService,
  ) { }

  formValidation(form: FormGroup){
    form.valueChanges
      .subscribe((changedObj: any) => {
        this.addBtnConfig.disabled = !form.valid;
      });
    this.addBtnConfig.disabled = !form.valid;
  }

  inputValues(event: any){

    this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_RELEASE_NOTE_ALREADY_EXISTS"));

    this.formReleaseNoteAdd.controls[event.target.name].setValue(event.target.value);
    
    if (event.target.name === 'releaseNoteType') {
      this.getLastVersionByType(event.target.value?.type);
    }
    
    this.formValidation(this.formReleaseNoteAdd);
  }

  inputCountryValues(event: any){

    this.utilsService.removeValidationError(this.validationErrors, this.getTranslation("ERROR_RELEASE_NOTE_ALREADY_EXISTS"));

    const index = (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).controls.findIndex(
      (control) => control.get("countryIsoCode")?.value == event.target.name
    );

    (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).at(index).get("resourceValue")?.setValue(event.target.value);    
    this.formValidation(this.formReleaseNoteAdd);
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  backToList(isEdited: boolean) {
    if(!this.id){
      this.router.navigateByUrl('/release-notes');
    }
    else{
      this.editService.showEditSnackBar = isEdited;
      this.router.navigate(['/release-notes/detail', this.id]);
    }
  }

  ngOnInit(): void {
    this.countryList = this.getAllCountry();
  }

  getAllCountry(): any{
    this.clientService.countryGetAllV2(true).subscribe(
      response =>{

        this.countryList = new Array<ReleaseNoteCountry>();

        response.forEach(element => {

          var country: ReleaseNoteCountry = {
            countryIsoCode: element.countryIsoCode!,
            countryName: this.getTranslation("COUNTRY_" + element.countryIsoCode),
            isChecked: false,
          }
          this.countryList.push(country);
          this.formReleaseNoteInfo.input.push({
            name: country.countryName,
            optional: false, 
            type: 'textarea', 
            placeholder: 'Content ' + country.countryIsoCode, 
            class: 'input-form', 
            inputName: country.countryIsoCode,
            value: '',
            maxLength: 5000       
          });
        });
      },
      error=> console.log(error)
    );
  }

  getLastVersionByType(releaseNoteType: string): any{
    
    if (releaseNoteType != '' && releaseNoteType != undefined)
    {
      this.clientService.releaseNoteGetLastVersionByType(releaseNoteType).subscribe(
        response =>{

          this.formReleaseNoteAdd.patchValue({
            version: response
          });

          this.formReleaseNoteInfo!.input[0].value = this.formReleaseNoteAdd.get("version")?.value;
          this.formValidation(this.formReleaseNoteAdd);
        },
        error=> console.log(error)
      );
    }
  }

  checkCountry(country: ReleaseNoteCountry){
    country.isChecked = !country.isChecked;
    
    if (country.isChecked){
      
      (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).push(this.fb.group({
        countryIsoCode: [country.countryIsoCode, Validators.required],
        resourceValue: [null, Validators.required]
      }));
    }
    else{
      const index = (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).controls.findIndex(
        (control) => control.get("countryIsoCode")?.value == country.countryIsoCode
      );
      (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).removeAt(index);
    }
  }
  
  saveReleaseNote(){
    if (this.formReleaseNoteAdd.valid) {

      //this.loading = true;
      let releaseNote: IReleaseNoteSetRQ;
      releaseNote = {
        idReleaseNote: this.id,
        releaseNoteType: this.formReleaseNoteAdd.get('releaseNoteType')?.value?.type,
        version: this.formReleaseNoteAdd.get('version')?.value,
        featuresIntroduced: this.formReleaseNoteAdd.get('featuresIntroduced')?.value,        
        genericFeaturesContent: this.formReleaseNoteAdd.get('genericFeaturesContent')?.value,
      }

      var dict: { [countryIsoCode: string] : string; } = {};
      for (var i = 0; i < (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).length; i++){
        var countryIsoCode = (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).at(i)?.get("countryIsoCode")?.value;
        var resourceValue = (this.formReleaseNoteAdd.controls["countryFeaturesContents"] as FormArray).at(i)?.get("resourceValue")?.value;
        dict[countryIsoCode] = resourceValue;
      }
      
      releaseNote.countryFeaturesContent = Object.assign({}, dict);

      this.clientService.releaseNoteSet(new ReleaseNoteSetRQ(releaseNote)).subscribe(
        response =>{

          this.loading = false;

          if(response.success){

            this.idCreated = response.idEntity;

            if(!this.id){
              this.modalService.setModalData({ 
                title: 'RELEASE_NOTE_ADD_SUCCESSFULLY',
                actionToDo: () => 
                {
                  this.router.navigate(['/release-notes/detail', this.idCreated]);
                }
              });
              this.ngxModal.getModal('successModal').open();
            }
            else{
              this.backToList(true)
            }
          }
          else{
            if(response?.errorType){
              this.utilsService.addValidationError(this.validationErrors, this.getTranslation(response?.errorType));
            }              
          }
        },
        error=> {
          this.loading = false;
          this.utilsService.addValidationError(this.validationErrors, this.getTranslation("ERROR_GENERIC"));
        }
      )
    }
  }

  quit(){
    this.modalService.setModalData({
      actionToDo: () => { this.backToList(false); }
     });
    this.ngxModal.getModal('quitModal').open();
  }
}


class ReleaseNoteType{
  id: number | undefined;
  type: string | undefined;  
}

class ReleaseNoteCountry{
  countryIsoCode!: string;
  countryName!: string;
  isChecked: boolean = false;
}