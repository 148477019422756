import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent implements OnInit {

  @Input() durationConfig : any;
  @Output() startDuration : EventEmitter<any> = new EventEmitter<any>();
  @Output() endDuration : EventEmitter<any> = new EventEmitter<any>();

  startDate?: Date;
  endDate?: Date;
  showEnd?: boolean;
  oneYearBtnConfig = {
    bclass : 'btn button-duration',
    type : 'button',
    text : this.getTranslation('BTN_PRESCRIPTION_END_ONE_YEAR'),
    disabled : false
  }
  EndOfTheYearConfig = {
    bclass : 'btn button-duration',
    type : 'button',
    text : this.getTranslation('BTN_PRESCRIPTION_END_OF_YEAR'),
    disabled : false
  }
  sixMonthsConfig = {
    bclass : 'btn button-duration',
    type : 'button',
    text : this.getTranslation('BTN_PRESCRIPTION_END_SIX_MONTHS'),
    disabled : false
  }
  oneMonthConfig = {
    bclass : 'btn button-duration',
    type : 'button',
    text : this.getTranslation('BTN_PRESCRIPTION_END_ONE_MONTH'),
    disabled : false
  }
  constructor(
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    if(this.durationConfig.datePickerConfig[0].value){
      this.startDate = this.parse(this.durationConfig.datePickerConfig[0].value);
      this.endDate = this.parse(this.durationConfig.datePickerConfig[1].value);
      this.showEnd = true;
    } else {
      this.showEnd = false;
    }
  }

  ShowSecondDatePicker(event: any){
    this.startDate=event.target.value;
    if(this.endDate && this.startDate){
      if(this.startDate!<this.endDate!){
        this.noError();
      } else {
        this.error();
      }
    }

    if(this.durationConfig.maxDuration){
      this.durationConfig.datePickerConfig[1].maxDate = {
        year: event.target.value.getFullYear() +1,
        month: event.target.value.getMonth() + 1,
        day: event.target.value.getDate()
        };
        if(this.endDate){
          let datePlusOneYear = new Date(this.startDate!.getFullYear()+1, this.startDate!.getMonth(), this.startDate!.getDate());
          if(this.endDate > datePlusOneYear){
            this.endDate = datePlusOneYear;
            this.durationConfig.datePickerConfig[1].value=this.formatDate(datePlusOneYear);
          }
        }
    }

    if(this.durationConfig.endDate){
      if(!event.target.value){
        this.showEnd = false;
        this.durationConfig.datePickerConfig[1].value='';
    } else {
        this.showEnd = true;
        this.startDate = event.target.value;
      }
    }
    this.startDuration.emit(event);
  }

  emit(event: any){
    this.endDate=event.target.value;
    if(this.startDate!<this.endDate!){
      this.endDuration.emit(event);
      this.noError();
    } else {
      this.error();
      let e = {
        target:{
          name : this.durationConfig.datePickerConfig[1].inputName,
          value : null
        }
      }
      this.endDuration.emit(e);
    }
  }

  selectEnd(time: string){
    switch (time){
      case '1 year':
        let datePlusOneYear = new Date(this.startDate!.getFullYear()+1, this.startDate!.getMonth(), this.startDate!.getDate());
        this.durationConfig.datePickerConfig[1].value=this.formatDate(datePlusOneYear);
        datePlusOneYear.setHours(2,0,0);
        this.endDate = datePlusOneYear;
        break;
      case '1 month':
        let datePlusOneMonth = new Date(this.startDate!.getFullYear(), this.startDate!.getMonth()+1, this.startDate!.getDate());
        this.durationConfig.datePickerConfig[1].value=this.formatDate(datePlusOneMonth);
        datePlusOneMonth.setHours(2,0,0);
        this.endDate = datePlusOneMonth;
        break;
      case '6 months':
        let datePlusSixMonths = new Date(this.startDate!.getFullYear(), this.startDate!.getMonth()+6, this.startDate!.getDate());
        this.durationConfig.datePickerConfig[1].value=this.formatDate(datePlusSixMonths);
        datePlusSixMonths.setHours(2,0,0);
        this.endDate = datePlusSixMonths;
        break;
      case 'End of year':
        let endOfYear = new Date(this.startDate!.getFullYear(), 11, 31);
        this.durationConfig.datePickerConfig[1].value=this.formatDate(endOfYear);
        endOfYear.setHours(2,0,0);
        this.endDate = endOfYear;
        break;
    }
    let e = {
      target:{
        name : this.durationConfig.datePickerConfig[1].inputName,
        value : this.endDate
      }
    }
    this.emit(e);

  }

  formatDate(date: Date){
    return date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear();
  }

  getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  error(){
    this.durationConfig.datePickerConfig[0].class="error";
    this.durationConfig.datePickerConfig[1].class="error";
    this.durationConfig.errorMessage=this.getTranslation('DURATION_DATES_ERROR_MESSAGE');
  }

  noError(){
    this.durationConfig.datePickerConfig[0].class="form-control";
    this.durationConfig.datePickerConfig[1].class="form-control";
    this.durationConfig.errorMessage='';
  }

  parse(value: string) {
    if (value) {
      let date = value.split('.');
      let dateSplitted = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
      return new Date(dateSplitted.year, dateSplitted.month-1, dateSplitted.day);
    }
    return undefined;
  }

}
