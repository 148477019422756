<ngx-smart-modal #successModal identifier="successModal" class="status-modal" data-backdrop="static"
data-keyboard="false" [closable]="false" [dismissable]="false" id="st-modal">
<div class="modal-header header-edit">
    <div class="container-fluid">
        <div class="text-center">
            <div><i class="fas fa-check-circle display-4"></i></div>
            <p class="modal-title mt-4">{{title}}</p>
            <p class="modal-description mt-4" [innerHtml]="description"></p>
            <i style="color:#737482" claas="modal-info mt-4" [innerHtml]="info"></i>
        </div>
    </div>
</div>
<div class="modal-body mt-2 text-center">
    <app-button (click)="closeModal({target:{value:'close'}})" [buttonConfig]="btnMainModal" ></app-button>
</div>
</ngx-smart-modal>