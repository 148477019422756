<table class="table">

  <thead class="thead">
    <tr class="table-header">
      <ng-container *ngFor="let header of tableConfig.headerList">
        <ng-container [ngSwitch]="header.field">
          <ng-container *ngSwitchCase="'birthDate'">
            <th [attr.data-name]="header.field" [appSort]="tableConfig.gridData" data-order="desc"
              [attr.data-name]="header.field" scope="col">
              {{header.name}} <svg-icon src="../../../assets/icons/sort-icon.svg"></svg-icon>
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'dateDue'">
            <th [attr.data-name]="header.field" [appSort]="tableConfig.gridData" data-order="desc"
              [attr.data-name]="header.field" scope="col">
              <svg-icon src="../../../assets/icons/calendar-icon.svg" class="dark"></svg-icon> {{header.name}}
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="'dateDeliveryExtimated'">
            <th [attr.data-name]="header.field" [appSort]="tableConfig.gridData" data-order="desc"
              [attr.data-name]="header.field" scope="col">
              <svg-icon src="../../../assets/icons/truck-icon.svg" class="dark"></svg-icon> {{header.name}}
            </th>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <th [attr.data-name]="header.field">
              {{header.name}}
            </th>
          </ng-container>
        </ng-container>
      </ng-container>
      <th *ngIf="tableConfig.notes" class="notes-th"><span>{{'LABEL_NOTES'|translate}}</span></th>
      <th *ngIf="tableConfig.actions"></th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let record of tableConfig.gridData| paginate: tableConfig.pagination">
      <tr class="table-row" [style.height]="tableConfig.rowHeight" [class]="dueColor(record)">
        <td *ngFor="let header of tableConfig.headerList">

          <ng-container [ngSwitch]="header.field">
            <!-- PRESCRIPTION-LIST : VALIDITY-->
            <ng-container *ngSwitchCase="'validityEnd'">
              <div *ngIf="checkValidityDate(record[header.field])">
                <span class="validity">Valid until:<br></span>
                <span class="validity">{{record[header.field] | DateFormatPipe}} </span>
              </div>
              <div
                *ngIf="!checkValidityDate(record[header.field]) && !expired(record[header.field]) && record[header.field]">
                <span class="error-validity">{{'LABEL_DUE_DATE'|translate}}<br></span>
                <span class="error-validity">{{record[header.field] | DateFormatPipe}}</span>
              </div>
              <span *ngIf="expired(record[header.field])" class="error-validity">{{'LABEL_EXPIRED'|translate}}</span>
            </ng-container>

            <!-- ORDER-LIST : DATE DUE-->
            <ng-container *ngSwitchCase="'dateDue'">
              <div class="mt-1" *ngIf="!record.reviewOrderIdOrder">
                <div class="due-date mb-1" [class]="dueColor(record)"
                  *ngIf="getDueDateWarning(record) && dueColor(record)=='red'">
                  {{getDueDateWarning(record)}}
                </div>
                <span class="due-date" [class]="dueColor(record)">{{record[header.field]|date: 'EEEE, d MMMM'}}</span>
                <div class="due-date mt-1" [class]="dueColor(record)"
                  *ngIf="getDueDateWarning(record) && dueColor(record)!='red'">
                  {{getDueDateWarning(record)}}
                </div>
              </div>

              <div class="mt-1" *ngIf="record.reviewOrderIdOrder">
                <svg-icon class="clock-due" src="../../../assets/icons/clock-icon.svg"></svg-icon><br>
                <span class="due-date mt-1 gray" *ngIf="getDueDateWarning(record)">
                  {{getPatientDueDateWarning(record)}}
                </span>
              </div>
            </ng-container>

            <!-- ORDER-LIST : ORDER TYPE -->
            <ng-container *ngSwitchCase="'orderLevel'">
              <span>{{record.isFirstOrder ? ('FIRST_ORDER_LABEL'|translate) : ('ROUTINE_ORDER_LABEL'|translate)}}</span>
            </ng-container>

            <!-- ORDER-LIST : ESTIMATED DELIVERY DATE -->
            <ng-container *ngSwitchCase="'dateDeliveryExtimated'">
              <span *ngIf="isDate(record[header.field])">{{record[header.field]|date: 'EEEE, d MMMM'}} </span>
            </ng-container>

            <!-- DEFAULT -->
            <ng-container *ngSwitchDefault>
              <span *ngIf="isDate(record[header.field])">{{record[header.field] | DateFormatPipe}} </span>
              <span *ngIf="!isDate(record[header.field])">{{record[header.field]}} </span>
            </ng-container>

          </ng-container>
        </td>

        <!-- PRESCRIPTION-LIST : NOTES-->
        <td *ngIf="tableConfig.notes" class="notes-table">
          <div *ngIf="!record['isWithMissingTreatmentInfo']">
            <ng-container [ngSwitch]="record['taskStatusForLabel']">
              <div *ngSwitchCase="'InProgress'">
                <svg-icon class="notes-bookmark bm-purple" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                <div class="notes">
                  <span class="task-status task-status-progress">{{'LABEL_TASK_IN_PROGRESS'|translate}}<br></span>
                  <span>{{'NOTE_TASK_PRESCRIPTION_RENEWAL'|translate}}<br></span>
                  <span class="task-comment">{{'NOTE_TASK_NURSE_IN_CHARGE'|translate}}
                    {{record['taskNurseFullName']}}</span>
                </div>
              </div>
              <div *ngSwitchCase="'Pending'" class="notes-div">
                <svg-icon class="notes-bookmark bm-orange" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                <div class="notes">
                  <span class="task-status task-status-pending">{{'LABEL_TASK_PENDING'|translate}}<br></span>
                  <span>{{'NOTE_TASK_PRESCRIPTION_RENEWAL'|translate}}<br></span>
                  <span class="task-comment">{{'NOTE_TASK_SIGNATURE_OUTSTANDING'|translate}}</span>
                </div>
              </div>
              <div *ngSwitchCase="'New'">
                <svg-icon class="notes-bookmark bm-blue" src="../../../assets/icons/bookmark-icon.svg"></svg-icon>
                <div class="notes">
                  <span class="task-status task-status-new">{{'LABEL_TASK_NEW'|translate}}<br></span>
                  <span>{{'NOTE_TASK_PRESCRIPTION_RENEWAL'|translate}}<br></span>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="record['isWithMissingTreatmentInfo']" class="notes-div">
            <img class="notes-img" src="../../../assets/icons/error-small-icon.svg">
            <span class="missing-treatment-info" [innerHTML]="'NOTE_TASK_MISSING' | translate"></span>
          </div>
        </td>

        <td *ngIf="tableConfig.actions" class="button-table">
          <span *ngFor="let buttonConfig of tableConfig.recordButtonConfigs.get(record.id)" class="table-btn-wrapper">
            <app-button [buttonConfig]="buttonConfig" (onClick)="onClickButton(record, buttonConfig.type, $event)">
            </app-button>
            <div *ngIf="buttonConfig.type=='info' && tableConfig.recordInfoConfigs" style="position: relative;">
              <app-info-box [infoBoxConfig]="tableConfig.recordInfoConfigs.get(record.id)"></app-info-box>
            </div>
          </span>
        </td>
      </tr>
    </ng-container>

  </tbody>
</table>

<div class="empty-table"
  *ngIf="this.tableConfig.gridData == undefined || !this.tableConfig.gridData[0] || !this.tableConfig || !this.tableConfig.gridData">
  <span *ngIf="tableConfig.noRecords">
    <img class="image-empty" src="../../../assets//images/no-tasks-note.png">
    <div class="message-empty">
      <p *ngIf="tableConfig.orders" [innerHTML]="'NO_ORDERS_MESSAGE' | translate"></p>
      <p *ngIf="!tableConfig.orders" [innerHTML]="'NO_PRESCRIPTIONS_MESSAGE' | translate"></p>
    </div>
  </span>
  <span *ngIf="!tableConfig.noRecords">
    <img class="image-empty" src="../../../assets//images/no-tasks-note.png">
    <div class="message-empty">
      <p *ngIf="tableConfig.orders" [innerHTML]="'SEARCHING_NO_ORDERS_MESSAGE' | translate"></p>
      <p *ngIf="!tableConfig.orders" [innerHTML]="'SEARCHING_NO_PRESCRIPTIONS_MESSAGE' | translate"></p>
    </div>
  </span>

</div>

<pagination-controls *ngIf="this.tableConfig.gridData != undefined && this.tableConfig.gridData[0]"
  nextLabel="{{'BTN_NEXT' | translate}}" previousLabel="{{'BTN_PREVIOUS' | translate}}"
  (pageChange)="handlePageChange($event)"></pagination-controls>
